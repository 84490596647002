html {
  @apply h-full;
  scroll-behavior: smooth;
}

body {
  @apply font-montserrat min-h-full flex flex-col text-base font-medium leading-normal text-blue-dark;
}

#app {
  @apply min-h-full relative overflow-hidden;
}

.page {
  @apply w-full bg-grey pt-4 md:pt-16;
}

.wrapper {
  @apply container mx-auto px-4;
}

.line {
  @apply border-t border-offwhite;

  &.line-grey {
    @apply border-gray-200;
  }
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}


.section-scroll-anchor {
	> div {
		@apply transform -translate-y-16
	}

	&.with-menu {
		> div {
			@apply transform -translate-y-40
		}
	}
}

// Scrollbars
.scrollable {
  @apply overflow-y-auto pr-4;
}
.scrollable::-webkit-scrollbar {
  @apply w-[0.4rem] transition-all duration-1000 ease-in-out;
}
.scrollable::-webkit-scrollbar-thumb {
  @apply w-[0.4rem] bg-white rounded-xl;
}
.scrollable::-webkit-scrollbar-track {
  @apply bg-transparent rounded-xl border;
}
.scrollable.mobile::-webkit-scrollbar {
  @apply w-[0.4rem];
}

.scrollable-blue {
  @apply overflow-y-auto pr-4;
}
.scrollable-blue::-webkit-scrollbar {
  @apply w-[0.4rem] h-[0.4rem] transition-all duration-1000 ease-in-out;
}
.scrollable-blue::-webkit-scrollbar-thumb {
  @apply w-[0.4rem] h-[0.4rem] bg-blue-dark rounded-xl;
}
.scrollable-blue::-webkit-scrollbar-track {
  @apply bg-escgrey-midlight rounded-xl border;
}
.scrollable-blue.mobile::-webkit-scrollbar {
  @apply w-[0.4rem] h-[0.4rem];
}