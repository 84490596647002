// The site wide search buttn that sits in the top header bar.
.site-search-btn {
  @apply relative w-36 h-8 bg-white text-blue-dark text-sm text-left ml-5 mr-2 pl-10 rounded-md;

  .site-search-btn__icon {
    @apply absolute left-2.5 w-5;
  }
}

// The site wide search that pops up in the top header bar.
.site-search {
	@apply relative text-blue-dark h-16 flex items-center mb-4;

	// The Search Box
	.site-search__container {
    @apply relative flex items-center w-full h-16 rounded-full shadow-sm;

		.site-search__icon {
			@apply absolute left-5 w-5;
		}
    .site-search__clear {
      @apply absolute right-0 w-20 h-full flex justify-center items-center text-sm cursor-pointer;
    }
		.site-search__input {
			@apply w-full h-full pl-14 pr-20 text-sm text-blue-dark bg-white outline-none rounded-b-lg;
		}

  }
}

.site-search-results-ko-layer {
	@apply fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70 z-50;
}

// Results
.site-search-results {
  @apply fixed top-0 flex flex-col justify-start z-50 overflow-hidden inset-x-4 md:inset-x-[92px] max-w-3xl xl:max-w-4xl;
	max-height: calc(100% - 6rem);

  // Quick Links
  .site-search-results__quick-links {
    @apply bg-white mb-4 p-6 rounded-lg;

    &:last-child {
      @apply mb-0;
    }

    .site-search-results__quick-links-title {
      @apply flex justify-between items-center text-base font-normal text-blue-dark mb-4;

      span {
        @apply text-sm ml-2.5;
      }
    }
    .site-search-results__quick-links-list {
      @apply space-y-4;

      li {
        a {
          @apply flex items-center font-bold;

          &.chevron:after {
            @apply w-5 h-5 ml-1.5;
            content: '';
            background-image: url(/images/icons/search/chevron-right.svg);
          }
          &.magnifying-glass:before {
            @apply w-4 h-4 mr-2;
            content: '';
            background-image: url(/images/icons/search/magnifying-glass.svg);
          }
        }
      }
    }
  }

	.site-search-results__results-area {
		@apply w-full flex justify-start flex-wrap flex-grow space-y-4 overflow-x-hidden;

		.site-search-results__group {
			@apply w-full bg-white p-6 rounded-lg;

			&:last-child {
				@apply mb-0;
			}

			&.half {
				@apply w-full lg:w-1/2;

        &.events {
          @apply lg:pr-4 rounded-b-none lg:rounded-l-lg lg:rounded-r-none;
        }
        &.courses {
          @apply mt-0 lg:mt-4 pt-2 lg:pt-6 lg:pl-4 rounded-t-none lg:rounded-r-lg lg:rounded-l-none;
        }
      }

      .see-all {
        @apply bg-blue-extralight block w-full text-center text-sm font-bold mt-3 p-2 rounded-md border border-gray-200;
      }

			.site-search-results__group-heading {
				@apply w-full flex flex-wrap justify-between;

				h4 {
					@apply text-sm md:text-base font-bold cursor-pointer mb-4;

          span {
            @apply font-normal;
          }
				}

				a {
					@apply text-sm md:text-base font-bold underline mb-2;
				}
			}

      // Jobs
      .site-search-job-cards {
        @apply space-y-3;

        .site-search-job-card__container {

          .site-search-job-card {
            @apply bg-grey p-3 rounded-md border border-gray-200;

            .site-search-job-card__wrapper {
              @apply flex items-center;

              .site-search-job-card__logo-container {
                @apply w-9 h-9 flex justify-center items-center bg-white rounded-md border border-gray-200 mr-4 overflow-hidden;

                .site-search-job-card__logo {
                  @apply w-full h-auto;
                }
              }

              .site-search-job-card__body {
                @apply w-full flex flex-wrap flex-1 items-center;

                .site-search-job-card__body-start {
                  @apply pr-3;

                  .site-search-job-card__title {
                    @apply text-sm font-bold text-blue-dark;
                  }

                  .site-search-job-card__group-items {
                    @apply flex flex-wrap items-center w-full;

                    li {
                      @apply text-xs font-bold text-blue-dark flex items-center my-1 mr-4;

                      p {
                        @apply text-sm font-normal;
                      }
                      span {
                        @apply font-normal;
                      }

                      .job-card__group-icon-wrapper {
                        @apply w-5 h-auto mr-0.5;

                        .job-card__group-icon {
                          @apply w-auto h-4;
                        }
                      }
                    }
                  }
                }

                .site-search-job-card__body-end {
                  @apply flex items-center w-auto text-xs font-bold text-blue-dark space-x-2;

                  p {
                    span {
                      @apply font-normal;
                    }
                  }

                  .site-search-job-card__body-end-term {
                    @apply bg-blue text-white text-xxs font-normal whitespace-nowrap px-1 rounded-sm;
                  }
                }
              }
            }
            .chevron {
              @apply w-auto h-3 rotate-180 ml-auto;

              path {
                @apply fill-current text-blue-dark;
              }
            }
          }
        }
      }

      // Organisations
      .site-search-organisation-cards {
        @apply grid md:grid-cols-2 lg:grid-cols-3 gap-3;
        
        .site-search-organisation-card-container {
          
          .site-search-organisation-card {
            @apply flex items-center h-full bg-grey p-3 rounded-md border border-gray-200;

            .site-search-organisation-card__logo-container {
              @apply w-9 h-9 flex justify-center items-center bg-white rounded-md border border-gray-200 overflow-hidden;
  
              .site-search-organisation-card__logo {
                @apply w-full h-auto;
              }
            }
  
            .site-search-organisation-card__title {
              @apply flex-1 text-sm font-bold pl-4 pr-2;
            }

            .site-search-organisation-card__group {
              @apply flex items-center ml-auto;

              .site-search-organisation-card__body-tablet {
                @apply bg-blue text-white text-xxs font-normal whitespace-nowrap mr-2 px-1 rounded-sm;
              }
            }

            .chevron {
              @apply w-auto h-3 rotate-180 ml-auto;

              path {
                @apply fill-current text-blue-dark;
              }
            }
          }
        }
      }

      // Events
      .site-search-event-card-container {
        
        .site-search-event-card {
          @apply flex items-center h-full bg-grey p-3 rounded-md border border-gray-200;

          .site-search-event-card__logo-container {
            @apply w-9 h-9 flex justify-center items-center self-start bg-white rounded-md border border-gray-200 overflow-hidden;

            .site-search-event-card__logo {
              @apply w-full h-full object-cover object-center;
            }
          }

          .site-search-event-card__body {
            @apply flex flex-1 flex-col pl-4 pr-2;

            .site-search-event-card__title {
              @apply text-sm font-bold mb-2;
            }
  
            .site-search-event-card__group {
              @apply flex items-center;

              .site-search-event-card__body-group-date {
                @apply flex items-center mr-8;

                .icon-wrapper {
                  @apply w-[14px] h-auto mr-2;

                  .icon {
                    @apply w-full h-full;
                  }
                }

                p {
                  @apply text-xs font-bold;
                }
              }              
              .site-search-event-card__footer-price {
                @apply text-sm font-bold;
              }
            }
          }

          .chevron {
            @apply w-auto h-3 rotate-180 ml-auto;

            path {
              @apply fill-current text-blue-dark;
            }
          }
        }
      }

      // Programmes & Courses
      .site-search-course-program-card-container {
        
        .site-search-course-program-card {
          @apply flex items-center h-full bg-grey p-3 rounded-md border border-gray-200;

          .site-search-course-program-card__logo-container {
            @apply w-9 h-9 flex justify-center items-center self-start bg-white rounded-md border border-gray-200 overflow-hidden;

            .site-search-course-program-card__logo {
              @apply w-full h-full object-cover object-center;
            }
          }

          .site-search-course-program-card__body {
            @apply flex flex-1 flex-col pl-4 pr-2;

            .site-search-course-program-card__title {
              @apply text-sm font-bold mb-2;
            }
  
            .site-search-course-program-card__group {
              @apply flex items-center;

              .site-search-course-program-card__body-group-date {
                @apply flex items-center mr-8;

                .icon-wrapper {
                  @apply w-[14px] h-auto mr-2;

                  .icon {
                    @apply w-full h-full;
                  }
                }

                p {
                  @apply text-xs font-bold;
                }
              }              
              .site-search-course-program-card__footer-price {
                @apply text-sm font-bold;
              }
            }
          }

          .chevron {
            @apply w-auto h-3 rotate-180 ml-auto;

            path {
              @apply fill-current text-blue-dark;
            }
          }
        }
      }

      // Resources
      .site-search-resource-cards {
        @apply space-y-3;

        .site-search-resource-card-container {

          .site-search-resource-card {
            @apply flex items-center w-full bg-grey p-3 rounded-md border border-gray-200;

            .site-search-resource-card__logo-container {
              @apply w-9 h-9 flex justify-center items-center bg-white rounded-md border border-gray-200 overflow-hidden;

              .site-search-resource-card__logo {
                @apply w-full h-full object-cover object-center;
              }
            }

            .site-search-resource-card__title {
              @apply max-w-[60%] text-sm font-bold text-blue-dark pl-4 pr-2;
            }

            .site-search-resource-card__tablet-group {
              @apply flex items-center space-x-2;

              .site-search-resource-card__tablet {
                @apply bg-escgrey-dark text-white text-xxs font-normal whitespace-nowrap px-1 rounded-sm;
              }
            }

            .chevron {
              @apply w-auto h-3 rotate-180 ml-auto;

              path {
                @apply fill-current text-blue-dark;
              }
            }
          }
        }
      }

      // Collections
      .collection-cards.all {
        @apply w-[calc(100%+1rem)] -ml-2;

        .collection-card__container {
          @apply h-48 mt-0 mb-4 last:mb-0 md:my-0 px-2;

          .collection-card__title {
            @apply text-lg;
          }
        }
      }

      // Other
      .other-cards {
        @apply flex flex-wrap w-[calc(100%+1rem)] -ml-2 -mb-4;

        .other-card__container {
          @apply w-full md:w-1/3 h-48 mb-4 px-2;
      
          .other-card {
            @apply relative w-full h-full bg-no-repeat bg-center bg-cover p-2 rounded-md z-0;
      
            &:before {
              content: '';
              @apply absolute inset-0 bg-black opacity-50 rounded-md -z-1;
            }
      
            a {
              @apply flex flex-col justify-center items-center text-center w-full h-full;
      
              .other-card__title {
                @apply text-lg font-bold text-white mb-2.5;
              }
      
              .btn {
                @apply min-w-0 text-sm font-bold text-white py-1;
              }
            }
          }
        }
      }
		}
	}
}
