.org-cards {
  @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 w-full;
}

.org-card {
  @apply bg-white text-blue-dark border border-blue-dark/10 rounded-[10px];

  a {
    @apply first:flex first:flex-col first:h-full first:p-6;

    .org-card__header {
      @apply flex items-start;

      .org-card__img-container {
        @apply flex justify-center items-center w-[60px] h-[60px] bg-blue-dark/5 rounded-[10px] overflow-hidden;

        .org-card__img {
          @apply w-full h-auto;
        }
      }

      .org-card__title {
        @apply flex-1 text-2xl font-bold px-4;
        word-break: break-word;

				.org-card__title-reviews {
					@apply w-full text-base flex justify-start items-center;

					.org-card__title-reviews-icon {
						@apply w-4 h-4 mr-2;
					}
				}
      }

      .icon.heart {
        @apply w-5 h-auto;
      }

			.btn-small {
				@apply mt-0;
			}
    }

    .org-card__body {
      @apply flex flex-col flex-1 mt-6;

      .org-card__stats {
        @apply flex flex-wrap justify-between items-center;

        .org-card__stats-item {
          @apply flex justify-center items-center text-xs font-bold mb-2;

          .org-card__stats-item-icon {
            @apply w-5 h-auto mr-1.5;
          }

          span {
            @apply font-normal;
          }
        }
      }

			.org-card__location {
				@apply flex justify-start items-center;

				.org-card__location-icon {
					@apply mr-2;
				}
			}

      .org-card__summary {
        @apply flex-1 text-sm font-normal pt-4 pb-6;
      }

      .org-card__icons {
        @apply flex justify-between items-center mb-1;

        .org-card__icons-item {

          .org-card__icons-item-icon {
            @apply w-auto h-6;
          }
        }
      }
    }

    .org-card__footer {
      @apply flex justify-between items-center mt-4 gap-4;

      .org-card__btn--alert {
        @apply text-xs font-semibold m-0 bg-blue-light border-2 border-blue-light text-white rounded-full flex-1 py-[10px];
      }

			.org-card__btn--compare {
        @apply text-xs font-semibold m-0 border-2 border-grey-dark border-dashed text-grey-dark rounded-full flex-1 py-[10px];
      }

      .org-card__btn--roles {
        @apply flex justify-center items-center bg-blue-light text-white text-xs font-normal m-0 py-[10px] px-2 rounded-[5px];

        .org-card__icons-item-icon {
          @apply w-auto h-3 mr-2 mb-px;
        }
      }
    }

  }
}
