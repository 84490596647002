.saved-jobs__btns {
  @apply relative;
}

.saved-jobs-cards {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-4;

  .saved-jobs-card__outter {
    @apply mb-5 mr-7;
    max-width: 750px;

    &:last-child {
      @apply mb-0;
    }

    // .saved-jobs-card {
    //   @apply relative flex bg-white z-10;

    //   // input checkbox
    //   .saved-jobs-card__checkbox {
    //     @apply hidden;

    //     &:checked + .saved-jobs-card__inner {
    //       @apply transform -translate-x-48 md:-translate-x-44 xl:translate-x-0 rounded-r-md;
    //     }
    //     &:checked + .saved-jobs-card__inner + .saved-jobs-card-options .saved-jobs-card-options__container {
    //       @apply transform xl:translate-x-44;
    //     }
    //     &:checked + .saved-jobs-card__inner + .saved-jobs-card-options .saved-jobs-card-options__container .saved-jobs-card-options__btn .open {
    //       @apply hidden;
    //     }
    //     &:checked + .saved-jobs-card__inner + .saved-jobs-card-options .saved-jobs-card-options__container .saved-jobs-card-options__btn .close {
    //       @apply block;
    //     }
    //   }

    //   .saved-jobs-card__inner {
    //     @apply flex w-full bg-white z-10 rounded-l-md shadow-md transition duration-500 ease-in-out;

    //     .saved-jobs-card__header {
    //       @apply  w-24 md:w-40 flex flex-col justify-center items-center p-2 md:p-3 bg-gray-100;

    //       .saved-jobs-card__logo-container {
    //         @apply w-14 h-14 flex justify-center items-center bg-white rounded-full border border-gray-200 mb-2 overflow-hidden;

    //         .saved-jobs-card__logo {
    //           @apply w-14 h-auto border-none;
    //         }
    //       }

    //       .saved-jobs-card__orgs {
    //         @apply flex flex-col items-center;

    //         .saved-jobs-card__org1 {
    //           @apply flex items-center text-sm font-bold text-blue-dark md:mb-2;

    //           span {
    //             @apply font-normal ml-0.5;
    //           }
    //           .saved-jobs-card__org1-icon {
    //             @apply h-4 w-auto mr-0.5;
    //           }
    //         }
    //         .saved-jobs-card__org2 {
    //           @apply hidden md:block text-sm font-normal text-blue-dark underline mb-2.5 cursor-pointer;
    //         }
    //         .saved-jobs-card__org3 {
    //           @apply hidden md:block text-xs font-normal text-blue-dark bg-blue-pale p-1 px-1.5 rounded;
    //         }
    //       }
    //     }

    //     .saved-jobs-card__body {
    //       @apply flex flex-col flex-1 justify-between p-4 md:p-5;

    //       .saved-jobs-card__body-top {
    //         .saved-jobs-card__title {
    //           @apply text-sm md:text-xl font-bold text-blue-dark mb-2.5;
    //         }
    //         .saved-jobs-card__description {
    //           @apply hidden md:block flex-1 text-sm font-normal text-blue-dark mb-2.5;
    //         }
    //       }

    //       .saved-jobs-card__body-bottom {
    //         @apply flex justify-between items-center;

    //         .saved-jobs-card__footer {
    //           @apply flex flex-1 items-center justify-between;

    //           .saved-jobs-card__footer-items {
    //             @apply flex flex-wrap justify-start items-center w-full;

    //             li {
    //               @apply text-xs font-bold text-blue-dark flex items-center my-1 mr-5;

    //               &:last-child {
    //                 @apply hidden md:flex mr-0;
    //               }

    //               .saved-jobs-card__footer-item-icon {
    //                 @apply h-4 w-auto mr-1.5;
    //               }
    //             }
    //           }
    //         }

    //         .btn-bookmark {
    //           @apply w-7 h-7; 
    //         }
    //       }
    //     }
    //   }

    //   .saved-jobs-card-options {

    //     .saved-jobs-card-options__container {
    //       @apply absolute top-0 left-8 w-full h-full flex justify-between items-center bg-grey rounded-r-md z-0 transition duration-500 ease-in-out;
          
    //       .saved-jobs-card-options__body {
    //         @apply flex items-center justify-end w-full h-full border-2 border-l-0 border-r-0;

    //         .saved-jobs-card-options__body-wrapper {
    //           @apply w-auto md:w-44 flex flex-row justify-center h-full md:flex-col md:px-3.5;

    //           .btn {
    //             @apply flex justify-center items-center md:min-w-min w-24 md:w-auto text-sm md:text-base whitespace-normal md:whitespace-nowrap m-0 md:my-2 pr-5 md:pr-6 rounded-none md:rounded-md;
    //           }
    //         }
    //       }

    //       .saved-jobs-card-options__btn {
    //         @apply w-9 md:w-8 h-full flex justify-center items-center bg-pink rounded-r-md border-pink cursor-pointer;

    //         .saved-jobs-card-options_btn-icon {
    //           @apply w-4 h-auto;

    //           &.open {
    //             @apply block;
    //           }
    //           &.close {
    //             @apply hidden;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}