.quiz {
  @apply pb-28;

  .quiz__container {
    @apply container mx-auto px-4;

    .quiz__inner {
      @apply p-10 lg:px-16 xl:px-20 border-2 bg-white border-gray-300 rounded-4xl overflow-hidden;

      &.iframe {
        @apply p-0;
        height: 38rem;
      }

      .quiz__iframe {
        @apply block w-full h-full border-none bg-white;
      }

      .quiz__title {
        @apply text-3xl font-black mb-2 text-center;
      }

      .quiz__sub-title {
        @apply hidden lg:block text-xl font-black mb-2 text-center;
      }

      .quiz__main {
        @apply flex flex-col lg:flex-row-reverse items-center mt-10;

        .quiz__main-img {
          @apply w-3/4 md:w-2/4 lg:w-2/5 xl:w-1/3 max-w-max mb-10 lg:mb-0;
        }

        .quiz__main-text {
          @apply flex flex-col justify-center w-full lg:w-4/5 lg:pr-10;

          p {
            @apply mb-4;
          }
        }

        .quiz__btn {
          @apply w-full sm:w-72 bg-blue-light text-xl text-white text-center font-black leading-none uppercase my-4 mx-auto p-4 rounded-md cursor-pointer;
        }

        .quiz__outro {
          @apply text-center text-sm;
        }
      }
    }
  }
}
