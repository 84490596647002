// .home-top-opportunities-intro {
//   @apply bg-[#F6F7F8] w-full pb-4;

//   .container {
//     @apply w-full;

//     .home-top-opportunities-intro__header {
//       @apply flex flex-col lg:flex-row justify-between lg:items-center w-full md:flex-1 mb-8;

//       .home-top-opportunities-intro__header-title {
//         @apply text-2xl font-bold mb-4;
//       }
//     }

//     .home-top-opportunities-intro__intro {
//       @apply flex flex-col lg:flex-row mb-8;

//       .home-top-opportunities-intro__intro-img-container {
//         @apply w-full md:w-[21.875rem] h-auto mb-6;

//         .home-top-opportunities-intro__intro-img {
//           @apply w-full;
//         }
//       }

//       .home-top-opportunities-intro__intro-content {
//         @apply flex-1 mb-6 lg:pl-[18px] lg:pr-8;

//         .home-top-opportunities-intro__intro-title {
//           @apply mb-4;
//         }
//       }

//       .newsletter-subscribe {
//         @apply w-[362px];
//       }
//     }
//   }
// }

// .home-top-opportunities {
//   @apply relative bg-[#F6F7F8] pb-12;

//   .container {
//     @apply w-full max-h-[26rem] md:max-h-[12.5rem] overflow-hidden transition-all ease-in-out duration-700;

//     &.view-all {
//       @apply max-h-[2000px];
//     }

//     .home-top-opportunities__header {
//       @apply flex justify-between items-center mb-4;

//       .home-top-opportunities__title {
//         @apply text-2xl font-bold;
//       }
//     }

//     .top-opportunities__cards {
//       @apply grid md:grid-cols-2 lg:grid-cols-3 gap-8 w-full mb-6 pt-2;
//     }

//     .home-top-opportunities__btn-group {
//       @apply w-full text-center lg:text-left;
//     }
//   }
// }


.top10-opportunities {
  @apply bg-[#F6F7F8] w-full;

  .container {
    @apply w-full mx-auto px-4;

    .top10-opportunities-title {
      @apply text-[2.5rem] font-bold text-center mb-16;
    }
    
    // Cards
    .top10-opportunities-card {
      @apply relative grid grid-rows-[0] min-h-[136vw] sm:min-h-[678px] md:min-h-[784px] lg:min-h-[428px] bg-white mb-10 p-8 lg:py-10 lg:px-12 rounded-2xl overflow-y-hidden;
      @apply after:absolute after:bottom-0 after:left-0 after:w-full after:h-28 after:bg-gradient-to-t after:from-white after:via-white/[98%] after:rounded-b-2xl;
      transition: grid-template-rows 500ms;
      
      .top10-opportunities-card__container {

        .top10-opportunities-card__intro {
          @apply relative w-full min-h-[276px] h-[98vw] sm:h-[538px] md:h-[628px] lg:h-[17.5rem] lg:flex-1 mb-4 md:mb-8 lg:mb-4 overflow-hidden;
          @apply after:absolute after:bottom-0 after:left-0 after:w-full after:h-9 after:bg-gradient-to-t after:from-white after:via-white;

          .top10-opportunities-card__image {
            @apply float-left w-full lg:w-96 h-auto bg-[#F6F7F8] rounded-xl lg:mr-10 mb-6 lg:mb-8;

            img {
              @apply w-full h-full object-cover rounded-xl;
            }
          }

          .top10-opportunities-card__intro-title {
            @apply text-xl font-bold mb-3;
          }
        }
      }

      .top-opportunities__cards {
        @apply grid md:grid-cols-2 lg:grid-cols-3 gap-8 w-full h-auto pointer-events-none;
      }        

      .top10-opportunities-card__btn {
        @apply absolute bottom-8 left-1/2 -translate-x-1/2 w-40 z-10;
      }

      &.is-open {
        @apply grid-rows-1;
        @apply after:opacity-0 after:pointer-events-none;

        .top10-opportunities-card__container {

          .top10-opportunities-card__text {
            @apply h-auto overflow-visible;
          }

          .top10-opportunities-card__intro {
            @apply h-auto overflow-visible;
          }
        }

        .top-opportunities__cards {
          @apply pointer-events-auto;
        }

        .top10-opportunities-card__btn {
          @apply hidden;
        }
      }
    }
  }

  // Newsletter
  .top10-opportunities-newsletter {
    @apply relative flex flex-col items-center;

    .top10-opportunities-newsletter__body { 
      @apply flex max-w-[490px] w-full;
      
      .email-subscribe__input {
        @apply w-full mb-0 py-4 px-6 border rounded-l-md rounded-r-none;

        &.error {
          @apply border border-red;
        }
      }

      .email-subscribe__button {
        @apply w-36 p-4 bg-green text-white font-semibold rounded-r-md;
      }
    }

    p.error {
      @apply absolute top-16 text-red;
    }

    .top10-opportunities-newsletter__footer {
      @apply max-w-[615px] w-full flex flex-wrap items-center justify-evenly lg:justify-between mt-4;

      .avatars {
        @apply mt-7;
      }

      .arrow {
        @apply lg:order-3;  
      }

      .text {
        @apply w-full lg:max-w-[300px] text-lg text-center lg:text-left mt-6 lg:mt-8;

        p {
          @apply w-full max-w-[300px] mx-auto;
        }
      }
    }
  }
}