.modal-backdrop {
  @apply fixed inset-0 flex justify-center items-center px-4 z-50;

	.modal-dimmer {
		@apply fixed inset-0 bg-black/75 z-10;
	}

  .modal {
    @apply relative w-full max-w-screen-sm bg-white flex flex-col overflow-x-hidden shadow-md rounded-md p-9 z-20;
    min-height: 40rem;

		&.modal-auto-height {
			min-height:0;
		}

    &.modal-full {
      @apply p-0;
    }

		&.maxed {
			min-height:90vh;
		}

    &.modal-video {
      @apply min-h-0 max-w-4xl p-0 lg:pr-0 bg-transparent overflow-x-visible;

      .btn-close {
        @apply bg-white text-black text-4xl -top-12 lg:-top-8 right-0 lg:-right-8 rounded-full;
      }

      .modal-body {
        @apply bg-black rounded-xl md:rounded-2xl overflow-hidden;
      }

			&.short {
				@apply max-w-sm;
			}
    }

    .btn-close {
      @apply absolute top-4 right-4 flex justify-center items-center w-8 h-8 text-5xl outline-none cursor-pointer z-100;
    }

    .modal-header,
    .modal-body,
    .modal-footer {

      p {
        @apply mb-4;
      }
      .btn {
        @apply mb-auto;

        &:disabled {
          @apply opacity-10 cursor-not-allowed;
        }
      }
    }

    .modal-header {
      .modal-header-title {
        @apply text-3xl font-bold text-blue-dark mb-4;
      }
    }

    &> .modal-body {
      @apply flex flex-col flex-1;

      .modal-body-title {
        @apply text-lg font-bold mb-2;
      }
    }

    .modal-footer {

    }

    &> .modal-spinner {
      @apply absolute inset-0 flex justify-center items-center w-full h-full bg-white bg-opacity-90 pointer-events-none;

      .spinner {
        @for $i from 3 through 10 {
          &.wh-#{$i} {
            @apply w-#{$i} h-#{$i};
          }
        }
      }
    }

    .modal-message {
      @apply absolute inset-0 bg-white flex flex-col justify-center items-center text-center z-10 border-t-4 border-blue-light;

      .modal-message__title {
        @apply font-blackout text-2xl text-blue mt-8;

        &:after {
          content: '';
          @apply w-2/5 block border-2 border-blue-light rounded mt-2 mx-auto;
        }
      }
      .image {
        @apply flex justify-center items-center flex-1;

        img {
          @apply w-auto h-52;
        }
      }
      .modal-message__text {
        @apply font-blackout text-2xl md:text-3xl mb-6 mx-4;
      }
      .modal-message__join {
        @apply w-full bg-grey py-3;
      }
      .modal-message__login {
        @apply py-8;

        a {
          @apply font-bold underline;
        }
      }
    }

    .iframe-signin {
      @apply absolute top-0 left-0 w-full min-h-full overflow-x-hidden;
    }
  }
}
