.application {
  @apply relative bg-offwhite -mt-12 pt-16 pb-14 z-10;

  .application-header {
    @apply flex flex-col lg:flex-row justify-between items-center mb-5 lg:mb-8;

    .application-title {
      @apply text-lg lg:text-2xl font-bold text-blue-dark;
    }
    .btn {
      @apply text-sm;
    }
  }

  .application-boxes {
    @apply flex flex-col lg:flex-row items-center;

    .application-box {
      @apply relative flex lg:flex-col justify-center items-center w-full h-auto lg:h-48 xl:h-60 bg-white border-2 border-blue-dark mb-5 lg:mb-0 lg:mr-5 p-3 rounded-md opacity-30;

      &:after {
        content: '';
        @apply absolute -bottom-3.5 lg:bottom-1/2 lg:-right-6 transform rotate-90 lg:rotate-0 w-6 h-1 bg-blue-dark;
      }
      &:last-child {
        @apply mr-0;

        &:after {
          @apply hidden;
        }
      }

      .application-box-image {
        @apply h-8 lg:h-16 xl:h-24 w-auto mr-5 lg:mr-0 lg:mb-5;
      }

      .application-box-title {
        @apply text-sm font-bold text-black text-center;
      } 

      .application-box-icon {
        @apply hidden;
      }

      &.active {
        @apply opacity-100;

        .application-box-icon {
          @apply block absolute top-1/2 lg:-top-3 right-3 lg:-right-3 transform -translate-y-1/2 lg:translate-y-0 fill-current text-green-medium;

          &.rejected {
            @apply fill-current text-red-esc;
          }
        }
      }
    }
  }
}
