.wysiwyg {

  h2,h3,h4,h5,h6 {
    @apply text-base font-bold mb-5;
  }
  h3 {
    @apply text-lg;
  }
  h2 {
    @apply text-xl;
  }

  p {
    @apply mb-5;
		line-height: 1.75rem!important;

    &:last-child {
      @apply mb-0;
    }
  }

  strong, b {
    @apply font-bold;
  }

  i, em {
    @apply italic;
  }

  u {
    @apply underline;
  }

  small {
    @apply text-sm;
  }

  a {
    @apply underline cursor-pointer hover:text-blue-light transition-colors duration-75 ease-in-out;
  }

  ul {
    @apply list-disc ml-5 mb-5;

    li {
      @apply text-base mb-5 pl-2;

      &::marker {
        @apply text-blue-light text-2xl;
      }
    }
  }

  ol {
    @apply list-none mb-5;
    counter-reset: item;

    li {
      @apply mb-5 ml-10;
      counter-increment: item;

      &:before {
        @apply inline-flex justify-center items-center bg-blue-light w-7 h-7 -ml-10 mr-3 text-white font-bold rounded-full;
        content: counters(item, '.') '';
      }
    }
  }
}
