.header {
  @apply absolute top-0 left-0 w-full h-[64px] bg-blue-dark z-50;

	&.header-fixed {
		@apply fixed;
	}

  .header__container {
    @apply flex items-center w-full h-full bg-blue-dark text-white px-4;

    .header-logo__container {
      @apply w-14 h-auto;

      .header-logo {
        @apply w-full h-auto fill-current text-white;
      }
    }

    .header-nav {
      @apply hidden lg:flex justify-between items-center flex-1 h-full;

      .header-nav__left {
        @apply flex h-full;

        .header-nav__left--item {
          @apply relative hover:border-t-4 hover:border-b-4 hover:border-white hover:border-t-transparent;

          &:hover {
            > .dropdown {
              @apply flex left-[-86px];
            }
          }

          a, button {
            @apply flex items-center h-full py-4 px-4 text-[15px] xl:text-base font-normal outline-none whitespace-nowrap;

            svg {
              @apply w-4 h-4 ml-2;
            }
          }
        }
      }

      .header-nav__right {
        @apply flex items-center text-base font-normal space-x-6;

        .header-nav__right--item {
          @apply relative flex items-center;

          &:first-child {
            &:before {
              content: '';
              @apply hidden lg:inline-block w-px h-7 mr-4 bg-white;
            }
          }

          &:hover {
            .dropdown {
              @apply block left-auto right-[-76px];
            }
          }

          &.signin {

            button {
              @apply font-bold;
            }

            .dropdown {
              @apply min-w-[244px] right-0 pt-5;

              a {
                @apply font-bold;
              }
            }
          }

          a, button {
            @apply relative flex items-center h-full py-4 text-[15px] xl:text-base font-normal outline-none text-right;

            .avatar,
            .initial {
              @apply flex justify-center items-center w-8 h-8 rounded-full;
            }
            .initial {
              @apply border border-white;
            }

            svg {
              @apply w-4 h-4 ml-2;
            }
          }
        }
      }

      .dropdown {
        @apply hidden absolute top-full min-w-[460px] text-blue-dark pt-5;

        .dropdown__inner {
          @apply w-full h-full flex bg-white rounded-3xl border border-grey-medium shadow-lg;

          .dropdown__list {
            @apply flex-1 p-8 space-y-8 rounded-l-3xl;

            .dropdown__list-item {

              a {
                @apply h-auto p-0 hover:text-blue-light transition ease-out duration-200;

                .icon {
                  @apply w-14 h-14 bg-[#f3f4f3] mr-6 rounded-lg flex-none;

                  img {
                    @apply w-full h-full;
                  }
                }

                .titles {
                  @apply text-left;

                  .title {
                    @apply font-bold;
                  }
                  .subtitle {

                  }
                }
              }
            }
          }

          .dropdown-recommended {
            @apply w-[250px] bg-gray-200 p-8 rounded-r-3xl;

            p {
              @apply mb-6;
            }

            .dropdown-recommended__list {
              @apply space-y-4;

              .dropdown-recommended__list-item {
                a {
                  @apply h-auto p-0 hover:text-blue-light transition ease-out duration-200;
                }
              }
            }
          }
        }
      }
    }

    .header-nav__mobile {
      @apply relative flex flex-1 justify-end lg:hidden z-50 transition duration-500 ease-in-out;
    }
    .mobile-checkbox {
      @apply hidden;
    }
    .header-nav__mobile.open + .mobile-menu {
      @apply opacity-100 visible transition duration-500 ease-in-out xl:hidden;
    }

    .unread-messages-icon {
      @apply relative w-5 h-5 flex justify-center items-center bg-red-esc border border-white rounded-full text-xxs font-normal ml-2 mb-0.5;

      &.unread-messages-icon-avatar {
        @apply absolute top-0 left-9 mt-0.5;
      }

      p {
        @apply mt-px;
      }
    }
  }
}
