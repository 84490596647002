// The new 2023 search box which sits in the hero area and has carousels within it too.

.rainbow-search-hero {
  @apply w-full bg-white;

	&.is_fixed {
		@apply fixed top-0 left-0 w-full z-40;

		.search-hero__bg {
			.btn-scroll-top {
				@apply opacity-100 cursor-pointer pointer-events-auto;
			}
		}
	}

	.search-hero__bg {
		@apply w-[110vw] relative h-24 transition-all;
		background: repeating-linear-gradient(120deg, #ef89b2 0, #ef89b2 11.1vw, #90d0f3 11.1vw, #90d0f3 22.2vw, #936614 22.2vw, #936614 33.3vw, #b33930 33.3vw, #b33930 44.4vw, #b3772e 44.4vw, #b3772e 55.5vw, #b39f1e 55.5vw, #b39f1e 66.6vw, #309f67 66.6vw, #309f67 77.7vw, #113c51 77.7vw, #113c51 88.8vw, #7a6aad 88.8vw, #7a6aad 100vw);
		transform: translateX(-5vw);
		margin-top:-36px;

		&:before {
			content: "";
			@apply absolute inset-0 bg-black opacity-50 z-10;
		}

		&:after {
			content: "";
			@apply absolute bottom-0 z-20 w-[110vw] h-2;
			background: repeating-linear-gradient(120deg, #ef89b2 0, #ef89b2 11.1vw, #90d0f3 11.1vw, #90d0f3 22.2vw, #936614 22.2vw, #936614 33.3vw, #b33930 33.3vw, #b33930 44.4vw, #b3772e 44.4vw, #b3772e 55.5vw, #b39f1e 55.5vw, #b39f1e 66.6vw, #309f67 66.6vw, #309f67 77.7vw, #113c51 77.7vw, #113c51 88.8vw, #7a6aad 88.8vw, #7a6aad 100vw);
			transform: translateX(2vw);
		}

		.search-hero__menu {
			@apply absolute list-none flex top-1 left-1/2 -translate-x-1/2 z-30 p-0 transition-opacity opacity-0;

			li {
				@apply px-4;

				a {
					@apply text-white;
				}

				&.active {
					a {
						@apply underline;
					}
				}
			}
		}

		.btn-back-to-browse {
			@apply absolute bottom-1 left-24 xl:left-32 -translate-y-1/2 text-white cursor-pointer pointer-events-auto font-light text-lg z-50 transition-opacity hidden lg:flex items-center justify-start;

			.arrow {
				@apply w-6 h-6 mr-2 pointer-events-none;
			}
		}


		.btn-scroll-top {
			@apply absolute bottom-1 right-24 xl:right-32 -translate-y-1/2 text-white pointer-events-none font-light text-lg z-50 opacity-0 transition-opacity hidden lg:flex items-center justify-start;

			.arrow {
				@apply w-6 h-6 mr-2 pointer-events-none;
			}
		}
	}

	&.expanded {
		.search-hero__bg {
			@apply mt-0;

			.search-hero__menu {
				@apply opacity-100;
			}
		}
	}

  .search-hero__container {
    @apply w-full px-4 lg:px-8 bg-white relative flex flex-col border-b border-b-gray-200;

    form {
    	@apply relative flex items-center justify-center w-full -translate-y-1/2 pointer-events-none;

			.search-hero__search {
				@apply relative w-full h-[64px] max-w-xl p-2 pl-4 flex justify-between border-2 border-escgrey-midlight bg-white rounded-2xl pointer-events-auto;

				.search-hero__group {
					@apply flex justify-start w-full;

					.search-hero__icon {
						@apply w-6 mr-2 text-escgrey-midlight;
					}
					.search-hero__input {
						@apply bg-transparent text-sm md:text-base text-blue-dark  outline-none w-full pr-3;
					}
				}

				.search-hero__button {
					@apply bg-white text-blue-dark font-semibold px-4 py-2 border border-escgrey-midlight rounded-xl flex;

					.search-hero__button-icon {
						@apply md:hidden mx-auto w-6 h-auto;
					}

					.search-hero__button-icon path {
						@apply fill-current;
					}

					.search-hero__button-label {
						@apply hidden md:block;
					}

					.search-hero__button-count {
						@apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
					}
				}

			}

			// .search-hero__facets {
			// 	@apply w-full h-full px-6 py-1 hidden lg:grid grid-cols-3 gap-4;

			// 	.search-hero__facet {
			// 		@apply w-full h-full relative;

			// 		.search-hero__facet-button {
			// 			@apply relative w-full h-full cursor-pointer rounded-2xl border-2 border-escgrey-midlight bg-white py-2 pl-3 pr-10 text-left sm:text-sm;
			// 		}

			// 		.search-hero__facet-options {
			// 			@apply absolute z-10 mt-1 max-h-56 w-auto overflow-auto border-2 rounded-2xl bg-white py-1 text-base shadow-lg sm:text-sm;

			// 			label {
			// 				@apply cursor-pointer;

			// 				p {
			// 					@apply text-xs font-bold ml-3 block truncate
			// 				}

			// 				input {
			// 					@apply appearance-none h-5 w-5 bg-white border-2 border-escgrey-dark rounded focus:outline-none;

			// 					&:checked {
			// 						@apply bg-blue-light bg-contain bg-center bg-no-repeat border-transparent;
			// 						background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
			// 					}
			// 				}
			// 			}

			// 			.search-hero__facet-count {
			// 				@apply absolute inset-y-2 right-2 flex justify-center items-center w-5 h-5 text-xxs font-semibold rounded bg-blue-extralight text-blue-extradark;
			// 			}
			// 		}
			// 	}
			// }

			// .search-hero__filters {
			// 	.search-hero__button {
			// 		@apply relative rounded-2xl py-4 px-8 bg-blue-dark text-white text-sm font-bold	focus:outline-none cursor-pointer;

			// 		.search-hero__button-icon {
			// 			@apply md:hidden mx-auto w-6 h-auto;
			// 		}
			// 		.search-hero__button-count {
			// 			@apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
			// 		}
			// 	}
			// }
	  }

		.search-hero__carousels {
			@apply w-full grid grid-cols-1 mt-[-22px];

			&[data-count="2"] {
				@apply grid-cols-2 gap-8;
			}

			.search-hero__carousel {
				@apply w-full h-32 flex flex-col justify-between;

				h3 {
					@apply leading-5;

					&.desktop {
						@apply hidden lg:inline-block;
					}
					&.mobile {
						@apply inline-block lg:hidden text-center;
					}
				}

				.search-hero__carousel-slider {
					@apply w-full rounded-lg flex px-3 h-[100px];

          // Arrows
          .splide__arrow {
						@apply w-5 h-5 bg-white border border-escgrey-dark;

						svg {
							@apply w-4 h-4;
						}

						&.splide__arrow--prev {
							@apply -translate-x-4 lg:-translate-x-2 -translate-y-1/2;
						}

						&.splide__arrow--next {
							@apply translate-x-4 lg:translate-x-2 -translate-y-1/2;
						}

            &:disabled {
              @apply pointer-events-none opacity-10 cursor-default;
            }
					}

          // Track
          .splide__track {
            @apply w-full;

            ul.splide__list {
              @apply w-full h-full;

              li.splide__slide {
                @apply m-0 pb-1;

                > a,
                > label {
                	@apply flex flex-col justify-center items-center h-full cursor-pointer;

                	&.active {
                		@apply text-blue-light font-bold;

										&:after {
											content:"";
											@apply absolute w-full border-b-2 border-b-blue-light left-0 bottom-0;
										}
                	}

									&:hover {
										@apply text-blue;
									}

                  input[type='checkbox'] {
                    @apply hidden;
                  }

                  span[class^="ico-"] {
                    @apply text-2xl mb-2;
                  }

                	> p {
                		@apply text-center text-xs;// min-h-[48px];
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                	}
                }
              }
            }
          }

          // Pagination
          .splide__pagination {
          }
				}
			}
		}
  }
}
