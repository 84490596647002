.video-facade__container {
  @apply relative flex justify-center w-full h-auto items-center bg-[#D9D9D9] cursor-pointer;
  aspect-ratio: 16/9;

  .video-facade__img {
    @apply absolute inset-0 object-cover object-center w-full h-full z-0;
  }

  .video-facade__play-btn {
    @apply w-16 h-auto text-white z-10;
  }
}