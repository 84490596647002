.alerts {
	@apply w-full mb-6 p-4 flex flex-col items-start bg-[#f0f0f0] md:rounded-xl border;

	.alerts__header {
		@apply w-full flex items-center justify-between mb-4;

		.alerts__header-title {
			@apply relative w-full text-base font-semibold text-escgrey-mid uppercase;

			&.applied__title {
				@apply pl-0;
			}
		}

    > .btn {
      @apply m-0;
    }
	}

	.alert__info {
		@apply w-full;

		.alert__info-inner {
			@apply w-full flex items-center bg-white rounded-xl relative;

			.alert__info-icon {
				@apply w-4 h-auto mx-4 flex-none;
			}

			.alert__info-title {
				@apply w-full;
			}

			.alert__info-button-edit {
				@apply mr-4;
			}

			.alert__info-button-save {
				@apply bg-blue-light text-white rounded-r-xl px-4 whitespace-nowrap min-w-min min-h-[4.5rem];
				@apply flex items-center justify-center font-semibold;
			}

			.alert__info-button-update {
				@apply bg-green-medium text-white rounded-r-xl px-4 whitespace-nowrap min-h-[4.5rem];
				@apply flex items-center justify-center font-semibold;
			}

      .save-alert-prompt {
        @apply absolute top-full right-0 bg-blue-dark text-white mt-3 p-4 space-y-1 rounded-xl z-10;
        @apply before:absolute before:bottom-9 before:right-8 before:w-14 before:h-14 before:rotate-45 before:bg-blue-dark before:rounded-md before:pointer-events-none before:-z-1;

        p {
          @apply first:pr-8 first:mt-0;
        }

        .close {
          @apply absolute top-2.5 right-4 w-5 h-5 cursor-pointer;
        }
      }
		}
	}


	.alerts__list {
		@apply w-full flex justify-between items-end;

		.alerts__list-message {
			@apply text-sm pb-3 pr-4;
		}

		.alerts__list-buttons {
			@apply w-40 mt-4 md:mt-0 pb-1;

			.alerts__list-button-create {
				@apply w-full min-h-[42px] flex items-center justify-center text-center text-sm font-bold py-2 px-4 bg-blue-light text-white rounded-xl;
				@apply whitespace-nowrap cursor-pointer;
			}
		}
	}

	.alerts__filters {
		@apply w-full pt-4 flex justify-between items-end;

		.alerts__filters-buttons {
			@apply w-52 mt-4 md:mt-0 pb-1 flex justify-end;

			.alerts__list-button-clear {
				@apply font-semibold;
			}
		}
	}

	.alerts__list,
	.alerts__filters {
		.alerts__pills {
			@apply flex flex-wrap items-center;

			.alerts_pills-label {
				@apply inline-flex items-center py-1 px-2 rounded-full text-xs font-bold text-blue-dark pr-12;
			}

			.pill {
				@apply cursor-pointer;

				.pill-logo {
					@apply rounded-full;
					border: 1px rgba(22, 47, 71, 0.1) solid;
				}
			}
		}
	}
}
