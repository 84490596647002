.staff, .our-advisors {
  @apply pb-11;

  .section__title {
    @apply max-w-screen-xl text-3xl font-blackout text-blue-dark mx-auto mb-10 px-5;
  }

  .members {
    @apply flex flex-wrap w-full max-w-screen-xl mx-auto;

    .member {
      @apply w-full sm:w-1/2 lg:w-1/3 mb-10 px-4;
      
      .member__body {
        @apply relative flex flex-col justify-end h-96 bg-no-repeat bg-center bg-cover p-5 rounded-md z-0;

        &:before {
        content: '';
        @apply absolute inset-0 bg-black opacity-50 rounded-md -z-1; 
        }

        .member__name {
          @apply w-1/2 text-2xl font-bold text-white leading-7 mb-2.5;
        }
        .member__job-title {
          @apply text-sm font-normal tracking-normal text-white uppercase;
        }
      }

      .member__footer {
        @apply mt-2.5;

        .member__bio {
          @apply hidden;

          &:checked + .member__bio-title p:nth-child(1) {
            @apply invisible opacity-0;
          }
          &:checked + .member__bio-title p:nth-child(2) {
            @apply visible opacity-100 float-left;
          }
          &:checked + .member__bio-title + .member__footer-bio {
            @apply visible opacity-100 h-full;
          }
        }
        .member__bio-social {
          @apply inline-block float-right;

          img {
            @apply w-6 h-auto;
          }
        }
        .member__bio-title {
          @apply inline-block text-sm font-normal tracking-tight text-blue-dark uppercase cursor-pointer;

          p {
            @apply inline-block transition duration-500 ease-in-out;

            &:nth-child(2) {
              @apply invisible opacity-0;
            }
          }
        }
        .member__footer-bio {
          @apply invisible opacity-0 h-0 mt-4 transition duration-500 ease-in-out;
        }
      }
    }
  }
}
