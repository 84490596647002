.saved-searches-cards {
  @apply mb-4; //flex flex-wrap

  .saved-searches-card__outter {
    @apply relative bg-grey mr-7 z-10; //flex-1 shadow-none
    max-width: 750px;

    // input checkbox
    .saved-searches-card__checkbox {
      @apply hidden;

      &:checked + .saved-searches-card__options + .saved-searches-card {
        @apply transform -translate-x-56 md:-translate-x-80 xl:translate-x-0 rounded-r;
      }
      &:checked + .saved-searches-card__options .saved-searches-card__options-container {
        @apply transform xl:translate-x-80;
      }
      &:checked + .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn .open {
        @apply hidden;
      }
      &:checked + .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn .close {
        @apply block;
      }
    }

    .saved-searches-card__options {
      @apply absolute top-0 left-8 w-full h-full -z-1; //shadow-none

      .saved-searches-card__options-container {
        @apply w-full h-full flex bg-grey transition duration-500 ease-in-out; //rounded-r-md justify-between items-center

        .saved-searches-card__options-body {
          @apply flex items-center justify-end w-full h-full border-2 border-r-0; //border-l-0

          .saved-searches-card__options-body-wrapper {
            @apply w-auto flex h-full md:px-3; //flex-row justify-center md:flex-col

            .saved-searches-card__options-body-btns {
              @apply flex md:items-center md:justify-start;
            }
            .btn {
              @apply flex justify-center items-center md:min-w-min w-28 md:w-auto text-sm md:text-base whitespace-normal md:whitespace-nowrap m-0 md:my-3 md:px-4 rounded-none md:rounded-md;

              &:first-child {
                @apply md:mr-4;
              }
              &.btn-border--blue-light {
                @apply bg-blue md:bg-white text-white md:text-blue-light border-blue md:border-blue-light;
              }
            }
          }
        }

        .saved-searches-card__options-btn {
          @apply w-9 md:w-8 h-full flex justify-center items-center bg-pink rounded-r-md cursor-pointer;

          .saved-searches-card__options-icon {
            @apply w-4 h-auto transition delay-700 duration-300 ease-in-out;

            &.open {
              @apply block;
            }
            &.close {
              @apply hidden;
            }
          }
        }
      }
    }

    .saved-searches-card {
      @apply mb-5 p-4 bg-grey border rounded-l-md shadow-md transition duration-500 ease-in-out;

      &:last-child {
        @apply mb-0;
      }

      .saved-searches-card__inner {
        @apply flex items-center bg-white py-2.5 px-5 rounded-md;

        .saved-searches-card__title-icon-container {
          @apply w-7 h-4;

          .saved-searches-card__title-icon {
            @apply w-auto h-4;
          }
        }

        .saved-searches-card__inner-body {
          @apply flex flex-wrap flex-1 justify-between;

          .saved-searches-card__title {
            @apply text-sm font-normal text-blue-dark my-1.5;
          }

          .saved-searches-card__inner-group {
            @apply flex items-center;

            .btn {
              @apply font-normal w-20 md:w-24 m-0 ml-3 p-1;
            }
          }
        }
      }
    }
  }
}
