.modal-backdrop {
	.modal-org-compare {
		@apply absolute bottom-8 top-8 left-8 right-8 w-[calc(100%-4rem)] max-w-none bg-white flex shadow-md rounded-2xl p-9 z-20 min-h-0;

		.modal-header {
      .modal-header-title {
        @apply font-blackout pr-5;
      }

			.modal-header-subtitle {
				@apply text-xl;
			}
    }

		.btn-close {
			@apply top-6 right-6;
		}

		.modal-body {
			@apply items-start justify-start h-[calc(100%-160px)] md:h-[calc(100%-52px)];

			.table-wrap {
				@apply w-full h-full overflow-auto md:pb-4;

				&.desktop {
					.compare-table {
						&[data-cols="1"] {@apply w-[calc(205px*1)] lg:w-[calc(290px*1)];}
						&[data-cols="2"] {@apply w-[calc(205px*2)] lg:w-[calc(290px*2)];}
						&[data-cols="3"] {@apply w-[calc(205px*3)] lg:w-[calc(290px*3)];}
						&[data-cols="4"] {@apply w-[calc(205px*4)] lg:w-[calc(290px*4)];}
						&[data-cols="5"] {@apply w-[calc(205px*5)] lg:w-[calc(290px*5)];}
						&[data-cols="6"] {@apply w-[calc(205px*6)] lg:w-[calc(290px*6)];}

						tr {
							th, td {
								@apply even:bg-gray-100 p-4 border-escgrey-midlight even:border-l even:border-r text-center w-[205px] lg:w-[290px];

								&:last-child {
									@apply border-r;
								}

								&:first-child {
									@apply sticky left-0 bg-white z-100;
								}
							}

							&.compare-table__row-heading {
								td {
									@apply bg-[#ecf8f9] even:bg-[#e8f4f7];

									&.clear {
										@apply bg-white even:bg-gray-100;
									}

									&.section-heading {
										@apply font-bold text-left;
									}
								}
							}

							&.compare-table__row_top {
								th {
									@apply border-t align-top;

									&:nth-child(2) {
										@apply rounded-tl-2xl
									}

									&:first-child {
										@apply border-t-0
									}

									&:last-child {
										@apply rounded-tr-2xl
									}
								}
							}

							&.compare-table__row_bottom {
								td {
									@apply border-b;

									&:nth-child(2) {
										@apply rounded-bl-2xl
									}

									&:first-child {
										@apply border-b-0
									}

									&:last-child {
										@apply rounded-br-2xl
									}
								}
							}

						}
					}
				}

				&.mobile {
					.compare-table {
						@apply w-full;

						tr {
							th, td {
								@apply even:bg-gray-100 p-4 w-1/2 text-center;

								&:first-child {
									@apply border-l;
								}

								&:last-child {
									@apply border-r;
								}
							}

							td {
								@apply font-bold;

								span {
									@apply font-normal;
								}
							}

							&.compare-table__row-heading {
								td {
									@apply bg-[#ecf8f9];

									&.section-heading {
										@apply font-bold text-center;
									}
								}
							}

							&.compare-table__row_top {
								th {
									@apply border-t align-top;

									&:first-child {
										@apply rounded-tl-2xl;
									}

									&:last-child {
										@apply rounded-tr-2xl;
									}
								}
							}

							&.compare-table__row_bottom {
								td {
									@apply border-b;

									&:first-child {
										@apply rounded-bl-2xl;
									}

									&:last-child {
										@apply rounded-br-2xl;
									}
								}
							}
						}
					}
				}


				.compare-table {

					@apply w-auto border-separate;
					border-spacing: 0; // Not in this version of tailwind.

					tr {

						th.compare-table__main-heading {
							@apply flex items-center min-h-13;
						}

						th.compare-table__org-hero {
							.logo-img {
								@apply relative w-32 h-32 border border-escgrey-midlight rounded-lg overflow-hidden mx-auto mb-2;

								img {
									@apply absolute w-full h-full object-cover;
								}

								.overlay {
									@apply absolute z-10 w-full h-full flex flex-col justify-center items-center cursor-pointer opacity-0 transition-opacity;
									@apply before:absolute before:bg-black before:w-full before:h-full before:opacity-60 z-0;

									.icon {
										@apply text-white w-4 h-4 z-10;
									}

									div {
										@apply text-white w-full z-10;
									}
								}

								&:hover {
									.overlay {
										@apply opacity-100;
									}
								}
							}
						}


					}
				}
			}
		}
	}


}
