.team {
  @apply bg-grey;

 .team-container {
   @apply container mx-auto;

  .team__title {
    @apply font-blackout text-4xl font-normal text-blue-dark mx-7 mb-5 text-center;
  }
  .team__sub-title {
    @apply text-lg font-normal text-blue-dark mx-4 mb-7;
  }

  .team__list {
    @apply flex flex-wrap w-full mb-4;

    .team__list-item {
      @apply w-1/2 md:w-1/3 lg:w-1/4 p-4;

      .team__list-item-inner {
        @apply flex flex-col justify-center items-center mb-2.5;

        .shape {
          @apply w-0 h-0;
        }
        .team__list-item-image {
          @apply w-full h-72 bg-cover bg-no-repeat bg-center bg-white mb-5 rounded-md;
          -webkit-clip-path: url(#my-clip-path);
          clip-path: url(#my-clip-path);
        }
        .team__list-item-image-svg {
          @apply absolute w-0 h-0;
        }
        .team__list-item-name {
          @apply text-lg font-bold text-blue-dark mb-1;
        }
        .team__list-item-job-title {
          @apply text-base text-blue-dark;
        }
      }
    }
  }

 }
}