.resources__cards {
  @apply flex w-full h-full pt-5;

  .resources__card {
    @apply w-full border border-gray-50 rounded shadow-md cursor-move;

    a {
      @apply flex flex-col h-full;
    }

    .resources__header {
      @apply relative w-full h-32 p-5 bg-grey bg-center bg-cover bg-no-repeat border-b border-grey-medium rounded-t;
    }

    .resources__body {
      @apply flex flex-col justify-between flex-1 bg-white text-base font-normal text-blue-dark leading-snug text-left p-4;

      .resources__body-tags {
        @apply flex flex-wrap mt-5;

        li {
          @apply text-xxs font-bold text-grey-light uppercase no-underline mr-1.5;

          &:after {
            content: ', ';
            @apply inline-block;
          }

          &:last-child {
            &:after {
              @apply hidden;
            }
          }
        }
      }
    }
  }
}
