// .top-opportunities__cards {
//   @apply grid md:grid-cols-2 lg:grid-cols-3 gap-8 w-full mb-8 pt-2;
// }

.top-opportunities__card {
  @apply relative flex justify-between items-center w-full mb-0;

  .top-opportunities__card-number {
    @apply absolute -top-2 -left-2 flex justify-center items-center w-[25px] h-[25px] bg-blue-light text-white text-xs rounded-full;
  }

  a {
    @apply bg-[#FAFAFA] h-full flex flex-1 justify-center items-center p-[10px] border border-[#EBEBEB] shadow-none rounded-lg;

    .top-opportunities__card-avatar {
      @apply flex justify-center items-center w-[60px] h-[60px] bg-white mr-3 rounded-lg;

      .top-opportunities__card-logo-container {
        @apply flex justify-center items-center w-12 h-12 rounded-md;

        .top-opportunities__card-logo {
          @apply w-12 h-auto rounded-md;
        }
      }
    }

    .top-opportunities__card-body {
      @apply flex flex-col flex-1 h-full justify-center;

      .top-opportunities__card-title {
        @apply text-sm font-semibold text-blue-dark mb-2;
        word-break: break-word;
      }

      .top-opportunities__card-info {
        @apply flex justify-between items-center;

        .top-opportunities__card-company {
          @apply text-xs font-normal text-blue-dark;
        }
        
        .top-opportunities__card-cat {
          @apply inline-flex py-[2px] px-1.5 rounded text-white text-xs font-normal;

          &.job {
            @apply text-green bg-green-pale;
          }
          &.kickstarter,
          &.volunteer {
            @apply text-blue-light bg-blue-pale;
          }           
          &.project {
            @apply text-yellow bg-yellow-pale;
          }
          &.internship {
            @apply bg-purple-pale text-purple;
          }
          &.co-Founder, &.programme {
            @apply text-pink bg-pink-pale;
          }
        }
      }
    }

    .top-opportunities__card-like {
      @apply self-start w-4 h-full ml-4;

      .icon.heart {
        @apply transition-all ease-in-out duration-300;

        &.active {
          @apply fill-current text-red-esc;

          path {
            @apply stroke-current text-red-esc;
          }
        }
      }
    }
  }
}