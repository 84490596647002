.home-welcome {
  @apply relative bg-beige-light text-xl font-black text-blue-dark text-center;

  .line {
    @apply absolute border-0;

    &.line-1 {
      @apply right-0 top-[28rem] sm:top-[22rem] md:top-72;
    }
    &.line-2 {
      @apply left-0 top-[1050px] md:top-[830px] scale-[-1];
    }
    &.line-3 {
      @apply right-0 top-[1730px] md:top-[1370px];
    }
    &.line-4 {
      @apply left-0 top-[2130px] md:top-[1920px] scale-[-1];
    }
    &.line-5 {
      @apply right-0 top-[2870px] md:top-[2500px];
    }
  }

  .home-welcome__intro {
    @apply relative z-10;

    .home-welcome__intro-title1 {
      @apply text-lg font-medium mb-5;
    }

    .home-welcome__intro-title2 {
      @apply grid text-[2.75rem] font-extrabold leading-normal mx-auto mb-5;
      @apply bg-clip-text text-transparent bg-gradient-to-r from-[#052e47] to-[#28a6bf];
      width: fit-content;
    }

    .home-welcome__intro-title3 {
      @apply text-[1.7rem] font-medium leading-normal mx-auto mb-2;
      @apply bg-clip-text text-transparent bg-gradient-to-r from-[#328df5] to-[#27c2a9];
      width: fit-content;
    }

    p {
      @apply text-base last:mb-8;
    }
  }

  .home-welcome-cards {
    @apply text-white text-left space-y-16 mt-32;

    .home-welcome-card {
      @apply relative grid md:grid-cols-2 w-full md:min-h-[482px] bg-beige-dark/95 rounded-2xl overflow-hidden;

      .bg {
        @apply absolute;
      }

      .left {
        @apply relative pl-9 pt-16;

        h3 {
          @apply text-lg font-light mb-4;
        }

        p {
          @apply font-medium mb-4;

          strong {
            @apply text-3xl mb-4;
          }
        }
      }

      .right {
        @apply relative flex justify-center items-center;

        .grid-images {
          @apply grid grid-cols-2 gap-7 py-8;

          .image {
            @apply w-[120px] h-[120px] bg-white rounded-lg relative overflow-hidden;

						img {
							@apply absolute left-0 top-0 w-full h-full object-contain opacity-0 transition-opacity duration-500;
						}

						@for $i from 0 through 24 {
							&[data-order="#{$i}"] {
								img[data-id="#{$i}"] {
									@apply opacity-100;
								}
							}
						}
          }
        }
      }

      &:nth-child(1) {
        .bg {
          @apply top-2.5 -left-3 md:-left-40;
        }
        .left {
          @apply md:pt-[120px] w-full max-w-[430px];
        }
      }
      &:nth-child(2) {
        .left {
          @apply w-full max-w-[470px];
        }
        .right {
          .grid-images {
            a {
              @apply flex flex-col justify-center items-center w-[120px] h-[120px] bg-white rounded-lg;

              .icon {
                @apply w-12 h-12 object-contain;
              }

              p {
                @apply text-xs font-light text-center text-black mt-2 mb-0;
              }
            }
          }
        }
      }
      &:nth-child(3) {
        .left {
          @apply pb-6 md:pt-[152px] w-full max-w-[420px];
        }
      }
      &:nth-child(4) {
        .left {
          @apply pt-[120px] md:pt-[140px] w-full max-w-[370px];
        }
        .right {
          @apply flex flex-col items-start p-8 md:pr-[40px];

          div {
            @apply flex items-center w-full max-w-[430px] bg-white text-black p-5 rounded-xl first:mb-8 md:first:mb-16 last:ml-auto;

            > img {
              @apply mr-4;
            }
          }
        }
      }
      &:nth-child(5) {
        .bg {
          @apply top-7 -left-20 md:-left-40;
        }
        .left {
          @apply md:pt-[126px] w-full max-w-[380px] md:max-w-[460px];
        }
        .right {
          @apply py-8;

          div {
            @apply w-full max-w-[380px] bg-white p-10 rounded-xl;
          }
        }
      }
    }
  }
}
