.site-alert {
	@apply fixed w-full bg-white bottom-0 left-0 z-[20] text-center px-16 pt-2;

	.site-alert__message {
		@apply text-current inline-block mb-2;
	}

	.site-alert__button {
		@apply min-w-0 inline-flex justify-center items-center font-bold text-base text-center mx-6 mb-2 py-1.5 px-3 rounded-md whitespace-nowrap outline-none focus:outline-none cursor-pointer
	}

	.site-alert__dismiss {
		@apply absolute h-6 w-6 top-3 right-3 cursor-pointer;
	}
}


.site-alert__anim-enter-active,
.site-alert__anim-leave-active {
  transition: transform 0.5s ease;
}

.site-alert__anim-enter-from,
.site-alert__anim-leave-to {
  transform: translateY(100%);
}
