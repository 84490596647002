input.tabs-input {
  @apply hidden;
}  

nav.tabs-nav {
  @apply flex items-center md:hidden bg-white font-bold mb-4 px-4;

  > ul {
    @apply flex justify-around w-full;

    li {
      @apply relative;

      label {
        @apply block py-5 cursor-pointer;
      }
    }
  }
}  

.tabs {
  @apply md:flex md:flex-wrap;
}

.tabs > div {
  @apply hidden md:block w-full;
}

#tab1:checked ~ .tabs .tab1,
#tab2:checked ~ .tabs .tab2,
#tab3:checked ~ .tabs .tab3 {
  @apply block;
}

#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3 {
  &:after {
    content: '';
    @apply absolute bottom-0 left-0 w-full h-1 bg-blue-light rounded-full;
  }
}

.tab1, .tab2  {
  .no-results {
    @apply mt-4 px-4;
  }
}