// Sidebar Filters For Search
.search-filters {
  @apply fixed top-0 right-0 transform translate-x-full-extra z-50 w-10/12 max-w-screen-sm h-full overflow-y-auto pt-8 pb-28 px-6 bg-white border-r border-gray-300 transition-transform duration-500 ease-in-out shadow-2xl;

  &.isActive {
    @apply transform -translate-x-0;
  }

  .search-filters-container {

  	.search-filters-heading {
  		@apply flex justify-between;

	    h3 {
	      @apply text-sm font-semibold text-gray-400 mb-4 uppercase;
	    }

	    .icon {
	    	width: 27px;
	    	height: 27px;

	    	* {
	    		fill: --color-escgrey-mid;
	    	}
		  }
	  }

	  @import './partials/geo-search';

		@import './partials/accordion-facets';

		@import './partials/toggle-facets';
  }
}

.search-filters-summary {

	@apply fixed bottom-0 right-0 transform translate-x-full-extra z-50 w-10/12 max-w-screen-sm flex justify-between items-center border-t border-gray-200 p-6  bg-white;
	@apply transition-transform duration-500 ease-in-out shadow-2xl;

	&.isActive {
    @apply transform -translate-x-0;
  }

	.summary-count {
		@apply items-center py-3 px-4 text-sm font-semibold bg-red-esclight text-red-esc rounded-full;
	}

	.clear-filters {
		@apply items-center text-white text-xs font-semibold bg-blue-light py-2 px-4 rounded;
	}
}


