.testimonials-cards {
  @apply flex flex-wrap flex-col md:flex-row w-full mt-8;

  .testimonials-card-container {
    @apply w-full md:w-1/2 lg:w-1/3 my-4 px-4;

    .testimonials-card {
      @apply relative flex flex-col h-full text-left bg-white rounded-md shadow-md;

      .testimonials-card__header {
        @apply relative flex items-end bg-blue-dark bg-no-repeat bg-cover bg-center w-full h-60 p-5 rounded-t-md z-0;

        &:before {
          content: '';
          @apply absolute inset-0 bg-black opacity-50 rounded-t-md -z-1; 
        }

        .testimonials-title {
          @apply text-2xl text-white font-bold;
        }
      }

      .testimonials-card__body {
        @apply flex-1 p-5 bg-white rounded-b-md;

        p,span {
          @apply text-sm text-blue-dark text-left leading-relaxed;
        }

        a {
          @apply text-blue-light;
        }
      }
    }
  }
}