// The search box which sits at the top center of the pages.

.search-filter {
  @apply w-full flex justify-center absolute left-1/2 -bottom-6 transform -translate-x-1/2;

  &.is_fixed {
  	@apply fixed top-12 pt-4 left-0 bg-blue-dark z-100 mt-0.5 pb-4 w-full bottom-auto translate-x-0 shadow-md;

  	&.has_menu {
  		@apply top-24 mt-2.5;
  	}

  	.search-filter__container {
  		form {
				.search-filter__input {
					@apply p-3 pl-12;
				}
  			.search-filter__button {
  				@apply bg-white text-blue-dark border-l border-gray-200;

					.search-filter__button-icon path {
						@apply fill-current text-blue-dark;
					}
  			}
  		}
  	}
  }

  .search-filter__container {
    @apply relative flex items-center w-full mx-4 md:mx-6 rounded-full shadow-sm;
    max-width: 600px;

    form {
    	@apply relative flex items-center w-full h-full;

			&.search {
				.search-filter__icon {
					@apply hidden md:block;
				}
				.search-filter__input {
					@apply pl-8 md:pl-12;
				}
			}

	    .search-filter__icon {
	      @apply absolute left-4 w-6;
	    }
	    .search-filter__input {
	      @apply w-full rounded-l-full p-4 pl-12 border-t border-b border-l border-gray-200 text-sm md:text-base text-blue-dark bg-white outline-none;
	    }
	    .search-filter__button {
	      @apply relative h-full w-20 md:w-32 rounded-r-full border-t border-b border-r border-blue-dark bg-blue-dark text-white text-sm font-bold	uppercase focus:outline-none cursor-pointer;
	    
				.search-filter__button-icon {
					@apply md:hidden mx-auto w-6 h-auto;
				}
        .search-filter__button-count {
          @apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
        }
			}
	  }
  }
}
