.breadcrumb {
  @apply bg-grey;

  .breadcrumb__inner {
    @apply flex flex-wrap justify-center md:justify-between items-center container mx-auto py-4 px-4;

    .breadcrumb__links {
      @apply flex text-xs font-normal text-blue-dark;

      li {
        @apply flex items-center mr-2;

        &:last-child {
          @apply mr-0;
        }

        a {
          @apply mr-2 py-2;
        }
      }
    }

    .btn {
      @apply flex justify-between m-0 py-1.5 text-sm bg-blue;
    }
  }
}

.content-section {

  .content-section__container {
    @apply flex flex-wrap container mx-auto px-4;

    .content-section__main {
      @apply w-full md:flex-1 mb-4 md:mb-0 pr-0 md:pr-8;

      .video-container {
        @apply relative h-0 aspect-w-16 aspect-h-9 mb-6;
      }

			iframe[src*="youtube"] {
				width:100%;
				height:450px;
			}
    }

    .content-section__aside {
      @apply w-full md:w-2/5 lg:w-1/3;

      .side-element-card {
        @apply bg-grey mb-7 p-6 border rounded-md;

        .side-element-card__img {

        }
        .side-element-card__title {
          @apply text-2xl font-bold text-blue-dark mb-4;
        }

        .words-of-wisdom {
          @apply mb-6;

          .words-of-wisdom__title {
            @apply text-sm font-bold text-escgrey-dark uppercase mb-2;
          }
          p {
            @apply text-xs font-normal text-escgrey-dark;
          }
        }

        .best-for {
          @apply mb-6;

          .best-for__title {
            @apply text-sm font-bold text-escgrey-dark uppercase mb-2;
          }
          p {
            @apply text-sm font-normal text-escgrey-dark;
          }
        }

        .esc-rating {
          @apply mb-6;

          .esc-rating__title {
            @apply text-sm font-bold text-escgrey-dark uppercase mb-2;
          }
          .esc-rating-group {
            @apply flex items-center text-sm text-blue-dark;

            .esc-rating-icon-wrapper {
              @apply w-5 h-auto mr-1.5;

              .esc-rating-icon {
                @apply w-auto h-4;
              }
            }
            span {
              @apply mx-1;
            }
          }

          &.text-center {

            .esc-rating-group {
              @apply justify-center;
            }
          }
        }

        .author,
        .company {
          @apply text-center mb-6;

          .author__logo-container,
          .company__logo-container {
            @apply w-14 h-14 flex justify-center items-center bg-white rounded-full border border-gray-200 mx-auto mb-2.5 overflow-hidden;

            .author__logo
            .company__logo {
              @apply w-14 h-auto;
            }
          }
          .author__name,
          .company__title {
            @apply text-2xl font-blackout text-blue-dark mb-2.5;
          }
          .author__title,
          .company__sub-title {
            @apply text-base font-normal text-blue-dark mb-2.5;
          }
          .author__bio,
          .company__Published, {
            @apply text-xs font-normal text-blue-dark;
          }
        }

        .social-media {
          @apply flex justify-between items-center mt-5 py-2 px-4;

          li {
            .icon-container {
              @apply flex justify-center w-6 cursor-pointer;
            }
          }
        }
      }
    }
  }
}

.takeaways {
  @apply bg-grey;

  .takeaway-cards {
    @apply flex flex-wrap container mx-auto;

    .takeaway-card {
      @apply w-full sm:w-1/2 lg:w-1/3 my-2 md:my-4 px-4;

      .takeaway-card__inner {
        @apply h-full bg-white text-center p-6 rounded-md shadow-md;

        .takeaway-card__img {
          @apply w-8 h-auto mb-6 mx-auto;
        }
        .takeaway-card__title {
          @apply text-2xl font-bold text-blue-dark leading-tight mb-4;
        }
        .takeaway-card__text {
          @apply text-sm font-normal text-grey-dark;
        }
      }
    }
  }
}

.download {
  @apply bg-blue-light px-4 py-8;

  .download__container {
    @apply flex flex-col md:flex-row justify-between items-center container mx-auto md:px-4;

    .download__group {
      @apply flex items-center w-full md:w-2/3 lg:w-1/2 mb-4 md:mb-0;

      .download__group-img {
        @apply w-auto h-24 rounded mr-4;
      }
      .download__group-title {
        @apply text-xl md:text-2xl flex-1 font-blackout text-white;
      }
    }
    .btn {
      @apply my-0;
    }
  }
}

.why-we-like-it {

  .why-we-like-it__list {
    @apply flex flex-wrap px-4;

    li {
      @apply relative w-full md:w-1/2 p-4 pl-6;

      &:before {
        content: '';
        @apply absolute left-0 top-6 w-1.5 h-1.5 bg-blue-light rounded-full;
      }
    }
  }
}
