.accordion-facets {
  @apply mb-8;

  .tab {
    @apply w-full mb-4 overflow-hidden;

    .tab-heading {
      @apply w-full flex justify-between items-center text-sm uppercase font-semibold mt-2;

			.tab-label-total {
				@apply flex justify-center items-center w-6 p-1 mx-2 bg-blue-extralight text-blue-extradark text-xxs leading-normal font-semibold rounded;
			}

			.tab-label-clear {
				@apply bg-escgrey-midlight text-xs ml-auto py-1 px-2 rounded cursor-pointer;
			}
    }

    .tab-content {
      @apply w-full flex;

      .tab-content-inner {
      	@apply w-full flex flex-wrap;

      	label {
	        @apply flex w-full lg:w-1/2 lg:pr-4 items-center text-xs font-medium text-blue-dark py-2.5 cursor-pointer;

          .icon {
            @apply hidden;
          }

          .tab-content-wrap {
            @apply flex-1;

            > p {
              @apply flex-1 text-left font-bold;
            }
            > .label-total {
              @apply hidden;
            }
          }

	        .label-total {
	          @apply flex justify-center items-center w-9 p-1 ml-2 bg-blue-extradark text-white text-xxs leading-normal font-semibold rounded;
	        }
	      }
	      input {
	        @apply appearance-none h-5 w-5 mr-2 bg-white border-2 border-escgrey-dark rounded focus:outline-none;

	        &:checked {
	          @apply bg-blue-light bg-contain bg-center bg-no-repeat border-transparent;
	          background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
	        }
	      }

	      &.has-slider {
	      	@apply pt-16 pb-2.5 pl-7 pr-8;
	      }

	      .slider {
	      	@apply w-full h-2.5;

	      	.noUi-handle {
	      		@apply bg-blue-medium shadow-none border-none cursor-pointer w-6 h-6 -top-2;


	      		&:before {
	      			@apply h-3 left-3.5;
	      		}

	      		&:after {
	      			@apply h-3 left-2.5;
	      		}
	      	}

	      	.noUi-connect {
	      		@apply bg-blue-light;
	      	}

	      	.noUi-tooltip {
	      		@apply text-xs border-0;
	      	}
	      }
      }
    }

    // if contains class "advanced-"
    &[class*='advanced-'] {
      .tab-label.always-open {
        // @apply bg-transparent;
      }
    }

    // Advanced 1
    &.advanced-1 {
      .tab-content-inner {
        @apply grid grid-cols-2 md:grid-cols-4 p-0 mt-2.5 overflow-hidden border rounded-md;

        > label {
          @apply flex-col justify-center w-full min-h-[77px] bg-white hover:bg-blue hover:text-white border-b border-r p-3 transition-all ease-in-out duration-300;

          // &:nth-child(even) {
          //   @apply border-r-0;
          // }
          &.odd, &.even {
            @apply border-b-0;
          }
          &.even:nth-last-child(2) {
            @apply border-b md:border-b-0;
          }

          &:has(> input:checked) {
            @apply bg-blue-light text-white;
          }

          > input {
            @apply absolute pointer-events-none hidden;
          }

          .tab-icon {
            @apply inline-block text-[28px] pb-2;
          }

          .tab-content-wrap {
            @apply flex-none;

            > p {
              @apply text-center;
            }
          }

          .label-total {
            @apply hidden;
          }
        }
      }
    }

    // Advanced 2
    &.advanced-2 {
      .tab-content-inner {
        @apply grid grid-cols-2 md:grid-cols-4 p-0 mt-2.5 overflow-hidden border rounded-md;

        > label {
          @apply w-full min-h-[73px] bg-white hover:bg-blue hover:text-white border-b border-r p-3 transition-all ease-in-out duration-300;

          // &:nth-child(even) {
          //   @apply border-r-0;
          // }
          &.odd, &.even {
            @apply border-b-0;
          }
          &.even:nth-last-child(2) {
            @apply border-b md:border-b-0;
          }

          &:has(> input:checked) {
            @apply bg-blue-light text-white;
          }

          > input {
            @apply absolute pointer-events-none hidden;
          }

          .tab-icon {
            @apply inline-block text-[28px] pr-2;
          }

          .label-total {
            @apply hidden;
          }
        }
      }
    }

    // Advanced 3
    &.advanced-3 {
      .tab-content-inner {
        @apply grid grid-cols-2 md:grid-cols-4 gap-2.5 p-0 pt-2.5;

        > label {
          @apply flex-col justify-center w-full min-h-[78px] bg-white hover:bg-blue hover:text-white p-3 border rounded-md transition-all ease-in-out duration-300;

          &:has(> input:checked) {
            @apply bg-blue-light text-white;
          }

          > input {
            @apply absolute pointer-events-none hidden;
          }

          .tab-icon {
            @apply inline-block text-[28px] pb-2;
          }

          .tab-content-wrap {
            @apply flex-none;

            > p {
              @apply text-center;
            }
          }

          .label-total {
            @apply hidden;
          }
        }
      }
    }

    // Advanced 4
    &.advanced-4 {
      .tab-content-inner {
        @apply grid grid-cols-2 md:grid-cols-4 gap-2.5 p-0 pt-2.5;

        > label {
          @apply w-full min-h-[53px] justify-start bg-white hover:bg-blue hover:text-white p-3 border rounded-md transition-all ease-in-out duration-300;

          &:has(> input:checked) {
            @apply bg-blue-light text-white;
          }

          > input {
            @apply absolute pointer-events-none hidden;
          }

          .tab-icon {
            @apply inline-block text-[28px] pr-2;
          }

          .label-total {
            @apply hidden;
          }
        }
      }
    }

    // Advanced 5
    &.advanced-5 {
      .tab-content-inner {
        @apply grid grid-cols-2 md:grid-cols-4 p-0 mt-2.5 overflow-hidden border rounded-md;

        > label {
          @apply w-full items-start bg-white hover:bg-blue hover:text-white border-b border-r p-3 transition-all ease-in-out duration-300;

          &:nth-child(even) {
            @apply border-r-0;
          }
          &.odd, &.even {
            @apply border-b-0;
          }
          &.even:nth-last-child(2) {
            @apply border-b md:border-b-0;
          }

          &:has(> input:checked) {
            @apply bg-blue-light text-white;

            .tab-content-wrap > .label-total {
              @apply bg-white text-blue-dark;
            }
          }

          > input {
            @apply absolute pointer-events-none hidden;
          }

          .tab-icon {
            @apply inline-block text-[28px] pr-2;
          }

          .tab-content-wrap {
            @apply pt-1.5;

            > p {
              @apply mb-1.5;
            }
            > .label-total {
              @apply block m-0 text-center;
            }
          }

          .label-total {
            @apply hidden;
          }
        }
      }
    }
  }
}
