.our-approach__cards {
  @apply mb-5;

  .our-approach__card {
    @apply bg-white mt-8 mb-6 p-6 border rounded-md;

    .our-approach__card-title {
      @apply text-lg font-bold text-blue-dark mb-3;
    }
    .our-approach__card-text {
      @apply text-base font-normal text-blue-dark mb-5;
    }
    .our-approach__card-dropdown-panel {
      @apply max-h-0 lg:max-h-full overflow-hidden;

      &.active {
        @apply max-h-full;
      }
    }
    .our-approach__card-dropdown-label {
      span {
        @apply relative lg:hidden h-7 text-base font-bold text-blue-dark uppercase cursor-pointer;

        &:before {
          content: '';
          @apply block w-full h-0.5 bg-yellow absolute -bottom-1.5 left-0 rounded;
        }
      }
    }

    .our-approach__card-list {
      @apply grid grid-cols-1 md:grid-cols-2 gap-5 mb-6 lg:mb-0;

      li {
        @apply w-full flex items-center list-none p-0;

        &:before {
          @apply hidden;
        }

        .our-approach__card-list-img-container {
          @apply w-8 h-8 mr-2.5;

          .our-approach__card-list-img {
            @apply w-8 h-8;
          }
        }
        .our-approach__card-list-text {
          @apply flex-1 text-base m-0 pr-2;
        }
      }
    }

    ul {
      li {
        @apply relative pl-6;
      
        &:before {
          content: '• ';
          @apply absolute -top-4 left-0 text-5xl text-blue-light;
        }
      }
    }
    ol {
      counter-reset: esc-counter;
    
      li {
        counter-increment: esc-counter;
        @apply relative pt-1 pl-10 mb-5;
      
        &:before {
          content: counter(esc-counter);
          @apply absolute top-0 left-0 flex justify-center items-center bg-blue-light w-7 h-7 text-lg font-bold text-white rounded-full;
        }
      }
    }
  }
}