.search {
	@apply w-full;

	&.with-sidebar {
  	@apply w-full lg:w-2/3 lg:pr-8;
  }
}

.search-jobs {
  .search__main {
    .search__results {
      .search__results-info {
        @apply px-4 md:px-0;
      }
    }
  }
}

.search__aside {
  @apply hidden lg:block flex-1;

  .search-top-10-opportunities {
    @apply bg-white mb-5 p-5 rounded-[10px] border border-escgrey-midlight;

		.search-top-10-opportunities__meta {
			@apply flex justify-between text-escgrey text-sm tracking-tight mb-3;
		}

    .search-top-10-opportunities__title {
      @apply text-3xl text-blue-dark font-bold tracking-tight mb-4;
    }
		.search-top-10-opportunities__strapline {
      @apply text-base text-blue-dark font-light tracking-tight mb-1;
    }
		.search-top-10-opportunities__link {
      @apply text-base text-blue-light font-semibold mb-6 inline-block;
    }

    .top-opportunities__cards {
      @apply space-y-3;
    }
  }
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
  padding:0;
  margin:0;
}
