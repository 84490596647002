.newsletter-subscribe {
  @apply bg-white w-full h-auto text-sm font-normal text-center p-5 border border-escgrey-midlight rounded-lg; // w-[362px]
  height: fit-content;

  &.bg-grey {
    @apply bg-grey;
  }

  .newsletter-subscribe__header {
    @apply w-[90%] mx-auto mb-5 space-y-3;

    & > :first-child {
      @apply text-base font-bold;
    }

    // .img-container {
    //   @apply w-14 h-14 mx-auto rounded-full overflow-hidden;

    //   .img {
    //     @apply w-full h-full;
    //   }
    // }

    // .title {
    //   @apply text-base font-bold;

    //   &.blackout {
    //     @apply text-2xl font-medium font-blackout;
    //   }
    // }

    // .subtitle {
    //   @apply text-base font-normal;
    // }

    p {
      @apply text-xs font-normal;
    }
  }

  .newsletter-subscribe__footer {
    ul {
      @apply w-[90%] flex justify-between items-center mt-7 mb-1 mx-auto;
    }
  }
}

// Shared between all newsletters, just the email input and button.
.email-subscribe {
  
  .email-subscribe__container {

    .email-subscribe__input {
      @apply w-full py-3.5 px-6 border border-[#D9D9D9] rounded-full; // mt-2

      &.error {
        @apply border-red-esc transition duration-150 ease-linear;
      }

      + .error {
        @apply w-full text-center text-sm text-red-esc mt-1.5 transition duration-150 ease-linear;
      }
    }

    .email-subscribe__button {
      @extend .btn;
      @apply w-full bg-blue-dark text-white mb-0.5 py-4 rounded-full;
    }
  }
}