// Default hero as used by most pages of the 2021 site.
.hero {
  @apply w-full relative bg-no-repeat bg-center bg-cover z-20;

  .underlay {
    @apply absolute inset-0 -z-1 bg-gradient-to-b from-blue-light via-blue-light to-blue-dark;
  }
  .overlay {
    @apply absolute inset-0 bg-black -z-2;
  }

  .hero__top {
    @apply absolute top-0 left-0 w-full flex justify-center items-center z-10;
  }

  .hero__container {
    @apply relative flex items-center container mx-auto p-4 z-0;
    min-height: 500px;

    &.tall {
      min-height: 700px;
    }
    &.half {
      @apply flex-col sm:flex-row justify-evenly;
    }

    .hero__inner {
      @apply w-full mx-auto;

      &.left {
        @apply md:w-3/4 lg:w-3/5 md:ml-0;
      }
      &.center {
        @apply lg:w-4/5 xl:w-2/3 text-center;

        .hero__header {
          @apply justify-center;

          &.top-center {
            @apply absolute top-0 left-0 right-0;
          }
        }
        .hero__body {
          .hero__title,
          .hero__text {
            @apply w-full;
          }
        }
      }
      &.half {
        @apply w-full sm:w-1/2 md:ml-0;

        .hero__image {
          @apply w-auto h-full max-h-64 md:max-h-96 mx-auto md:mr-0;
        }
      }

      .hero__header {
        @apply flex items-center mb-4;

        .hero__icon {
          @apply flex justify-center items-center w-20 h-20 rounded-full overflow-hidden;

          .icon {
            @apply w-full h-auto rounded-full border-transparent;
          }
        }

        .btn-group {
          @apply flex-1 w-auto ml-6;

          .btn {
            @apply text-sm font-bold text-white my-1 mr-2;

            &:last-child {
              @apply mr-0;
            }
          }
        }

        .hero__link {
          @apply absolute top-11 left-4 lg:left-8 flex items-center text-white text-sm z-50;

          .hero__link-icon {
            @apply w-1.5 h-auto mr-1.5;
          }
        }
      }

      .hero__body {

        .hero__logo {
          @apply w-auto h-24 mb-4 mx-auto;
        }

        .hero__headline {
          @apply text-sm md:text-base font-normal uppercase text-white mb-4 w-11/12;
        }

        .hero__title {
          @apply w-full font-blackout text-3xl md:text-hero leading-tight text-white mb-4 w-11/12;

          .hero__title-number {
            @apply text-blue-light;
          }
        }

        .hero__body-group {
          @apply flex items-center mb-4;

          .hero__title {
            @apply w-auto mb-0 mr-8 leading-none;
          }
          .btn-group {
            @apply flex-row items-stretch;

            .btn {
              @apply mr-2.5;

              &:last-child {
                @apply mr-0;
              }
            }
          }
        }

				&.group-left {
          @apply w-full flex flex-col md:flex-row items-start md:items-end justify-start md:justify-center mb-4 ;

					.content {
						@apply md:ml-8 pt-8 w-full xl:w-4/5;

						.hero__title,
						.hero__text {
							@apply w-full pr-0 xl:w-4/5;
						}

						.btn {
							@apply mb-0 md:my-0;

						}
					}
        }

        .hero__sub-title {
          @apply text-xl md:text-2xl text-white mb-7;

          span, b, strong {
            @apply font-bold;
          }
        }
        .hero__text {
          @apply text-sm md:text-base font-normal text-white mb-4 w-4/5;
        }
      }

      .hero__footer {

        .hero__list {
          @apply flex mt-6;

          li {
            @apply flex items-center mr-6;

            &:last-child {
              @apply mr-0;
            }

            .hero__list-icon {
              @apply w-5 h-5 mr-3;
            }
            .hero__list-title {
              @apply text-xs font-bold text-white;
            }
          }
        }

        .btn-group {
          @apply flex-row;

          .btn {
            @apply mr-2.5;

            &:last-child {
              @apply mr-0;
            }
          }
        }
      }
    }
  }
}
