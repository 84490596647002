.footer {
  @apply relative w-full bg-blue-dark text-white text-sm mt-auto pt-20 pb-6;

  &::before {
    content: '';
    @apply absolute -top-9 left-0 w-full bg-blue-dark h-10;
    -webkit-clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  }

  .footer__top {
    @apply container mx-auto mb-8 px-4 flex flex-wrap items-center justify-between;

    .footer__top-left {
      @apply flex items-center;

      .esc-logo {
        @apply h-10 w-auto fill-current text-white mr-12;
      }
  
      .certified-b-corporation-logo {
        @apply h-14 w-auto fill-current text-white;
      }
    }
    .footer__top-right {
      .report-a-bug {
        @apply bg-blue-darker text-white text-sm font-normal border border-blue border-opacity-50;

        svg {
          @apply w-5 h-auto ml-1;
        }
      }
    }
  }

  .footer__middle {
    @apply container mx-auto mb-10 px-4 flex flex-wrap justify-between;

    .footer__address {
      @apply not-italic font-normal leading-normal flex-auto w-1/2 md:flex-1;
      
      a {
        @apply no-underline text-white;
      }
    }

    .footer-nav {
      @apply flex-auto w-1/2 md:flex-1 mb-5 md:mb-0;

      .footer-nav__title {
        @apply text-base font-normal uppercase;

        &::after {
          content: '';
          @apply block border-yellow border-b-2 mt-2 mb-3 w-7/12;
        }
      }

      .footer-nav__item {
        @apply mt-3.5 font-normal;
      }
    }

  }

  .footer__hr {
    @apply container mx-auto px-4 mb-8;

    span {
      @apply flex border-t-2 border-gray-500;
    }
  }

  .footer__bottom {
    @apply container mx-auto mb-4 px-4 flex flex-col items-center md:flex-row md:justify-between;

    .footer-nav__term {
      @apply flex mb-8 md:mb-0;

      li {
        @apply mr-4 text-xs font-normal;
      }
    }

    .footer__social-media {
      @apply flex list-none ;
      
      li {
        @apply w-6 h-6 mx-3;

        &:last-child {
          @apply md:mr-0;
        }

        img {
          @apply w-6 h-6;
        }
      }
    }
  }

  .footer__copyright {
    @apply container mx-auto px-4 flex justify-center md:justify-start text-xs font-normal;
  }

}
