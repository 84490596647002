.take-on-them {
  @apply bg-offwhite;

  .take-on-them-container {
    @apply container mx-auto px-4 w-full md:w-4/5 text-center;

    .take-on-them-image {
      @apply w-16 h-auto mx-auto mb-6;
    }
    .take-on-them-title {
      @apply font-blackout text-3xl md:text-4xl font-normal text-blue-dark mb-7;
    }
    .take-on-them-text {
      @apply text-xl md:text-2xl mb-6;

      &:before {
        content: '“';
        @apply text-4xl font-blackout font-bold align-middle pr-2;
      }
      &:after {
        content: '”';
        @apply text-4xl font-blackout font-bold align-middle pl-2;
      }

      &:last-child {
        @apply mb-0;
      }
    }
  }
}