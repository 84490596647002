
.opportunity {
  @apply lg:-mt-5;

  .sidebar {
    @apply relative bg-grey w-full lg:w-[40%] border-b-2 border-blue-light lg:border-0 -mt-12 lg:mt-0 pt-20 pb-10 lg:pt-16 lg:pl-10 lg:pb-10 px-4 md:px-0;

    &:before,
    &:after {
      content: '';
      @apply bg-grey absolute top-0 left-1/2 -translate-x-1/2 bottom-[-2px] w-[200vw] border-b-2 lg:border-none border-blue-light -z-1; // -bottom-0.5
    }
    &:after {
      @apply right-0 lg:hidden;
    }

    &__title {
      @apply text-lg font-bold text-blue-dark mb-3;
    }

    //
    .sidebar__company {
      @apply bg-white mt-8 mb-6 border rounded-md;

      .company__header {
        @apply flex flex-col lg:flex-row -translate-y-8 -mb-8;

        .company__logo-container {
          @apply flex justify-center items-center w-36 h-36 translate-x-4 lg:-translate-x-4 bg-white rounded-lg overflow-hidden;

          .company__logo {
            @apply w-full h-full object-contain;
          }
        }

        .company__intro {
          @apply flex-1 pt-4 lg:pt-12 pl-4 pr-6;

          .company__title {
            @apply text-2xl font-bold mb-2;
          }

          .company__info {
            @apply flex mb-1.5;

            .company__review {
              @apply flex items-center text-sm font-bold mr-2;

              .company__review-icon {
                @apply w-4 h-4 mr-2 mb-[2px];
              }
              span {
                @apply font-normal ml-0.5;
              }
            }
          }

          .company__roles {
            @apply bg-blue-light inline-flex items-center py-1 px-2 rounded text-xs text-white;
          }
        }
      }

      .company__body {
        @apply p-6 pb-3.5;

        .company__headline {
          @apply mb-4;
        }
      }
    }

    .sidebar__title {
      @apply font-bold mb-4;

      span {
        &.orange {
          @apply text-orange;
        }
        &.pink {
          @apply text-pink-medium;
        }
        &.green {
          @apply text-green;
        }
      }
    }

    .sidebar__title-table {
      @apply font-bold mt-8 mb-4 ml-4;
    }

    // Table {
    .table {
      @apply w-full bg-white text-sm border rounded-b-lg divide-y overflow-hidden;

      &.table--xs {
        @apply text-xs;

        .table__row {
          .table__title,
          .table__desc {
            @apply w-1/2;

            .description {
              @apply flex-1;
            }
          }
        }
      }

      .table__row {
        @apply flex divide-x font-bold;

        .table__title,
        .table__desc {
          @apply flex items-center p-3.5;

          p {
            @apply mb-0;
          }
        }

        .table__title {
          @apply w-2/6;

          img {
            @apply w-5 h-auto mr-2.5;
          }
        }

        .table__desc {
          @apply flex-1 gap-4;

          img {
            @apply w-5 h-auto mr-1.5;
          }
          .img-text {
            &.employer {
              @apply text-green;
            }
            &.escape {
              @apply text-blue-light;
            }
          }

          .tooltip {
            @apply ml-2;
          }
        }
      }
    }

    // Overview
    .sidebar__overview {
      @apply mt-6 mb-8;

      .img-container {
        @apply w-full flex justify-center items-center bg-white min-h-[64px];

        img {
          @apply object-contain max-h-[240px];
					position:relative!important;
        }
      }
    }


    .sidebar__why,
    .sidebar__take,
    .sidebar__newsfeed {
      @apply bg-white mt-6 mb-8 p-6 border rounded-lg;
    }

    //
    .sidebar__why {

      .sidebar__why-header {
        @apply flex justify-between items-center mb-6;

        .sidebar__title {
          @apply mb-0;
        }

        label {
          @apply relative font-bold;
          @apply before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2;
          &:before {
            content: 'View All';
          }
          span {
            @apply text-transparent;
          }
        }
      }

      .viewall_why {
        @apply hidden;

        &:checked + .sidebar__why-header label {
          @apply before:content-['Close'];
        }

        &:checked ~ ul {
          @apply max-h-[1000px];

          li {
            @apply max-h-[1000px];
          }
        }
      }

      .sidebar__why-benefits {
        @apply space-y-4 md:space-y-0 md:grid grid-cols-2 gap-6 max-h-64 overflow-hidden transition-all ease-in-out duration-300;

        li {
          @apply flex max-h-0 overflow-hidden transition-all ease-in-out duration-300;

          &:nth-child(-n+6) {
            @apply max-h-[1000px];
          }

          .sidebar__why-benefits__img-container {
            @apply w-8 h-8 mr-4 bg-grey-medium rounded-md;

            img {
              @apply w-full h-full;
            }
          }

          p {
            @apply flex-1;
          }
        }
      }
    }

    //
    .sidebar__take {

      .sidebar__take-header {
        @apply flex items-center mb-3;

        .sidebar__take-img-container {
          @apply w-[50px] h-[50px] bg-grey-medium mr-3 rounded-full;

          .sidebar__take-img {
            @apply w-full h-auto rounded-full;
          }
        }

        .sidebar__take-header-intro {

          .sidebar__title {
            @apply leading-tight mb-0;
          }
          .sidebar__take-subtitle {
            @apply text-sm;

            span {
              @apply font-normal;
            }
          }
        }
      }

      .sidebar__take-quote {
        @apply relative pt-2 px-8;
        @apply before:absolute before:top-0 before:left-0 before:w-5 before:h-5 before:font-blackout before:text-4xl before:font-black before:text-blue-light before:content-['“'];
        @apply after:absolute after:top-0 after:right-0 after:w-5 after:h-5 after:font-blackout after:text-4xl after:font-black after:text-blue-light after:content-['”'];
      }
    }

    //
    .sidebar__newsfeed {
      ul {
        @apply divide-y;

        li {
          a {
            @apply flex items-center py-4;

            img {
              @apply w-10 h-10 rounded-lg bg-grey-medium mr-3;
            }

            p {
              @apply text-sm font-bold pr-4;
            }

            .icon {
              @apply w-4 h-auto -rotate-90 ml-auto;
            }
          }
        }
      }
    }

  }
}
