.hero-home-default {
  @apply w-full relative z-20 bg-blue-dark overflow-hidden;

	.hero__container {
    @apply relative flex justify-center items-center container mx-auto p-4 min-h-[700px];

		.hero__inner {
      @apply w-full mx-auto py-8 flex flex-col items-center;

			.hero__body {
				@apply w-full mx-auto text-center;

        .hero__intro {
          @apply w-full text-[1.7rem] font-medium mx-auto mb-6;
          @apply bg-clip-text text-transparent bg-gradient-to-r from-[#328cf7] to-[#27c1a9];
          width: fit-content;
        }

        .hero__title {
          @apply w-full text-3xl md:text-5xl font-bold leading-normal text-white mb-6;
        }

        .hero__sub-title {
          @apply w-full max-w-[580px] text-2xl leading-normal text-white mx-auto mb-10;

          b {
            @apply font-bold;

            &.green {
              @apply text-[#75c055];
            }
            &.orange {
              @apply text-[#f6902d];
            }
            &.blue {
              @apply text-[#44a0d8];
            }
          }
        }

        .home-hero__carousels {
          @apply md:grid grid-cols-2 gap-8 max-w-[900px] bg-white text-left mx-auto p-5 rounded-xl;

          .home-hero__carousel {
            @apply first:mb-5 md:first:mb-0;

            .title {
              @apply text-sm mb-4;
            }

            .splide--slide {
              @apply flex w-full min-h-[120px] bg-[#f1f1f2] p-3 rounded-xl;

              // Arrows
              .splide__arrow {
                @apply w-5 h-5 bg-white border border-escgrey-dark;

                svg {
                  @apply w-4 h-4;
                }

                &.splide__arrow--prev {
                  @apply -translate-x-2 -translate-y-1/2;
                }

                &.splide__arrow--next {
                  @apply translate-x-2 -translate-y-1/2;
                }

                &:disabled {
                  @apply pointer-events-none opacity-10 cursor-default;
                }
              }

              // Track
              .splide__track {
                @apply w-full;

                ul.splide__list {
                  @apply w-full h-full;

                  li.splide__slide {
                    @apply m-0;

                    > a {
                      @apply flex flex-col items-center p-2 pb-0 cursor-pointer;

                      img {
                        @apply w-full h-8 object-contain mb-2;
                      }

                      > p {
                        @apply text-center text-xs;
                        height: fit-content;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      }
                    }
                  }
                }
              }
            }
          }
        }
			}

      .hero__btns {
        @apply mt-6 md:space-x-6 text-center;
      }
		}
	}
  
  .hero__bg {
    @apply absolute inset-0 -z-1;
    @apply after:absolute after:inset-0 after:bg-black/30;

    img {
      @apply w-full h-full md:h-auto object-cover;
    }
  }

  .hero__planet {
    @apply absolute bottom-0 right-0 min-w-[14rem] max-w-[28rem] w-[40vw] -z-1;
  }
}
