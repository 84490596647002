// The new 2022 search box which sits in the hero area and has carousels within it too.

.search-hero-home {
  @apply max-w-[900px] w-full flex;

	&.is_fixed {
		@apply fixed top-0 left-0 w-full bg-blue-dark z-100 ;

		.search-hero__container {
			@apply flex container mx-auto bg-transparent pb-4;

			form {
				.search-hero__filters {
					.search-hero__button {
						@apply bg-white text-blue-dark;

						.search-hero__button-icon path {
							@apply fill-current;
						}
					}
				}
			}
		}
	}

  .search-hero__container {
    @apply w-full p-8 bg-white rounded-2xl;

		.search-hero__carousels {
			@apply w-full grid grid-cols-1;

			&[data-count="2"] {
				@apply md:grid-cols-2 gap-8;
			}

			.search-hero__carousel {
				@apply w-full h-full;

				h3 {
					@apply leading-5;

					&.desktop {
						@apply hidden lg:inline-block;
					}
					&.mobile {
						@apply inline-block lg:hidden text-center;
					}
				}

				.search-hero__carousel-slider {
					@apply w-full rounded-lg bg-escgrey-light flex mt-4 p-3 min-h-[120px];

          // Arrows
          .splide__arrow {
						@apply w-5 h-5 bg-white border border-escgrey-dark;

						svg {
							@apply w-4 h-4;
						}

						&.splide__arrow--prev {
							@apply -translate-x-2 -translate-y-1/2;
						}

						&.splide__arrow--next {
							@apply translate-x-2 -translate-y-1/2;
						}

            &:disabled {
              @apply pointer-events-none opacity-10 cursor-default;
            }
					}

          // Track
          .splide__track {
            @apply w-full;

            ul.splide__list {
              @apply w-full h-full;

              li.splide__slide {
                @apply m-0;

                > a,
                > label {
                	@apply flex flex-col justify-center items-center h-full p-2 rounded-lg cursor-pointer;

                	&.active {
                		@apply bg-blue text-white;
                	}

                  input[type='checkbox'] {
                    @apply hidden;
                  }

                  span[class^="ico-"] {
                    @apply text-2xl mb-2;
                  }

                	> p {
                		@apply text-center text-xs min-h-[48px];
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                	}
                }
              }
            }
          }

          // Pagination
          .splide__pagination {
          }
				}
			}
		}


    form {
    	@apply relative flex items-center justify-between w-full mt-8;

			.search-hero__search {
				@apply relative min-w-[280px];

				.search-hero__icon {
					@apply absolute left-4 top-4 w-6;
				}
				.search-hero__input {
					@apply w-full rounded-2xl p-4 pl-12 border-2 border-escgrey-midlight text-sm md:text-base text-blue-dark bg-white outline-none;
				}

			}

			.search-hero__facets {
				@apply w-full h-full px-6 py-1 hidden lg:grid grid-cols-3 gap-4;

				.search-hero__facet {
					@apply w-full h-full relative;

					.search-hero__facet-button {
						@apply relative w-full h-full cursor-pointer rounded-2xl border-2 border-escgrey-midlight bg-white py-2 pl-3 pr-10 text-left sm:text-sm;
					}

					.search-hero__facet-options {
						@apply absolute z-10 mt-1 max-h-56 w-auto overflow-auto border-2 rounded-2xl bg-white py-1 text-base shadow-lg sm:text-sm;

						label {
							@apply cursor-pointer;

							p {
								@apply text-xs font-bold ml-3 block truncate
							}

							input {
								@apply appearance-none h-5 w-5 bg-white border-2 border-escgrey-dark rounded focus:outline-none;

								&:checked {
									@apply bg-blue-light bg-contain bg-center bg-no-repeat border-transparent;
									background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
								}
							}
						}

						.search-hero__facet-count {
							@apply absolute inset-y-2 right-2 flex justify-center items-center w-5 h-5 text-xxs font-semibold rounded bg-blue-extralight text-blue-extradark;
						}
					}
				}
			}

			.search-hero__filters {
				.search-hero__button {
					@apply relative rounded-2xl py-4 px-8 bg-blue-dark text-white text-sm font-bold	focus:outline-none cursor-pointer;

					.search-hero__button-icon {
						@apply md:hidden mx-auto w-6 h-auto;
					}
					.search-hero__button-count {
						@apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
					}
				}
			}
	  }
  }
}
