.collection {
  @apply -mt-8;
}

.collection-cards {
  @apply flex flex-wrap w-full;

  .collection-card__container {
    @apply w-full md:w-1/3 h-64 my-4 px-4;

    .collection-card {
      @apply relative w-full h-full bg-no-repeat bg-center bg-cover p-2 rounded-md z-0;

      &:before {
        content: '';
        @apply absolute inset-0 bg-black opacity-50 rounded-md -z-1;
      }

      a {
        @apply flex flex-col justify-center items-center text-center w-full h-full;

        .collection-card__title {
          @apply text-xl font-bold text-white mb-2.5;
        }

        .btn {
          @apply min-w-0 text-sm font-bold text-white py-1;
        }
      }
    }
  }
}
