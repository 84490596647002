@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  @apply h-full;
  scroll-behavior: smooth;
}

body {
  @apply font-montserrat min-h-full flex flex-col text-base font-medium leading-normal text-blue-dark;
}

#app {
  @apply min-h-full relative overflow-hidden;
}

.page {
  @apply w-full bg-grey pt-4 md:pt-16;
}

.wrapper {
  @apply container mx-auto px-4;
}

.line {
  @apply border-t border-offwhite;
}
.line.line-grey {
  @apply border-gray-200;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.section-scroll-anchor > div {
  @apply transform -translate-y-16;
}
.section-scroll-anchor.with-menu > div {
  @apply transform -translate-y-40;
}

.scrollable {
  @apply overflow-y-auto pr-4;
}

.scrollable::-webkit-scrollbar {
  @apply w-[0.4rem] transition-all duration-1000 ease-in-out;
}

.scrollable::-webkit-scrollbar-thumb {
  @apply w-[0.4rem] bg-white rounded-xl;
}

.scrollable::-webkit-scrollbar-track {
  @apply bg-transparent rounded-xl border;
}

.scrollable.mobile::-webkit-scrollbar {
  @apply w-[0.4rem];
}

.scrollable-blue {
  @apply overflow-y-auto pr-4;
}

.scrollable-blue::-webkit-scrollbar {
  @apply w-[0.4rem] h-[0.4rem] transition-all duration-1000 ease-in-out;
}

.scrollable-blue::-webkit-scrollbar-thumb {
  @apply w-[0.4rem] h-[0.4rem] bg-blue-dark rounded-xl;
}

.scrollable-blue::-webkit-scrollbar-track {
  @apply bg-escgrey-midlight rounded-xl border;
}

.scrollable-blue.mobile::-webkit-scrollbar {
  @apply w-[0.4rem] h-[0.4rem];
}

.fade-enter-active, .fade-leave-active {
  @apply transition-opacity duration-500 ease-in-out;
}

.fade-enter, .fade-leave-to {
  @apply opacity-0;
}

.fast-fade-enter-active, .fast-fade-leave-active {
  @apply transition-opacity duration-200 ease-in-out;
}

.fast-fade-enter, .fast-fade-leave-to {
  @apply opacity-0;
}

.modal-fade-enter,
.modal-fade-leave-to {
  @apply opacity-0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  @apply transition-opacity duration-500 ease-in-out;
}

.slide-enter-to,
.slide-leave {
  @apply max-h-full overflow-hidden;
}

.slide-enter,
.slide-leave-to {
  @apply max-h-0 overflow-hidden;
}

.slide-enter-active,
.slide-leave-active {
  @apply transition-all duration-700 ease-in-out;
}

.toast-enter-active, .toast-leave-active {
  @apply transition duration-500 ease-in-out;
}

.toast-enter, .toast-leave-to {
  @apply opacity-0 transform translate-x-full;
}

.header {
  @apply absolute top-0 left-0 w-full h-[64px] bg-blue-dark z-50;
}
.header.header-fixed {
  @apply fixed;
}
.header .header__container {
  @apply flex items-center w-full h-full bg-blue-dark text-white px-4;
}
.header .header__container .header-logo__container {
  @apply w-14 h-auto;
}
.header .header__container .header-logo__container .header-logo {
  @apply w-full h-auto fill-current text-white;
}
.header .header__container .header-nav {
  @apply hidden lg:flex justify-between items-center flex-1 h-full;
}
.header .header__container .header-nav .header-nav__left {
  @apply flex h-full;
}
.header .header__container .header-nav .header-nav__left .header-nav__left--item {
  @apply relative hover:border-t-4 hover:border-b-4 hover:border-white hover:border-t-transparent;
}
.header .header__container .header-nav .header-nav__left .header-nav__left--item:hover > .dropdown {
  @apply flex left-[-86px];
}
.header .header__container .header-nav .header-nav__left .header-nav__left--item a, .header .header__container .header-nav .header-nav__left .header-nav__left--item button {
  @apply flex items-center h-full py-4 px-4 text-[15px] xl:text-base font-normal outline-none whitespace-nowrap;
}
.header .header__container .header-nav .header-nav__left .header-nav__left--item a svg, .header .header__container .header-nav .header-nav__left .header-nav__left--item button svg {
  @apply w-4 h-4 ml-2;
}
.header .header__container .header-nav .header-nav__right {
  @apply flex items-center text-base font-normal space-x-6;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item {
  @apply relative flex items-center;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item:first-child:before {
  content: "";
  @apply hidden lg:inline-block w-px h-7 mr-4 bg-white;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item:hover .dropdown {
  @apply block left-auto right-[-76px];
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item.signin button {
  @apply font-bold;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item.signin .dropdown {
  @apply min-w-[244px] right-0 pt-5;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item.signin .dropdown a {
  @apply font-bold;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item a, .header .header__container .header-nav .header-nav__right .header-nav__right--item button {
  @apply relative flex items-center h-full py-4 text-[15px] xl:text-base font-normal outline-none text-right;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item a .avatar,
.header .header__container .header-nav .header-nav__right .header-nav__right--item a .initial, .header .header__container .header-nav .header-nav__right .header-nav__right--item button .avatar,
.header .header__container .header-nav .header-nav__right .header-nav__right--item button .initial {
  @apply flex justify-center items-center w-8 h-8 rounded-full;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item a .initial, .header .header__container .header-nav .header-nav__right .header-nav__right--item button .initial {
  @apply border border-white;
}
.header .header__container .header-nav .header-nav__right .header-nav__right--item a svg, .header .header__container .header-nav .header-nav__right .header-nav__right--item button svg {
  @apply w-4 h-4 ml-2;
}
.header .header__container .header-nav .dropdown {
  @apply hidden absolute top-full min-w-[460px] text-blue-dark pt-5;
}
.header .header__container .header-nav .dropdown .dropdown__inner {
  @apply w-full h-full flex bg-white rounded-3xl border border-grey-medium shadow-lg;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown__list {
  @apply flex-1 p-8 space-y-8 rounded-l-3xl;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown__list .dropdown__list-item a {
  @apply h-auto p-0 hover:text-blue-light transition ease-out duration-200;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown__list .dropdown__list-item a .icon {
  @apply w-14 h-14 bg-[#f3f4f3] mr-6 rounded-lg flex-none;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown__list .dropdown__list-item a .icon img {
  @apply w-full h-full;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown__list .dropdown__list-item a .titles {
  @apply text-left;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown__list .dropdown__list-item a .titles .title {
  @apply font-bold;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown-recommended {
  @apply w-[250px] bg-gray-200 p-8 rounded-r-3xl;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown-recommended p {
  @apply mb-6;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown-recommended .dropdown-recommended__list {
  @apply space-y-4;
}
.header .header__container .header-nav .dropdown .dropdown__inner .dropdown-recommended .dropdown-recommended__list .dropdown-recommended__list-item a {
  @apply h-auto p-0 hover:text-blue-light transition ease-out duration-200;
}
.header .header__container .header-nav__mobile {
  @apply relative flex flex-1 justify-end lg:hidden z-50 transition duration-500 ease-in-out;
}
.header .header__container .mobile-checkbox {
  @apply hidden;
}
.header .header__container .header-nav__mobile.open + .mobile-menu {
  @apply opacity-100 visible transition duration-500 ease-in-out xl:hidden;
}
.header .header__container .unread-messages-icon {
  @apply relative w-5 h-5 flex justify-center items-center bg-red-esc border border-white rounded-full text-xxs font-normal ml-2 mb-0.5;
}
.header .header__container .unread-messages-icon.unread-messages-icon-avatar {
  @apply absolute top-0 left-9 mt-0.5;
}
.header .header__container .unread-messages-icon p {
  @apply mt-px;
}

.mobile-menu {
  @apply bg-white opacity-0 invisible fixed inset-0 top-16 transition duration-500 ease-in-out lg:hidden z-100 overflow-y-auto;
}
.mobile-menu .mobile-menu-list {
  @apply w-full h-full;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item {
  @apply lg:hover:bg-grey lg:hover:border-l-4 lg:border-blue-light px-8;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item > label,
.mobile-menu .mobile-menu-list .mobile-menu-list-item > a,
.mobile-menu .mobile-menu-list .mobile-menu-list-item > button {
  @apply w-full flex items-center text-base font-normal text-blue-dark py-6 m-0 border-b border-grey-light border-opacity-30;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.user-employer a {
  @apply py-4;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.user-employer a .user-employer-logo-container {
  @apply flex justify-center items-center rounded-full overflow-hidden mr-5;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.user-employer a .user-employer-logo-container .user-employer-logo {
  @apply w-12 h-12;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.user-employer a .user-employer-text {
  @apply flex-1;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.dropdown > label svg,
.mobile-menu .mobile-menu-list .mobile-menu-list-item.dropdown > a svg {
  @apply w-6 h-6 ml-auto;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.dropdown .dropdown-menu {
  @apply max-h-0 pt-0 overflow-hidden transition-all ease-in-out duration-300;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.dropdown .dropdown-menu a {
  @apply w-full flex justify-between items-center text-base text-black py-2 px-4;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.dropdown .mobile-checkbox:checked + label svg {
  @apply transform -rotate-180 transition-all ease-in-out duration-300;
}
.mobile-menu .mobile-menu-list .mobile-menu-list-item.dropdown .mobile-checkbox:checked ~ .dropdown-menu {
  @apply max-h-screen py-6 border-b border-grey-light border-opacity-30;
}

main {
  @apply pt-[64px];
}
main.search-fixed {
  @apply pt-[291px] lg:pt-[291px];
}
main.search-fixed.search-expanded {
  @apply pt-[327px] lg:pt-[327px];
}

.footer {
  @apply relative w-full bg-blue-dark text-white text-sm mt-auto pt-20 pb-6;
}
.footer::before {
  content: "";
  @apply absolute -top-9 left-0 w-full bg-blue-dark h-10;
  -webkit-clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
}
.footer .footer__top {
  @apply container mx-auto mb-8 px-4 flex flex-wrap items-center justify-between;
}
.footer .footer__top .footer__top-left {
  @apply flex items-center;
}
.footer .footer__top .footer__top-left .esc-logo {
  @apply h-10 w-auto fill-current text-white mr-12;
}
.footer .footer__top .footer__top-left .certified-b-corporation-logo {
  @apply h-14 w-auto fill-current text-white;
}
.footer .footer__top .footer__top-right .report-a-bug {
  @apply bg-blue-darker text-white text-sm font-normal border border-blue border-opacity-50;
}
.footer .footer__top .footer__top-right .report-a-bug svg {
  @apply w-5 h-auto ml-1;
}
.footer .footer__middle {
  @apply container mx-auto mb-10 px-4 flex flex-wrap justify-between;
}
.footer .footer__middle .footer__address {
  @apply not-italic font-normal leading-normal flex-auto w-1/2 md:flex-1;
}
.footer .footer__middle .footer__address a {
  @apply no-underline text-white;
}
.footer .footer__middle .footer-nav {
  @apply flex-auto w-1/2 md:flex-1 mb-5 md:mb-0;
}
.footer .footer__middle .footer-nav .footer-nav__title {
  @apply text-base font-normal uppercase;
}
.footer .footer__middle .footer-nav .footer-nav__title::after {
  content: "";
  @apply block border-yellow border-b-2 mt-2 mb-3 w-7/12;
}
.footer .footer__middle .footer-nav .footer-nav__item {
  @apply mt-3.5 font-normal;
}
.footer .footer__hr {
  @apply container mx-auto px-4 mb-8;
}
.footer .footer__hr span {
  @apply flex border-t-2 border-gray-500;
}
.footer .footer__bottom {
  @apply container mx-auto mb-4 px-4 flex flex-col items-center md:flex-row md:justify-between;
}
.footer .footer__bottom .footer-nav__term {
  @apply flex mb-8 md:mb-0;
}
.footer .footer__bottom .footer-nav__term li {
  @apply mr-4 text-xs font-normal;
}
.footer .footer__bottom .footer__social-media {
  @apply flex list-none;
}
.footer .footer__bottom .footer__social-media li {
  @apply w-6 h-6 mx-3;
}
.footer .footer__bottom .footer__social-media li:last-child {
  @apply md:mr-0;
}
.footer .footer__bottom .footer__social-media li img {
  @apply w-6 h-6;
}
.footer .footer__copyright {
  @apply container mx-auto px-4 flex justify-center md:justify-start text-xs font-normal;
}

.wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
  @apply text-base font-bold mb-5;
}
.wysiwyg h3 {
  @apply text-lg;
}
.wysiwyg h2 {
  @apply text-xl;
}
.wysiwyg p {
  @apply mb-5;
  line-height: 1.75rem !important;
}
.wysiwyg p:last-child {
  @apply mb-0;
}
.wysiwyg strong, .wysiwyg b {
  @apply font-bold;
}
.wysiwyg i, .wysiwyg em {
  @apply italic;
}
.wysiwyg u {
  @apply underline;
}
.wysiwyg small {
  @apply text-sm;
}
.wysiwyg a {
  @apply underline cursor-pointer hover:text-blue-light transition-colors duration-75 ease-in-out;
}
.wysiwyg ul {
  @apply list-disc ml-5 mb-5;
}
.wysiwyg ul li {
  @apply text-base mb-5 pl-2;
}
.wysiwyg ul li::marker {
  @apply text-blue-light text-2xl;
}
.wysiwyg ol {
  @apply list-none mb-5;
  counter-reset: item;
}
.wysiwyg ol li {
  @apply mb-5 ml-10;
  counter-increment: item;
}
.wysiwyg ol li:before {
  @apply inline-flex justify-center items-center bg-blue-light w-7 h-7 -ml-10 mr-3 text-white font-bold rounded-full;
  content: counters(item, ".") "";
}

.section {
  @apply py-24;
}
.section .section-title__wrapper {
  @apply flex flex-col w-full md:flex-1;
}
.section .section-title__wrapper .section-title {
  @apply font-blackout text-3xl font-normal uppercase mb-4;
}
.section .section-btn__wrapper {
  @apply flex flex-col items-start md:items-center lg:items-start w-full lg:w-auto order-1 lg:order-none mb-5 mx-auto;
}
.section .section-btn__wrapper .btn, .section .section-btn__wrapper .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .section .section-btn__wrapper .email-subscribe__button {
  @apply block w-full md:w-auto mt-5;
}
.section .section-header {
  @apply flex justify-between items-center mb-10;
}
.section .section-header.section__header-center {
  @apply justify-center text-center px-4;
}
.section .section-header .section-header__title {
  @apply font-blackout text-3xl font-normal uppercase;
}

.pill {
  @apply inline-flex items-center py-1 px-2 rounded-full text-xs font-bold text-white;
}
.pill.pill-grey {
  @apply bg-grey border text-xs font-normal text-blue-dark my-1 mr-2.5 p-1.5;
}
.pill.pill-blue {
  @apply bg-blue-pale border text-xs font-normal text-blue-dark my-1 mr-2.5 p-1.5;
}
.pill .pill-logo {
  @apply w-5 h-auto mr-1;
}
.pill .pill-remove {
  @apply w-4 h-auto mr-1 cursor-pointer;
}
.pill .pill-icon {
  @apply w-2.5 h-auto mr-1;
}
.pill.default, .pill.course, .pill.escape-story, .pill.story, .pill.video {
  @apply bg-blue-light;
}
.pill.article {
  @apply bg-orange;
}
.pill.audio {
  @apply bg-purple;
}
.pill.guide {
  @apply bg-green;
}
.pill.template, .pill.templates {
  @apply bg-blue;
}
.pill.tool {
  @apply bg-red-esc;
}

.tooltip {
  @apply w-4 h-4 cursor-help;
}
.tooltip .icon {
  @apply w-full h-auto cursor-help;
}

.talent {
  @apply hidden lg:block bg-offwhite;
}
.talent .talent__container {
  @apply container mx-auto px-4 flex text-center;
}
.talent .talent__container .talent-find {
  @apply w-full lg:w-1/2 pr-10 xl:pr-32;
}
.talent .talent__container .talent-find .talent-find__title {
  @apply text-2xl font-bold text-blue-dark mb-5;
}
.talent .talent__container .talent-find .talent-find__sub-title {
  @apply text-base font-normal text-blue-dark mb-5;
}
.talent .talent__container .talent-trusted {
  @apply w-full lg:w-1/2;
}
.talent .talent__container .talent-trusted .talent-trusted__title {
  @apply text-base font-normal text-blue-dark mt-5 mb-5;
}
.talent .talent__container .talent-trusted .talent-trusted-images {
  @apply flex flex-wrap items-center justify-between;
}
.talent .talent__container .talent-trusted .talent-trusted-images .talent-trusted-image {
  @apply mt-5 mx-7 xl:mx-12;
}

.modal-backdrop {
  @apply fixed inset-0 flex justify-center items-center px-4 z-50;
}
.modal-backdrop .modal-dimmer {
  @apply fixed inset-0 bg-black/75 z-10;
}
.modal-backdrop .modal {
  @apply relative w-full max-w-screen-sm bg-white flex flex-col overflow-x-hidden shadow-md rounded-md p-9 z-20;
  min-height: 40rem;
}
.modal-backdrop .modal.modal-auto-height {
  min-height: 0;
}
.modal-backdrop .modal.modal-full {
  @apply p-0;
}
.modal-backdrop .modal.maxed {
  min-height: 90vh;
}
.modal-backdrop .modal.modal-video {
  @apply min-h-0 max-w-4xl p-0 lg:pr-0 bg-transparent overflow-x-visible;
}
.modal-backdrop .modal.modal-video .btn-close {
  @apply bg-white text-black text-4xl -top-12 lg:-top-8 right-0 lg:-right-8 rounded-full;
}
.modal-backdrop .modal.modal-video .modal-body {
  @apply bg-black rounded-xl md:rounded-2xl overflow-hidden;
}
.modal-backdrop .modal.modal-video.short {
  @apply max-w-sm;
}
.modal-backdrop .modal .btn-close {
  @apply absolute top-4 right-4 flex justify-center items-center w-8 h-8 text-5xl outline-none cursor-pointer z-100;
}
.modal-backdrop .modal .modal-header p,
.modal-backdrop .modal .modal-body p,
.modal-backdrop .modal .modal-footer p {
  @apply mb-4;
}
.modal-backdrop .modal .modal-header .btn, .modal-backdrop .modal .modal-header .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .modal-backdrop .modal .modal-header .email-subscribe__button,
.modal-backdrop .modal .modal-body .btn,
.modal-backdrop .modal .modal-body .email-subscribe .email-subscribe__container .email-subscribe__button,
.email-subscribe .email-subscribe__container .modal-backdrop .modal .modal-body .email-subscribe__button,
.modal-backdrop .modal .modal-footer .btn,
.modal-backdrop .modal .modal-footer .email-subscribe .email-subscribe__container .email-subscribe__button,
.email-subscribe .email-subscribe__container .modal-backdrop .modal .modal-footer .email-subscribe__button {
  @apply mb-auto;
}
.modal-backdrop .modal .modal-header .btn:disabled, .modal-backdrop .modal .modal-header .email-subscribe .email-subscribe__container .email-subscribe__button:disabled, .email-subscribe .email-subscribe__container .modal-backdrop .modal .modal-header .email-subscribe__button:disabled,
.modal-backdrop .modal .modal-body .btn:disabled,
.modal-backdrop .modal .modal-body .email-subscribe .email-subscribe__container .email-subscribe__button:disabled,
.email-subscribe .email-subscribe__container .modal-backdrop .modal .modal-body .email-subscribe__button:disabled,
.modal-backdrop .modal .modal-footer .btn:disabled,
.modal-backdrop .modal .modal-footer .email-subscribe .email-subscribe__container .email-subscribe__button:disabled,
.email-subscribe .email-subscribe__container .modal-backdrop .modal .modal-footer .email-subscribe__button:disabled {
  @apply opacity-10 cursor-not-allowed;
}
.modal-backdrop .modal .modal-header .modal-header-title {
  @apply text-3xl font-bold text-blue-dark mb-4;
}
.modal-backdrop .modal > .modal-body {
  @apply flex flex-col flex-1;
}
.modal-backdrop .modal > .modal-body .modal-body-title {
  @apply text-lg font-bold mb-2;
}
.modal-backdrop .modal > .modal-spinner {
  @apply absolute inset-0 flex justify-center items-center w-full h-full bg-white bg-opacity-90 pointer-events-none;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-3 {
  @apply w-3 h-3;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-4 {
  @apply w-4 h-4;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-5 {
  @apply w-5 h-5;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-6 {
  @apply w-6 h-6;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-7 {
  @apply w-7 h-7;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-8 {
  @apply w-8 h-8;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-9 {
  @apply w-9 h-9;
}
.modal-backdrop .modal > .modal-spinner .spinner.wh-10 {
  @apply w-10 h-10;
}
.modal-backdrop .modal .modal-message {
  @apply absolute inset-0 bg-white flex flex-col justify-center items-center text-center z-10 border-t-4 border-blue-light;
}
.modal-backdrop .modal .modal-message .modal-message__title {
  @apply font-blackout text-2xl text-blue mt-8;
}
.modal-backdrop .modal .modal-message .modal-message__title:after {
  content: "";
  @apply w-2/5 block border-2 border-blue-light rounded mt-2 mx-auto;
}
.modal-backdrop .modal .modal-message .image {
  @apply flex justify-center items-center flex-1;
}
.modal-backdrop .modal .modal-message .image img {
  @apply w-auto h-52;
}
.modal-backdrop .modal .modal-message .modal-message__text {
  @apply font-blackout text-2xl md:text-3xl mb-6 mx-4;
}
.modal-backdrop .modal .modal-message .modal-message__join {
  @apply w-full bg-grey py-3;
}
.modal-backdrop .modal .modal-message .modal-message__login {
  @apply py-8;
}
.modal-backdrop .modal .modal-message .modal-message__login a {
  @apply font-bold underline;
}
.modal-backdrop .modal .iframe-signin {
  @apply absolute top-0 left-0 w-full min-h-full overflow-x-hidden;
}

input.tabs-input {
  @apply hidden;
}

nav.tabs-nav {
  @apply flex items-center md:hidden bg-white font-bold mb-4 px-4;
}
nav.tabs-nav > ul {
  @apply flex justify-around w-full;
}
nav.tabs-nav > ul li {
  @apply relative;
}
nav.tabs-nav > ul li label {
  @apply block py-5 cursor-pointer;
}

.tabs {
  @apply md:flex md:flex-wrap;
}

.tabs > div {
  @apply hidden md:block w-full;
}

#tab1:checked ~ .tabs .tab1,
#tab2:checked ~ .tabs .tab2,
#tab3:checked ~ .tabs .tab3 {
  @apply block;
}

#tab1:checked ~ nav .tab1:after,
#tab2:checked ~ nav .tab2:after,
#tab3:checked ~ nav .tab3:after {
  content: "";
  @apply absolute bottom-0 left-0 w-full h-1 bg-blue-light rounded-full;
}

.tab1 .no-results, .tab2 .no-results {
  @apply mt-4 px-4;
}

.splide {
  @apply w-full;
}
.splide .splide__slide {
  @apply mb-4;
}

@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon/icomoon.ttf?aqt20b") format("truetype"), url("/fonts/icomoon/icomoon.woff?aqt20b") format("woff"), url("/fonts/icomoon/icomoon.svg?aqt20b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=ico-], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-default:before {
  content: "\e90e";
}

.ico-vol-cat-finance:before {
  content: "\e909";
}

.ico-vol-cat-gov:before {
  content: "\e90a";
}

.ico-vol-cat-mentoring:before {
  content: "\e90b";
}

.ico-vol-cat-ops:before {
  content: "\e90c";
}

.ico-vol-cat-planning:before {
  content: "\e90d";
}

.ico-vol-type-ongoing-proj2:before {
  content: "\e900";
}

.ico-sector-mental-heath:before {
  content: "̳";
}

.ico-sector-families:before {
  content: "\e901";
}

.ico-sector-community:before {
  content: "\e902";
}

.ico-sector-young-people:before {
  content: "\e903";
}

.ico-sector-education:before {
  content: "\e904";
}

.ico-vol-type-trustee:before {
  content: "\e905";
}

.ico-gen-map-pin:before {
  content: "\e906";
}

.ico-vol-type-short-term-proj:before {
  content: "\e907";
}

.ico-vol-type-ongoing-proj:before {
  content: "\e908";
}

.banners {
  @apply w-full;
}
.banners.pt-none {
  @apply pt-0;
}
.banners.pb-none {
  @apply pb-0;
}
.banners.py-none {
  @apply py-0;
}
.banners .container {
  @apply mx-auto px-4 space-y-16;
}
.banners .container .banner {
  @apply lg:flex lg:items-center p-10 rounded-2xl border bg-no-repeat bg-cover bg-center;
}
.banners .container .banner .banner__image {
  @apply w-full lg:w-[400px] xl:w-[480px] h-[160px] lg:h-[190px] xl:h-[220px] lg:mr-12 xl:mr-20;
}
.banners .container .banner .banner__image img {
  @apply w-auto h-full mx-auto;
}
.banners .container .banner .banner__text {
  @apply flex-1 text-xl xl:text-2xl mt-8 lg:mt-0 text-center lg:text-left;
}
.banners .container .banner .banner__text b, .banners .container .banner .banner__text strong {
  @apply font-extrabold;
}
.banners .container .banner .banner__text a {
  @apply underline cursor-pointer;
}

button {
  @apply outline-none focus:outline-none;
}

.btn-group {
  @apply flex flex-wrap justify-start items-center;
}
.btn-group > * {
  @apply mr-3;
}
.btn-group > *:last-child {
  @apply mr-0;
}
.btn-group.centred {
  @apply justify-center;
}
.btn-group.between {
  @apply justify-between;
}

.btn-group-saved {
  @apply flex md:justify-start md:items-center;
}

.btn-center {
  @apply w-full text-center;
}

.btn-half {
  @apply flex-1 text-center;
}

.btn-link {
  @apply text-sm font-bold uppercase border-b-2 border-yellow mx-6 outline-none;
}

.btn, .email-subscribe .email-subscribe__container .email-subscribe__button {
  @apply min-w-0 inline-flex justify-center items-center font-bold text-base text-center my-3 py-3 px-6 rounded-md whitespace-nowrap outline-none focus:outline-none cursor-pointer;
}
.btn.default-cursor, .email-subscribe .email-subscribe__container .default-cursor.email-subscribe__button {
  @apply cursor-default;
}
.btn.btn-blue-light, .email-subscribe .email-subscribe__container .btn-blue-light.email-subscribe__button {
  @apply bg-blue-light;
}
.btn.btn-blue-pale, .email-subscribe .email-subscribe__container .btn-blue-pale.email-subscribe__button {
  @apply bg-blue-pale text-blue-light;
}
.btn.btn-white, .email-subscribe .email-subscribe__container .btn-white.email-subscribe__button {
  @apply bg-white text-blue-light;
}
.btn.btn-pink, .email-subscribe .email-subscribe__container .btn-pink.email-subscribe__button {
  @apply bg-pink text-white text-sm font-normal min-w-0 py-1 px-3 rounded-full;
}
.btn.btn-red, .email-subscribe .email-subscribe__container .btn-red.email-subscribe__button {
  @apply bg-red text-white text-sm font-normal min-w-0 py-1 px-3 rounded-md;
}
.btn.btn-yellow, .email-subscribe .email-subscribe__container .btn-yellow.email-subscribe__button {
  @apply bg-yellow;
}
.btn.btn-blue, .email-subscribe .email-subscribe__container .btn-blue.email-subscribe__button {
  @apply bg-blue-light text-white text-sm font-normal min-w-0 py-1 px-3 rounded-md;
}
.btn.btn-blue-inverse, .email-subscribe .email-subscribe__container .btn-blue-inverse.email-subscribe__button {
  @apply bg-blue-extralight text-blue-light border border-red;
}
.btn.btn-expired, .email-subscribe .email-subscribe__container .btn-expired.email-subscribe__button {
  @apply bg-red-esclight text-white;
}
.btn.btn-applied, .email-subscribe .email-subscribe__container .btn-applied.email-subscribe__button {
  @apply bg-red-esclight text-red-esc;
}
.btn.btn-red-esclight, .email-subscribe .email-subscribe__container .btn-red-esclight.email-subscribe__button {
  @apply bg-red-esclight;
}
.btn.btn-border--white, .email-subscribe .email-subscribe__container .btn-border--white.email-subscribe__button {
  @apply min-w-0 bg-transparent hover:bg-white text-white hover:text-pink border-2 border-white;
}
.btn.btn-border--blue, .email-subscribe .email-subscribe__container .btn-border--blue.email-subscribe__button {
  @apply text-blue-dark border-2 border-blue-dark;
}
.btn.btn-border--blue-light, .email-subscribe .email-subscribe__container .btn-border--blue-light.email-subscribe__button {
  @apply bg-white text-blue-light border-2 border-blue-light outline-none;
}
.btn.btn-border--blue-light.active, .email-subscribe .email-subscribe__container .btn-border--blue-light.active.email-subscribe__button {
  @apply bg-blue-light text-white;
}
.btn.btn-small, .email-subscribe .email-subscribe__container .btn-small.email-subscribe__button {
  @apply text-sm font-normal min-w-0 py-1 px-3;
}
.btn.btn-medium, .email-subscribe .email-subscribe__container .btn-medium.email-subscribe__button {
  @apply text-sm font-normal min-w-0 py-2 px-4;
}
.btn.btn-large, .email-subscribe .email-subscribe__container .btn-large.email-subscribe__button {
  @apply min-w-0 py-4 px-6;
}
.btn.btn-xlarge, .email-subscribe .email-subscribe__container .btn-xlarge.email-subscribe__button {
  @apply min-w-0 py-4 px-14;
}
.btn.btn-full, .email-subscribe .email-subscribe__container .btn-full.email-subscribe__button {
  @apply w-full;
}
.btn.btn-toggle, .email-subscribe .email-subscribe__container .btn-toggle.email-subscribe__button {
  @apply bg-escgrey-midlight text-blue-dark text-sm font-normal min-w-0 py-1.5 mt-0 px-4 border;
}
.btn.btn-toggle.active, .email-subscribe .email-subscribe__container .btn-toggle.active.email-subscribe__button {
  @apply bg-white text-blue-dark font-bold;
}
.btn.btn-normal, .email-subscribe .email-subscribe__container .btn-normal.email-subscribe__button {
  @apply font-normal;
}
.btn.btn-bold, .email-subscribe .email-subscribe__container .btn-bold.email-subscribe__button {
  @apply font-bold;
}
.btn.btn-rounded, .email-subscribe .email-subscribe__container .btn-rounded.email-subscribe__button {
  @apply rounded-full;
}
.btn.btn-flex, .email-subscribe .email-subscribe__container .btn-flex.email-subscribe__button {
  @apply flex justify-center items-center;
}
.btn.btn-centred, .email-subscribe .email-subscribe__container .btn-centred.email-subscribe__button {
  @apply mx-auto;
}
.btn.btn-blue-dark, .email-subscribe .email-subscribe__container .btn-blue-dark.email-subscribe__button {
  @apply bg-blue-dark text-white;
}
.btn.btn-blue-light, .email-subscribe .email-subscribe__container .btn-blue-light.email-subscribe__button {
  @apply bg-blue-light text-white;
}
.btn.btn-blue-muted, .email-subscribe .email-subscribe__container .btn-blue-muted.email-subscribe__button {
  @apply bg-blue-muted text-blue-dark;
}
.btn.btn-esc-red, .email-subscribe .email-subscribe__container .btn-esc-red.email-subscribe__button {
  @apply bg-pink text-white;
}
.btn.btn-grey, .email-subscribe .email-subscribe__container .btn-grey.email-subscribe__button {
  @apply bg-escgrey-midlight text-blue-dark;
}
.btn.btn-yellow, .email-subscribe .email-subscribe__container .btn-yellow.email-subscribe__button {
  @apply bg-yellow text-white;
}

.btn-alert,
.btn-follow,
.btn-bookmark {
  @apply flex justify-center items-center w-5 h-5 cursor-pointer z-20;
}
.btn-alert .btn-alert-icon,
.btn-alert .btn-follow-icon,
.btn-alert .btn-bookmark-icon,
.btn-follow .btn-alert-icon,
.btn-follow .btn-follow-icon,
.btn-follow .btn-bookmark-icon,
.btn-bookmark .btn-alert-icon,
.btn-bookmark .btn-follow-icon,
.btn-bookmark .btn-bookmark-icon {
  @apply w-full h-auto;
}
.btn-alert.active .btn-alert-icon,
.btn-alert.active .btn-follow-icon,
.btn-alert.active .btn-bookmark-icon,
.btn-follow.active .btn-alert-icon,
.btn-follow.active .btn-follow-icon,
.btn-follow.active .btn-bookmark-icon,
.btn-bookmark.active .btn-alert-icon,
.btn-bookmark.active .btn-follow-icon,
.btn-bookmark.active .btn-bookmark-icon {
  @apply stroke-current fill-current text-pink;
}
.btn-alert.active .btn-alert-icon path,
.btn-alert.active .btn-follow-icon path,
.btn-alert.active .btn-bookmark-icon path,
.btn-follow.active .btn-alert-icon path,
.btn-follow.active .btn-follow-icon path,
.btn-follow.active .btn-bookmark-icon path,
.btn-bookmark.active .btn-alert-icon path,
.btn-bookmark.active .btn-follow-icon path,
.btn-bookmark.active .btn-bookmark-icon path {
  @apply stroke-current text-pink;
}

.btn-notification {
  @apply bg-white text-blue-light border-2 border-blue-light outline-none;
}
.btn-notification.active {
  @apply bg-blue-light text-white;
}

.button {
  @apply min-w-0 inline-flex items-center justify-center text-sm font-bold text-center p-3 rounded-md border border-transparent whitespace-nowrap focus:outline-none cursor-pointer transition-colors duration-150;
}

.button-sm {
  @apply text-xs py-1.5;
}

.button-lg {
  @apply p-5;
}

.button-pill {
  @apply rounded-full;
}

.button-rounded {
  @apply rounded-lg;
}

.button-block {
  @apply w-full;
}

.button-red-white {
  @apply bg-red-esc text-white hover:bg-red-500;
}

.button-red-light-red {
  @apply bg-red-esclight text-red-esc hover:bg-red-200;
}

.button-red-light-white {
  @apply bg-red-esclight text-white hover:bg-red-200;
}

.button-red-white-outline {
  @apply bg-red-esc text-white border border-white hover:bg-red-200;
}

.button-blue-white {
  @apply bg-blue-dark text-white;
}

.button-blue-light-white {
  @apply bg-blue-light text-white hover:bg-blue-300;
}

.button-white-blue-light-outline {
  @apply bg-white text-blue-light border border-blue-light hover:bg-blue-light hover:text-white;
}

.button-white-blue-light {
  @apply bg-white text-blue-light hover:bg-blue-light hover:text-white;
}

.button-grey {
  @apply bg-grey text-grey-dark hover:bg-grey-dark hover:text-grey;
}

.button-group {
  @apply flex flex-col md:flex-row justify-start items-center;
}
.button-group > * {
  @apply my-1.5 md:mr-2.5;
}
.button-group > *:last-child {
  @apply md:mr-0;
}

.button-icon {
  @apply mx-1;
}
.button-icon-left {
  @apply mr-1;
}
.button-icon-right {
  @apply m-1;
}

.button-save,
.button-saved {
  @apply w-36 py-1.5 bg-red text-white font-normal border border-red;
}
.button-save .btn-icon,
.button-saved .btn-icon {
  @apply w-4 h-auto mr-1.5 fill-current;
}
.button-save .spinner,
.button-saved .spinner {
  @apply mr-1.5;
}

.button-save {
  @apply bg-red text-white;
}
.button-save .btn-icon {
  @apply text-white;
}

.button-saved {
  @apply bg-red-esclight text-red;
}
.button-saved .btn-icon {
  @apply text-red;
}

.questions .questions-header {
  @apply relative mb-5;
}
.questions .questions-header .ask-public {
  @apply w-48 mb-4;
}
.questions .questions-header .ask-public .toggle-label {
  @apply flex-none flex items-center cursor-pointer;
}
.questions .questions-header .ask-public .toggle-label .toggle {
  @apply relative flex;
}
.questions .questions-header .ask-public .toggle-label .toggle input {
  @apply absolute opacity-0;
}
.questions .questions-header .ask-public .toggle-label .toggle input:checked ~ .toggle-line {
  @apply bg-blue-light;
}
.questions .questions-header .ask-public .toggle-label .toggle input:checked ~ .toggle-dot {
  @apply transform translate-x-full;
}
.questions .questions-header .ask-public .toggle-label .toggle .toggle-line {
  @apply block bg-gray-200 w-12 h-7 p-1 rounded-full transition-all ease-in-out duration-300;
}
.questions .questions-header .ask-public .toggle-label .toggle .toggle-dot {
  @apply absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition;
}
.questions .questions-header .ask-public .toggle-label p {
  @apply m-0 ml-3;
}
.questions .questions-header .ask-question .ask-question-inner {
  @apply relative flex;
}
.questions .questions-header .ask-question .ask-question-inner input {
  @apply w-full md:w-1/2 py-3 px-5 bg-white text-sm font-normal text-blue-dark border-2 border-gray-200 rounded-md outline-none;
}
.questions .questions-header .ask-question .ask-question-inner input.error {
  @apply border border-red-esc transition duration-100 ease-linear;
}
.questions .questions-header .ask-question .ask-question-inner .btn, .questions .questions-header .ask-question .ask-question-inner .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .questions .questions-header .ask-question .ask-question-inner .email-subscribe__button {
  @apply absolute top-0 right-0 bottom-0 md:relative min-w-0 flex justify-center items-center lg:w-40 m-0 mb-0 md:ml-2 px-5 outline-none;
}
.questions .questions-header .ask-question .ask-question-inner .btn span, .questions .questions-header .ask-question .ask-question-inner .email-subscribe .email-subscribe__container .email-subscribe__button span, .email-subscribe .email-subscribe__container .questions .questions-header .ask-question .ask-question-inner .email-subscribe__button span {
  @apply hidden md:block;
}
.questions .questions-header .ask-question .ask-question-inner .btn .arrow, .questions .questions-header .ask-question .ask-question-inner .email-subscribe .email-subscribe__container .email-subscribe__button .arrow, .email-subscribe .email-subscribe__container .questions .questions-header .ask-question .ask-question-inner .email-subscribe__button .arrow {
  @apply block md:hidden w-5 h-auto;
}
.questions .questions-header .ask-question-posted p {
  @apply text-green text-sm mt-2 transition duration-100 ease-linear;
}
.questions .questions-header p.error {
  @apply text-red-esc text-sm mt-2 transition duration-100 ease-linear;
}
.questions .questions-header .btn, .questions .questions-header .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .questions .questions-header .email-subscribe__button {
  @apply my-0;
}
.questions .questions-cards {
  @apply flex flex-col;
}
.questions .questions-cards .questions-card {
  @apply w-full bg-grey p-5 mb-2.5 border-2 border-gray-200 rounded-md shadow;
}
.questions .questions-cards .questions-card .questions-card__header {
  @apply flex flex-wrap;
}
.questions .questions-cards .questions-card .questions-card__header .questions-card__date {
  @apply text-xs font-bold text-blue-dark uppercase mb-2 mr-1.5;
}
.questions .questions-cards .questions-card .questions-card__header .questions-card__public-private {
  @apply text-xs text-blue-dark uppercase;
}
.questions .questions-cards .questions-card .questions-card__question {
  @apply text-sm font-normal text-blue-dark mb-2.5;
}
.questions .questions-cards .questions-card .questions-card__answer {
  @apply bg-white border-2 border-grey-medium p-5 rounded-md;
}
.questions .questions-cards .questions-card .questions-card__answer .questions-card__answer-title {
  @apply inline-block lg:block text-sm font-bold text-blue-dark uppercase mb-2.5;
}
.questions .questions-cards .questions-card .questions-card__answer .questions-card__answer-employers-logo {
  @apply w-10 h-auto lg:mr-2 inline-block align-top float-right lg:float-none lg:mt-0.5;
}
.questions .questions-cards .questions-card .questions-card__answer .questions-card__answer-employers-logo img, .questions .questions-cards .questions-card .questions-card__answer .questions-card__answer-employers-logo svg {
  @apply ml-auto lg:ml-0;
}
.questions .questions-cards .questions-card .questions-card__answer .questions-card__answer-response {
  @apply block lg:inline-block align-top w-5/6;
}
.questions .questions-cards .questions-card .questions-card__answer .questions-card__answer-response p {
  @apply text-sm font-normal text-blue-dark mb-0;
}

.search-filter {
  @apply w-full flex justify-center absolute left-1/2 -bottom-6 transform -translate-x-1/2;
}
.search-filter.is_fixed {
  @apply fixed top-12 pt-4 left-0 bg-blue-dark z-100 mt-0.5 pb-4 w-full bottom-auto translate-x-0 shadow-md;
}
.search-filter.is_fixed.has_menu {
  @apply top-24 mt-2.5;
}
.search-filter.is_fixed .search-filter__container form .search-filter__input {
  @apply p-3 pl-12;
}
.search-filter.is_fixed .search-filter__container form .search-filter__button {
  @apply bg-white text-blue-dark border-l border-gray-200;
}
.search-filter.is_fixed .search-filter__container form .search-filter__button .search-filter__button-icon path {
  @apply fill-current text-blue-dark;
}
.search-filter .search-filter__container {
  @apply relative flex items-center w-full mx-4 md:mx-6 rounded-full shadow-sm;
  max-width: 600px;
}
.search-filter .search-filter__container form {
  @apply relative flex items-center w-full h-full;
}
.search-filter .search-filter__container form.search .search-filter__icon {
  @apply hidden md:block;
}
.search-filter .search-filter__container form.search .search-filter__input {
  @apply pl-8 md:pl-12;
}
.search-filter .search-filter__container form .search-filter__icon {
  @apply absolute left-4 w-6;
}
.search-filter .search-filter__container form .search-filter__input {
  @apply w-full rounded-l-full p-4 pl-12 border-t border-b border-l border-gray-200 text-sm md:text-base text-blue-dark bg-white outline-none;
}
.search-filter .search-filter__container form .search-filter__button {
  @apply relative h-full w-20 md:w-32 rounded-r-full border-t border-b border-r border-blue-dark bg-blue-dark text-white text-sm font-bold	uppercase focus:outline-none cursor-pointer;
}
.search-filter .search-filter__container form .search-filter__button .search-filter__button-icon {
  @apply md:hidden mx-auto w-6 h-auto;
}
.search-filter .search-filter__container form .search-filter__button .search-filter__button-count {
  @apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
}

.collection {
  @apply -mt-8;
}

.collection-cards {
  @apply flex flex-wrap w-full;
}
.collection-cards .collection-card__container {
  @apply w-full md:w-1/3 h-64 my-4 px-4;
}
.collection-cards .collection-card__container .collection-card {
  @apply relative w-full h-full bg-no-repeat bg-center bg-cover p-2 rounded-md z-0;
}
.collection-cards .collection-card__container .collection-card:before {
  content: "";
  @apply absolute inset-0 bg-black opacity-50 rounded-md -z-1;
}
.collection-cards .collection-card__container .collection-card a {
  @apply flex flex-col justify-center items-center text-center w-full h-full;
}
.collection-cards .collection-card__container .collection-card a .collection-card__title {
  @apply text-xl font-bold text-white mb-2.5;
}
.collection-cards .collection-card__container .collection-card a .btn, .collection-cards .collection-card__container .collection-card a .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .collection-cards .collection-card__container .collection-card a .email-subscribe__button {
  @apply min-w-0 text-sm font-bold text-white py-1;
}

.resources__cards {
  @apply flex w-full h-full pt-5;
}
.resources__cards .resources__card {
  @apply w-full border border-gray-50 rounded shadow-md cursor-move;
}
.resources__cards .resources__card a {
  @apply flex flex-col h-full;
}
.resources__cards .resources__card .resources__header {
  @apply relative w-full h-32 p-5 bg-grey bg-center bg-cover bg-no-repeat border-b border-grey-medium rounded-t;
}
.resources__cards .resources__card .resources__body {
  @apply flex flex-col justify-between flex-1 bg-white text-base font-normal text-blue-dark leading-snug text-left p-4;
}
.resources__cards .resources__card .resources__body .resources__body-tags {
  @apply flex flex-wrap mt-5;
}
.resources__cards .resources__card .resources__body .resources__body-tags li {
  @apply text-xxs font-bold text-grey-light uppercase no-underline mr-1.5;
}
.resources__cards .resources__card .resources__body .resources__body-tags li:after {
  content: ", ";
  @apply inline-block;
}
.resources__cards .resources__card .resources__body .resources__body-tags li:last-child:after {
  @apply hidden;
}

.site-alert {
  @apply fixed w-full bg-white bottom-0 left-0 z-[20] text-center px-16 pt-2;
}
.site-alert .site-alert__message {
  @apply text-current inline-block mb-2;
}
.site-alert .site-alert__button {
  @apply min-w-0 inline-flex justify-center items-center font-bold text-base text-center mx-6 mb-2 py-1.5 px-3 rounded-md whitespace-nowrap outline-none focus:outline-none cursor-pointer;
}
.site-alert .site-alert__dismiss {
  @apply absolute h-6 w-6 top-3 right-3 cursor-pointer;
}

.site-alert__anim-enter-active,
.site-alert__anim-leave-active {
  transition: transform 0.5s ease;
}

.site-alert__anim-enter-from,
.site-alert__anim-leave-to {
  transform: translateY(100%);
}

.site-search-btn {
  @apply relative w-36 h-8 bg-white text-blue-dark text-sm text-left ml-5 mr-2 pl-10 rounded-md;
}
.site-search-btn .site-search-btn__icon {
  @apply absolute left-2.5 w-5;
}

.site-search {
  @apply relative text-blue-dark h-16 flex items-center mb-4;
}
.site-search .site-search__container {
  @apply relative flex items-center w-full h-16 rounded-full shadow-sm;
}
.site-search .site-search__container .site-search__icon {
  @apply absolute left-5 w-5;
}
.site-search .site-search__container .site-search__clear {
  @apply absolute right-0 w-20 h-full flex justify-center items-center text-sm cursor-pointer;
}
.site-search .site-search__container .site-search__input {
  @apply w-full h-full pl-14 pr-20 text-sm text-blue-dark bg-white outline-none rounded-b-lg;
}

.site-search-results-ko-layer {
  @apply fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70 z-50;
}

.site-search-results {
  @apply fixed top-0 flex flex-col justify-start z-50 overflow-hidden inset-x-4 md:inset-x-[92px] max-w-3xl xl:max-w-4xl;
  max-height: calc(100% - 6rem);
}
.site-search-results .site-search-results__quick-links {
  @apply bg-white mb-4 p-6 rounded-lg;
}
.site-search-results .site-search-results__quick-links:last-child {
  @apply mb-0;
}
.site-search-results .site-search-results__quick-links .site-search-results__quick-links-title {
  @apply flex justify-between items-center text-base font-normal text-blue-dark mb-4;
}
.site-search-results .site-search-results__quick-links .site-search-results__quick-links-title span {
  @apply text-sm ml-2.5;
}
.site-search-results .site-search-results__quick-links .site-search-results__quick-links-list {
  @apply space-y-4;
}
.site-search-results .site-search-results__quick-links .site-search-results__quick-links-list li a {
  @apply flex items-center font-bold;
}
.site-search-results .site-search-results__quick-links .site-search-results__quick-links-list li a.chevron:after {
  @apply w-5 h-5 ml-1.5;
  content: "";
  background-image: url(/images/icons/search/chevron-right.svg);
}
.site-search-results .site-search-results__quick-links .site-search-results__quick-links-list li a.magnifying-glass:before {
  @apply w-4 h-4 mr-2;
  content: "";
  background-image: url(/images/icons/search/magnifying-glass.svg);
}
.site-search-results .site-search-results__results-area {
  @apply w-full flex justify-start flex-wrap flex-grow space-y-4 overflow-x-hidden;
}
.site-search-results .site-search-results__results-area .site-search-results__group {
  @apply w-full bg-white p-6 rounded-lg;
}
.site-search-results .site-search-results__results-area .site-search-results__group:last-child {
  @apply mb-0;
}
.site-search-results .site-search-results__results-area .site-search-results__group.half {
  @apply w-full lg:w-1/2;
}
.site-search-results .site-search-results__results-area .site-search-results__group.half.events {
  @apply lg:pr-4 rounded-b-none lg:rounded-l-lg lg:rounded-r-none;
}
.site-search-results .site-search-results__results-area .site-search-results__group.half.courses {
  @apply mt-0 lg:mt-4 pt-2 lg:pt-6 lg:pl-4 rounded-t-none lg:rounded-r-lg lg:rounded-l-none;
}
.site-search-results .site-search-results__results-area .site-search-results__group .see-all {
  @apply bg-blue-extralight block w-full text-center text-sm font-bold mt-3 p-2 rounded-md border border-gray-200;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-results__group-heading {
  @apply w-full flex flex-wrap justify-between;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-results__group-heading h4 {
  @apply text-sm md:text-base font-bold cursor-pointer mb-4;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-results__group-heading h4 span {
  @apply font-normal;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-results__group-heading a {
  @apply text-sm md:text-base font-bold underline mb-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards {
  @apply space-y-3;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card {
  @apply bg-grey p-3 rounded-md border border-gray-200;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper {
  @apply flex items-center;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__logo-container {
  @apply w-9 h-9 flex justify-center items-center bg-white rounded-md border border-gray-200 mr-4 overflow-hidden;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__logo-container .site-search-job-card__logo {
  @apply w-full h-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body {
  @apply w-full flex flex-wrap flex-1 items-center;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start {
  @apply pr-3;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start .site-search-job-card__title {
  @apply text-sm font-bold text-blue-dark;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start .site-search-job-card__group-items {
  @apply flex flex-wrap items-center w-full;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start .site-search-job-card__group-items li {
  @apply text-xs font-bold text-blue-dark flex items-center my-1 mr-4;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start .site-search-job-card__group-items li p {
  @apply text-sm font-normal;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start .site-search-job-card__group-items li span {
  @apply font-normal;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start .site-search-job-card__group-items li .job-card__group-icon-wrapper {
  @apply w-5 h-auto mr-0.5;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-start .site-search-job-card__group-items li .job-card__group-icon-wrapper .job-card__group-icon {
  @apply w-auto h-4;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-end {
  @apply flex items-center w-auto text-xs font-bold text-blue-dark space-x-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-end p span {
  @apply font-normal;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .site-search-job-card__wrapper .site-search-job-card__body .site-search-job-card__body-end .site-search-job-card__body-end-term {
  @apply bg-blue text-white text-xxs font-normal whitespace-nowrap px-1 rounded-sm;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .chevron {
  @apply w-auto h-3 rotate-180 ml-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-job-cards .site-search-job-card__container .site-search-job-card .chevron path {
  @apply fill-current text-blue-dark;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards {
  @apply grid md:grid-cols-2 lg:grid-cols-3 gap-3;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card {
  @apply flex items-center h-full bg-grey p-3 rounded-md border border-gray-200;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card .site-search-organisation-card__logo-container {
  @apply w-9 h-9 flex justify-center items-center bg-white rounded-md border border-gray-200 overflow-hidden;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card .site-search-organisation-card__logo-container .site-search-organisation-card__logo {
  @apply w-full h-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card .site-search-organisation-card__title {
  @apply flex-1 text-sm font-bold pl-4 pr-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card .site-search-organisation-card__group {
  @apply flex items-center ml-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card .site-search-organisation-card__group .site-search-organisation-card__body-tablet {
  @apply bg-blue text-white text-xxs font-normal whitespace-nowrap mr-2 px-1 rounded-sm;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card .chevron {
  @apply w-auto h-3 rotate-180 ml-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-organisation-cards .site-search-organisation-card-container .site-search-organisation-card .chevron path {
  @apply fill-current text-blue-dark;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card {
  @apply flex items-center h-full bg-grey p-3 rounded-md border border-gray-200;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__logo-container {
  @apply w-9 h-9 flex justify-center items-center self-start bg-white rounded-md border border-gray-200 overflow-hidden;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__logo-container .site-search-event-card__logo {
  @apply w-full h-full object-cover object-center;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body {
  @apply flex flex-1 flex-col pl-4 pr-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body .site-search-event-card__title {
  @apply text-sm font-bold mb-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body .site-search-event-card__group {
  @apply flex items-center;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body .site-search-event-card__group .site-search-event-card__body-group-date {
  @apply flex items-center mr-8;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body .site-search-event-card__group .site-search-event-card__body-group-date .icon-wrapper {
  @apply w-[14px] h-auto mr-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body .site-search-event-card__group .site-search-event-card__body-group-date .icon-wrapper .icon {
  @apply w-full h-full;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body .site-search-event-card__group .site-search-event-card__body-group-date p {
  @apply text-xs font-bold;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .site-search-event-card__body .site-search-event-card__group .site-search-event-card__footer-price {
  @apply text-sm font-bold;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .chevron {
  @apply w-auto h-3 rotate-180 ml-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-event-card-container .site-search-event-card .chevron path {
  @apply fill-current text-blue-dark;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card {
  @apply flex items-center h-full bg-grey p-3 rounded-md border border-gray-200;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__logo-container {
  @apply w-9 h-9 flex justify-center items-center self-start bg-white rounded-md border border-gray-200 overflow-hidden;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__logo-container .site-search-course-program-card__logo {
  @apply w-full h-full object-cover object-center;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body {
  @apply flex flex-1 flex-col pl-4 pr-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body .site-search-course-program-card__title {
  @apply text-sm font-bold mb-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body .site-search-course-program-card__group {
  @apply flex items-center;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body .site-search-course-program-card__group .site-search-course-program-card__body-group-date {
  @apply flex items-center mr-8;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body .site-search-course-program-card__group .site-search-course-program-card__body-group-date .icon-wrapper {
  @apply w-[14px] h-auto mr-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body .site-search-course-program-card__group .site-search-course-program-card__body-group-date .icon-wrapper .icon {
  @apply w-full h-full;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body .site-search-course-program-card__group .site-search-course-program-card__body-group-date p {
  @apply text-xs font-bold;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .site-search-course-program-card__body .site-search-course-program-card__group .site-search-course-program-card__footer-price {
  @apply text-sm font-bold;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .chevron {
  @apply w-auto h-3 rotate-180 ml-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-course-program-card-container .site-search-course-program-card .chevron path {
  @apply fill-current text-blue-dark;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards {
  @apply space-y-3;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card {
  @apply flex items-center w-full bg-grey p-3 rounded-md border border-gray-200;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card .site-search-resource-card__logo-container {
  @apply w-9 h-9 flex justify-center items-center bg-white rounded-md border border-gray-200 overflow-hidden;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card .site-search-resource-card__logo-container .site-search-resource-card__logo {
  @apply w-full h-full object-cover object-center;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card .site-search-resource-card__title {
  @apply max-w-[60%] text-sm font-bold text-blue-dark pl-4 pr-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card .site-search-resource-card__tablet-group {
  @apply flex items-center space-x-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card .site-search-resource-card__tablet-group .site-search-resource-card__tablet {
  @apply bg-escgrey-dark text-white text-xxs font-normal whitespace-nowrap px-1 rounded-sm;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card .chevron {
  @apply w-auto h-3 rotate-180 ml-auto;
}
.site-search-results .site-search-results__results-area .site-search-results__group .site-search-resource-cards .site-search-resource-card-container .site-search-resource-card .chevron path {
  @apply fill-current text-blue-dark;
}
.site-search-results .site-search-results__results-area .site-search-results__group .collection-cards.all {
  @apply w-[calc(100%+1rem)] -ml-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .collection-cards.all .collection-card__container {
  @apply h-48 mt-0 mb-4 last:mb-0 md:my-0 px-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .collection-cards.all .collection-card__container .collection-card__title {
  @apply text-lg;
}
.site-search-results .site-search-results__results-area .site-search-results__group .other-cards {
  @apply flex flex-wrap w-[calc(100%+1rem)] -ml-2 -mb-4;
}
.site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container {
  @apply w-full md:w-1/3 h-48 mb-4 px-2;
}
.site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container .other-card {
  @apply relative w-full h-full bg-no-repeat bg-center bg-cover p-2 rounded-md z-0;
}
.site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container .other-card:before {
  content: "";
  @apply absolute inset-0 bg-black opacity-50 rounded-md -z-1;
}
.site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container .other-card a {
  @apply flex flex-col justify-center items-center text-center w-full h-full;
}
.site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container .other-card a .other-card__title {
  @apply text-lg font-bold text-white mb-2.5;
}
.site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container .other-card a .btn, .site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container .other-card a .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .site-search-results .site-search-results__results-area .site-search-results__group .other-cards .other-card__container .other-card a .email-subscribe__button {
  @apply min-w-0 text-sm font-bold text-white py-1;
}

.carousel {
  @apply container mx-auto;
}
.carousel .carousel__viewport .carousel__track .carousel__slide {
  @apply w-full sm:w-1/2 lg:w-1/3 xl:w-1/4;
}
.carousel .carousel__viewport .carousel__track .carousel__slide .thumbnail {
  @apply bg-grey h-52 md:h-56 bg-no-repeat bg-cover bg-center rounded-md cursor-pointer;
}

.newsletter-subscribe {
  @apply bg-white w-full h-auto text-sm font-normal text-center p-5 border border-escgrey-midlight rounded-lg;
  height: fit-content;
}
.newsletter-subscribe.bg-grey {
  @apply bg-grey;
}
.newsletter-subscribe .newsletter-subscribe__header {
  @apply w-[90%] mx-auto mb-5 space-y-3;
}
.newsletter-subscribe .newsletter-subscribe__header > :first-child {
  @apply text-base font-bold;
}
.newsletter-subscribe .newsletter-subscribe__header p {
  @apply text-xs font-normal;
}
.newsletter-subscribe .newsletter-subscribe__footer ul {
  @apply w-[90%] flex justify-between items-center mt-7 mb-1 mx-auto;
}

.email-subscribe .email-subscribe__container .email-subscribe__input {
  @apply w-full py-3.5 px-6 border border-[#D9D9D9] rounded-full;
}
.email-subscribe .email-subscribe__container .email-subscribe__input.error {
  @apply border-red-esc transition duration-150 ease-linear;
}
.email-subscribe .email-subscribe__container .email-subscribe__input + .error {
  @apply w-full text-center text-sm text-red-esc mt-1.5 transition duration-150 ease-linear;
}
.email-subscribe .email-subscribe__container .email-subscribe__button {
  @apply w-full bg-blue-dark text-white mb-0.5 py-4 rounded-full;
}

.nav-icon {
  @apply relative w-6 h-5 transform rotate-0 transition-all duration-500 ease-in-out cursor-pointer;
}

.nav-icon span {
  @apply absolute left-0 block w-full h-0.5 bg-white rounded-sm opacity-100 transform rotate-0 transition-all duration-200 ease-in-out;
}

.nav-icon span:nth-child(1) {
  @apply top-0;
}

.nav-icon span:nth-child(2),
.nav-icon span:nth-child(3) {
  @apply top-2;
}

.nav-icon span:nth-child(4) {
  @apply top-4;
}

.nav-icon.open span:nth-child(1),
.nav-icon.open span:nth-child(4) {
  @apply w-0 top-3 left-1/2;
}

.nav-icon.open span:nth-child(2) {
  @apply transform rotate-45;
}

.nav-icon.open span:nth-child(3) {
  @apply transform -rotate-45;
}

.quiz {
  @apply pb-28;
}
.quiz .quiz__container {
  @apply container mx-auto px-4;
}
.quiz .quiz__container .quiz__inner {
  @apply p-10 lg:px-16 xl:px-20 border-2 bg-white border-gray-300 rounded-4xl overflow-hidden;
}
.quiz .quiz__container .quiz__inner.iframe {
  @apply p-0;
  height: 38rem;
}
.quiz .quiz__container .quiz__inner .quiz__iframe {
  @apply block w-full h-full border-none bg-white;
}
.quiz .quiz__container .quiz__inner .quiz__title {
  @apply text-3xl font-black mb-2 text-center;
}
.quiz .quiz__container .quiz__inner .quiz__sub-title {
  @apply hidden lg:block text-xl font-black mb-2 text-center;
}
.quiz .quiz__container .quiz__inner .quiz__main {
  @apply flex flex-col lg:flex-row-reverse items-center mt-10;
}
.quiz .quiz__container .quiz__inner .quiz__main .quiz__main-img {
  @apply w-3/4 md:w-2/4 lg:w-2/5 xl:w-1/3 max-w-max mb-10 lg:mb-0;
}
.quiz .quiz__container .quiz__inner .quiz__main .quiz__main-text {
  @apply flex flex-col justify-center w-full lg:w-4/5 lg:pr-10;
}
.quiz .quiz__container .quiz__inner .quiz__main .quiz__main-text p {
  @apply mb-4;
}
.quiz .quiz__container .quiz__inner .quiz__main .quiz__btn {
  @apply w-full sm:w-72 bg-blue-light text-xl text-white text-center font-black leading-none uppercase my-4 mx-auto p-4 rounded-md cursor-pointer;
}
.quiz .quiz__container .quiz__inner .quiz__main .quiz__outro {
  @apply text-center text-sm;
}

.podcast-banner {
  @apply container mx-auto px-4;
}
.podcast-banner .podcast-banner__container {
  @apply flex flex-col lg:flex-row bg-blue text-white rounded-3xl p-10;
}
.podcast-banner .podcast-banner__container .podcast-banner__image {
  @apply w-full lg:w-1/2;
}
.podcast-banner .podcast-banner__container .podcast-banner__image img {
  @apply max-w-xs lg:max-w-none	mx-auto -rotate-3 pb-12 lg:pb-0;
}
.podcast-banner .podcast-banner__container .podcast-banner__content {
  @apply w-full lg:w-1/2 flex flex-col justify-center lg:px-10;
}
.podcast-banner .podcast-banner__container .podcast-banner__content .podcast-banner__title {
  @apply text-xl mb-2;
}
.podcast-banner .podcast-banner__container .podcast-banner__content .podcast-banner__sub-title {
  @apply text-4xl font-semibold mb-4;
}
.podcast-banner .podcast-banner__container .podcast-banner__content .podcast-banner__intro {
  @apply text-yellow font-semibold mb-4 uppercase;
}
.podcast-banner .podcast-banner__container .podcast-banner__content .podcast-banner__description {
  @apply mb-4;
}
.podcast-banner .podcast-banner__container .podcast-banner__content .btn-yellow {
  @apply w-min mb-0 uppercase;
}

.auto-complete-org {
  @apply relative w-full h-full flex flex-col justify-start;
}
.auto-complete-org .auto-complete-org__input-area {
  @apply relative w-full rounded-3xl border border-escgrey-midlight bg-white py-2 pl-8 pr-2;
}
.auto-complete-org .auto-complete-org__input-area input {
  @apply ring-0 focus:outline-none w-full;
}
.auto-complete-org .auto-complete-org__input-area.with-results {
  @apply rounded-bl-none rounded-br-none border-b-0;
}
.auto-complete-org .auto-complete-org__input-area .search-icon {
  @apply absolute w-4 h-4 left-3 top-3;
}
.auto-complete-org .auto-complete-org__results-area {
  @apply absolute top-10 w-full rounded-bl-3xl rounded-br-3xl border border-escgrey-midlight bg-white py-2;
}
.auto-complete-org .auto-complete-org__results-area li {
  @apply w-full p-2 flex items-start justify-start cursor-pointer pl-4 text-left;
}
.auto-complete-org .auto-complete-org__results-area li .logo-img {
  @apply w-7 h-7 border border-escgrey-midlight relative rounded-md overflow-hidden mr-2;
}
.auto-complete-org .auto-complete-org__results-area li .logo-img img {
  @apply absolute w-full h-full object-cover;
}
.auto-complete-org .auto-complete-org__results-area li .label {
  @apply pt-[2px];
}

.share-btn {
  @apply relative flex items-center bg-white text-sm font-semibold text-blue-dark py-3 px-6 rounded-full cursor-default;
}
.share-btn .icon.share {
  @apply w-6 h-auto mr-3;
}
.share-btn .icon.share path {
  @apply fill-current text-blue-dark;
}
.share-btn:hover .share-btn-icons-container {
  @apply block;
}
.share-btn .share-btn-icons-container {
  @apply hidden absolute transition-all ease-in-out duration-500 cursor-pointer;
}
.share-btn .share-btn-icons-container .share-btn-icons {
  @apply flex bg-white py-3 px-6 rounded-full space-x-4 border drop-shadow-lg;
}
.share-btn:hover .share-btn-icons-container {
  @apply block;
}
.share-btn.left .share-btn-icons-container {
  @apply top-0 right-full pr-3;
}
.share-btn.right .share-btn-icons-container {
  @apply top-0 left-full pl-3;
}
.share-btn.top .share-btn-icons-container {
  @apply bottom-full left-1/2 -translate-x-1/2;
}
.share-btn.bottom .share-btn-icons-container {
  @apply top-full left-1/2 -translate-x-1/2;
}

.share-icon {
  @apply relative w-auto h-12 pl-1 cursor-default;
}
.share-icon .icon.share {
  @apply w-8 h-auto;
}
.share-icon .share-icon-icons-container {
  @apply hidden absolute transition-all ease-in-out duration-500 cursor-pointer;
}
.share-icon .share-icon-icons-container .share-icon-icons {
  @apply flex items-center bg-white py-2.5 px-6 rounded-full space-x-4 border drop-shadow-lg;
}
.share-icon:hover .share-icon-icons-container {
  @apply block;
}
.share-icon.left .share-icon-icons-container {
  @apply top-1/2 right-full -translate-y-1/2 pr-3;
}
.share-icon.right .share-icon-icons-container {
  @apply top-1/2 left-full -translate-y-1/2 pl-3;
}
.share-icon.top .share-icon-icons-container {
  @apply top-0 left-1/2 -translate-x-1/2 -translate-y-full;
}
.share-icon.bottom .share-icon-icons-container {
  @apply top-full left-1/2 -translate-x-1/2;
}
.share-icon.bottom-left .share-icon-icons-container {
  @apply top-full right-0;
}

.loader-overlay {
  @apply absolute left-0 top-0 w-full h-full flex justify-center items-center bg-opacity-50 bg-white z-100;
}
.loader-overlay .lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-overlay .lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.loader-overlay .lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  @apply bg-blue;
}
.loader-overlay .lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.loader-overlay .lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.loader-overlay .lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.loader-overlay .lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.loader-overlay .lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.loader-overlay .lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.loader-overlay .lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.loader-overlay .lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.loader-overlay .lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.loader-overlay .lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.loader-overlay .lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.loader-overlay .lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.loader-overlay .lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.loader-overlay .lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.loader-overlay .lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.loader-overlay .lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.video-facade__container {
  @apply relative flex justify-center w-full h-auto items-center bg-[#D9D9D9] cursor-pointer;
  aspect-ratio: 16/9;
}
.video-facade__container .video-facade__img {
  @apply absolute inset-0 object-cover object-center w-full h-full z-0;
}
.video-facade__container .video-facade__play-btn {
  @apply w-16 h-auto text-white z-10;
}

.tabs-component .tabs-component__header {
  @apply relative;
}
.tabs-component .tabs-component__header .select-arrow {
  @apply absolute top-1/2 -translate-y-1/2 right-6 w-2 h-auto -rotate-90;
}
.tabs-component .tabs-component__header > select {
  @apply w-full bg-grey text-blue-dark text-sm font-bold px-7 py-4 rounded-full outline-none lg:hidden appearance-none cursor-pointer;
}
.tabs-component .tabs-component__header > select option {
  @apply bg-white text-blue-dark text-base py-10 cursor-pointer;
}
.tabs-component .tabs-component__header .select-arrow {
  @apply right-8;
}
.tabs-component .tabs-component__header .select-arrow path {
  @apply fill-current text-blue-dark;
}
.tabs-component .tabs-component__header ul {
  @apply hidden lg:flex items-center gap-2;
}
.tabs-component .tabs-component__header ul li {
  @apply text-blue-dark text-base px-4 py-2 whitespace-nowrap cursor-pointer transition-all ease-in-out duration-300;
  @apply hover:bg-grey hover:rounded-full hover:font-bold;
}
.tabs-component .tabs-component__header ul li.is-active {
  @apply bg-grey rounded-full font-bold;
}
.tabs-component .tabs-component__content {
  @apply rounded-b-lg lg:rounded-tr-lg;
}
.tabs-component .tabs-component__content > div {
  @apply h-0 opacity-0 transition ease-in-out duration-1000 pointer-events-none;
}
.tabs-component .tabs-component__content > div.is-active {
  @apply h-auto opacity-100 pointer-events-auto;
}

.browse-sections {
  @apply bg-offwhite pt-16 pb-16;
}
.browse-sections .container {
  @apply container mx-auto px-4;
}
.browse-sections .container h2 {
  @apply text-3xl text-black font-bold;
}
.browse-sections .container .browse-sections__groups {
  @apply pt-6;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-menu menu {
  @apply flex justify-start list-none p-0 mb-8;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-menu menu li {
  @apply mr-8 text-black text-lg font-medium cursor-pointer;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-menu menu li.active {
  @apply font-bold;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group {
  @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item {
  @apply flex flex-col w-full bg-white mb-5 rounded-lg border border-gray-200;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper {
  @apply aspect-w-2 aspect-h-1 rounded-t-lg;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper .upper__icon {
  @apply flex justify-center items-center;
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper .upper__icon span {
  @apply inline-block text-[54px];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-1] {
  @apply bg-[#162f47] text-[#d3ebf2];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-2] {
  @apply bg-[#204666] text-[#d3ebf2];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-3] {
  @apply bg-[#2d668c] text-[#d3ebf2];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-4] {
  @apply bg-[#3d85ad] text-[#d3ebf2];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-5] {
  @apply bg-[#49a2c9] text-[#d3ebf2];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-6] {
  @apply bg-[#55bce0] text-[#162f47];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-7] {
  @apply bg-[#afe3ef] text-[#162f47];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=blue-8] {
  @apply bg-[#d3ebf2] text-[#162f47];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-1] {
  @apply bg-[#7040b5] text-[#d6d6fc];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-2] {
  @apply bg-[#7a50bc] text-[#d6d6fc];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-3] {
  @apply bg-[#825dc9] text-[#d6d6fc];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-4] {
  @apply bg-[#8566d3] text-[#d6d6fc];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-5] {
  @apply bg-[#8f76dd] text-[#d6d6fc];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-6] {
  @apply bg-[#a194ed] text-[#7040b5];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-7] {
  @apply bg-[#c9c7f9] text-[#7040b5];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=purple-8] {
  @apply bg-[#d6d6fc] text-[#7040b5];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-1] {
  @apply bg-[#d60303] text-[#f9cbca];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-2] {
  @apply bg-[#e80a0a] text-[#f9cbca];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-3] {
  @apply bg-[#fa2d0e] text-[#f9cbca];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-4] {
  @apply bg-[#ff4343] text-[#f9cbca];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-5] {
  @apply bg-[#ff5f5f] text-[#f9cbca];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-6] {
  @apply bg-[#ff7676] text-[#f9cbca];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-7] {
  @apply bg-[#ffabab] text-[#d60303];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=red-8] {
  @apply bg-[#f9cbca] text-[#d60303];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=orange-1] {
  @apply bg-[#fa9400] text-[#fce9c8];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=orange-3] {
  @apply bg-[#fbc32f] text-[#fce9c8];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=orange-5] {
  @apply bg-[#f9d58c] text-[#fa9400];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=orange-8] {
  @apply bg-[#fce9c8] text-[#fa9400];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=green-1] {
  @apply bg-[#00a50c] text-[#a8efa8];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=green-3] {
  @apply bg-[#2bbc2f] text-[#a8efa8];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=green-5] {
  @apply bg-[#53c953] text-[#a8efa8];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .upper[data-theme=green-8] {
  @apply bg-[#a8efa8] text-[#00a50c];
}
.browse-sections .container .browse-sections__groups .browse-group .browse-group__tab-group .browse-group__tab-item .lower {
  @apply p-3;
}

.top10-opportunities {
  @apply bg-[#F6F7F8] w-full;
}
.top10-opportunities .container {
  @apply w-full mx-auto px-4;
}
.top10-opportunities .container .top10-opportunities-title {
  @apply text-[2.5rem] font-bold text-center mb-16;
}
.top10-opportunities .container .top10-opportunities-card {
  @apply relative grid grid-rows-[0] min-h-[136vw] sm:min-h-[678px] md:min-h-[784px] lg:min-h-[428px] bg-white mb-10 p-8 lg:py-10 lg:px-12 rounded-2xl overflow-y-hidden;
  @apply after:absolute after:bottom-0 after:left-0 after:w-full after:h-28 after:bg-gradient-to-t after:from-white after:via-white/[98%] after:rounded-b-2xl;
  transition: grid-template-rows 500ms;
}
.top10-opportunities .container .top10-opportunities-card .top10-opportunities-card__container .top10-opportunities-card__intro {
  @apply relative w-full min-h-[276px] h-[98vw] sm:h-[538px] md:h-[628px] lg:h-[17.5rem] lg:flex-1 mb-4 md:mb-8 lg:mb-4 overflow-hidden;
  @apply after:absolute after:bottom-0 after:left-0 after:w-full after:h-9 after:bg-gradient-to-t after:from-white after:via-white;
}
.top10-opportunities .container .top10-opportunities-card .top10-opportunities-card__container .top10-opportunities-card__intro .top10-opportunities-card__image {
  @apply float-left w-full lg:w-96 h-auto bg-[#F6F7F8] rounded-xl lg:mr-10 mb-6 lg:mb-8;
}
.top10-opportunities .container .top10-opportunities-card .top10-opportunities-card__container .top10-opportunities-card__intro .top10-opportunities-card__image img {
  @apply w-full h-full object-cover rounded-xl;
}
.top10-opportunities .container .top10-opportunities-card .top10-opportunities-card__container .top10-opportunities-card__intro .top10-opportunities-card__intro-title {
  @apply text-xl font-bold mb-3;
}
.top10-opportunities .container .top10-opportunities-card .top-opportunities__cards {
  @apply grid md:grid-cols-2 lg:grid-cols-3 gap-8 w-full h-auto pointer-events-none;
}
.top10-opportunities .container .top10-opportunities-card .top10-opportunities-card__btn {
  @apply absolute bottom-8 left-1/2 -translate-x-1/2 w-40 z-10;
}
.top10-opportunities .container .top10-opportunities-card.is-open {
  @apply grid-rows-1;
  @apply after:opacity-0 after:pointer-events-none;
}
.top10-opportunities .container .top10-opportunities-card.is-open .top10-opportunities-card__container .top10-opportunities-card__text {
  @apply h-auto overflow-visible;
}
.top10-opportunities .container .top10-opportunities-card.is-open .top10-opportunities-card__container .top10-opportunities-card__intro {
  @apply h-auto overflow-visible;
}
.top10-opportunities .container .top10-opportunities-card.is-open .top-opportunities__cards {
  @apply pointer-events-auto;
}
.top10-opportunities .container .top10-opportunities-card.is-open .top10-opportunities-card__btn {
  @apply hidden;
}
.top10-opportunities .top10-opportunities-newsletter {
  @apply relative flex flex-col items-center;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__body {
  @apply flex max-w-[490px] w-full;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__body .email-subscribe__input {
  @apply w-full mb-0 py-4 px-6 border rounded-l-md rounded-r-none;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__body .email-subscribe__input.error {
  @apply border border-red;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__body .email-subscribe__button {
  @apply w-36 p-4 bg-green text-white font-semibold rounded-r-md;
}
.top10-opportunities .top10-opportunities-newsletter p.error {
  @apply absolute top-16 text-red;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__footer {
  @apply max-w-[615px] w-full flex flex-wrap items-center justify-evenly lg:justify-between mt-4;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__footer .avatars {
  @apply mt-7;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__footer .arrow {
  @apply lg:order-3;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__footer .text {
  @apply w-full lg:max-w-[300px] text-lg text-center lg:text-left mt-6 lg:mt-8;
}
.top10-opportunities .top10-opportunities-newsletter .top10-opportunities-newsletter__footer .text p {
  @apply w-full max-w-[300px] mx-auto;
}

.vue__loader {
  @apply w-full h-[calc(100vh-64px)] flex justify-center items-center;
}

.event-cards {
  @apply flex flex-wrap flex-col md:flex-row w-full rounded-b-md;
}
.event-cards .event-card-container {
  @apply w-full md:w-1/2 lg:w-1/3 my-4 px-4;
}
.event-cards .event-card-container .event-card {
  @apply bg-white rounded-md shadow-md h-full;
}
.event-cards .event-card-container .event-card .event-card__inner {
  @apply relative flex flex-col h-full text-left;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__header {
  @apply relative flex justify-between items-start bg-blue-dark bg-no-repeat bg-cover bg-center w-full h-32 p-5 rounded-t-md;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__header .event-card__header-message {
  @apply absolute right-0 bottom-0 text-xs font-bold text-white uppercase py-1 px-2;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__body {
  @apply flex-1 p-5 bg-white;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__body .event-card__body-title {
  @apply text-base font-bold text-blue-dark mb-4;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__body .event-card__body-group {
  @apply flex flex-wrap items-center;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__body .event-card__body-group .event-card__body-group-date {
  @apply flex items-center text-xs font-bold text-blue-dark mb-4 mr-5;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__body .event-card__body-group .event-card__body-group-date .icon-wrapper {
  @apply w-5 h-auto mr-1.5;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__body .event-card__body-group .event-card__body-group-date .icon-wrapper .icon {
  @apply w-full h-auto;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__body .event-card__body-text {
  @apply text-sm font-normal text-blue-dark;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__footer {
  @apply flex items-center py-3 px-5 rounded-b-md;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__footer .event-card__footer-img-wrapper {
  @apply flex justify-center items-center w-10 h-10 mr-2 bg-white rounded-full overflow-hidden;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__footer .event-card__footer-img-wrapper .event-card__footer-img {
  @apply w-full h-auto;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__footer .event-card__footer-group {
  @apply flex flex-col flex-1;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__footer .event-card__footer-group .event-card__footer-name {
  @apply flex-1 text-sm font-normal text-blue-dark;
}
.event-cards .event-card-container .event-card .event-card__inner .event-card__footer .event-card__footer-price {
  @apply text-base font-normal uppercase p-2 rounded-md;
}

.resource-cards {
  @apply flex flex-wrap flex-col md:flex-row w-full;
}
.resource-cards .resource-card-container {
  @apply w-full md:w-1/2 lg:w-1/3 my-4 px-4;
}
.resource-cards .resource-card-container .resource-card {
  @apply relative flex flex-col h-full text-left bg-white rounded-md shadow-md;
}
.resource-cards .resource-card-container .resource-card .resource-card__header {
  @apply flex justify-between items-start bg-blue-dark bg-no-repeat bg-cover bg-center w-full h-32 p-5 border-b border-grey-medium rounded-t-md;
}
.resource-cards .resource-card-container .resource-card .resource-card__body {
  @apply flex-1 p-5 bg-white;
}
.resource-cards .resource-card-container .resource-card .resource-card__body .resource-card__body-title {
  @apply text-base font-bold text-blue-dark mb-4;
}
.resource-cards .resource-card-container .resource-card .resource-card__body .resource-card__body-group {
  @apply flex flex-wrap items-center;
}
.resource-cards .resource-card-container .resource-card .resource-card__body .resource-card__body-group .resource-card__body-group-date {
  @apply flex items-center text-xs font-bold text-blue-dark mb-4 mr-5;
}
.resource-cards .resource-card-container .resource-card .resource-card__body .resource-card__body-group .resource-card__body-group-date .icon-wrapper {
  @apply w-5 h-auto mr-1.5;
}
.resource-cards .resource-card-container .resource-card .resource-card__body .resource-card__body-group .resource-card__body-group-date .icon-wrapper .icon {
  @apply w-full h-auto;
}
.resource-cards .resource-card-container .resource-card .resource-card__body .resource-card__body-text {
  @apply text-sm font-normal text-blue-dark;
}
.resource-cards .resource-card-container .resource-card .resource-card__footer {
  @apply flex items-center py-3 px-5 rounded-b-md min-h-4;
}
.resource-cards .resource-card-container .resource-card .resource-card__footer .resource-card__footer-img-wrapper {
  @apply w-10 h-10 mr-2 bg-white rounded-full overflow-hidden;
}
.resource-cards .resource-card-container .resource-card .resource-card__footer .resource-card__footer-img-wrapper .resource-card__footer-img {
  @apply w-full h-auto;
}
.resource-cards .resource-card-container .resource-card .resource-card__footer .resource-card__footer-group {
  @apply flex flex-col flex-1;
}
.resource-cards .resource-card-container .resource-card .resource-card__footer .resource-card__footer-group .resource-card__footer-title {
  @apply text-xs font-normal text-blue-dark;
}
.resource-cards .resource-card-container .resource-card .resource-card__footer .resource-card__footer-group .resource-card__footer-name {
  @apply text-sm font-normal text-blue-dark;
}
.resource-cards .resource-card-container .resource-card .resource-card__footer .resource-card__footer-price {
  @apply text-base font-normal uppercase p-2 rounded-md;
}

.programme-cards {
  @apply flex flex-wrap flex-col md:flex-row w-full;
}
.programme-cards .programme-card-container {
  @apply w-full md:w-1/2 lg:w-1/3 my-4 px-4;
}
.programme-cards .programme-card-container a {
  @apply flex flex-col flex-1;
}
.programme-cards .programme-card-container .programme-card {
  @apply relative flex flex-col h-full text-left bg-white rounded-md shadow-md;
}
.programme-cards .programme-card-container .programme-card .programme-card__header {
  @apply relative flex justify-between items-start bg-blue-dark bg-no-repeat bg-cover bg-center w-full h-32 p-5 rounded-t-md;
}
.programme-cards .programme-card-container .programme-card .programme-card__header .programme-card__header-message {
  @apply absolute right-0 bottom-0 text-xs font-bold text-white uppercase py-1 px-2;
}
.programme-cards .programme-card-container .programme-card .programme-card__header .programme-card__header-message.closing-soon {
  @apply bg-red-esc;
}
.programme-cards .programme-card-container .programme-card .programme-card__body {
  @apply flex-1 p-5 bg-white;
}
.programme-cards .programme-card-container .programme-card .programme-card__body .programme-card__body-title {
  @apply text-base font-bold text-blue-dark mb-4;
}
.programme-cards .programme-card-container .programme-card .programme-card__body .programme-card__body-group {
  @apply flex flex-wrap items-center;
}
.programme-cards .programme-card-container .programme-card .programme-card__body .programme-card__body-group .programme-card__body-item {
  @apply flex items-center text-xs font-bold text-blue-dark mb-4 mr-4;
}
.programme-cards .programme-card-container .programme-card .programme-card__body .programme-card__body-group .programme-card__body-item .icon-wrapper {
  @apply w-5 h-auto mr-1.5;
}
.programme-cards .programme-card-container .programme-card .programme-card__body .programme-card__body-group .programme-card__body-item .icon-wrapper .icon {
  @apply w-full h-auto;
}
.programme-cards .programme-card-container .programme-card .programme-card__body .programme-card__body-text {
  @apply text-sm font-normal text-blue-dark;
}
.programme-cards .programme-card-container .programme-card .programme-card__footer {
  @apply flex items-center min-h-0 h-16 py-3 px-5 rounded-b-md;
}
.programme-cards .programme-card-container .programme-card .programme-card__footer .programme-card__footer-img-wrapper {
  @apply w-10 h-10 flex justify-center items-center mr-2 bg-white rounded-full overflow-hidden;
}
.programme-cards .programme-card-container .programme-card .programme-card__footer .programme-card__footer-img-wrapper .programme-card__footer-img {
  @apply w-full h-auto;
}
.programme-cards .programme-card-container .programme-card .programme-card__footer .programme-card__footer-group {
  @apply flex flex-col flex-1;
}
.programme-cards .programme-card-container .programme-card .programme-card__footer .programme-card__footer-group .programme-card__footer-title {
  @apply text-xs font-normal text-blue-dark;
}
.programme-cards .programme-card-container .programme-card .programme-card__footer .programme-card__footer-group .programme-card__footer-name {
  @apply text-sm font-normal text-blue-dark;
}

.course-cards {
  @apply flex flex-wrap flex-col md:flex-row w-full;
}
.course-cards .course-card-container {
  @apply w-full md:w-1/2 lg:w-1/3 my-4 px-4;
}
.course-cards .course-card-container .course-card {
  @apply relative flex flex-col h-full text-left bg-white rounded-md shadow-md;
}
.course-cards .course-card-container .course-card a {
  @apply flex flex-col h-full;
}
.course-cards .course-card-container .course-card .course-card__header {
  @apply relative flex justify-between items-start bg-blue-dark bg-no-repeat bg-cover bg-center w-full h-32 p-5 rounded-t-md;
}
.course-cards .course-card-container .course-card .course-card__header .course-card__header-message {
  @apply absolute right-0 bottom-0 text-xs font-bold text-white uppercase py-1 px-2;
}
.course-cards .course-card-container .course-card .course-card__header .course-card__header-message.closing-soon {
  @apply bg-red-esc;
}
.course-cards .course-card-container .course-card .course-card__body {
  @apply flex-1 p-5 bg-white;
}
.course-cards .course-card-container .course-card .course-card__body .course-card__body-title {
  @apply text-base font-bold text-blue-dark mb-4;
}
.course-cards .course-card-container .course-card .course-card__body .course-card__body-text {
  @apply text-sm font-normal text-blue-dark;
}
.course-cards .course-card-container .course-card .course-card__footer {
  @apply flex items-center py-3 px-5 rounded-b-md h-16 min-h-0;
}
.course-cards .course-card-container .course-card .course-card__footer .course-card__footer-group {
  @apply flex items-center flex-1;
}
.course-cards .course-card-container .course-card .course-card__footer .course-card__footer-group .course-card__footer-item {
  @apply flex items-center text-xs md:text-sm font-bold text-blue-dark my-1.5 mr-5;
}
.course-cards .course-card-container .course-card .course-card__footer .course-card__footer-group .course-card__footer-item .icon-wrapper {
  @apply w-5 h-auto mx-2;
}
.course-cards .course-card-container .course-card .course-card__footer .course-card__footer-group .course-card__footer-item .icon-wrapper:first-child {
  @apply ml-0;
}
.course-cards .course-card-container .course-card .course-card__footer .course-card__footer-group .course-card__footer-item .icon-wrapper .icon {
  @apply w-full h-auto;
}
.course-cards .course-card-container .course-card .course-card__footer .course-card__footer-group .course-card__footer-item .course-card__footer-title {
  @apply text-xs font-normal text-blue-dark;
}
.course-cards .course-card-container .course-card .course-card__footer .course-card__footer-price {
  @apply text-base font-normal uppercase p-2 rounded-md;
}

.escape100-cards {
  @apply flex flex-col w-full max-w-screen-lg mx-auto;
}
.escape100-cards .escape100-container {
  @apply w-full mb-4;
}
.escape100-cards .escape100-container .escape100-card {
  @apply relative min-h-13 flex flex-col md:flex-row w-full rounded-md bg-white shadow-md;
}
.escape100-cards .escape100-container .escape100-card .escape100__header {
  @apply relative flex justify-between items-start w-full md:w-2/5 h-32 md:h-auto bg-no-repeat bg-cover bg-center p-5 rounded-t-md md:rounded-l-md md:rounded-r-none;
}
.escape100-cards .escape100-container .escape100-card .escape100__header .escape100__header-number {
  @apply flex justify-center items-center w-10 h-10 rounded-full bg-white font-bold border;
}
.escape100-cards .escape100-container .escape100-card .escape100__header .escape100__header-logo-container {
  @apply absolute right-7 md:-right-7 top-full md:top-1/2 transform -translate-y-1/2 w-14 h-14 flex justify-center items-center rounded-full border border-gray-200 bg-white overflow-hidden;
}
.escape100-cards .escape100-container .escape100-card .escape100__header .escape100__header-logo-container .escape100__header-logo {
  @apply w-14 h-auto;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper {
  @apply flex flex-col flex-1;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__body {
  @apply flex-1 text-left p-5 md:pl-12 md:rounded-tr-md;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__body .escape100__body-header {
  @apply flex justify-between items-center md:items-start;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__body .escape100__body-header .escape100__body-title {
  @apply flex-1 text-lg md:text-xl font-bold text-blue-dark mb-4 pr-4;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__body .escape100__body-header .escape100__body-title .escape100__body-location {
  @apply text-xs mt-1;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__body .escape100__body-header .escape100__body-jobs {
  @apply w-20 h-8 bg-blue-pale text-blue text-xs font-bold rounded uppercase m-0 cursor-pointer;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__body .escape100__body-text {
  @apply text-sm font-normal text-blue-dark mb-4;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__body .escape100__body-text:last-child {
  @apply mb-0;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer {
  @apply flex justify-between items-center p-5 pt-0 md:pl-12 rounded-br-md;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container {
  @apply flex justify-start items-center;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .escape100__footer-item,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .industry,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .review-score,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .listings,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .size {
  @apply flex items-center text-xs font-bold mr-5;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .escape100__footer-item-icon,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .industry-icon,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .review-score-icon,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .listings-icon,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .size-icon {
  @apply w-4 h-auto mr-1.5;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .escape100__footer-item .review-score-reviews,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .industry .review-score-reviews,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .review-score .review-score-reviews,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .listings .review-score-reviews,
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .escape100__footer-container .size .review-score-reviews {
  @apply font-normal ml-1;
}
.escape100-cards .escape100-container .escape100-card .escape100__wrapper .escape100__footer .more-info-label {
  @apply w-24 bg-blue-dark text-white text-xs font-bold px-2.5 py-2 rounded uppercase cursor-pointer;
}
.escape100-cards .escape100-container .more-info-checkbox {
  @apply hidden;
}
.escape100-cards .escape100-container .more-info-checkbox:checked + .escape100__dropdown {
  @apply flex flex-col-reverse md:flex-row transition-all duration-700 ease-in-out;
}
.escape100-cards .escape100-container .escape100__dropdown {
  @apply hidden w-full bg-white rounded-b-md shadow-md overflow-hidden transition-all duration-700 ease-in-out;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item {
  @apply w-full md:w-1/2 p-5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score {
  @apply w-full text-left;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .escape-score-title {
  @apply text-lg font-normal text-blue-dark mb-5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-checkbox {
  @apply hidden;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-checkbox:checked + .dropdown-btn .dropdown-btn-arrow {
  @apply transform rotate-90;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-checkbox:checked + .dropdown-btn + .dropdown-body {
  @apply block;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-btn {
  @apply flex items-center bg-grey-medium m-0 mb-3 py-3 px-4 rounded-md cursor-pointer pointer-events-auto;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-btn p {
  @apply flex-1 text-base font-normal uppercase;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-btn .dropdown-btn-awards {
  @apply flex items-center mr-2.5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-btn .dropdown-btn-awards li {
  @apply flex justify-center items-center ml-1;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-btn .dropdown-btn-awards li svg {
  @apply w-5 h-auto fill-current text-yellow;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body {
  @apply hidden mb-5 px-2.5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body p {
  @apply mb-4 italic;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body .awarded-category {
  @apply text-xs font-normal uppercase not-italic text-grey-light;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body .awarded-category-btn {
  @apply flex justify-between items-center w-full bg-blue-extrapale py-3 px-4 rounded-md;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body .awarded-category-btn.employee-rating {
  @apply mt-2.5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body .awarded-category-btn span {
  @apply flex flex-col items-start text-sm text-blue-light font-bold uppercase m-0;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body .awarded-category-btn span span {
  @apply text-xxs text-escgrey-mid;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .dropdown__card .dropdown-body .awarded-category-btn svg {
  @apply w-4 h-auto fill-current text-blue-light;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .total {
  @apply flex justify-between items-center bg-gray-200 font-bold mb-8 py-3 px-4 rounded-md;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .escape-score .escape-score__link {
  @apply font-bold text-blue-light;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview {
  @apply text-left mb-5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview .overview__person {
  @apply flex items-center mb-8;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview .overview__person .img-avatar {
  @apply w-14 h-14 bg-white rounded-full border border-gray-200 bg-no-repeat bg-center bg-cover;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview .overview__details {
  @apply flex-1 ml-2.5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview .overview__details .overview__name {
  @apply text-sm font-normal uppercase;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview .overview__details .overview__title {
  @apply text-sm font-normal;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview .overview__description {
  @apply bg-blue-extrapale p-5 rounded-md;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .overview .overview__description p {
  @apply mb-4;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews {
  @apply text-left mb-8;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-title {
  @apply text-lg font-normal text-blue-dark mb-2.5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-ratings {
  @apply flex justify-between items-center w-56 mb-5 py-2 px-5 bg-grey-medium rounded-full;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-ratings .employee-reviews-ratings-stars {
  @apply flex items-center;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-ratings .employee-reviews-ratings-stars li {
  @apply mr-2;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-ratings .employee-reviews-ratings-stars li:last-child {
  @apply mr-0;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-ratings .employee-reviews-ratings-stars li svg {
  @apply w-5 h-auto fill-current text-white;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-ratings .employee-reviews-ratings-stars li svg.active path {
  @apply fill-current text-red-esc;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-reviews-ratings .employee-reviews-fraction {
  @apply text-base;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review {
  @apply bg-blue-extrapale p-5 pt-4 rounded-md mb-5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review-toggle-checkbox {
  @apply hidden;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review-toggle-checkbox:checked + .employee-review-text-container {
  @apply h-full;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review-toggle-checkbox:checked + .employee-review-text-container + .employee-review__footer .employee-review-toggle-more {
  @apply opacity-0;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review-toggle-checkbox:checked + .employee-review-text-container + .employee-review__footer .employee-review-toggle-less {
  @apply opacity-100;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review-text-container .employee-review-text {
  @apply mb-4 pt-1 italic overflow-ellipsis overflow-hidden;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review__footer {
  @apply relative flex justify-between items-center mt-2.5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review__footer .employee-review-toggle-more,
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review__footer .employee-review-toggle-less {
  @apply font-bold cursor-pointer;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review__footer .employee-review-toggle-less {
  @apply opacity-0 absolute;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .employee-reviews .employee-review .employee-review__footer .employee-review-link {
  @apply text-sm font-bold text-blue-light;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs {
  @apply text-center mb-4;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__header {
  @apply flex justify-between items-center mb-5;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__header .jobs__title {
  @apply text-lg font-normal text-blue-dark;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__header .btn, .escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__header .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__header .email-subscribe__button {
  @apply text-sm m-0 py-1.5 px-3 rounded-full;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__list li {
  @apply py-4 border-b text-left;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__list li:last-child {
  @apply border-none;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .jobs__list li a {
  @apply font-bold text-blue-light;
}
.escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .btn, .escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .escape100-cards .escape100-container .escape100__dropdown .escape100__dropdown-item .jobs .email-subscribe__button {
  @apply text-sm py-2 px-3;
}

.job-cards {
  @apply grid grid-cols-1;
}

.job-card {
  @apply relative;
  @apply before:hidden md:before:block before:absolute before:bottom-0 before:left-0 before:w-px before:h-full before:bg-[#cccbca] first:before:h-[calc(100%-58px)];
  @apply after:hidden md:after:block after:absolute after:top-[58px] after:left-0 after:w-4 after:h-4 after:border after:rounded-full after:-translate-x-1/2 after:-translate-y-1/2;
}
.job-card.last {
  @apply before:bottom-auto before:top-0 before:h-[58px] pb-3;
}
.job-card.job {
  @apply after:border-green after:bg-green-pale;
}
.job-card.job .listing-type {
  @apply text-green bg-green-pale;
}
.job-card.kickstarter, .job-card.volunteer {
  @apply after:border-blue-light after:bg-blue-pale;
}
.job-card.kickstarter .listing-type, .job-card.volunteer .listing-type {
  @apply text-blue-light bg-blue-pale;
}
.job-card.project {
  @apply after:border-yellow after:bg-yellow-pale;
}
.job-card.project .listing-type {
  @apply text-yellow bg-yellow-pale;
}
.job-card.internship {
  @apply after:border-purple after:bg-purple-pale;
}
.job-card.internship .listing-type {
  @apply text-purple bg-purple-pale;
}
.job-card.co-founder, .job-card.programme {
  @apply after:border-pink after:bg-pink-pale;
}
.job-card.co-founder .listing-type, .job-card.programme .listing-type {
  @apply text-pink bg-pink-pale;
}
.job-card input.job-card__input {
  @apply hidden;
}
.job-card input.job-card__input:checked + label {
  @apply bg-white md:bg-transparent;
}
.job-card input.job-card__input:checked + label .job-card__right .job-card__right-inner {
  @apply md:bg-white;
}
.job-card input.job-card__input:checked + label .job-card__right .job-card__right-inner .job-card__org-summary {
  @apply h-auto opacity-100 transition duration-300 ease-in w-full md:w-[calc(100%+288px)] lg:w-[calc(100%+195px)];
}
.job-card input.job-card__input:checked + label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-right .job-card__right-content-right-bottom {
  @apply opacity-0 pointer-events-none hover:opacity-0 hover:pointer-events-none;
}
.job-card input.job-card__input:checked + label .job-card__right .job-card__right-inner .job-card__right-bottom {
  @apply max-h-[700px];
}
.job-card input.job-card__input:checked + label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-bottom {
  @apply opacity-100 pointer-events-auto;
}
.job-card label {
  @apply flex w-full text-blue-dark py-1.5 border-b md:border-none border-[#e6e6e5] first:border-t pointer-events-auto md:pointer-events-none;
}
.job-card label .job-card__left {
  @apply relative w-auto h-[65px] pt-3.5 md:pt-5 px-4 sm:px-8 sm:pr-4 pointer-events-auto;
}
.job-card label .job-card__left .job-card__logo-container {
  @apply flex justify-center items-center w-[52px] h-[52px] md:w-[65px] md:h-[65px] bg-white border border-[#cccbca] rounded-full overflow-hidden;
}
.job-card label .job-card__left .job-card__logo-container:hover + .job-card__hover-panel {
  @apply flex;
}
.job-card label .job-card__left .job-card__logo-container .job-card__logo {
  @apply w-full h-auto rounded-[5px];
}
.job-card label .job-card__left .job-card__hover-panel {
  @apply absolute top-full left-0 w-72 hidden hover:flex focus:flex flex-col p-4 bg-white border border-gray-200 shadow-lg rounded z-30 cursor-default;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-header {
  @apply w-full flex justify-between items-center pb-4;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-header .btn, .job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-header .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-header .email-subscribe__button {
  @apply flex justify-center items-center text-sm text-blue-light font-bold bg-blue-pale py-2 px-3 rounded-full cursor-pointer;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-header .btn.active, .job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-header .email-subscribe .email-subscribe__container .active.email-subscribe__button, .email-subscribe .email-subscribe__container .job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-header .active.email-subscribe__button {
  @apply bg-blue-light text-white;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-body {
  @apply w-full mb-3;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-body .job-card__hover-panel-org-name {
  @apply text-xl font-bold mb-3 hover:underline leading-tight;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-body .job-card__hover-panel-factors {
  @apply flex flex-wrap mb-2;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-body .job-card__hover-panel-factors li {
  @apply min-w-0 text-xs font-normal text-center bg-blue-pale mb-1 mr-1 p-1 px-1.5 rounded;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-body .job-card__hover-panel-org-summary {
  @apply text-sm text-escgrey-dark mb-1.5;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-body .job-card__hover-panel-org-summary:last-child {
  @apply mb-0;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-footer {
  @apply w-full flex justify-between;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-footer .job-card__hover-panel-bottom-jobs,
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-footer .job-card__hover-panel-bottom-reviews {
  @apply text-2xl text-black font-bold;
}
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-footer .job-card__hover-panel-bottom-jobs span,
.job-card label .job-card__left .job-card__hover-panel .job-card__hover-panel-footer .job-card__hover-panel-bottom-reviews span {
  @apply text-base text-escgrey-dark font-bold;
}
.job-card label .job-card__right {
  @apply relative flex flex-col justify-between w-full pointer-events-auto;
}
.job-card label .job-card__right .job-card__right-inner {
  @apply p-4 pb-1 md:p-5 md:pb-2 overflow-hidden rounded-3xl md:hover:bg-white cursor-pointer transition-colors ease-in-out duration-300;
}
.job-card label .job-card__right .job-card__right-inner.can-hover:hover .job-card__right-top .job-card__right-content-right .job-card__right-content-right-bottom {
  @apply opacity-100 pointer-events-auto transition-opacity ease-in duration-300;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top {
  @apply flex justify-between;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left {
  @apply w-full pr-2.5;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__title {
  @apply text-sm font-semibold mb-3;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__right-group {
  @apply flex flex-wrap items-center;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__right-group .job-card__org-name {
  @apply text-xs font-semibold mb-3 mr-8;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__right-group .job-card__org-review {
  @apply flex items-center text-xs font-semibold mb-3 mr-5;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__right-group .job-card__org-review .job-card__org-review-icon {
  @apply w-auto h-4 mr-1.5;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__right-group .job-card__org-review span {
  @apply font-normal;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__right-group .job-card__location {
  @apply flex text-xs mb-3;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__right-group .job-card__location svg {
  @apply w-auto h-4 mr-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .job-card__org-summary {
  @apply text-sm mb-3.5 h-0 opacity-0 transition duration-200 ease-out;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .tab-list {
  @apply flex flex-wrap items-center;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .tab-list .tag {
  @apply flex items-center bg-[#dff2f4] text-xs font-semibold py-1.5 px-3 mr-2 mb-2 last:mr-0 rounded-full;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .tab-list .tag svg {
  @apply w-auto h-4 mr-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .tab-list .listing-type,
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-left .tab-list .posted-date {
  @apply md:hidden;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .listing-type {
  @apply text-xs font-semibold whitespace-nowrap mb-3 md:mb-0 mr-1.5 py-1.5 px-3 rounded-full;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .posted-date {
  @apply text-xs font-semibold mb-3 md:mb-0 py-1 mr-1.5;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .posted-date.chip {
  @apply rounded-md bg-escgrey-midlight px-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-right {
  @apply hidden md:flex flex-col justify-between items-end w-full lg:max-w-[195px];
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-right .job-card__right-content-right-top {
  @apply flex flex-wrap items-center;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-right .job-card__right-content-right-top .listing-type,
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-right .job-card__right-content-right-top .posted-date {
  @apply hidden md:block;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-top .job-card__right-content-right .job-card__right-content-right-bottom {
  @apply opacity-0 pointer-events-none mt-4 mb-3 transition-all ease-in-out duration-100;
  @apply hidden md:block;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom {
  @apply flex justify-between items-start h-full max-h-0 overflow-hidden transition-all ease-in-out duration-700;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner {
  @apply w-full md:mt-3 py-[14px] md:pb-0 md:pt-5 md:border-t border-dashed border-[#e6e6e5];
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top {
  @apply w-full flex flex-wrap justify-start md:justify-between items-center;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group {
  @apply flex items-center mb-3 mr-3 md:mr-4;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-icon-container,
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__volunteer-icon-container {
  @apply w-5 h-5 mr-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-icon-container svg, .job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-icon-container .job-card__salary-icon, .job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-icon-container .job-card__volunteer-icon,
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__volunteer-icon-container svg,
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__volunteer-icon-container .job-card__salary-icon,
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__volunteer-icon-container .job-card__volunteer-icon {
  @apply w-full h-full;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-text {
  @apply text-xs text-blue-dark font-bold;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-text span {
  @apply inline-flex items-center mr-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-text span svg {
  @apply w-3 h-3 mr-1.5 translate-y-[1px];
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-text.estimated span:last-child {
  @apply font-normal text-grey-light;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__salary-group .job-card__salary-text.verified span:last-child {
  @apply font-normal text-green;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__team {
  @apply flex items-center text-xs font-semibold mb-3 mr-4;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__team svg {
  @apply w-auto h-4 mr-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__roles {
  @apply flex items-center text-xs font-semibold mb-3;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-top .job-card__roles svg {
  @apply w-auto h-4 mr-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-middle {
  @apply pt-3 pb-5 text-sm;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-bottom .job-card__right-bottom-inner .job-card__right-bottom-bottom {
  @apply md:pb-3 opacity-0 transition-opacity duration-300 ease-out pointer-events-none;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-actions {
  @apply flex justify-end items-center w-auto h-full space-x-3;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-actions a.btn, .job-card label .job-card__right .job-card__right-inner .job-card__right-actions .email-subscribe .email-subscribe__container a.email-subscribe__button, .email-subscribe .email-subscribe__container .job-card label .job-card__right .job-card__right-inner .job-card__right-actions a.email-subscribe__button {
  @apply w-full md:w-auto font-semibold my-0 py-1.5;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-actions a.btn .chevron, .job-card label .job-card__right .job-card__right-inner .job-card__right-actions .email-subscribe .email-subscribe__container a.email-subscribe__button .chevron, .email-subscribe .email-subscribe__container .job-card label .job-card__right .job-card__right-inner .job-card__right-actions a.email-subscribe__button .chevron {
  @apply w-auto h-3 ml-2 scale-x-[-1];
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-actions .btn-bookmark {
  @apply w-full md:w-auto min-w-[102px] h-auto font-semibold my-0 py-1.5;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-actions .btn-bookmark .heart {
  @apply w-auto h-3.5 ml-2;
}
.job-card label .job-card__right .job-card__right-inner .job-card__right-actions .btn-bookmark .heart path {
  @apply stroke-current text-pink;
}

.job-card-slim {
  @apply flex w-full h-full bg-white text-blue-dark  p-4 md:p-5 border border-gray-50 shadow rounded-[5px];
}
.job-card-slim .job-card-slim__left {
  @apply relative w-auto h-full;
}
.job-card-slim .job-card-slim__left .job-card-slim__logo-container {
  @apply flex justify-center items-center w-[60px] h-[60px] md:w-[65px] md:h-[65px] bg-escgrey-light rounded-[5px] overflow-hidden;
}
.job-card-slim .job-card-slim__left .job-card-slim__logo-container:hover + .job-card-slim__hover-panel {
  @apply flex;
}
.job-card-slim .job-card-slim__left .job-card-slim__logo-container .job-card-slim__logo {
  @apply w-full h-auto rounded-[5px];
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel {
  @apply absolute top-full left-0 w-72 hidden hover:flex focus:flex flex-col p-4 bg-white border border-gray-200 shadow-lg rounded z-10 cursor-default;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-header {
  @apply w-full flex justify-between items-center pb-4;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-header .btn, .job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-header .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-header .email-subscribe__button {
  @apply flex justify-center items-center text-sm text-blue-light font-bold bg-blue-pale py-2 px-3 rounded-full cursor-pointer;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-header .btn.active, .job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-header .email-subscribe .email-subscribe__container .active.email-subscribe__button, .email-subscribe .email-subscribe__container .job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-header .active.email-subscribe__button {
  @apply bg-blue-light text-white;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-body {
  @apply w-full mb-3;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-body .job-card-slim__hover-panel-org-name {
  @apply text-xl font-bold mb-3 hover:underline leading-tight;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-body .job-card-slim__hover-panel-factors {
  @apply flex flex-wrap mb-2;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-body .job-card-slim__hover-panel-factors li {
  @apply min-w-0 text-xs font-normal text-center bg-blue-pale mb-1 mr-1 p-1 px-1.5 rounded;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-body .job-card-slim__hover-panel-org-summary {
  @apply text-sm text-escgrey-dark mb-1.5;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-body .job-card-slim__hover-panel-org-summary:last-child {
  @apply mb-0;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-footer {
  @apply w-full flex justify-between;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-footer .job-card-slim__hover-panel-bottom-jobs,
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-footer .job-card-slim__hover-panel-bottom-reviews {
  @apply text-2xl text-black font-bold;
}
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-footer .job-card-slim__hover-panel-bottom-jobs span,
.job-card-slim .job-card-slim__left .job-card-slim__hover-panel .job-card-slim__hover-panel-footer .job-card-slim__hover-panel-bottom-reviews span {
  @apply text-base text-escgrey-dark font-bold;
}
.job-card-slim .job-card-slim__right {
  @apply flex flex-col justify-between w-full pl-2.5 md:pl-5;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-top {
  @apply flex;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-top .job-card-slim__right-content {
  @apply flex-1 pr-2.5;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-top .job-card-slim__right-content .job-card-slim__title {
  @apply text-sm md:text-base font-semibold mb-1.5;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-top .job-card-slim__right-actions {
  @apply flex items-start w-auto h-full;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-top .job-card-slim__right-actions .btn-alert-like {
  @apply w-[18px] h-auto;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-top {
  @apply flex flex-wrap items-end text-xs font-normal md:mb-1;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-top .job-card-slim__org-name {
  @apply font-semibold mr-3;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-top .job-card-slim__location {
  @apply text-xs font-medium;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-top .job-card-slim__location span {
  @apply text-xs font-semibold ml-px;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom {
  @apply flex flex-wrap items-center text-xs font-normal space-y-2;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__salary-group {
  @apply flex items-center mt-2 mr-3;
  @apply md:mt-2 md:mb-0 md:mr-3;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__salary-group .job-card-slim__salary-icon-container {
  @apply w-auto h-3.5 mr-1.5;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__salary-group .job-card-slim__salary-icon-container .job-card-slim__salary-icon {
  @apply w-auto h-full;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__salary-group .job-card-slim__salary-text {
  @apply text-xs text-blue-dark font-bold mb-0 space-x-1;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__salary-group .job-card-slim__salary-text.estimated span:last-child {
  @apply font-normal text-grey-light;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__salary-group .job-card-slim__salary-text.verified span:last-child {
  @apply font-normal text-green;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__listing-type {
  @apply bg-blue text-[8px] text-white whitespace-nowrap md:mr-5 md:mb-1.5 py-0.5 px-1.5 rounded-[2px];
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__right-bottom-icons {
  @apply flex items-center ml-auto my-0.5 pl-3 md:pl-4 space-x-3 md:space-x-4 hidden;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__right-bottom-icons .job-card-slim__right-bottom-icon-container {
  @apply w-auto h-4;
}
.job-card-slim .job-card-slim__right .job-card-slim__right-bottom .job-card-slim__right-bottom-group-bottom .job-card-slim__right-bottom-icons .job-card-slim__right-bottom-icon-container .job-card-slim__right-bottom-icon {
  @apply w-auto h-full;
}

.organisation-cards {
  @apply flex flex-wrap flex-col md:flex-row w-full;
}
.organisation-cards .organisation-card-container {
  @apply w-full lg:w-1/2 xl:w-1/3 my-2.5 lg:my-4 px-4;
}
.organisation-cards .organisation-card-container.featured {
  @apply w-full mt-0;
}
.organisation-cards .organisation-card-container .organisation-card {
  @apply relative flex flex-wrap flex-row md:flex-col items-center w-full h-full bg-white rounded-md shadow-md;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured {
  @apply w-full md:flex-auto flex-col md:flex-row md:mr-0 md:mb-8;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .client-logo-container {
  @apply absolute top-48 md:top-1/2 -right-2.5 md:left-2/5 transform -translate-y-1/2 -translate-x-1/2 w-14 h-14 flex justify-center items-center m-0 bg-white rounded-full border border-gray-200 overflow-hidden;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .client-logo-container .client-logo {
  @apply w-14 h-auto;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__header {
  @apply flex md:w-2/5 h-48 md:h-full md:rounded-l-md md:rounded-r-none;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__header .e100 {
  @apply flex;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__header .btn-alert {
  @apply md:absolute md:right-2.5;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper {
  @apply flex flex-col justify-between w-full h-full md:w-3/5;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a {
  @apply h-full;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__body {
  @apply md:pt-10 md:pl-12;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__body .organisation-card__body-title {
  @apply text-lg md:text-2xl;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__body .organisation-card__body-title .review-score-title {
  @apply hidden md:flex items-center;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__body .organisation-card__body-title .review-score-title .review-score-reviews {
  @apply ml-1.5;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__footer {
  @apply pb-2.5 md:pb-10 md:pl-12;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__footer .city, .organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__footer .industry, .organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__footer .review-score, .organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__footer .listings, .organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__footer .size {
  @apply flex w-1/3 lg:w-1/4 mr-0;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__wrapper a .organisation-card__footer .review-score {
  @apply md:hidden;
}
.organisation-cards .organisation-card-container .organisation-card.organisation-card-featured .organisation-card__jobs {
  @apply md:rounded-l-none;
}
.organisation-cards .organisation-card-container .organisation-card .client-logo-container {
  @apply w-14 h-14 flex justify-center items-center md:absolute top-24 right-5 bg-white rounded-full border border-gray-200 ml-5 overflow-hidden;
}
.organisation-cards .organisation-card-container .organisation-card .client-logo-container .client-logo {
  @apply w-14 h-auto;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__header {
  @apply hidden md:flex justify-between items-start bg-no-repeat bg-cover bg-center w-full h-32 p-2.5 rounded-t-md;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__header .e100 {
  @apply hidden md:flex items-center w-20 h-7 bg-blue-pale rounded-md text-xxs font-normal leading-3 text-blue-light pt-px;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__header .e100 .e100-icon {
  @apply w-5 h-auto mx-2;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__header .btn-alert {
  @apply hidden md:block w-7 h-7 p-2;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper {
  @apply w-full flex-1;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a {
  @apply h-full flex flex-col justify-between;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__body {
  @apply pt-3.5 md:pt-5 px-5 rounded-tr-md md:rounded-tr-none;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__body .organisation-card__body-title {
  @apply flex items-center text-base md:text-xl font-bold text-blue-dark mb-2.5;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__body .organisation-card__body-title .review-score-title {
  @apply flex items-center md:hidden text-xs md:text-sm;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__body .organisation-card__body-title .review-score-title .review-score-title-icon {
  @apply w-3 h-auto ml-2.5 mr-1;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__body .organisation-card__body-title .review-score-title .review-score-reviews {
  @apply font-normal;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__body .organisation-card__body-text {
  @apply text-sm font-normal text-blue-dark;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__footer {
  @apply flex items-center bg-white flex-wrap pt-2.5 pb-3 md:py-4 pl-5 rounded-b-md;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__footer .organisation-card__footer-item {
  @apply hidden md:flex items-center text-xs md:text-sm font-bold text-blue-dark my-1.5 mr-5;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__footer .organisation-card__footer-item:nth-child(1), .organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__footer .organisation-card__footer-item:nth-child(2) {
  @apply flex;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__footer .organisation-card__footer-item .organisation-card__footer-item-icon {
  @apply w-4 h-3.5 mr-1.5;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__wrapper a .organisation-card__footer .organisation-card__footer-item .review-score-reviews {
  @apply font-normal ml-1;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__jobs {
  @apply flex justify-center items-center w-full h-8 bg-blue text-white text-sm font-bold rounded-b-md cursor-pointer;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__jobs a {
  @apply flex flex-row justify-center items-center w-full h-8 py-1.5 px-5;
}
.organisation-cards .organisation-card-container .organisation-card .organisation-card__jobs a .organisation-card__jobs-icon {
  @apply w-auto h-3 mr-1.5;
}

.testimonials-cards {
  @apply flex flex-wrap flex-col md:flex-row w-full mt-8;
}
.testimonials-cards .testimonials-card-container {
  @apply w-full md:w-1/2 lg:w-1/3 my-4 px-4;
}
.testimonials-cards .testimonials-card-container .testimonials-card {
  @apply relative flex flex-col h-full text-left bg-white rounded-md shadow-md;
}
.testimonials-cards .testimonials-card-container .testimonials-card .testimonials-card__header {
  @apply relative flex items-end bg-blue-dark bg-no-repeat bg-cover bg-center w-full h-60 p-5 rounded-t-md z-0;
}
.testimonials-cards .testimonials-card-container .testimonials-card .testimonials-card__header:before {
  content: "";
  @apply absolute inset-0 bg-black opacity-50 rounded-t-md -z-1;
}
.testimonials-cards .testimonials-card-container .testimonials-card .testimonials-card__header .testimonials-title {
  @apply text-2xl text-white font-bold;
}
.testimonials-cards .testimonials-card-container .testimonials-card .testimonials-card__body {
  @apply flex-1 p-5 bg-white rounded-b-md;
}
.testimonials-cards .testimonials-card-container .testimonials-card .testimonials-card__body p, .testimonials-cards .testimonials-card-container .testimonials-card .testimonials-card__body span {
  @apply text-sm text-blue-dark text-left leading-relaxed;
}
.testimonials-cards .testimonials-card-container .testimonials-card .testimonials-card__body a {
  @apply text-blue-light;
}

.featured-programme-course__cards {
  @apply flex flex-wrap;
}
.featured-programme-course__cards .featured-programme-course-container {
  @apply w-full;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course {
  @apply relative flex flex-col md:flex-row h-full bg-white rounded-md shadow-md;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .client-logo-container {
  @apply absolute top-48 md:top-1/2 -right-2.5 md:left-2/5 transform -translate-y-1/2 -translate-x-1/2 flex justify-center items-center w-14 h-14 bg-white rounded-full border border-gray-200 overflow-hidden;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .client-logo-container .client-logo {
  @apply w-14 h-auto;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__header {
  @apply flex flex-wrap justify-between items-start w-full md:w-2/5 h-48 md:h-full p-5 rounded-t-md md:rounded-tr-none md:rounded-l-md bg-blue-dark bg-no-repeat bg-cover bg-center;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper {
  @apply flex-1 w-full md:w-3/5;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__body {
  @apply p-5 md:pt-10 md:p-5 md:pl-12 rounded-tr-md md:rounded-tr-none;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__body .featured-programme-course__body-title {
  @apply flex items-center text-lg md:text-2xl font-bold leading-none text-blue-dark mb-2.5;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__body .featured-programme-course__body-title .review-score-title {
  @apply hidden md:flex text-xs md:text-sm;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__body .featured-programme-course__body-title .review-score-title .review-score-title-icon {
  @apply w-3 h-auto ml-2.5 mr-1;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__body .featured-programme-course__body-title .review-score-title .review-score-reviews {
  @apply font-normal;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__body .featured-programme-course__body-text {
  @apply text-sm font-normal text-blue-dark;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__footer {
  @apply flex flex-wrap items-center py-4 pl-5 md:pb-10 md:pl-12 rounded-b-md;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__footer .featured-programme-course__footer-item {
  @apply flex items-center text-xs md:text-sm font-bold text-blue-dark my-1.5 mr-5;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__footer .featured-programme-course__footer-item .icon-wrapper {
  @apply w-5 h-auto mr-1.5;
}
.featured-programme-course__cards .featured-programme-course-container .featured-programme-course .featured-programme-course__wrapper a .featured-programme-course__footer .featured-programme-course__footer-item .icon-wrapper .icon {
  @apply w-full h-auto;
}

.our-approach__cards {
  @apply mb-5;
}
.our-approach__cards .our-approach__card {
  @apply bg-white mt-8 mb-6 p-6 border rounded-md;
}
.our-approach__cards .our-approach__card .our-approach__card-title {
  @apply text-lg font-bold text-blue-dark mb-3;
}
.our-approach__cards .our-approach__card .our-approach__card-text {
  @apply text-base font-normal text-blue-dark mb-5;
}
.our-approach__cards .our-approach__card .our-approach__card-dropdown-panel {
  @apply max-h-0 lg:max-h-full overflow-hidden;
}
.our-approach__cards .our-approach__card .our-approach__card-dropdown-panel.active {
  @apply max-h-full;
}
.our-approach__cards .our-approach__card .our-approach__card-dropdown-label span {
  @apply relative lg:hidden h-7 text-base font-bold text-blue-dark uppercase cursor-pointer;
}
.our-approach__cards .our-approach__card .our-approach__card-dropdown-label span:before {
  content: "";
  @apply block w-full h-0.5 bg-yellow absolute -bottom-1.5 left-0 rounded;
}
.our-approach__cards .our-approach__card .our-approach__card-list {
  @apply grid grid-cols-1 md:grid-cols-2 gap-5 mb-6 lg:mb-0;
}
.our-approach__cards .our-approach__card .our-approach__card-list li {
  @apply w-full flex items-center list-none p-0;
}
.our-approach__cards .our-approach__card .our-approach__card-list li:before {
  @apply hidden;
}
.our-approach__cards .our-approach__card .our-approach__card-list li .our-approach__card-list-img-container {
  @apply w-8 h-8 mr-2.5;
}
.our-approach__cards .our-approach__card .our-approach__card-list li .our-approach__card-list-img-container .our-approach__card-list-img {
  @apply w-8 h-8;
}
.our-approach__cards .our-approach__card .our-approach__card-list li .our-approach__card-list-text {
  @apply flex-1 text-base m-0 pr-2;
}
.our-approach__cards .our-approach__card ul li {
  @apply relative pl-6;
}
.our-approach__cards .our-approach__card ul li:before {
  content: "• ";
  @apply absolute -top-4 left-0 text-5xl text-blue-light;
}
.our-approach__cards .our-approach__card ol {
  counter-reset: esc-counter;
}
.our-approach__cards .our-approach__card ol li {
  counter-increment: esc-counter;
  @apply relative pt-1 pl-10 mb-5;
}
.our-approach__cards .our-approach__card ol li:before {
  content: counter(esc-counter);
  @apply absolute top-0 left-0 flex justify-center items-center bg-blue-light w-7 h-7 text-lg font-bold text-white rounded-full;
}

.employer-card {
  @apply text-white mt-16;
}
.employer-card .employer-card__header {
  @apply flex flex-col md:flex-row bg-blue-dark p-6 pb-8 rounded-md;
}
.employer-card .employer-card__header .employer-card__header-group {
  @apply w-full md:flex-1 md:pr-4;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card-logo-group {
  @apply flex items-center mb-4;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card-logo-group .employer-card-logo-container {
  @apply w-12 h-12 flex justify-center items-center mr-4 rounded-full overflow-hidden;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card-logo-group .employer-card-logo-container .employer-card-logo {
  @apply w-12 h-auto;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card-logo-group .employer-card-name {
  @apply flex-1 font-blackout text-2xl;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container {
  @apply w-full flex justify-between items-center mb-4 md:hidden;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container .btn, .employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container .email-subscribe__button, .employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container a {
  @apply m-0;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container .btn p, .employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container .email-subscribe .email-subscribe__container .email-subscribe__button p, .email-subscribe .email-subscribe__container .employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container .email-subscribe__button p, .employer-card .employer-card__header .employer-card__header-group .employer-card__header-btns-container a p {
  @apply mb-0;
}
.employer-card .employer-card__header .employer-card__header-group .employer-card-description {
  @apply text-sm font-normal md:mb-0;
}
.employer-card .employer-card-overview {
  @apply flex flex-wrap md:flex-col w-full md:w-52;
}
.employer-card .employer-card-overview li {
  @apply flex items-center w-1/2 md:w-full mb-4;
}
.employer-card .employer-card-overview li:nth-child(3) {
  @apply mb-0 underline;
}
.employer-card .employer-card-overview li:last-child {
  @apply mb-0 md:hidden;
}
.employer-card .employer-card-overview li .employer-card-icon {
  @apply w-6 h-6 flex justify-start items-center mr-1.5;
}
.employer-card .employer-card-overview li .employer-card-icon .icon {
  @apply w-auto h-5;
}
.employer-card .employer-card-overview li .employer-card-desc p {
  @apply text-sm font-normal m-0;
}
.employer-card .employer-card-btns-container {
  @apply hidden md:flex flex-col justify-between items-center;
}
.employer-card .employer-card-btns-container .btn, .employer-card .employer-card-btns-container .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .employer-card .employer-card-btns-container .email-subscribe__button, .employer-card .employer-card-btns-container a {
  @apply m-0;
}
.employer-card .employer-card-btns-container .btn p, .employer-card .employer-card-btns-container .email-subscribe .email-subscribe__container .email-subscribe__button p, .email-subscribe .email-subscribe__container .employer-card .employer-card-btns-container .email-subscribe__button p, .employer-card .employer-card-btns-container a p {
  @apply mb-0;
}
.employer-card .employer-card-btns-container .btn, .employer-card .employer-card-btns-container .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .employer-card .employer-card-btns-container .email-subscribe__button {
  @apply mb-8;
}
.employer-card .employer-card__footer {
  @apply bg-blue-medium rounded-b-md overflow-hidden transform -translate-y-1;
}
.employer-card .employer-card__footer .employer-card__footer-btn {
  @apply flex justify-between items-center py-5 px-6 cursor-pointer;
}
.employer-card .employer-card__footer .employer-card__footer-btn .employer-card__footer-btn-title {
  @apply font-bold;
}
.employer-card .employer-card__footer .employer-card__footer-btn .arrow-down {
  @apply w-5 h-auto transform -rotate-90 transition duration-150 ease-in-out;
}
.employer-card .employer-card__footer .employer-card__footer-btn .arrow-down.active {
  @apply transform rotate-0;
}
.employer-card .employer-card__footer .employer-card__footer-panel {
  @apply overflow-hidden;
}
.employer-card .employer-card__footer .employer-card__footer-panel .employer-card__footer-perks {
  @apply flex flex-col md:flex-row md:flex-wrap p-6;
}
.employer-card .employer-card__footer .employer-card__footer-panel .employer-card__footer-perks li {
  @apply flex w-full md:w-1/2;
}
.employer-card .employer-card__footer .employer-card__footer-panel .employer-card__footer-perks li .perks-icon-container {
  @apply w-8 h-8;
}
.employer-card .employer-card__footer .employer-card__footer-panel .employer-card__footer-perks li .perks-text {
  @apply flex-1 text-sm font-normal px-4;
}

.org-cards {
  @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 w-full;
}

.org-card {
  @apply bg-white text-blue-dark border border-blue-dark/10 rounded-[10px];
}
.org-card a {
  @apply first:flex first:flex-col first:h-full first:p-6;
}
.org-card a .org-card__header {
  @apply flex items-start;
}
.org-card a .org-card__header .org-card__img-container {
  @apply flex justify-center items-center w-[60px] h-[60px] bg-blue-dark/5 rounded-[10px] overflow-hidden;
}
.org-card a .org-card__header .org-card__img-container .org-card__img {
  @apply w-full h-auto;
}
.org-card a .org-card__header .org-card__title {
  @apply flex-1 text-2xl font-bold px-4;
  word-break: break-word;
}
.org-card a .org-card__header .org-card__title .org-card__title-reviews {
  @apply w-full text-base flex justify-start items-center;
}
.org-card a .org-card__header .org-card__title .org-card__title-reviews .org-card__title-reviews-icon {
  @apply w-4 h-4 mr-2;
}
.org-card a .org-card__header .icon.heart {
  @apply w-5 h-auto;
}
.org-card a .org-card__header .btn-small {
  @apply mt-0;
}
.org-card a .org-card__body {
  @apply flex flex-col flex-1 mt-6;
}
.org-card a .org-card__body .org-card__stats {
  @apply flex flex-wrap justify-between items-center;
}
.org-card a .org-card__body .org-card__stats .org-card__stats-item {
  @apply flex justify-center items-center text-xs font-bold mb-2;
}
.org-card a .org-card__body .org-card__stats .org-card__stats-item .org-card__stats-item-icon {
  @apply w-5 h-auto mr-1.5;
}
.org-card a .org-card__body .org-card__stats .org-card__stats-item span {
  @apply font-normal;
}
.org-card a .org-card__body .org-card__location {
  @apply flex justify-start items-center;
}
.org-card a .org-card__body .org-card__location .org-card__location-icon {
  @apply mr-2;
}
.org-card a .org-card__body .org-card__summary {
  @apply flex-1 text-sm font-normal pt-4 pb-6;
}
.org-card a .org-card__body .org-card__icons {
  @apply flex justify-between items-center mb-1;
}
.org-card a .org-card__body .org-card__icons .org-card__icons-item .org-card__icons-item-icon {
  @apply w-auto h-6;
}
.org-card a .org-card__footer {
  @apply flex justify-between items-center mt-4 gap-4;
}
.org-card a .org-card__footer .org-card__btn--alert {
  @apply text-xs font-semibold m-0 bg-blue-light border-2 border-blue-light text-white rounded-full flex-1 py-[10px];
}
.org-card a .org-card__footer .org-card__btn--compare {
  @apply text-xs font-semibold m-0 border-2 border-grey-dark border-dashed text-grey-dark rounded-full flex-1 py-[10px];
}
.org-card a .org-card__footer .org-card__btn--roles {
  @apply flex justify-center items-center bg-blue-light text-white text-xs font-normal m-0 py-[10px] px-2 rounded-[5px];
}
.org-card a .org-card__footer .org-card__btn--roles .org-card__icons-item-icon {
  @apply w-auto h-3 mr-2 mb-px;
}

.org-card-slims {
  @apply grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 mx-auto px-4;
}

.org-card-slim {
  @apply w-full bg-white text-blue-dark border border-gray-50 shadow rounded-[5px];
}
.org-card-slim > a {
  @apply flex h-full p-5;
}
.org-card-slim > a .org-card-slim__left {
  @apply w-[60px] h-full;
}
.org-card-slim > a .org-card-slim__left .org-card-slim__img-container {
  @apply flex justify-center items-center w-[60px] h-[60px] rounded-[5px] overflow-hidden;
}
.org-card-slim > a .org-card-slim__left .org-card-slim__img-container .org-card-slim__img {
  @apply w-full h-full;
}
.org-card-slim > a .org-card-slim__right {
  @apply flex flex-col flex-1;
}
.org-card-slim > a .org-card-slim__right .org-card-slim_body {
  @apply flex h-full;
}
.org-card-slim > a .org-card-slim__right .org-card-slim_body .org-card-slim__content {
  @apply w-full px-2.5;
}
.org-card-slim > a .org-card-slim__right .org-card-slim_body .org-card-slim__content .org-card-slim__title {
  @apply text-base font-semibold mb-2.5;
}
.org-card-slim > a .org-card-slim__right .org-card-slim_body .org-card-slim__content .org-card-slim__summary {
  @apply text-xs font-normal;
}
.org-card-slim > a .org-card-slim__right .org-card-slim__footer {
  @apply w-full flex justify-between items-center pt-1 pl-2.5;
}
.org-card-slim > a .org-card-slim__right .org-card-slim__footer .org-card-slim__icons {
  @apply flex items-center space-x-3 md:space-x-4;
}
.org-card-slim > a .org-card-slim__right .org-card-slim__footer .org-card-slim__icons .org-card-slim__icon-container {
  @apply w-auto h-4;
}
.org-card-slim > a .org-card-slim__right .org-card-slim__footer .org-card-slim__icons .org-card-slim__icon-container .org-card-slim__icon {
  @apply w-auto h-full;
}
.org-card-slim > a .org-card-slim__right .org-card-slim__footer .org-card-slim__btn--roles {
  @apply flex items-center bg-blue-light py-[5px] px-2.5 rounded-[5px] text-xxs text-white font-semibold;
}
.org-card-slim > a .org-card-slim__right .org-card-slim__footer .org-card-slim__btn--roles .org-card-slim__icons-item-icon {
  @apply w-auto h-3 mr-1.5 mb-0.5;
}

.inspiration-cards {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5;
}
.inspiration-cards .inspiration-card {
  @apply flex flex-col h-full border border-black/10 rounded-[5px];
}
.inspiration-cards .inspiration-card .inspiration-card__header {
  @apply flex justify-center items-center w-full h-[150px] bg-[#D3F0F6] rounded-t-[5px];
}
.inspiration-cards .inspiration-card .inspiration-card__header .inspiration-card__img-container {
  @apply w-auto h-[50px];
}
.inspiration-cards .inspiration-card .inspiration-card__header .inspiration-card__img-container .inspiration-card__img {
  @apply w-full h-full bg-blue-light;
}
.inspiration-cards .inspiration-card .inspiration-card__body {
  @apply flex flex-col flex-1 bg-white p-4 text-blue-dark text-base font-bold rounded-b-[5px];
}
.inspiration-cards .inspiration-card .inspiration-card__body .inspiration-card__title {
  @apply flex-1 mb-4;
}
.inspiration-cards .inspiration-card .inspiration-card__body .inspiration-card__link {
  @apply flex items-center mb-1;
}
.inspiration-cards .inspiration-card .inspiration-card__body .inspiration-card__link .arrow-long {
  @apply w-auto h-2.5 ml-4;
}

.top-opportunities__card {
  @apply relative flex justify-between items-center w-full mb-0;
}
.top-opportunities__card .top-opportunities__card-number {
  @apply absolute -top-2 -left-2 flex justify-center items-center w-[25px] h-[25px] bg-blue-light text-white text-xs rounded-full;
}
.top-opportunities__card a {
  @apply bg-[#FAFAFA] h-full flex flex-1 justify-center items-center p-[10px] border border-[#EBEBEB] shadow-none rounded-lg;
}
.top-opportunities__card a .top-opportunities__card-avatar {
  @apply flex justify-center items-center w-[60px] h-[60px] bg-white mr-3 rounded-lg;
}
.top-opportunities__card a .top-opportunities__card-avatar .top-opportunities__card-logo-container {
  @apply flex justify-center items-center w-12 h-12 rounded-md;
}
.top-opportunities__card a .top-opportunities__card-avatar .top-opportunities__card-logo-container .top-opportunities__card-logo {
  @apply w-12 h-auto rounded-md;
}
.top-opportunities__card a .top-opportunities__card-body {
  @apply flex flex-col flex-1 h-full justify-center;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-title {
  @apply text-sm font-semibold text-blue-dark mb-2;
  word-break: break-word;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info {
  @apply flex justify-between items-center;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-company {
  @apply text-xs font-normal text-blue-dark;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat {
  @apply inline-flex py-[2px] px-1.5 rounded text-white text-xs font-normal;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat.job {
  @apply text-green bg-green-pale;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat.kickstarter, .top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat.volunteer {
  @apply text-blue-light bg-blue-pale;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat.project {
  @apply text-yellow bg-yellow-pale;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat.internship {
  @apply bg-purple-pale text-purple;
}
.top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat.co-Founder, .top-opportunities__card a .top-opportunities__card-body .top-opportunities__card-info .top-opportunities__card-cat.programme {
  @apply text-pink bg-pink-pale;
}
.top-opportunities__card a .top-opportunities__card-like {
  @apply self-start w-4 h-full ml-4;
}
.top-opportunities__card a .top-opportunities__card-like .icon.heart {
  @apply transition-all ease-in-out duration-300;
}
.top-opportunities__card a .top-opportunities__card-like .icon.heart.active {
  @apply fill-current text-red-esc;
}
.top-opportunities__card a .top-opportunities__card-like .icon.heart.active path {
  @apply stroke-current text-red-esc;
}

.modal-esc100 {
  @apply max-h-96;
}
.modal-esc100 .modal-body {
  @apply pt-0;
}
.modal-esc100 .modal-body .modal-body-categories {
  @apply text-xs text-grey-light uppercase mb-4;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-checkbox {
  @apply hidden;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-checkbox:checked + .dropdown-btn .dropdown-btn-arrow {
  @apply transform rotate-90;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-checkbox:checked + .dropdown-btn + .dropdown-body {
  @apply block;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-checkbox:checked + .dropdown-btn {
  @apply bg-blue-pale;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-btn {
  @apply flex items-center bg-grey-medium m-0 mb-3 py-3 px-4 rounded-md cursor-pointer pointer-events-auto;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-btn p {
  @apply flex-1 text-base font-normal uppercase mb-0;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-btn .dropdown-btn-awards {
  @apply flex items-center mr-2.5;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-btn .dropdown-btn-awards li {
  @apply flex justify-center items-center ml-1;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-btn .dropdown-btn-awards li svg {
  @apply w-5 h-auto fill-current text-yellow;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body {
  @apply hidden bg-offwhite mb-5 p-4 rounded-md;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body p {
  @apply mb-4 italic;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body p:last-child {
  @apply mb-0;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body a {
  @apply font-bold text-blue;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body img {
  @apply w-full mb-4;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body .awarded-category {
  @apply text-xs font-normal uppercase not-italic text-grey-light;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body .awarded-category-btn {
  @apply flex justify-between items-center w-full bg-blue-extrapale py-3 px-4 rounded-md;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body .awarded-category-btn.employee-rating {
  @apply mt-2.5;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body .awarded-category-btn span {
  @apply flex flex-col items-start text-sm text-blue-light font-bold uppercase m-0;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body .awarded-category-btn span span {
  @apply text-xxs text-escgrey-mid;
}
.modal-esc100 .modal-body .dropdown__card .dropdown-body .awarded-category-btn svg {
  @apply w-4 h-auto fill-current text-blue-light;
}

.modal-join-prompt {
  @apply absolute bottom-0 w-full max-w-90 bg-white flex justify-center shadow-md rounded-t-md p-9 z-20 border-t-4 border-blue-light;
  min-height: 20rem;
}
.modal-join-prompt .modal-join-prompt__inner {
  @apply relative w-full max-w-screen-md flex flex-col md:flex-row;
}
.modal-join-prompt .modal-join-prompt__inner .modal-join-prompt__image {
  @apply mr-10 h-24 md:h-auto;
}
.modal-join-prompt .modal-join-prompt__inner .modal-join-prompt__body .modal-message__title {
  @apply font-blackout text-2xl text-blue mt-8;
}
.modal-join-prompt .modal-join-prompt__inner .modal-join-prompt__body .modal-message__title:after {
  content: "";
  @apply w-20 block border-2 border-blue-light rounded mt-2;
}
.modal-join-prompt .modal-join-prompt__inner .modal-join-prompt__body .modal-message__text {
  @apply font-blackout text-2xl md:text-3xl my-4;
}
.modal-join-prompt .modal-join-prompt__inner .modal-join-prompt__body .modal-message__login {
  @apply pt-4;
}
.modal-join-prompt .modal-join-prompt__inner .modal-join-prompt__body .modal-message__login a {
  @apply font-bold underline;
}

.modal-backdrop .modal-org-compare {
  @apply absolute bottom-8 top-8 left-8 right-8 w-[calc(100%-4rem)] max-w-none bg-white flex shadow-md rounded-2xl p-9 z-20 min-h-0;
}
.modal-backdrop .modal-org-compare .modal-header .modal-header-title {
  @apply font-blackout pr-5;
}
.modal-backdrop .modal-org-compare .modal-header .modal-header-subtitle {
  @apply text-xl;
}
.modal-backdrop .modal-org-compare .btn-close {
  @apply top-6 right-6;
}
.modal-backdrop .modal-org-compare .modal-body {
  @apply items-start justify-start h-[calc(100%-160px)] md:h-[calc(100%-52px)];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap {
  @apply w-full h-full overflow-auto md:pb-4;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table[data-cols="1"] {
  @apply w-[calc(205px*1)] lg:w-[calc(290px*1)];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table[data-cols="2"] {
  @apply w-[calc(205px*2)] lg:w-[calc(290px*2)];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table[data-cols="3"] {
  @apply w-[calc(205px*3)] lg:w-[calc(290px*3)];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table[data-cols="4"] {
  @apply w-[calc(205px*4)] lg:w-[calc(290px*4)];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table[data-cols="5"] {
  @apply w-[calc(205px*5)] lg:w-[calc(290px*5)];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table[data-cols="6"] {
  @apply w-[calc(205px*6)] lg:w-[calc(290px*6)];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr th, .modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr td {
  @apply even:bg-gray-100 p-4 border-escgrey-midlight even:border-l even:border-r text-center w-[205px] lg:w-[290px];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr th:last-child, .modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr td:last-child {
  @apply border-r;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr th:first-child, .modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr td:first-child {
  @apply sticky left-0 bg-white z-100;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row-heading td {
  @apply bg-[#ecf8f9] even:bg-[#e8f4f7];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row-heading td.clear {
  @apply bg-white even:bg-gray-100;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row-heading td.section-heading {
  @apply font-bold text-left;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_top th {
  @apply border-t align-top;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_top th:nth-child(2) {
  @apply rounded-tl-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_top th:first-child {
  @apply border-t-0;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_top th:last-child {
  @apply rounded-tr-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_bottom td {
  @apply border-b;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_bottom td:nth-child(2) {
  @apply rounded-bl-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_bottom td:first-child {
  @apply border-b-0;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.desktop .compare-table tr.compare-table__row_bottom td:last-child {
  @apply rounded-br-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table {
  @apply w-full;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr th, .modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr td {
  @apply even:bg-gray-100 p-4 w-1/2 text-center;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr th:first-child, .modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr td:first-child {
  @apply border-l;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr th:last-child, .modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr td:last-child {
  @apply border-r;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr td {
  @apply font-bold;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr td span {
  @apply font-normal;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row-heading td {
  @apply bg-[#ecf8f9];
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row-heading td.section-heading {
  @apply font-bold text-center;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row_top th {
  @apply border-t align-top;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row_top th:first-child {
  @apply rounded-tl-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row_top th:last-child {
  @apply rounded-tr-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row_bottom td {
  @apply border-b;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row_bottom td:first-child {
  @apply rounded-bl-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap.mobile .compare-table tr.compare-table__row_bottom td:last-child {
  @apply rounded-br-2xl;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table {
  @apply w-auto border-separate;
  border-spacing: 0;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table tr th.compare-table__main-heading {
  @apply flex items-center min-h-13;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table tr th.compare-table__org-hero .logo-img {
  @apply relative w-32 h-32 border border-escgrey-midlight rounded-lg overflow-hidden mx-auto mb-2;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table tr th.compare-table__org-hero .logo-img img {
  @apply absolute w-full h-full object-cover;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table tr th.compare-table__org-hero .logo-img .overlay {
  @apply absolute z-10 w-full h-full flex flex-col justify-center items-center cursor-pointer opacity-0 transition-opacity;
  @apply before:absolute before:bg-black before:w-full before:h-full before:opacity-60 z-0;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table tr th.compare-table__org-hero .logo-img .overlay .icon {
  @apply text-white w-4 h-4 z-10;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table tr th.compare-table__org-hero .logo-img .overlay div {
  @apply text-white w-full z-10;
}
.modal-backdrop .modal-org-compare .modal-body .table-wrap .compare-table tr th.compare-table__org-hero .logo-img:hover .overlay {
  @apply opacity-100;
}

.modal__recommended-orgs .btn-close {
  @apply top-6 right-6;
}
.modal__recommended-orgs .modal-body {
  @apply flex;
}
.modal__recommended-orgs .modal-body .org-card {
  @apply opacity-100 transition-opacity ease-in duration-300;
}
.modal__recommended-orgs .modal-body .org-card.fade {
  @apply opacity-0 transition-opacity ease-out duration-300;
}
.modal__recommended-orgs .modal-footer {
  @apply flex justify-between items-center;
}
.modal__recommended-orgs .modal-footer > .btn, .modal__recommended-orgs .email-subscribe .email-subscribe__container .modal-footer > .email-subscribe__button, .email-subscribe .email-subscribe__container .modal__recommended-orgs .modal-footer > .email-subscribe__button {
  @apply font-bold m-0;
}
.modal__recommended-orgs .modal-footer .arrow-container {
  @apply w-10 h-full;
}
.modal__recommended-orgs .modal-footer .arrow-container > button {
  @apply flex justify-center items-center w-full h-full disabled:opacity-10 disabled:cursor-not-allowed;
}
.modal__recommended-orgs .modal-footer .arrow-container > button .chevron {
  @apply w-3 h-auto;
}
.modal__recommended-orgs .modal-footer .arrow-container > button .chevron path {
  @apply fill-current text-blue-dark;
}
.modal__recommended-orgs .modal-footer .arrow-container > button .chevron.next {
  @apply scale-x-[-1];
}

.hero {
  @apply w-full relative bg-no-repeat bg-center bg-cover z-20;
}
.hero .underlay {
  @apply absolute inset-0 -z-1 bg-gradient-to-b from-blue-light via-blue-light to-blue-dark;
}
.hero .overlay {
  @apply absolute inset-0 bg-black -z-2;
}
.hero .hero__top {
  @apply absolute top-0 left-0 w-full flex justify-center items-center z-10;
}
.hero .hero__container {
  @apply relative flex items-center container mx-auto p-4 z-0;
  min-height: 500px;
}
.hero .hero__container.tall {
  min-height: 700px;
}
.hero .hero__container.half {
  @apply flex-col sm:flex-row justify-evenly;
}
.hero .hero__container .hero__inner {
  @apply w-full mx-auto;
}
.hero .hero__container .hero__inner.left {
  @apply md:w-3/4 lg:w-3/5 md:ml-0;
}
.hero .hero__container .hero__inner.center {
  @apply lg:w-4/5 xl:w-2/3 text-center;
}
.hero .hero__container .hero__inner.center .hero__header {
  @apply justify-center;
}
.hero .hero__container .hero__inner.center .hero__header.top-center {
  @apply absolute top-0 left-0 right-0;
}
.hero .hero__container .hero__inner.center .hero__body .hero__title,
.hero .hero__container .hero__inner.center .hero__body .hero__text {
  @apply w-full;
}
.hero .hero__container .hero__inner.half {
  @apply w-full sm:w-1/2 md:ml-0;
}
.hero .hero__container .hero__inner.half .hero__image {
  @apply w-auto h-full max-h-64 md:max-h-96 mx-auto md:mr-0;
}
.hero .hero__container .hero__inner .hero__header {
  @apply flex items-center mb-4;
}
.hero .hero__container .hero__inner .hero__header .hero__icon {
  @apply flex justify-center items-center w-20 h-20 rounded-full overflow-hidden;
}
.hero .hero__container .hero__inner .hero__header .hero__icon .icon {
  @apply w-full h-auto rounded-full border-transparent;
}
.hero .hero__container .hero__inner .hero__header .btn-group {
  @apply flex-1 w-auto ml-6;
}
.hero .hero__container .hero__inner .hero__header .btn-group .btn, .hero .hero__container .hero__inner .hero__header .btn-group .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .hero .hero__container .hero__inner .hero__header .btn-group .email-subscribe__button {
  @apply text-sm font-bold text-white my-1 mr-2;
}
.hero .hero__container .hero__inner .hero__header .btn-group .btn:last-child, .hero .hero__container .hero__inner .hero__header .btn-group .email-subscribe .email-subscribe__container .email-subscribe__button:last-child, .email-subscribe .email-subscribe__container .hero .hero__container .hero__inner .hero__header .btn-group .email-subscribe__button:last-child {
  @apply mr-0;
}
.hero .hero__container .hero__inner .hero__header .hero__link {
  @apply absolute top-11 left-4 lg:left-8 flex items-center text-white text-sm z-50;
}
.hero .hero__container .hero__inner .hero__header .hero__link .hero__link-icon {
  @apply w-1.5 h-auto mr-1.5;
}
.hero .hero__container .hero__inner .hero__body .hero__logo {
  @apply w-auto h-24 mb-4 mx-auto;
}
.hero .hero__container .hero__inner .hero__body .hero__headline {
  @apply text-sm md:text-base font-normal uppercase text-white mb-4 w-11/12;
}
.hero .hero__container .hero__inner .hero__body .hero__title {
  @apply w-full font-blackout text-3xl md:text-hero leading-tight text-white mb-4 w-11/12;
}
.hero .hero__container .hero__inner .hero__body .hero__title .hero__title-number {
  @apply text-blue-light;
}
.hero .hero__container .hero__inner .hero__body .hero__body-group {
  @apply flex items-center mb-4;
}
.hero .hero__container .hero__inner .hero__body .hero__body-group .hero__title {
  @apply w-auto mb-0 mr-8 leading-none;
}
.hero .hero__container .hero__inner .hero__body .hero__body-group .btn-group {
  @apply flex-row items-stretch;
}
.hero .hero__container .hero__inner .hero__body .hero__body-group .btn-group .btn, .hero .hero__container .hero__inner .hero__body .hero__body-group .btn-group .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .hero .hero__container .hero__inner .hero__body .hero__body-group .btn-group .email-subscribe__button {
  @apply mr-2.5;
}
.hero .hero__container .hero__inner .hero__body .hero__body-group .btn-group .btn:last-child, .hero .hero__container .hero__inner .hero__body .hero__body-group .btn-group .email-subscribe .email-subscribe__container .email-subscribe__button:last-child, .email-subscribe .email-subscribe__container .hero .hero__container .hero__inner .hero__body .hero__body-group .btn-group .email-subscribe__button:last-child {
  @apply mr-0;
}
.hero .hero__container .hero__inner .hero__body.group-left {
  @apply w-full flex flex-col md:flex-row items-start md:items-end justify-start md:justify-center mb-4;
}
.hero .hero__container .hero__inner .hero__body.group-left .content {
  @apply md:ml-8 pt-8 w-full xl:w-4/5;
}
.hero .hero__container .hero__inner .hero__body.group-left .content .hero__title,
.hero .hero__container .hero__inner .hero__body.group-left .content .hero__text {
  @apply w-full pr-0 xl:w-4/5;
}
.hero .hero__container .hero__inner .hero__body.group-left .content .btn, .hero .hero__container .hero__inner .hero__body.group-left .content .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .hero .hero__container .hero__inner .hero__body.group-left .content .email-subscribe__button {
  @apply mb-0 md:my-0;
}
.hero .hero__container .hero__inner .hero__body .hero__sub-title {
  @apply text-xl md:text-2xl text-white mb-7;
}
.hero .hero__container .hero__inner .hero__body .hero__sub-title span, .hero .hero__container .hero__inner .hero__body .hero__sub-title b, .hero .hero__container .hero__inner .hero__body .hero__sub-title strong {
  @apply font-bold;
}
.hero .hero__container .hero__inner .hero__body .hero__text {
  @apply text-sm md:text-base font-normal text-white mb-4 w-4/5;
}
.hero .hero__container .hero__inner .hero__footer .hero__list {
  @apply flex mt-6;
}
.hero .hero__container .hero__inner .hero__footer .hero__list li {
  @apply flex items-center mr-6;
}
.hero .hero__container .hero__inner .hero__footer .hero__list li:last-child {
  @apply mr-0;
}
.hero .hero__container .hero__inner .hero__footer .hero__list li .hero__list-icon {
  @apply w-5 h-5 mr-3;
}
.hero .hero__container .hero__inner .hero__footer .hero__list li .hero__list-title {
  @apply text-xs font-bold text-white;
}
.hero .hero__container .hero__inner .hero__footer .btn-group {
  @apply flex-row;
}
.hero .hero__container .hero__inner .hero__footer .btn-group .btn, .hero .hero__container .hero__inner .hero__footer .btn-group .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .hero .hero__container .hero__inner .hero__footer .btn-group .email-subscribe__button {
  @apply mr-2.5;
}
.hero .hero__container .hero__inner .hero__footer .btn-group .btn:last-child, .hero .hero__container .hero__inner .hero__footer .btn-group .email-subscribe .email-subscribe__container .email-subscribe__button:last-child, .email-subscribe .email-subscribe__container .hero .hero__container .hero__inner .hero__footer .btn-group .email-subscribe__button:last-child {
  @apply mr-0;
}

.hero-jobs {
  @apply w-full mt-16 relative z-20 bg-blue-dark;
}
.hero-jobs .hero__container {
  @apply relative flex container mx-auto p-4 min-h-[500px];
}
.hero-jobs .hero__container .hero__inner {
  @apply w-full mx-auto py-8;
}
.hero-jobs .hero__container .hero__inner .hero__body {
  @apply w-full max-w-3xl;
}
.hero-jobs .hero__container .hero__inner .hero__body .hero__title {
  @apply w-full text-3xl font-blackout leading-tight text-white mb-4 w-11/12;
}
.hero-jobs .hero__container .hero__inner .hero__body .hero__sub-title {
  @apply w-full text-2xl font-blackout leading-tight text-white mb-4 w-11/12;
}
.hero-jobs .hero__container .hero__inner .hero__body .hero__sub-title span {
  @apply font-bold;
}
.hero-jobs .hero__container .hero__inner .hero__body .hero__sub-title span.color1 {
  @apply text-[#87bf63];
}
.hero-jobs .hero__container .hero__inner .hero__body .hero__sub-title span.color2 {
  @apply text-[#e89645];
}
.hero-jobs .hero__container .hero__inner .hero__body .hero__sub-title span.color3 {
  @apply text-[#5d9fd5];
}

.search-hero {
  @apply w-full flex;
}
.search-hero.is_fixed {
  @apply fixed top-0 left-0 w-full bg-blue-dark z-100;
}
.search-hero.is_fixed .search-hero__container {
  @apply flex container mx-auto bg-transparent pb-4;
}
.search-hero.is_fixed .search-hero__container form .search-hero__filters .search-hero__button {
  @apply bg-white text-blue-dark;
}
.search-hero.is_fixed .search-hero__container form .search-hero__filters .search-hero__button .search-hero__button-icon path {
  @apply fill-current;
}
.search-hero .search-hero__container {
  @apply w-full p-8 bg-white rounded-2xl;
}
.search-hero .search-hero__container .search-hero__carousels {
  @apply w-full grid grid-cols-1;
}
.search-hero .search-hero__container .search-hero__carousels[data-count="2"] {
  @apply md:grid-cols-2 gap-8;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel {
  @apply w-full h-full;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel h3 {
  @apply leading-5;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel h3.desktop {
  @apply hidden lg:inline-block;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel h3.mobile {
  @apply inline-block lg:hidden text-center;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider {
  @apply w-full rounded-lg bg-escgrey-light flex mt-4 p-3 min-h-[120px];
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow {
  @apply w-5 h-5 bg-white border border-escgrey-dark;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow svg {
  @apply w-4 h-4;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow.splide__arrow--prev {
  @apply -translate-x-2 -translate-y-1/2;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow.splide__arrow--next {
  @apply translate-x-2 -translate-y-1/2;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow:disabled {
  @apply pointer-events-none opacity-10 cursor-default;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track {
  @apply w-full;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list {
  @apply w-full h-full;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide {
  @apply m-0;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a,
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label {
  @apply flex flex-col justify-center items-center h-full p-2 rounded-lg cursor-pointer;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a.active,
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label.active {
  @apply bg-blue text-white;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a input[type=checkbox],
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label input[type=checkbox] {
  @apply hidden;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a span[class^=ico-],
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label span[class^=ico-] {
  @apply text-2xl mb-2;
}
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a > p,
.search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label > p {
  @apply text-center text-xs min-h-[48px];
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search-hero .search-hero__container form {
  @apply relative flex items-center justify-between w-full mt-8;
}
.search-hero .search-hero__container form .search-hero__search {
  @apply relative min-w-[280px];
}
.search-hero .search-hero__container form .search-hero__search .search-hero__icon {
  @apply absolute left-4 top-4 w-6;
}
.search-hero .search-hero__container form .search-hero__search .search-hero__input {
  @apply w-full rounded-2xl p-4 pl-12 border-2 border-escgrey-midlight text-sm md:text-base text-blue-dark bg-white outline-none;
}
.search-hero .search-hero__container form .search-hero__facets {
  @apply w-full h-full px-6 py-1 hidden lg:grid grid-cols-3 gap-4;
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet {
  @apply w-full h-full relative;
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-button {
  @apply relative w-full h-full cursor-pointer rounded-2xl border-2 border-escgrey-midlight bg-white py-2 pl-3 pr-10 text-left sm:text-sm;
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options {
  @apply absolute z-10 mt-1 max-h-56 w-auto overflow-auto border-2 rounded-2xl bg-white py-1 text-base shadow-lg sm:text-sm;
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label {
  @apply cursor-pointer;
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label p {
  @apply text-xs font-bold ml-3 block truncate;
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label input {
  @apply appearance-none h-5 w-5 bg-white border-2 border-escgrey-dark rounded focus:outline-none;
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label input:checked {
  @apply bg-blue-light bg-contain bg-center bg-no-repeat border-transparent;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}
.search-hero .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options .search-hero__facet-count {
  @apply absolute inset-y-2 right-2 flex justify-center items-center w-5 h-5 text-xxs font-semibold rounded bg-blue-extralight text-blue-extradark;
}
.search-hero .search-hero__container form .search-hero__filters .search-hero__button {
  @apply relative rounded-2xl py-4 px-8 bg-blue-dark text-white text-sm font-bold	focus:outline-none cursor-pointer;
}
.search-hero .search-hero__container form .search-hero__filters .search-hero__button .search-hero__button-icon {
  @apply md:hidden mx-auto w-6 h-auto;
}
.search-hero .search-hero__container form .search-hero__filters .search-hero__button .search-hero__button-count {
  @apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
}

.search-hero-home {
  @apply max-w-[900px] w-full flex;
}
.search-hero-home.is_fixed {
  @apply fixed top-0 left-0 w-full bg-blue-dark z-100;
}
.search-hero-home.is_fixed .search-hero__container {
  @apply flex container mx-auto bg-transparent pb-4;
}
.search-hero-home.is_fixed .search-hero__container form .search-hero__filters .search-hero__button {
  @apply bg-white text-blue-dark;
}
.search-hero-home.is_fixed .search-hero__container form .search-hero__filters .search-hero__button .search-hero__button-icon path {
  @apply fill-current;
}
.search-hero-home .search-hero__container {
  @apply w-full p-8 bg-white rounded-2xl;
}
.search-hero-home .search-hero__container .search-hero__carousels {
  @apply w-full grid grid-cols-1;
}
.search-hero-home .search-hero__container .search-hero__carousels[data-count="2"] {
  @apply md:grid-cols-2 gap-8;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel {
  @apply w-full h-full;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel h3 {
  @apply leading-5;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel h3.desktop {
  @apply hidden lg:inline-block;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel h3.mobile {
  @apply inline-block lg:hidden text-center;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider {
  @apply w-full rounded-lg bg-escgrey-light flex mt-4 p-3 min-h-[120px];
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow {
  @apply w-5 h-5 bg-white border border-escgrey-dark;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow svg {
  @apply w-4 h-4;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow.splide__arrow--prev {
  @apply -translate-x-2 -translate-y-1/2;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow.splide__arrow--next {
  @apply translate-x-2 -translate-y-1/2;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow:disabled {
  @apply pointer-events-none opacity-10 cursor-default;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track {
  @apply w-full;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list {
  @apply w-full h-full;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide {
  @apply m-0;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a,
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label {
  @apply flex flex-col justify-center items-center h-full p-2 rounded-lg cursor-pointer;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a.active,
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label.active {
  @apply bg-blue text-white;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a input[type=checkbox],
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label input[type=checkbox] {
  @apply hidden;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a span[class^=ico-],
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label span[class^=ico-] {
  @apply text-2xl mb-2;
}
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a > p,
.search-hero-home .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label > p {
  @apply text-center text-xs min-h-[48px];
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.search-hero-home .search-hero__container form {
  @apply relative flex items-center justify-between w-full mt-8;
}
.search-hero-home .search-hero__container form .search-hero__search {
  @apply relative min-w-[280px];
}
.search-hero-home .search-hero__container form .search-hero__search .search-hero__icon {
  @apply absolute left-4 top-4 w-6;
}
.search-hero-home .search-hero__container form .search-hero__search .search-hero__input {
  @apply w-full rounded-2xl p-4 pl-12 border-2 border-escgrey-midlight text-sm md:text-base text-blue-dark bg-white outline-none;
}
.search-hero-home .search-hero__container form .search-hero__facets {
  @apply w-full h-full px-6 py-1 hidden lg:grid grid-cols-3 gap-4;
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet {
  @apply w-full h-full relative;
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-button {
  @apply relative w-full h-full cursor-pointer rounded-2xl border-2 border-escgrey-midlight bg-white py-2 pl-3 pr-10 text-left sm:text-sm;
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options {
  @apply absolute z-10 mt-1 max-h-56 w-auto overflow-auto border-2 rounded-2xl bg-white py-1 text-base shadow-lg sm:text-sm;
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label {
  @apply cursor-pointer;
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label p {
  @apply text-xs font-bold ml-3 block truncate;
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label input {
  @apply appearance-none h-5 w-5 bg-white border-2 border-escgrey-dark rounded focus:outline-none;
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options label input:checked {
  @apply bg-blue-light bg-contain bg-center bg-no-repeat border-transparent;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}
.search-hero-home .search-hero__container form .search-hero__facets .search-hero__facet .search-hero__facet-options .search-hero__facet-count {
  @apply absolute inset-y-2 right-2 flex justify-center items-center w-5 h-5 text-xxs font-semibold rounded bg-blue-extralight text-blue-extradark;
}
.search-hero-home .search-hero__container form .search-hero__filters .search-hero__button {
  @apply relative rounded-2xl py-4 px-8 bg-blue-dark text-white text-sm font-bold	focus:outline-none cursor-pointer;
}
.search-hero-home .search-hero__container form .search-hero__filters .search-hero__button .search-hero__button-icon {
  @apply md:hidden mx-auto w-6 h-auto;
}
.search-hero-home .search-hero__container form .search-hero__filters .search-hero__button .search-hero__button-count {
  @apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
}

.rainbow-search-hero {
  @apply w-full bg-white;
}
.rainbow-search-hero.is_fixed {
  @apply fixed top-0 left-0 w-full z-40;
}
.rainbow-search-hero.is_fixed .search-hero__bg .btn-scroll-top {
  @apply opacity-100 cursor-pointer pointer-events-auto;
}
.rainbow-search-hero .search-hero__bg {
  @apply w-[110vw] relative h-24 transition-all;
  background: repeating-linear-gradient(120deg, #ef89b2 0, #ef89b2 11.1vw, #90d0f3 11.1vw, #90d0f3 22.2vw, #936614 22.2vw, #936614 33.3vw, #b33930 33.3vw, #b33930 44.4vw, #b3772e 44.4vw, #b3772e 55.5vw, #b39f1e 55.5vw, #b39f1e 66.6vw, #309f67 66.6vw, #309f67 77.7vw, #113c51 77.7vw, #113c51 88.8vw, #7a6aad 88.8vw, #7a6aad 100vw);
  transform: translateX(-5vw);
  margin-top: -36px;
}
.rainbow-search-hero .search-hero__bg:before {
  content: "";
  @apply absolute inset-0 bg-black opacity-50 z-10;
}
.rainbow-search-hero .search-hero__bg:after {
  content: "";
  @apply absolute bottom-0 z-20 w-[110vw] h-2;
  background: repeating-linear-gradient(120deg, #ef89b2 0, #ef89b2 11.1vw, #90d0f3 11.1vw, #90d0f3 22.2vw, #936614 22.2vw, #936614 33.3vw, #b33930 33.3vw, #b33930 44.4vw, #b3772e 44.4vw, #b3772e 55.5vw, #b39f1e 55.5vw, #b39f1e 66.6vw, #309f67 66.6vw, #309f67 77.7vw, #113c51 77.7vw, #113c51 88.8vw, #7a6aad 88.8vw, #7a6aad 100vw);
  transform: translateX(2vw);
}
.rainbow-search-hero .search-hero__bg .search-hero__menu {
  @apply absolute list-none flex top-1 left-1/2 -translate-x-1/2 z-30 p-0 transition-opacity opacity-0;
}
.rainbow-search-hero .search-hero__bg .search-hero__menu li {
  @apply px-4;
}
.rainbow-search-hero .search-hero__bg .search-hero__menu li a {
  @apply text-white;
}
.rainbow-search-hero .search-hero__bg .search-hero__menu li.active a {
  @apply underline;
}
.rainbow-search-hero .search-hero__bg .btn-back-to-browse {
  @apply absolute bottom-1 left-24 xl:left-32 -translate-y-1/2 text-white cursor-pointer pointer-events-auto font-light text-lg z-50 transition-opacity hidden lg:flex items-center justify-start;
}
.rainbow-search-hero .search-hero__bg .btn-back-to-browse .arrow {
  @apply w-6 h-6 mr-2 pointer-events-none;
}
.rainbow-search-hero .search-hero__bg .btn-scroll-top {
  @apply absolute bottom-1 right-24 xl:right-32 -translate-y-1/2 text-white pointer-events-none font-light text-lg z-50 opacity-0 transition-opacity hidden lg:flex items-center justify-start;
}
.rainbow-search-hero .search-hero__bg .btn-scroll-top .arrow {
  @apply w-6 h-6 mr-2 pointer-events-none;
}
.rainbow-search-hero.expanded .search-hero__bg {
  @apply mt-0;
}
.rainbow-search-hero.expanded .search-hero__bg .search-hero__menu {
  @apply opacity-100;
}
.rainbow-search-hero .search-hero__container {
  @apply w-full px-4 lg:px-8 bg-white relative flex flex-col border-b border-b-gray-200;
}
.rainbow-search-hero .search-hero__container form {
  @apply relative flex items-center justify-center w-full -translate-y-1/2 pointer-events-none;
}
.rainbow-search-hero .search-hero__container form .search-hero__search {
  @apply relative w-full h-[64px] max-w-xl p-2 pl-4 flex justify-between border-2 border-escgrey-midlight bg-white rounded-2xl pointer-events-auto;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__group {
  @apply flex justify-start w-full;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__group .search-hero__icon {
  @apply w-6 mr-2 text-escgrey-midlight;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__group .search-hero__input {
  @apply bg-transparent text-sm md:text-base text-blue-dark  outline-none w-full pr-3;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__button {
  @apply bg-white text-blue-dark font-semibold px-4 py-2 border border-escgrey-midlight rounded-xl flex;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__button .search-hero__button-icon {
  @apply md:hidden mx-auto w-6 h-auto;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__button .search-hero__button-icon path {
  @apply fill-current;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__button .search-hero__button-label {
  @apply hidden md:block;
}
.rainbow-search-hero .search-hero__container form .search-hero__search .search-hero__button .search-hero__button-count {
  @apply absolute top-1 right-1 flex justify-center items-center w-5 h-5 text-xxs font-normal text-white bg-blue-light rounded;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels {
  @apply w-full grid grid-cols-1 mt-[-22px];
}
.rainbow-search-hero .search-hero__container .search-hero__carousels[data-count="2"] {
  @apply grid-cols-2 gap-8;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel {
  @apply w-full h-32 flex flex-col justify-between;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel h3 {
  @apply leading-5;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel h3.desktop {
  @apply hidden lg:inline-block;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel h3.mobile {
  @apply inline-block lg:hidden text-center;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider {
  @apply w-full rounded-lg flex px-3 h-[100px];
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow {
  @apply w-5 h-5 bg-white border border-escgrey-dark;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow svg {
  @apply w-4 h-4;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow.splide__arrow--prev {
  @apply -translate-x-4 lg:-translate-x-2 -translate-y-1/2;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow.splide__arrow--next {
  @apply translate-x-4 lg:translate-x-2 -translate-y-1/2;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__arrow:disabled {
  @apply pointer-events-none opacity-10 cursor-default;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track {
  @apply w-full;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list {
  @apply w-full h-full;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide {
  @apply m-0 pb-1;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a,
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label {
  @apply flex flex-col justify-center items-center h-full cursor-pointer;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a.active,
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label.active {
  @apply text-blue-light font-bold;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a.active:after,
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label.active:after {
  content: "";
  @apply absolute w-full border-b-2 border-b-blue-light left-0 bottom-0;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a:hover,
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label:hover {
  @apply text-blue;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a input[type=checkbox],
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label input[type=checkbox] {
  @apply hidden;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a span[class^=ico-],
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label span[class^=ico-] {
  @apply text-2xl mb-2;
}
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > a > p,
.rainbow-search-hero .search-hero__container .search-hero__carousels .search-hero__carousel .search-hero__carousel-slider .splide__track ul.splide__list li.splide__slide > label > p {
  @apply text-center text-xs;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.browse-hero {
  @apply w-full min-h-[600px] relative;
}
.browse-hero .browse-hero__bg {
  @apply absolute left-0 top-0 w-full h-full object-cover -z-1 bg-offwhite;
}
.browse-hero .browse-hero__bg image {
  @apply w-full;
}
.browse-hero .container {
  @apply container mx-auto py-16 md:py-32 px-4 flex flex-col lg:flex-row;
}
.browse-hero .container .browse-hero__content-left {
  @apply w-full lg:w-1/2 flex flex-col items-start pr-16;
}
.browse-hero .container .browse-hero__content-left h1 {
  @apply text-4xl text-blue-darker font-bold;
}
.browse-hero .container .browse-hero__content-left h2 {
  @apply text-black text-2xl font-bold mt-8;
}
.browse-hero .container .browse-hero__content-left h2 strong, .browse-hero .container .browse-hero__content-left h2 b {
  @apply text-white;
}
.browse-hero .container .browse-hero__content-left .button {
  @apply mt-8;
}
.browse-hero .container .browse-hero__content-right {
  @apply w-full lg:w-1/2 flex mt-3 lg:mt-0;
}

.hero-home-default {
  @apply w-full relative z-20 bg-blue-dark overflow-hidden;
}
.hero-home-default .hero__container {
  @apply relative flex justify-center items-center container mx-auto p-4 min-h-[700px];
}
.hero-home-default .hero__container .hero__inner {
  @apply w-full mx-auto py-8 flex flex-col items-center;
}
.hero-home-default .hero__container .hero__inner .hero__body {
  @apply w-full mx-auto text-center;
}
.hero-home-default .hero__container .hero__inner .hero__body .hero__intro {
  @apply w-full text-[1.7rem] font-medium mx-auto mb-6;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-[#328cf7] to-[#27c1a9];
  width: fit-content;
}
.hero-home-default .hero__container .hero__inner .hero__body .hero__title {
  @apply w-full text-3xl md:text-5xl font-bold leading-normal text-white mb-6;
}
.hero-home-default .hero__container .hero__inner .hero__body .hero__sub-title {
  @apply w-full max-w-[580px] text-2xl leading-normal text-white mx-auto mb-10;
}
.hero-home-default .hero__container .hero__inner .hero__body .hero__sub-title b {
  @apply font-bold;
}
.hero-home-default .hero__container .hero__inner .hero__body .hero__sub-title b.green {
  @apply text-[#75c055];
}
.hero-home-default .hero__container .hero__inner .hero__body .hero__sub-title b.orange {
  @apply text-[#f6902d];
}
.hero-home-default .hero__container .hero__inner .hero__body .hero__sub-title b.blue {
  @apply text-[#44a0d8];
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels {
  @apply md:grid grid-cols-2 gap-8 max-w-[900px] bg-white text-left mx-auto p-5 rounded-xl;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel {
  @apply first:mb-5 md:first:mb-0;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .title {
  @apply text-sm mb-4;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide {
  @apply flex w-full min-h-[120px] bg-[#f1f1f2] p-3 rounded-xl;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__arrow {
  @apply w-5 h-5 bg-white border border-escgrey-dark;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__arrow svg {
  @apply w-4 h-4;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__arrow.splide__arrow--prev {
  @apply -translate-x-2 -translate-y-1/2;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__arrow.splide__arrow--next {
  @apply translate-x-2 -translate-y-1/2;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__arrow:disabled {
  @apply pointer-events-none opacity-10 cursor-default;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__track {
  @apply w-full;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__track ul.splide__list {
  @apply w-full h-full;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__track ul.splide__list li.splide__slide {
  @apply m-0;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__track ul.splide__list li.splide__slide > a {
  @apply flex flex-col items-center p-2 pb-0 cursor-pointer;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__track ul.splide__list li.splide__slide > a img {
  @apply w-full h-8 object-contain mb-2;
}
.hero-home-default .hero__container .hero__inner .hero__body .home-hero__carousels .home-hero__carousel .splide--slide .splide__track ul.splide__list li.splide__slide > a > p {
  @apply text-center text-xs;
  height: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.hero-home-default .hero__container .hero__inner .hero__btns {
  @apply mt-6 md:space-x-6 text-center;
}
.hero-home-default .hero__bg {
  @apply absolute inset-0 -z-1;
  @apply after:absolute after:inset-0 after:bg-black/30;
}
.hero-home-default .hero__bg img {
  @apply w-full h-full md:h-auto object-cover;
}
.hero-home-default .hero__planet {
  @apply absolute bottom-0 right-0 min-w-[14rem] max-w-[28rem] w-[40vw] -z-1;
}

.home-welcome {
  @apply relative bg-beige-light text-xl font-black text-blue-dark text-center;
}
.home-welcome .line {
  @apply absolute border-0;
}
.home-welcome .line.line-1 {
  @apply right-0 top-[28rem] sm:top-[22rem] md:top-72;
}
.home-welcome .line.line-2 {
  @apply left-0 top-[1050px] md:top-[830px] scale-[-1];
}
.home-welcome .line.line-3 {
  @apply right-0 top-[1730px] md:top-[1370px];
}
.home-welcome .line.line-4 {
  @apply left-0 top-[2130px] md:top-[1920px] scale-[-1];
}
.home-welcome .line.line-5 {
  @apply right-0 top-[2870px] md:top-[2500px];
}
.home-welcome .home-welcome__intro {
  @apply relative z-10;
}
.home-welcome .home-welcome__intro .home-welcome__intro-title1 {
  @apply text-lg font-medium mb-5;
}
.home-welcome .home-welcome__intro .home-welcome__intro-title2 {
  @apply grid text-[2.75rem] font-extrabold leading-normal mx-auto mb-5;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-[#052e47] to-[#28a6bf];
  width: fit-content;
}
.home-welcome .home-welcome__intro .home-welcome__intro-title3 {
  @apply text-[1.7rem] font-medium leading-normal mx-auto mb-2;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-[#328df5] to-[#27c2a9];
  width: fit-content;
}
.home-welcome .home-welcome__intro p {
  @apply text-base last:mb-8;
}
.home-welcome .home-welcome-cards {
  @apply text-white text-left space-y-16 mt-32;
}
.home-welcome .home-welcome-cards .home-welcome-card {
  @apply relative grid md:grid-cols-2 w-full md:min-h-[482px] bg-beige-dark/95 rounded-2xl overflow-hidden;
}
.home-welcome .home-welcome-cards .home-welcome-card .bg {
  @apply absolute;
}
.home-welcome .home-welcome-cards .home-welcome-card .left {
  @apply relative pl-9 pt-16;
}
.home-welcome .home-welcome-cards .home-welcome-card .left h3 {
  @apply text-lg font-light mb-4;
}
.home-welcome .home-welcome-cards .home-welcome-card .left p {
  @apply font-medium mb-4;
}
.home-welcome .home-welcome-cards .home-welcome-card .left p strong {
  @apply text-3xl mb-4;
}
.home-welcome .home-welcome-cards .home-welcome-card .right {
  @apply relative flex justify-center items-center;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images {
  @apply grid grid-cols-2 gap-7 py-8;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image {
  @apply w-[120px] h-[120px] bg-white rounded-lg relative overflow-hidden;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image img {
  @apply absolute left-0 top-0 w-full h-full object-contain opacity-0 transition-opacity duration-500;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="0"] img[data-id="0"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="1"] img[data-id="1"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="2"] img[data-id="2"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="3"] img[data-id="3"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="4"] img[data-id="4"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="5"] img[data-id="5"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="6"] img[data-id="6"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="7"] img[data-id="7"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="8"] img[data-id="8"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="9"] img[data-id="9"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="10"] img[data-id="10"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="11"] img[data-id="11"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="12"] img[data-id="12"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="13"] img[data-id="13"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="14"] img[data-id="14"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="15"] img[data-id="15"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="16"] img[data-id="16"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="17"] img[data-id="17"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="18"] img[data-id="18"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="19"] img[data-id="19"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="20"] img[data-id="20"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="21"] img[data-id="21"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="22"] img[data-id="22"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="23"] img[data-id="23"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card .right .grid-images .image[data-order="24"] img[data-id="24"] {
  @apply opacity-100;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(1) .bg {
  @apply top-2.5 -left-3 md:-left-40;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(1) .left {
  @apply md:pt-[120px] w-full max-w-[430px];
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(2) .left {
  @apply w-full max-w-[470px];
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(2) .right .grid-images a {
  @apply flex flex-col justify-center items-center w-[120px] h-[120px] bg-white rounded-lg;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(2) .right .grid-images a .icon {
  @apply w-12 h-12 object-contain;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(2) .right .grid-images a p {
  @apply text-xs font-light text-center text-black mt-2 mb-0;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(3) .left {
  @apply pb-6 md:pt-[152px] w-full max-w-[420px];
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(4) .left {
  @apply pt-[120px] md:pt-[140px] w-full max-w-[370px];
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(4) .right {
  @apply flex flex-col items-start p-8 md:pr-[40px];
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(4) .right div {
  @apply flex items-center w-full max-w-[430px] bg-white text-black p-5 rounded-xl first:mb-8 md:first:mb-16 last:ml-auto;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(4) .right div > img {
  @apply mr-4;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(5) .bg {
  @apply top-7 -left-20 md:-left-40;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(5) .left {
  @apply md:pt-[126px] w-full max-w-[380px] md:max-w-[460px];
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(5) .right {
  @apply py-8;
}
.home-welcome .home-welcome-cards .home-welcome-card:nth-child(5) .right div {
  @apply w-full max-w-[380px] bg-white p-10 rounded-xl;
}

.home-hiring {
  @apply relative bg-[#f6f8fc] text-base font-black text-blue-dark pt-40;
}
.home-hiring .medal {
  @apply absolute top-0 left-1/2 -translate-x-1/2;
}
.home-hiring .container {
  @apply relative;
}
.home-hiring .container .lady-hiring {
  @apply absolute right-0 bottom-0 w-28 md:w-40 lg:w-60 h-auto translate-y-24 z-0;
}
.home-hiring .container .home-hiring__intro {
  @apply flex flex-col lg:flex-row w-full;
}
.home-hiring .container .home-hiring__intro .home-hiring__image {
  @apply w-full mb-10 lg:mb-0;
}
.home-hiring .container .home-hiring__intro .home-hiring__body {
  @apply w-full;
}
.home-hiring .container .home-hiring__intro .home-hiring__body .home-hiring__header {
  @apply text-lg font-medium mb-4;
}
.home-hiring .container .home-hiring__intro .home-hiring__body .home-hiring__title {
  @apply text-4xl font-extrabold mb-4;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-[#052e47] to-[#4cb7ca];
}
.home-hiring .container .home-hiring__intro .home-hiring__body .home-hiring__subtitle {
  @apply max-w-3xl text-xl font-bold leading-relaxed mb-4 mx-auto;
}
.home-hiring .container .home-hiring__intro .home-hiring__body .home-hiring__btn {
  @apply text-lg font-semibold mb-0 py-3;
}
.home-hiring .container .home-hiring__btn {
  @apply bg-blue-dark inline-block text-white font-semibold text-sm mt-4 mb-16 py-4 px-12 rounded-full cursor-pointer;
}
.home-hiring .container .trusted-by {
  @apply mt-16;
}
.home-hiring .container .trusted-by .trusted-by__title {
  @apply text-lg font-medium text-center mb-8;
}
.home-hiring .container .trusted-by .trusted-by__companies {
  @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 lg:gap-10;
}
.home-hiring .container .trusted-by .trusted-by__companies .trusted-by__img {
  @apply bg-white h-[100px] p-3 rounded-xl;
}
.home-hiring .container .trusted-by .trusted-by__companies .trusted-by__img img {
  @apply w-auto h-full mx-auto;
}

.hero-home-user {
  @apply w-full mt-16 relative z-20 bg-[#1c3c56];
}
.hero-home-user .hero__container {
  @apply relative flex justify-center items-center container mx-auto p-4 min-h-[500px];
}
.hero-home-user .hero__container .hero__inner {
  @apply w-full flex flex-col items-center mx-auto;
}
.hero-home-user .hero__container .hero__inner .hero__body {
  @apply w-full;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__title {
  @apply w-full text-3xl md:text-5xl text-center md:text-left font-blackout leading-normal text-white mb-4;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section {
  @apply flex flex-col-reverse lg:flex-row md:pt-7;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches {
  @apply relative flex flex-col justify-start w-full lg:max-w-[360px] h-auto bg-white rounded-lg mb-2 p-4 lg:mb-5 order-3 lg:order-none;
  @apply after:absolute after:top-2 after:left-2 after:w-full after:h-full after:bg-blue-light after:rounded-lg after:z-[-1];
  @apply self-start;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__heading {
  @apply text-xl font-bold;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__tip {
  @apply text-sm border-l-4 border-l-orange pl-3 mt-2;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items {
  @apply flex items-center;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items .job-matches__container {
  @apply flex flex-col justify-start w-full;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items .job-matches__container .job-matches__title {
  @apply text-base w-full;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items .job-matches__container .job-matches__summary {
  @apply flex mt-3;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items .job-matches__container .job-matches__summary .job-matches__counter {
  @apply text-xs font-bold px-2.5 py-1 rounded-full;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items .job-matches__container .job-matches__summary .job-matches__counter.blue {
  @apply bg-blue-pale;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items .job-matches__container .job-matches__summary .job-matches__counter.grey {
  @apply bg-escgrey-midlight;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__items .job-matches__container .job-matches__summary .job-matches__btn {
  @apply border border-grey-dark text-xs font-bold px-2.5 py-1 rounded-full ml-4;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__links {
  @apply flex justify-between w-full mt-4;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .job-matches .job-matches__links .link {
  @apply text-sm font-semibold;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group {
  @apply flex flex-col-reverse md:flex-row md:justify-center lg:ml-auto;
  @apply mt-10 lg:mt-0;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__profile {
  @apply relative bg-white w-full md:w-1/2 h-auto lg:w-60 md:h-60 ml-0 mb-5 md:mb-0 rounded-lg overflow-hidden;
  @apply justify-center items-center;
  @apply hidden md:flex;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__profile img {
  @apply w-full max-w-xs;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__profile .edit-profile {
  @apply absolute top-2 left-1/2 -translate-x-1/2 opacity-100 lg:opacity-0 transition-opacity;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__profile:hover .edit-profile {
  @apply cursor-pointer opacity-100;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps {
  @apply flex-none w-full md:w-1/2 h-auto lg:w-52 md:ml-5 lg:mr-5 mb-5 md:mb-0;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps {
  @apply relative rounded-lg bg-white min-h-full pt-7 md:pt-8 pb-4 md:pb-6 px-4 flex flex-col justify-between text-center;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps .tick {
  @apply absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 w-14 h-14;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps .next-steps__header {
  @apply flex sm:flex-col justify-center items-center;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps .next-steps__header p {
  @apply w-auto text-sm font-normal leading-relaxed px-px m-0;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps .next-steps__header p span {
  @apply sm:hidden;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps p {
  @apply w-full text-xl font-bold leading-normal mt-3 mb-5 lg:my-0;
}
.hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps .btn, .hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .hero-home-user .hero__container .hero__inner .hero__body .hero__section .hero__section__group .hero__cards-steps .card.next-steps .email-subscribe__button {
  @apply w-auto mx-auto lg:mx-0 text-xs font-semibold m-0 py-2;
}
.hero-home-user .hero__container .hero__inner .hero__footer {
  @apply w-full pt-7 lg:pt-4 pb-5;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-title {
  @apply flex justify-center md:justify-start items-center font-bold text-white mb-5;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-title .site-search-btn__icon {
  @apply w-4 h-auto mr-2 text-white;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations {
  @apply flex flex-wrap justify-center md:justify-start items-center gap-4 min-h-[32px];
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation {
  @apply flex items-center w-auto text-sm;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation div {
  @apply flex items-center p-1.5;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation div.loading {
  @apply min-h-[32px] py-0;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation div:first-child {
  @apply bg-white rounded-l-full px-2.5;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation div:first-child .site-search-btn__icon {
  @apply w-3.5 h-auto mr-1.5;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation div:first-child p {
  @apply flex-1;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation div:last-child {
  @apply bg-yellow font-bold rounded-r-full;
}
.hero-home-user .hero__container .hero__inner .hero__footer .hero__footer-recommendations .recommendation div:last-child p {
  @apply w-full text-center  px-2.5;
}

.saved-user-data-tabs {
  @apply flex justify-start w-full mx-auto container bg-white px-4 pt-10;
}
.saved-user-data-tabs ul {
  @apply flex flex-wrap justify-center items-center px-2 md:px-0;
}
.saved-user-data-tabs ul li {
  @apply flex w-1/2 md:w-auto pr-2;
}
.saved-user-data-tabs ul li a {
  @apply flex flex-row items-center w-full text-sm font-bold bg-blue-dark text-white rounded-tl-lg rounded-tr-md p-5 drop-shadow-lg;
}

.section.applications, .section.saved-jobs, .section.favourite-companies, .section.matches, .section.saved-resources, .section.saved-searches {
  @apply pt-6 md:pt-8 pb-8;
}
.section.applications .section-title__wrapper, .section.saved-jobs .section-title__wrapper, .section.favourite-companies .section-title__wrapper, .section.matches .section-title__wrapper, .section.saved-resources .section-title__wrapper, .section.saved-searches .section-title__wrapper {
  @apply flex flex-row items-center mb-2.5;
}
.section.applications .section-title__wrapper .section-icon, .section.saved-jobs .section-title__wrapper .section-icon, .section.favourite-companies .section-title__wrapper .section-icon, .section.matches .section-title__wrapper .section-icon, .section.saved-resources .section-title__wrapper .section-icon, .section.saved-searches .section-title__wrapper .section-icon {
  @apply mr-2;
}
.section.applications .section-title__wrapper .section-title, .section.saved-jobs .section-title__wrapper .section-title, .section.favourite-companies .section-title__wrapper .section-title, .section.matches .section-title__wrapper .section-title, .section.saved-resources .section-title__wrapper .section-title, .section.saved-searches .section-title__wrapper .section-title {
  @apply font-montserrat text-2xl	font-bold text-blue-dark normal-case mb-0;
}
.section.applications .section-text, .section.saved-jobs .section-text, .section.favourite-companies .section-text, .section.matches .section-text, .section.saved-resources .section-text, .section.saved-searches .section-text {
  @apply text-sm font-normal text-blue-dark mb-5;
}

.appliction__cards {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-4;
}

.dashboard-tabs-container {
  @apply container mx-auto px-4 pb-16;
}

.search-sort__header {
  @apply flex justify-between items-center h-[50px] mb-6;
}
.search-sort__header .search-sort__search {
  @apply relative h-full;
}
.search-sort__header .search-sort__search > input {
  @apply h-full pl-12 pr-4 text-sm border rounded-full;
}
.search-sort__header .search-sort__search > svg {
  @apply absolute top-1/2 left-4 -translate-y-1/2 w-4 h-auto;
}
.search-sort__header .search-sort__sort {
  @apply relative;
}
.search-sort__header .search-sort__sort .search-sort__sort-btn {
  @apply relative w-full h-full rounded-full border border-blue-dark p-6 md:py-3.5 md:pl-12 md:pr-4 shadow-sm text-sm cursor-default;
}
.search-sort__header .search-sort__sort .search-sort__sort-btn .search-sort__sort-btn-icon {
  @apply pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 w-4 ml-3;
}
.search-sort__header .search-sort__sort .search-sort__sort-btn .search-sort__sort-btn-title {
  @apply hidden md:block font-semibold truncate;
}
.search-sort__header .search-sort__sort:hover .search-sort__list {
  @apply opacity-100 max-h-60 transition-all ease-in-out duration-200;
}
.search-sort__header .search-sort__sort .search-sort__list {
  @apply opacity-0 absolute top-full right-0 min-w-[186px] max-h-0 w-full overflow-hidden rounded-md bg-white text-sm shadow-lg border z-30;
}
.search-sort__header .search-sort__sort .search-sort__list .search-sort__list-item {
  @apply text-blue-dark relative py-2 pl-3 pr-9 cursor-pointer hover:bg-blue-dark hover:text-white;
}
.search-sort__header .search-sort__sort .search-sort__list .search-sort__list-item.active {
  @apply bg-blue-dark text-white;
}
.search-sort__header .search-sort__sort .search-sort__list .search-sort__list-item .search-sort__list-title {
  @apply font-normal block truncate;
}
.search-sort__header .search-sort__sort .search-sort__list .search-sort__list-item .search-sort__list-selected {
  @apply text-blue-light absolute inset-y-0 right-0 flex items-center pr-4;
}

.saved-jobs__btns {
  @apply relative;
}

.saved-jobs-cards {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-4;
}
.saved-jobs-cards .saved-jobs-card__outter {
  @apply mb-5 mr-7;
  max-width: 750px;
}
.saved-jobs-cards .saved-jobs-card__outter:last-child {
  @apply mb-0;
}

.saved-searches-cards {
  @apply mb-4;
}
.saved-searches-cards .saved-searches-card__outter {
  @apply relative bg-grey mr-7 z-10;
  max-width: 750px;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__checkbox {
  @apply hidden;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__checkbox:checked + .saved-searches-card__options + .saved-searches-card {
  @apply transform -translate-x-56 md:-translate-x-80 xl:translate-x-0 rounded-r;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__checkbox:checked + .saved-searches-card__options .saved-searches-card__options-container {
  @apply transform xl:translate-x-80;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__checkbox:checked + .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn .open {
  @apply hidden;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__checkbox:checked + .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn .close {
  @apply block;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options {
  @apply absolute top-0 left-8 w-full h-full -z-1;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container {
  @apply w-full h-full flex bg-grey transition duration-500 ease-in-out;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body {
  @apply flex items-center justify-end w-full h-full border-2 border-r-0;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper {
  @apply w-auto flex h-full md:px-3;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .saved-searches-card__options-body-btns {
  @apply flex md:items-center md:justify-start;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .btn, .saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .email-subscribe__button {
  @apply flex justify-center items-center md:min-w-min w-28 md:w-auto text-sm md:text-base whitespace-normal md:whitespace-nowrap m-0 md:my-3 md:px-4 rounded-none md:rounded-md;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .btn:first-child, .saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .email-subscribe .email-subscribe__container .email-subscribe__button:first-child, .email-subscribe .email-subscribe__container .saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .email-subscribe__button:first-child {
  @apply md:mr-4;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .btn.btn-border--blue-light, .saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .email-subscribe .email-subscribe__container .btn-border--blue-light.email-subscribe__button, .email-subscribe .email-subscribe__container .saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-body .saved-searches-card__options-body-wrapper .btn-border--blue-light.email-subscribe__button {
  @apply bg-blue md:bg-white text-white md:text-blue-light border-blue md:border-blue-light;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn {
  @apply w-9 md:w-8 h-full flex justify-center items-center bg-pink rounded-r-md cursor-pointer;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn .saved-searches-card__options-icon {
  @apply w-4 h-auto transition delay-700 duration-300 ease-in-out;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn .saved-searches-card__options-icon.open {
  @apply block;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card__options .saved-searches-card__options-container .saved-searches-card__options-btn .saved-searches-card__options-icon.close {
  @apply hidden;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card {
  @apply mb-5 p-4 bg-grey border rounded-l-md shadow-md transition duration-500 ease-in-out;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card:last-child {
  @apply mb-0;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner {
  @apply flex items-center bg-white py-2.5 px-5 rounded-md;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__title-icon-container {
  @apply w-7 h-4;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__title-icon-container .saved-searches-card__title-icon {
  @apply w-auto h-4;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__inner-body {
  @apply flex flex-wrap flex-1 justify-between;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__inner-body .saved-searches-card__title {
  @apply text-sm font-normal text-blue-dark my-1.5;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__inner-body .saved-searches-card__inner-group {
  @apply flex items-center;
}
.saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__inner-body .saved-searches-card__inner-group .btn, .saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__inner-body .saved-searches-card__inner-group .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .saved-searches-cards .saved-searches-card__outter .saved-searches-card .saved-searches-card__inner .saved-searches-card__inner-body .saved-searches-card__inner-group .email-subscribe__button {
  @apply font-normal w-20 md:w-24 m-0 ml-3 p-1;
}

.top-opportunities-discover .container {
  @apply flex flex-col flex-wrap;
}
.top-opportunities-discover .container .top-opportunities-discover__header {
  @apply flex items-center w-full md:flex-1 mb-12;
}
.top-opportunities-discover .container .top-opportunities-discover__header .top-opportunities-discover__header-icon {
  @apply w-20 h-auto mr-3;
}
.top-opportunities-discover .container .top-opportunities-discover__header .top-opportunities-discover__header-title {
  @apply font-blackout text-3xl font-bold uppercase mb-0.5;
}
.top-opportunities-discover .container .top-opportunities-discover__header .top-opportunities-discover__header-text {
  @apply text-sm font-normal;
}
.top-opportunities-discover .container .top-opportunities__cards {
  @apply grid grid-cols-1 md:grid-cols-2 gap-8;
}

.escape100-banner {
  @apply flex flex-col lg:flex-row bg-blue-dark w-full;
}
.escape100-banner .escape100-container {
  @apply container mx-auto px-4 flex flex-col lg:flex-row py-12 items-center;
}
.escape100-banner .escape100-container .escape100-img {
  @apply flex justify-center items-center w-full lg:w-5/12 mb-5 lg:mb-0;
}
.escape100-banner .escape100-container .escape100-body {
  @apply w-full lg:w-7/12 text-center lg:text-left;
}
.escape100-banner .escape100-container .escape100-body .escape100-body-title {
  @apply text-2xl font-bold text-white mb-5;
}
.escape100-banner .escape100-container .escape100-body .escape100-body-text {
  @apply text-base font-normal text-white mb-5;
}
.escape100-banner .escape100-container .escape100-body .btn, .escape100-banner .escape100-container .escape100-body .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .escape100-banner .escape100-container .escape100-body .email-subscribe__button {
  @apply mb-0;
}

.explore {
  @apply container mx-auto bg-grey;
}
.explore .explore__mobile-title {
  @apply font-blackout text-2xl font-normal mb-2.5 px-4 md:hidden;
}
.explore .explore-by.explore-by-type, .explore .explore-by.explore-by-escape-factor {
  @apply md:w-full lg:w-1/2 md:overflow-hidden px-4 md:pb-4 mb-5;
}
.explore .explore-by.explore-by-work-area {
  @apply px-4;
}
.explore .explore-by.explore-by-work-area .explore-by__cards {
  @apply grid md:grid-cols-3 xl:grid-cols-4 md:gap-x-7;
}
.explore .explore-by .explore-by__title {
  @apply hidden md:block text-2xl font-bold text-blue-dark mb-5;
}
.explore .explore-by .explore-by__title span {
  @apply text-blue-light;
}
.explore .explore-by .explore-by__cards {
  @apply grid md:grid-cols-2 gap-y-3.5 gap-x-7;
}
.explore .explore-by .explore-by__cards .explore-by__card {
  @apply p-2 md:p-4 bg-white rounded-md shadow-md;
}
.explore .explore-by .explore-by__cards .explore-by__card a {
  @apply flex flex-row items-center;
}
.explore .explore-by .explore-by__cards .explore-by__card a .explore-by__card-img {
  @apply flex justify-center items-center w-14 h-14 mr-4;
}
.explore .explore-by .explore-by__cards .explore-by__card a .explore-by__card-img img, .explore .explore-by .explore-by__cards .explore-by__card a .explore-by__card-img svg {
  @apply w-auto h-14;
}
.explore .explore-by .explore-by__cards .explore-by__card a .explore-by__card-body {
  @apply flex md:flex-col;
}
.explore .explore-by .explore-by__cards .explore-by__card a .explore-by__card-body .explore-by__card-body__title {
  @apply text-sm font-bold text-blue-dark mr-1.5 md:mr-0;
}
.explore .explore-by .explore-by__cards .explore-by__card a .explore-by__card-body .explore-by__card-body__text {
  @apply text-sm font-normal text-blue-dark;
}

.hero-nav {
  @apply absolute w-full flex justify-center z-50;
}
.hero-nav.is_fixed {
  @apply fixed top-6 left-0 w-full bg-blue-dark z-100 pb-4;
}
.hero-nav .hero-nav__list {
  @apply w-full max-w-md flex justify-around md:justify-between items-center pt-12 md:px-4;
}
.hero-nav .hero-nav__list .hero-nav__list-item {
  @apply text-sm lg:text-base font-normal text-white cursor-pointer;
}
.hero-nav .hero-nav__list .hero-nav__list-item:last-child {
  @apply mr-0;
}
.hero-nav .hero-nav__list .hero-nav__list-item.isActive {
  @apply border border-white rounded-full py-1 px-3;
}

.organisation-collections {
  @apply pt-10 pb-0;
}
.organisation-collections .section-title {
  @apply font-blackout text-3xl font-normal text-blue-dark mx-auto mb-10;
}
.organisation-collections .organisation-collection__cards {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 w-full;
}
.organisation-collections .organisation-collection__cards.see-all .organisation-collection__card:nth-child(3), .organisation-collections .organisation-collection__cards.see-all .organisation-collection__card:nth-child(4) {
  @apply flex;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card {
  @apply flex justify-start md:justify-center bg-white p-4 rounded-md shadow-md cursor-pointer;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card:nth-child(3), .organisation-collections .organisation-collection__cards .organisation-collection__card:nth-child(4) {
  @apply hidden md:flex;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card a {
  @apply flex flex-row items-center;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card a .organisation-collection__card-img {
  @apply flex justify-center items-center h-14 w-14 mr-4;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card a .organisation-collection__card-img img {
  @apply w-full h-auto;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card a .organisation-collection__card-body {
  @apply flex-1;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card a .organisation-collection__card-body .organisation-collection__card-body__title {
  @apply text-sm font-bold text-blue-dark mb-1.5;
}
.organisation-collections .organisation-collection__cards .organisation-collection__card a .organisation-collection__card-body .organisation-collection__card-body__text {
  @apply text-sm font-normal text-blue-dark;
}
.organisation-collections .btn, .organisation-collections .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .organisation-collections .email-subscribe__button {
  @apply md:hidden;
}

.popular-caregories .popular-caregories__title {
  @apply text-lg font-bold mb-8;
}
.popular-caregories .popular-caregories-cards {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5 sm:gap-y-8;
}
.popular-caregories .popular-caregories-cards .popular-caregories-card {
  @apply flex bg-white border border-black/10 text-blue-dark text-base font-bold rounded-[5px] drop-shadow-md overflow-hidden;
}
.popular-caregories .popular-caregories-cards .popular-caregories-card .popular-caregories-card__img {
  @apply w-[100px] min-h-[150px] bg-grey rounded-l-[5px];
}
.popular-caregories .popular-caregories-cards .popular-caregories-card .popular-caregories-card__img img {
  @apply w-full h-full object-cover object-center;
}
.popular-caregories .popular-caregories-cards .popular-caregories-card .popular-caregories-card__body {
  @apply flex flex-1 flex-col justify-between p-4;
}
.popular-caregories .popular-caregories-cards .popular-caregories-card .popular-caregories-card__body .popular-caregories-card__footer {
  @apply flex items-center;
}
.popular-caregories .popular-caregories-cards .popular-caregories-card .popular-caregories-card__body .popular-caregories-card__footer .arrow-extralong {
  @apply w-12 h-auto ml-4;
}

.org-list {
  @apply bg-grey;
}
.org-list .org-list__filters {
  @apply w-full flex items-center mb-4;
}
.org-list .org-list__filters li {
  @apply relative mr-6 last:mr-0 mb-3 pb-2 text-lg font-bold whitespace-nowrap cursor-pointer;
  @apply after:hidden after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue-light;
}
.org-list .org-list__filters li.isActive {
  @apply after:block;
}

.org-banner {
  @apply bg-white;
}
.org-banner .org-banner-card {
  @apply flex flex-wrap items-center bg-grey p-8 border border-blue-dark/10 rounded-xl;
}
.org-banner .org-banner-card .org-banner-card__body {
  @apply md:flex-1 mb-8 md:pr-16;
}
.org-banner .org-banner-card .org-banner-card__body .org-banner-card__title {
  @apply text-lg font-bold mb-4;
}
.org-banner .org-banner-card .arrow-long {
  @apply w-auto h-[14px];
}

.search__main .search__results {
  @apply w-full mb-20;
}
.search__main .search__results .search__results-info {
  @apply flex justify-between items-center text-xs font-bold text-blue-dark mb-4;
}
.search__main .search__results .search__results-info .search__results-order {
  @apply bg-grey font-normal p-1 rounded outline-none cursor-pointer;
}
.search__main .search__results .organisation-cards .organisation-card-container {
  @apply px-0 md:px-4 md:w-1/2;
}
.search__main .search__results .organisation-cards .organisation-card-container:nth-child(odd) {
  @apply md:pl-0;
}
.search__main .search__results .organisation-cards .organisation-card-container:nth-child(even) {
  @apply md:pr-0;
}

.alerts {
  @apply w-full mb-6 p-4 flex flex-col items-start bg-[#f0f0f0] md:rounded-xl border;
}
.alerts .alerts__header {
  @apply w-full flex items-center justify-between mb-4;
}
.alerts .alerts__header .alerts__header-title {
  @apply relative w-full text-base font-semibold text-escgrey-mid uppercase;
}
.alerts .alerts__header .alerts__header-title.applied__title {
  @apply pl-0;
}
.alerts .alerts__header > .btn, .alerts .email-subscribe .email-subscribe__container .alerts__header > .email-subscribe__button, .email-subscribe .email-subscribe__container .alerts .alerts__header > .email-subscribe__button {
  @apply m-0;
}
.alerts .alert__info {
  @apply w-full;
}
.alerts .alert__info .alert__info-inner {
  @apply w-full flex items-center bg-white rounded-xl relative;
}
.alerts .alert__info .alert__info-inner .alert__info-icon {
  @apply w-4 h-auto mx-4 flex-none;
}
.alerts .alert__info .alert__info-inner .alert__info-title {
  @apply w-full;
}
.alerts .alert__info .alert__info-inner .alert__info-button-edit {
  @apply mr-4;
}
.alerts .alert__info .alert__info-inner .alert__info-button-save {
  @apply bg-blue-light text-white rounded-r-xl px-4 whitespace-nowrap min-w-min min-h-[4.5rem];
  @apply flex items-center justify-center font-semibold;
}
.alerts .alert__info .alert__info-inner .alert__info-button-update {
  @apply bg-green-medium text-white rounded-r-xl px-4 whitespace-nowrap min-h-[4.5rem];
  @apply flex items-center justify-center font-semibold;
}
.alerts .alert__info .alert__info-inner .save-alert-prompt {
  @apply absolute top-full right-0 bg-blue-dark text-white mt-3 p-4 space-y-1 rounded-xl z-10;
  @apply before:absolute before:bottom-9 before:right-8 before:w-14 before:h-14 before:rotate-45 before:bg-blue-dark before:rounded-md before:pointer-events-none before:-z-1;
}
.alerts .alert__info .alert__info-inner .save-alert-prompt p {
  @apply first:pr-8 first:mt-0;
}
.alerts .alert__info .alert__info-inner .save-alert-prompt .close {
  @apply absolute top-2.5 right-4 w-5 h-5 cursor-pointer;
}
.alerts .alerts__list {
  @apply w-full flex justify-between items-end;
}
.alerts .alerts__list .alerts__list-message {
  @apply text-sm pb-3 pr-4;
}
.alerts .alerts__list .alerts__list-buttons {
  @apply w-40 mt-4 md:mt-0 pb-1;
}
.alerts .alerts__list .alerts__list-buttons .alerts__list-button-create {
  @apply w-full min-h-[42px] flex items-center justify-center text-center text-sm font-bold py-2 px-4 bg-blue-light text-white rounded-xl;
  @apply whitespace-nowrap cursor-pointer;
}
.alerts .alerts__filters {
  @apply w-full pt-4 flex justify-between items-end;
}
.alerts .alerts__filters .alerts__filters-buttons {
  @apply w-52 mt-4 md:mt-0 pb-1 flex justify-end;
}
.alerts .alerts__filters .alerts__filters-buttons .alerts__list-button-clear {
  @apply font-semibold;
}
.alerts .alerts__list .alerts__pills,
.alerts .alerts__filters .alerts__pills {
  @apply flex flex-wrap items-center;
}
.alerts .alerts__list .alerts__pills .alerts_pills-label,
.alerts .alerts__filters .alerts__pills .alerts_pills-label {
  @apply inline-flex items-center py-1 px-2 rounded-full text-xs font-bold text-blue-dark pr-12;
}
.alerts .alerts__list .alerts__pills .pill,
.alerts .alerts__filters .alerts__pills .pill {
  @apply cursor-pointer;
}
.alerts .alerts__list .alerts__pills .pill .pill-logo,
.alerts .alerts__filters .alerts__pills .pill .pill-logo {
  @apply rounded-full;
  border: 1px rgba(22, 47, 71, 0.1) solid;
}

.search-filters {
  @apply fixed top-0 right-0 transform translate-x-full-extra z-50 w-10/12 max-w-screen-sm h-full overflow-y-auto pt-8 pb-28 px-6 bg-white border-r border-gray-300 transition-transform duration-500 ease-in-out shadow-2xl;
}
.search-filters.isActive {
  @apply transform -translate-x-0;
}
.search-filters .search-filters-container .search-filters-heading {
  @apply flex justify-between;
}
.search-filters .search-filters-container .search-filters-heading h3 {
  @apply text-sm font-semibold text-gray-400 mb-4 uppercase;
}
.search-filters .search-filters-container .search-filters-heading .icon {
  width: 27px;
  height: 27px;
}
.search-filters .search-filters-container .search-filters-heading .icon * {
  fill: --color-escgrey-mid;
}
.search-filters .search-filters-container .geo-search {
  @apply w-full flex justify-between items-center mb-4;
}
.search-filters .search-filters-container .geo-search .input {
  @apply bg-grey text-sm font-semibold bg-escgrey-light rounded-md p-3;
}
.search-filters .search-filters-container .geo-search .input.location {
  @apply flex-grow ml-2 min-w-0;
}
.search-filters .search-filters-container .geo-search .input.distance {
  @apply w-20 ml-4 mr-2;
}
.search-filters .search-filters-container .geo-search span.unit {
  @apply text-right text-sm w-8;
}
.search-filters .search-filters-container .accordion-facets {
  @apply mb-8;
}
.search-filters .search-filters-container .accordion-facets .tab {
  @apply w-full mb-4 overflow-hidden;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-heading {
  @apply w-full flex justify-between items-center text-sm uppercase font-semibold mt-2;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-heading .tab-label-total {
  @apply flex justify-center items-center w-6 p-1 mx-2 bg-blue-extralight text-blue-extradark text-xxs leading-normal font-semibold rounded;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-heading .tab-label-clear {
  @apply bg-escgrey-midlight text-xs ml-auto py-1 px-2 rounded cursor-pointer;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content {
  @apply w-full flex;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner {
  @apply w-full flex flex-wrap;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner label {
  @apply flex w-full lg:w-1/2 lg:pr-4 items-center text-xs font-medium text-blue-dark py-2.5 cursor-pointer;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner label .icon {
  @apply hidden;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner label .tab-content-wrap {
  @apply flex-1;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner label .tab-content-wrap > p {
  @apply flex-1 text-left font-bold;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner label .tab-content-wrap > .label-total {
  @apply hidden;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner label .label-total {
  @apply flex justify-center items-center w-9 p-1 ml-2 bg-blue-extradark text-white text-xxs leading-normal font-semibold rounded;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner input {
  @apply appearance-none h-5 w-5 mr-2 bg-white border-2 border-escgrey-dark rounded focus:outline-none;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner input:checked {
  @apply bg-blue-light bg-contain bg-center bg-no-repeat border-transparent;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner.has-slider {
  @apply pt-16 pb-2.5 pl-7 pr-8;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner .slider {
  @apply w-full h-2.5;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner .slider .noUi-handle {
  @apply bg-blue-medium shadow-none border-none cursor-pointer w-6 h-6 -top-2;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner .slider .noUi-handle:before {
  @apply h-3 left-3.5;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner .slider .noUi-handle:after {
  @apply h-3 left-2.5;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner .slider .noUi-connect {
  @apply bg-blue-light;
}
.search-filters .search-filters-container .accordion-facets .tab .tab-content .tab-content-inner .slider .noUi-tooltip {
  @apply text-xs border-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner {
  @apply grid grid-cols-2 md:grid-cols-4 p-0 mt-2.5 overflow-hidden border rounded-md;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label {
  @apply flex-col justify-center w-full min-h-[77px] bg-white hover:bg-blue hover:text-white border-b border-r p-3 transition-all ease-in-out duration-300;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label.odd, .search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label.even {
  @apply border-b-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label.even:nth-last-child(2) {
  @apply border-b md:border-b-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label:has(> input:checked) {
  @apply bg-blue-light text-white;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label > input {
  @apply absolute pointer-events-none hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label .tab-icon {
  @apply inline-block text-[28px] pb-2;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label .tab-content-wrap {
  @apply flex-none;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label .tab-content-wrap > p {
  @apply text-center;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-1 .tab-content-inner > label .label-total {
  @apply hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner {
  @apply grid grid-cols-2 md:grid-cols-4 p-0 mt-2.5 overflow-hidden border rounded-md;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label {
  @apply w-full min-h-[73px] bg-white hover:bg-blue hover:text-white border-b border-r p-3 transition-all ease-in-out duration-300;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label.odd, .search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label.even {
  @apply border-b-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label.even:nth-last-child(2) {
  @apply border-b md:border-b-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label:has(> input:checked) {
  @apply bg-blue-light text-white;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label > input {
  @apply absolute pointer-events-none hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label .tab-icon {
  @apply inline-block text-[28px] pr-2;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-2 .tab-content-inner > label .label-total {
  @apply hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner {
  @apply grid grid-cols-2 md:grid-cols-4 gap-2.5 p-0 pt-2.5;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner > label {
  @apply flex-col justify-center w-full min-h-[78px] bg-white hover:bg-blue hover:text-white p-3 border rounded-md transition-all ease-in-out duration-300;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner > label:has(> input:checked) {
  @apply bg-blue-light text-white;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner > label > input {
  @apply absolute pointer-events-none hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner > label .tab-icon {
  @apply inline-block text-[28px] pb-2;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner > label .tab-content-wrap {
  @apply flex-none;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner > label .tab-content-wrap > p {
  @apply text-center;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-3 .tab-content-inner > label .label-total {
  @apply hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-4 .tab-content-inner {
  @apply grid grid-cols-2 md:grid-cols-4 gap-2.5 p-0 pt-2.5;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-4 .tab-content-inner > label {
  @apply w-full min-h-[53px] justify-start bg-white hover:bg-blue hover:text-white p-3 border rounded-md transition-all ease-in-out duration-300;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-4 .tab-content-inner > label:has(> input:checked) {
  @apply bg-blue-light text-white;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-4 .tab-content-inner > label > input {
  @apply absolute pointer-events-none hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-4 .tab-content-inner > label .tab-icon {
  @apply inline-block text-[28px] pr-2;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-4 .tab-content-inner > label .label-total {
  @apply hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner {
  @apply grid grid-cols-2 md:grid-cols-4 p-0 mt-2.5 overflow-hidden border rounded-md;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label {
  @apply w-full items-start bg-white hover:bg-blue hover:text-white border-b border-r p-3 transition-all ease-in-out duration-300;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label:nth-child(even) {
  @apply border-r-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label.odd, .search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label.even {
  @apply border-b-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label.even:nth-last-child(2) {
  @apply border-b md:border-b-0;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label:has(> input:checked) {
  @apply bg-blue-light text-white;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label:has(> input:checked) .tab-content-wrap > .label-total {
  @apply bg-white text-blue-dark;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label > input {
  @apply absolute pointer-events-none hidden;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label .tab-icon {
  @apply inline-block text-[28px] pr-2;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label .tab-content-wrap {
  @apply pt-1.5;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label .tab-content-wrap > p {
  @apply mb-1.5;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label .tab-content-wrap > .label-total {
  @apply block m-0 text-center;
}
.search-filters .search-filters-container .accordion-facets .tab.advanced-5 .tab-content-inner > label .label-total {
  @apply hidden;
}
.search-filters .search-filters-container .toggle-facets {
  @apply w-full flex flex-wrap;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet {
  @apply flex justify-between items-center border-t border-gray-200 py-5 w-full md:w-1/2;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet:nth-child(2n) {
  @apply md:pl-2;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet:nth-child(2n-1) {
  @apply md:pr-2;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .icon {
  @apply mr-5 w-8 flex-none;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .icon img, .search-filters .search-filters-container .toggle-facets .toggle-facet .icon svg {
  @apply w-full;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet p {
  @apply text-blue text-sm font-medium flex-grow text-left;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .toggle-label {
  @apply flex-none flex items-center cursor-pointer ml-2;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .toggle-label .toggle {
  @apply relative flex;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .toggle-label .toggle input {
  @apply absolute opacity-0;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .toggle-label .toggle input:checked ~ .toggle-line {
  @apply bg-blue-light;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .toggle-label .toggle input:checked ~ .toggle-dot {
  @apply transform translate-x-full;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .toggle-label .toggle .toggle-line {
  @apply block bg-gray-200 w-12 h-7 p-1 rounded-full transition-all ease-in-out duration-300;
}
.search-filters .search-filters-container .toggle-facets .toggle-facet .toggle-label .toggle .toggle-dot {
  @apply absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition;
}

.search-filters-summary {
  @apply fixed bottom-0 right-0 transform translate-x-full-extra z-50 w-10/12 max-w-screen-sm flex justify-between items-center border-t border-gray-200 p-6  bg-white;
  @apply transition-transform duration-500 ease-in-out shadow-2xl;
}
.search-filters-summary.isActive {
  @apply transform -translate-x-0;
}
.search-filters-summary .summary-count {
  @apply items-center py-3 px-4 text-sm font-semibold bg-red-esclight text-red-esc rounded-full;
}
.search-filters-summary .clear-filters {
  @apply items-center text-white text-xs font-semibold bg-blue-light py-2 px-4 rounded;
}

.search {
  @apply w-full;
}
.search.with-sidebar {
  @apply w-full lg:w-2/3 lg:pr-8;
}

.search-jobs .search__main .search__results .search__results-info {
  @apply px-4 md:px-0;
}

.search__aside {
  @apply hidden lg:block flex-1;
}
.search__aside .search-top-10-opportunities {
  @apply bg-white mb-5 p-5 rounded-[10px] border border-escgrey-midlight;
}
.search__aside .search-top-10-opportunities .search-top-10-opportunities__meta {
  @apply flex justify-between text-escgrey text-sm tracking-tight mb-3;
}
.search__aside .search-top-10-opportunities .search-top-10-opportunities__title {
  @apply text-3xl text-blue-dark font-bold tracking-tight mb-4;
}
.search__aside .search-top-10-opportunities .search-top-10-opportunities__strapline {
  @apply text-base text-blue-dark font-light tracking-tight mb-1;
}
.search__aside .search-top-10-opportunities .search-top-10-opportunities__link {
  @apply text-base text-blue-light font-semibold mb-6 inline-block;
}
.search__aside .search-top-10-opportunities .top-opportunities__cards {
  @apply space-y-3;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
}

.form,
.form-EditSavedSearchAlert {
  @apply flex flex-col my-2.5;
}
.form .form-group,
.form-EditSavedSearchAlert .form-group {
  @apply flex flex-col mb-5;
}
.form .form-group .form-label,
.form-EditSavedSearchAlert .form-group .form-label {
  @apply text-lg font-bold mb-2;
}
.form .form-group .form-input,
.form-EditSavedSearchAlert .form-group .form-input {
  @apply p-3 border-2 rounded-md;
}
.form .form-group .form-checkbox-container,
.form-EditSavedSearchAlert .form-group .form-checkbox-container {
  @apply flex items-center;
}
.form .form-group .form-checkbox-container .form-checkbox,
.form-EditSavedSearchAlert .form-group .form-checkbox-container .form-checkbox {
  @apply w-4 h-4 mr-3 cursor-pointer;
}
.form .form-group .form-checkbox-container .form-checkbox + label,
.form-EditSavedSearchAlert .form-group .form-checkbox-container .form-checkbox + label {
  @apply leading-none;
}
.form .form-group .form-select,
.form-EditSavedSearchAlert .form-group .form-select {
  @apply w-1/2 p-3 border-2 rounded-md capitalize cursor-pointer;
}
.form .form-group .form-list,
.form-EditSavedSearchAlert .form-group .form-list {
  @apply flex;
}
.form .form-group .form-list li,
.form-EditSavedSearchAlert .form-group .form-list li {
  @apply mr-3;
}
.form .form-group .form-list li:last-child,
.form-EditSavedSearchAlert .form-group .form-list li:last-child {
  @apply mr-0;
}

.applications .cards .card .card-inner {
  @apply md:h-48 min-h-full rounded-r-md;
}
.applications .cards .card .card-inner .card-sub-header {
  @apply pt-4;
}

.opportunity {
  @apply relative flex flex-col lg:flex-row container bg-white mx-auto pt-0 md:px-4 z-0;
}
.opportunity .main {
  @apply w-full lg:w-[60%] px-4 md:px-0 lg:pr-10 pb-32;
}
.opportunity .main .divider {
  @apply block w-full h-0.5 bg-offwhite my-10;
}
.opportunity .main .title {
  @apply text-2xl font-bold text-blue-dark mt-5 lg:mt-16 mb-5;
}
.opportunity .main .secondary-title {
  @apply text-lg font-bold text-grey-dark uppercase mt-5 lg:mt-16 mb-5;
}
.opportunity .main p {
  @apply text-base mb-5;
}
.opportunity .main p:last-child {
  @apply mb-0;
}
.opportunity .main p.text-sm-bold {
  @apply text-sm font-bold text-blue-dark;
}

.hero-job {
  @apply w-full mt-16 relative z-20 bg-[#1c3c56] overflow-hidden;
}
.hero-job .hero__container {
  @apply relative flex container mx-auto px-4 min-h-[200px];
}
.hero-job .hero__container .hero__inner {
  @apply flex flex-col items-center w-full mx-auto;
}
.hero-job .hero__container .hero__inner .hero__top {
  @apply absolute top-0 left-1/2 -translate-x-1/2 flex justify-center items-start w-full;
}
.hero-job .hero__container .hero__inner .hero__top a {
  @apply mr-4;
}
.hero-job .hero__container .hero__inner .hero__top a:last-child {
  @apply mr-0;
}
.hero-job .hero__container .hero__inner .hero__top a .badge {
  @apply h-20 lg:h-[5.5rem];
}
.hero-job .hero__container .hero__inner .hero__body {
  @apply flex flex-wrap w-full py-8 sm:py-10 border-b border-b-blue-dark;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-content {
  @apply w-full lg:flex-1;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-content .btn--back {
  @apply relative flex items-center text-white text-sm z-10;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-content .btn--back img {
  @apply w-3.5 h-auto mr-2.5;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-content .hero__title {
  @apply text-3xl sm:text-4xl font-blackout leading-tight text-white mt-8 sm:mt-12 mb-2 lg:max-w-80;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-content .hero__org {
  @apply text-xl font-bold text-white mb-4;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-content .hero__posted {
  @apply flex items-center text-white text-sm;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-content .hero__posted img {
  @apply w-3.5 h-auto mr-2.5;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions {
  @apply flex items-start w-auto h-auto mt-8 lg:mt-[58px] space-x-3;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--apply,
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--expired,
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--save-job {
  @apply text-white w-24 px-5 my-0 py-3 rounded-full;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--apply {
  @apply bg-blue-light;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--expired {
  @apply bg-red-esc cursor-default;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--save-job {
  @apply bg-transparent border-2 border-white;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--save-job.button-save {
  @apply bg-green-300 border-green-300;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--save-job.button-saved {
  @apply bg-transparent border-white;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--save-job .btn-icon {
  @apply hidden;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--share {
  @apply relative w-12 h-auto;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--share .icon {
  @apply w-[50px] h-auto;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--share .btn--share-icons-container {
  @apply absolute top-1 left-1/2 translate-y-[65%] -translate-x-1/2 pt-3 transition-all ease-in-out duration-300;
}
.hero-job .hero__container .hero__inner .hero__body .hero__body-actions .btn--share .btn--share-icons-container .btn--share-icons {
  @apply flex bg-white py-3 px-6 rounded-full space-x-4 border drop-shadow-lg;
}
.hero-job .hero__container .hero__inner .hero__icon {
  @apply absolute -bottom-2 right-9 translate-y-[10%] -rotate-12 flex justify-center items-center bg-white w-[200px] h-[200px] rounded-lg overflow-hidden z-[-1];
}
.hero-job .hero__container .hero__inner .hero__icon .icon {
  @apply w-full h-auto;
}

.application {
  @apply relative bg-offwhite -mt-12 pt-16 pb-14 z-10;
}
.application .application-header {
  @apply flex flex-col lg:flex-row justify-between items-center mb-5 lg:mb-8;
}
.application .application-header .application-title {
  @apply text-lg lg:text-2xl font-bold text-blue-dark;
}
.application .application-header .btn, .application .application-header .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .application .application-header .email-subscribe__button {
  @apply text-sm;
}
.application .application-boxes {
  @apply flex flex-col lg:flex-row items-center;
}
.application .application-boxes .application-box {
  @apply relative flex lg:flex-col justify-center items-center w-full h-auto lg:h-48 xl:h-60 bg-white border-2 border-blue-dark mb-5 lg:mb-0 lg:mr-5 p-3 rounded-md opacity-30;
}
.application .application-boxes .application-box:after {
  content: "";
  @apply absolute -bottom-3.5 lg:bottom-1/2 lg:-right-6 transform rotate-90 lg:rotate-0 w-6 h-1 bg-blue-dark;
}
.application .application-boxes .application-box:last-child {
  @apply mr-0;
}
.application .application-boxes .application-box:last-child:after {
  @apply hidden;
}
.application .application-boxes .application-box .application-box-image {
  @apply h-8 lg:h-16 xl:h-24 w-auto mr-5 lg:mr-0 lg:mb-5;
}
.application .application-boxes .application-box .application-box-title {
  @apply text-sm font-bold text-black text-center;
}
.application .application-boxes .application-box .application-box-icon {
  @apply hidden;
}
.application .application-boxes .application-box.active {
  @apply opacity-100;
}
.application .application-boxes .application-box.active .application-box-icon {
  @apply block absolute top-1/2 lg:-top-3 right-3 lg:-right-3 transform -translate-y-1/2 lg:translate-y-0 fill-current text-green-medium;
}
.application .application-boxes .application-box.active .application-box-icon.rejected {
  @apply fill-current text-red-esc;
}

.opportunity {
  @apply lg:-mt-5;
}
.opportunity .sidebar {
  @apply relative bg-grey w-full lg:w-[40%] border-b-2 border-blue-light lg:border-0 -mt-12 lg:mt-0 pt-20 pb-10 lg:pt-16 lg:pl-10 lg:pb-10 px-4 md:px-0;
}
.opportunity .sidebar:before, .opportunity .sidebar:after {
  content: "";
  @apply bg-grey absolute top-0 left-1/2 -translate-x-1/2 bottom-[-2px] w-[200vw] border-b-2 lg:border-none border-blue-light -z-1;
}
.opportunity .sidebar:after {
  @apply right-0 lg:hidden;
}
.opportunity .sidebar__title {
  @apply text-lg font-bold text-blue-dark mb-3;
}
.opportunity .sidebar .sidebar__company {
  @apply bg-white mt-8 mb-6 border rounded-md;
}
.opportunity .sidebar .sidebar__company .company__header {
  @apply flex flex-col lg:flex-row -translate-y-8 -mb-8;
}
.opportunity .sidebar .sidebar__company .company__header .company__logo-container {
  @apply flex justify-center items-center w-36 h-36 translate-x-4 lg:-translate-x-4 bg-white rounded-lg overflow-hidden;
}
.opportunity .sidebar .sidebar__company .company__header .company__logo-container .company__logo {
  @apply w-full h-full object-contain;
}
.opportunity .sidebar .sidebar__company .company__header .company__intro {
  @apply flex-1 pt-4 lg:pt-12 pl-4 pr-6;
}
.opportunity .sidebar .sidebar__company .company__header .company__intro .company__title {
  @apply text-2xl font-bold mb-2;
}
.opportunity .sidebar .sidebar__company .company__header .company__intro .company__info {
  @apply flex mb-1.5;
}
.opportunity .sidebar .sidebar__company .company__header .company__intro .company__info .company__review {
  @apply flex items-center text-sm font-bold mr-2;
}
.opportunity .sidebar .sidebar__company .company__header .company__intro .company__info .company__review .company__review-icon {
  @apply w-4 h-4 mr-2 mb-[2px];
}
.opportunity .sidebar .sidebar__company .company__header .company__intro .company__info .company__review span {
  @apply font-normal ml-0.5;
}
.opportunity .sidebar .sidebar__company .company__header .company__intro .company__roles {
  @apply bg-blue-light inline-flex items-center py-1 px-2 rounded text-xs text-white;
}
.opportunity .sidebar .sidebar__company .company__body {
  @apply p-6 pb-3.5;
}
.opportunity .sidebar .sidebar__company .company__body .company__headline {
  @apply mb-4;
}
.opportunity .sidebar .sidebar__title {
  @apply font-bold mb-4;
}
.opportunity .sidebar .sidebar__title span.orange {
  @apply text-orange;
}
.opportunity .sidebar .sidebar__title span.pink {
  @apply text-pink-medium;
}
.opportunity .sidebar .sidebar__title span.green {
  @apply text-green;
}
.opportunity .sidebar .sidebar__title-table {
  @apply font-bold mt-8 mb-4 ml-4;
}
.opportunity .sidebar .table {
  @apply w-full bg-white text-sm border rounded-b-lg divide-y overflow-hidden;
}
.opportunity .sidebar .table.table--xs {
  @apply text-xs;
}
.opportunity .sidebar .table.table--xs .table__row .table__title,
.opportunity .sidebar .table.table--xs .table__row .table__desc {
  @apply w-1/2;
}
.opportunity .sidebar .table.table--xs .table__row .table__title .description,
.opportunity .sidebar .table.table--xs .table__row .table__desc .description {
  @apply flex-1;
}
.opportunity .sidebar .table .table__row {
  @apply flex divide-x font-bold;
}
.opportunity .sidebar .table .table__row .table__title,
.opportunity .sidebar .table .table__row .table__desc {
  @apply flex items-center p-3.5;
}
.opportunity .sidebar .table .table__row .table__title p,
.opportunity .sidebar .table .table__row .table__desc p {
  @apply mb-0;
}
.opportunity .sidebar .table .table__row .table__title {
  @apply w-2/6;
}
.opportunity .sidebar .table .table__row .table__title img {
  @apply w-5 h-auto mr-2.5;
}
.opportunity .sidebar .table .table__row .table__desc {
  @apply flex-1 gap-4;
}
.opportunity .sidebar .table .table__row .table__desc img {
  @apply w-5 h-auto mr-1.5;
}
.opportunity .sidebar .table .table__row .table__desc .img-text.employer {
  @apply text-green;
}
.opportunity .sidebar .table .table__row .table__desc .img-text.escape {
  @apply text-blue-light;
}
.opportunity .sidebar .table .table__row .table__desc .tooltip {
  @apply ml-2;
}
.opportunity .sidebar .sidebar__overview {
  @apply mt-6 mb-8;
}
.opportunity .sidebar .sidebar__overview .img-container {
  @apply w-full flex justify-center items-center bg-white min-h-[64px];
}
.opportunity .sidebar .sidebar__overview .img-container img {
  @apply object-contain max-h-[240px];
  position: relative !important;
}
.opportunity .sidebar .sidebar__why,
.opportunity .sidebar .sidebar__take,
.opportunity .sidebar .sidebar__newsfeed {
  @apply bg-white mt-6 mb-8 p-6 border rounded-lg;
}
.opportunity .sidebar .sidebar__why .sidebar__why-header {
  @apply flex justify-between items-center mb-6;
}
.opportunity .sidebar .sidebar__why .sidebar__why-header .sidebar__title {
  @apply mb-0;
}
.opportunity .sidebar .sidebar__why .sidebar__why-header label {
  @apply relative font-bold;
  @apply before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2;
}
.opportunity .sidebar .sidebar__why .sidebar__why-header label:before {
  content: "View All";
}
.opportunity .sidebar .sidebar__why .sidebar__why-header label span {
  @apply text-transparent;
}
.opportunity .sidebar .sidebar__why .viewall_why {
  @apply hidden;
}
.opportunity .sidebar .sidebar__why .viewall_why:checked + .sidebar__why-header label {
  @apply before:content-["Close"];
}
.opportunity .sidebar .sidebar__why .viewall_why:checked ~ ul {
  @apply max-h-[1000px];
}
.opportunity .sidebar .sidebar__why .viewall_why:checked ~ ul li {
  @apply max-h-[1000px];
}
.opportunity .sidebar .sidebar__why .sidebar__why-benefits {
  @apply space-y-4 md:space-y-0 md:grid grid-cols-2 gap-6 max-h-64 overflow-hidden transition-all ease-in-out duration-300;
}
.opportunity .sidebar .sidebar__why .sidebar__why-benefits li {
  @apply flex max-h-0 overflow-hidden transition-all ease-in-out duration-300;
}
.opportunity .sidebar .sidebar__why .sidebar__why-benefits li:nth-child(-n+6) {
  @apply max-h-[1000px];
}
.opportunity .sidebar .sidebar__why .sidebar__why-benefits li .sidebar__why-benefits__img-container {
  @apply w-8 h-8 mr-4 bg-grey-medium rounded-md;
}
.opportunity .sidebar .sidebar__why .sidebar__why-benefits li .sidebar__why-benefits__img-container img {
  @apply w-full h-full;
}
.opportunity .sidebar .sidebar__why .sidebar__why-benefits li p {
  @apply flex-1;
}
.opportunity .sidebar .sidebar__take .sidebar__take-header {
  @apply flex items-center mb-3;
}
.opportunity .sidebar .sidebar__take .sidebar__take-header .sidebar__take-img-container {
  @apply w-[50px] h-[50px] bg-grey-medium mr-3 rounded-full;
}
.opportunity .sidebar .sidebar__take .sidebar__take-header .sidebar__take-img-container .sidebar__take-img {
  @apply w-full h-auto rounded-full;
}
.opportunity .sidebar .sidebar__take .sidebar__take-header .sidebar__take-header-intro .sidebar__title {
  @apply leading-tight mb-0;
}
.opportunity .sidebar .sidebar__take .sidebar__take-header .sidebar__take-header-intro .sidebar__take-subtitle {
  @apply text-sm;
}
.opportunity .sidebar .sidebar__take .sidebar__take-header .sidebar__take-header-intro .sidebar__take-subtitle span {
  @apply font-normal;
}
.opportunity .sidebar .sidebar__take .sidebar__take-quote {
  @apply relative pt-2 px-8;
  @apply before:absolute before:top-0 before:left-0 before:w-5 before:h-5 before:font-blackout before:text-4xl before:font-black before:text-blue-light before:content-["“"];
  @apply after:absolute after:top-0 after:right-0 after:w-5 after:h-5 after:font-blackout after:text-4xl after:font-black after:text-blue-light after:content-["”"];
}
.opportunity .sidebar .sidebar__newsfeed ul {
  @apply divide-y;
}
.opportunity .sidebar .sidebar__newsfeed ul li a {
  @apply flex items-center py-4;
}
.opportunity .sidebar .sidebar__newsfeed ul li a img {
  @apply w-10 h-10 rounded-lg bg-grey-medium mr-3;
}
.opportunity .sidebar .sidebar__newsfeed ul li a p {
  @apply text-sm font-bold pr-4;
}
.opportunity .sidebar .sidebar__newsfeed ul li a .icon {
  @apply w-4 h-auto -rotate-90 ml-auto;
}

.dropdown-checkbox {
  @apply hidden;
}
.dropdown-checkbox:checked + .dropdown svg {
  @apply transform -rotate-90 transition-all duration-500 ease-in-out;
}
.dropdown-checkbox:checked + .dropdown-panel, .dropdown-checkbox:checked + .dropdown + .dropdown-panel {
  @apply max-h-full;
}

.dropdown label {
  @apply flex justify-between items-center text-sm md:text-2xl font-bold text-blue-dark mt-5 lg:mt-16 mb-5 cursor-pointer lg:pointer-events-none lg:cursor-default;
}

.dropdown-panel {
  @apply max-h-0 lg:max-h-full overflow-hidden transition-all duration-500 ease-in-out;
}

.opportunity .company-benefits {
  @apply bg-blue-dark text-white my-10 rounded-md;
}
.opportunity .company-benefits .company-benefits-body {
  @apply grid grid-rows-1 lg:grid-rows-2 lg:grid-flow-col gap-4 p-7;
}
.opportunity .company-benefits .company-benefits-body div:nth-child(1) {
  @apply order-1;
}
.opportunity .company-benefits .company-benefits-body div:nth-child(2) {
  @apply order-3 lg:order-2;
}
.opportunity .company-benefits .company-benefits-body div:nth-child(3) {
  @apply order-4 lg:order-3 lg:row-span-2;
}
.opportunity .company-benefits .company-benefits-body div:nth-child(4) {
  @apply order-2 lg:order-4 lg:row-span-2;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-logo {
  @apply mb-0;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-description {
  @apply text-sm font-normal mb-0;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details {
  @apply list-none flex flex-row lg:flex-col flex-wrap lg:flex-nowrap;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details li {
  @apply text-sm p-0 w-1/2 lg:w-full;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details li:before {
  @apply hidden;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details li:last-child {
  @apply lg:hidden;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details li:nth-child(2), .opportunity .company-benefits .company-benefits-body .company-benefits-details li:nth-child(4) {
  @apply justify-end lg:justify-start;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details li:nth-child(3), .opportunity .company-benefits .company-benefits-body .company-benefits-details li:nth-child(4) {
  @apply mb-0;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details li:nth-child(3) {
  @apply underline cursor-pointer;
}
.opportunity .company-benefits .company-benefits-body .company-benefits-details li .icon {
  @apply inline-block align-text-bottom w-5 h-5 mr-2;
}
.opportunity .company-benefits .company-benefits-body .btn-container {
  @apply flex flex-row lg:flex-col justify-between items-center;
}
.opportunity .company-benefits .company-benefits-body .btn-container .btn, .opportunity .company-benefits .company-benefits-body .btn-container .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .opportunity .company-benefits .company-benefits-body .btn-container .email-subscribe__button {
  @apply min-w-0 w-40 m-0 py-2;
}
.opportunity .company-benefits .company-benefits-panel {
  @apply bg-blue-medium p-7 pt-5 pb-0 rounded-b-md;
}
.opportunity .company-benefits .company-benefits-panel .dropdown {
  @apply pb-5;
}
.opportunity .company-benefits .company-benefits-panel .dropdown label {
  @apply flex justify-between items-center text-lg text-white m-0;
}
.opportunity .company-benefits .company-benefits-panel .dropdown-panel .ticks {
  @apply pb-5;
}

.opportunity .job-summary .job-summary__title {
  @apply mb-4;
}
.opportunity .job-summary .table {
  @apply w-full text-sm mb-10 border rounded-md divide-y overflow-hidden;
}
.opportunity .job-summary .table .table__row {
  @apply flex odd:bg-grey divide-x font-bold;
}
.opportunity .job-summary .table .table__row .table__title,
.opportunity .job-summary .table .table__row .table__desc {
  @apply flex items-center p-4;
}
.opportunity .job-summary .table .table__row .table__title p,
.opportunity .job-summary .table .table__row .table__desc p {
  @apply mb-0;
}
.opportunity .job-summary .table .table__row .table__title {
  @apply w-2/6;
}
.opportunity .job-summary .table .table__row .table__title img {
  @apply w-5 h-auto mr-2.5;
}
.opportunity .job-summary .table .table__row .table__desc {
  @apply flex-1;
}
.opportunity .job-summary .table .table__row .table__desc img {
  @apply w-5 h-auto mr-4;
}
.opportunity .job-summary .table .table__row .table__desc .img-text.employer {
  @apply text-green;
}
.opportunity .job-summary .table .table__row .table__desc .img-text.escape {
  @apply text-blue-light;
}
.opportunity .job-summary .table .table__row .table__desc .tooltip {
  @apply ml-4;
}

.similar-jobs {
  @apply bg-grey;
}
.similar-jobs .similar-jobs__header {
  @apply bg-blue-dark py-3;
}
.similar-jobs .similar-jobs__header > .container {
  @apply container mx-auto px-4 flex justify-between items-center;
}
.similar-jobs .similar-jobs__header > .container .btn-group {
  @apply flex items-center w-auto space-x-3;
}
.similar-jobs .similar-jobs__header > .container .btn-group .btn--apply,
.similar-jobs .similar-jobs__header > .container .btn-group .btn--expired,
.similar-jobs .similar-jobs__header > .container .btn-group .btn--save-job {
  @apply text-white w-24 px-5 my-0 py-3 rounded-full;
}
.similar-jobs .similar-jobs__header > .container .btn-group .btn--apply {
  @apply bg-blue-light;
}
.similar-jobs .similar-jobs__header > .container .btn-group .btn--expired {
  @apply bg-red-esc cursor-default;
}
.similar-jobs .similar-jobs__header > .container .btn-group .btn--save-job {
  @apply bg-transparent border-2 border-white;
}
.similar-jobs .similar-jobs__header > .container .btn-group .btn--save-job.button-save {
  @apply bg-green-300 border-green-300;
}
.similar-jobs .similar-jobs__header > .container .btn-group .btn--save-job.button-saved {
  @apply bg-transparent border-white;
}
.similar-jobs .similar-jobs__header > .container .btn-group .btn--save-job .btn-icon {
  @apply hidden;
}
.similar-jobs .similar-jobs__header > .container .btn, .similar-jobs .similar-jobs__header > .container .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .similar-jobs .similar-jobs__header > .container .email-subscribe__button {
  @apply my-3 first:ml-0 ml-3;
}
.similar-jobs .similar-jobs__header > .container .btn-share {
  @apply ml-auto;
}
.similar-jobs .similar-jobs__title {
  @apply text-lg text-blue-dark font-semibold mb-5;
}
.similar-jobs .job-cards {
  @apply grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 space-y-0;
}
.similar-jobs .btn, .similar-jobs .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .similar-jobs .email-subscribe__button {
  @apply flex mx-auto mt-8;
}

.jobs-recommended {
  @apply fixed top-0 left-0 flex justify-center w-full h-16 bg-blue-dark text-white py-3 border-t border-white/10 z-20;
}
.jobs-recommended .container {
  @apply flex justify-center items-center px-3;
}
.jobs-recommended .container .recommended-progress {
  @apply mx-4;
}
.jobs-recommended .container .jobs-recommended__actions {
  @apply flex items-start space-x-3;
}
.jobs-recommended .container .jobs-recommended__actions .btn--apply,
.jobs-recommended .container .jobs-recommended__actions .btn--expired,
.jobs-recommended .container .jobs-recommended__actions .btn--save-job {
  @apply text-white w-24 px-5 my-0 py-3 rounded-full;
}
.jobs-recommended .container .jobs-recommended__actions .btn--apply {
  @apply bg-blue-light;
}
.jobs-recommended .container .jobs-recommended__actions .btn--expired {
  @apply bg-red-esc cursor-default;
}
.jobs-recommended .container .jobs-recommended__actions .btn--save-job {
  @apply bg-transparent border-2 border-white;
}
.jobs-recommended .container .jobs-recommended__actions .btn--save-job.button-save {
  @apply bg-green-300 border-green-300;
}
.jobs-recommended .container .jobs-recommended__actions .btn--save-job.button-saved {
  @apply bg-transparent border-white;
}
.jobs-recommended .container .jobs-recommended__actions .btn--save-job .btn-icon {
  @apply hidden;
}
.jobs-recommended .container .jobs-recommended__actions .btn--share {
  @apply relative w-12 h-auto;
}
.jobs-recommended .container .jobs-recommended__actions .btn--share .icon {
  @apply w-[50px] h-auto;
}
.jobs-recommended .container .jobs-recommended__actions .btn--share .btn--share-icons-container {
  @apply absolute top-1 left-1/2 translate-y-[65%] -translate-x-1/2 pt-3 transition-all ease-in-out duration-300;
}
.jobs-recommended .container .jobs-recommended__actions .btn--share .btn--share-icons-container .btn--share-icons {
  @apply flex bg-white py-3 px-6 rounded-full space-x-4 border drop-shadow-lg;
}
.jobs-recommended .container .arrow-container {
  @apply w-10 h-full;
}
.jobs-recommended .container .arrow-container > button {
  @apply flex justify-center items-center w-full h-full;
}
.jobs-recommended .container .arrow-container > button .chevron {
  @apply w-3 h-auto;
}
.jobs-recommended .container .arrow-container > button .chevron.next {
  @apply scale-x-[-1];
}

.why-we-like {
  @apply mb-10;
}
.why-we-like .tags {
  @apply flex flex-wrap items-center gap-2 mt-4;
}
.why-we-like .tags .tag {
  @apply flex items-center py-2 px-3 rounded-full bg-blue-extralight font-bold text-sm;
}
.why-we-like .tags .tag img {
  @apply w-[18px] h-auto mr-2.5;
}

.headline {
  @apply text-xl font-bold mb-8;
}

.radio-tabs {
  @apply flex flex-col w-full;
}
.radio-tabs input[type=radio] {
  @apply hidden;
}
.radio-tabs .tab-group {
  @apply flex gap-2 mb-9;
}
.radio-tabs .tab-group label {
  @apply flex items-center py-2 px-3 rounded-full text-sm transition-colors duration-300 ease-in-out;
  @apply hover:bg-blue-extralight hover:font-bold cursor-pointer;
}
.radio-tabs .tab-group label .tooltip {
  @apply w-[18px] h-auto ml-2.5;
}
.radio-tabs .wysiwyg .short,
.radio-tabs .wysiwyg .long {
  @apply transition-all duration-300 ease-in-out;
}
.radio-tabs input[type=radio]#short:checked + #long + .tab-group label.short {
  @apply bg-blue-extralight font-bold;
}
.radio-tabs input[type=radio]#short:checked + #long + .tab-group + .wysiwyg .short {
  @apply block;
}
.radio-tabs input[type=radio]#short:checked + #long + .tab-group + .wysiwyg .long {
  @apply hidden;
}
.radio-tabs input[type=radio]#long:checked + .tab-group label.long {
  @apply bg-blue-extralight font-bold;
}
.radio-tabs input[type=radio]#long:checked + .tab-group + .wysiwyg .short {
  @apply hidden;
}
.radio-tabs input[type=radio]#long:checked + .tab-group + .wysiwyg .long {
  @apply block;
}

.organisation-nav {
  @apply w-full bg-white;
}
.organisation-nav .organisation-nav-list {
  @apply flex;
}
.organisation-nav .organisation-nav-list li {
  @apply text-base font-bold text-blue-dark mr-10;
}
.organisation-nav .organisation-nav-list li:last-child {
  @apply mr-0;
}
.organisation-nav .organisation-nav-list li a {
  @apply block pt-6 pb-5 border-b-4 border-transparent hover:border-blue-light cursor-pointer transition duration-200 ease-in-out;
}

.welcome .welcome-container {
  @apply container mx-auto px-4 text-center;
}
.welcome .welcome-container .welcome-title {
  @apply font-blackout text-4xl font-normal text-blue-dark mb-7;
}
.welcome .welcome-container .wysiwyg {
  @apply w-full lg:w-3/4 mx-auto mb-7;
}
.welcome .welcome-container .welcome-media {
  @apply w-full lg:w-3/5 mx-auto;
}
.welcome .welcome-container .welcome-media iframe, .welcome .welcome-container .welcome-media img {
  @apply rounded-md;
}

.our-mission {
  @apply bg-escgrey-light;
}
.our-mission .our-mission-container {
  @apply container mx-auto px-4 text-center;
}
.our-mission .our-mission-container .our-mission-title {
  @apply font-blackout text-4xl font-normal text-blue-dark mb-7;
}
.our-mission .our-mission-container .wysiwyg {
  @apply w-full lg:w-3/4 mx-auto mb-7;
}
.our-mission .our-mission-container .our-mission-media {
  @apply w-full lg:w-3/5 mx-auto;
}
.our-mission .our-mission-container .our-mission-media iframe, .our-mission .our-mission-container .our-mission-media img {
  @apply rounded-md;
}

.our-story {
  @apply bg-white;
}
.our-story .our-story-container {
  @apply container mx-auto px-4 flex flex-col;
}
.our-story .our-story-container .our-story-body-title {
  @apply font-blackout text-4xl font-normal text-blue-dark text-center mb-7;
}
.our-story .our-story-container .our-story-section {
  @apply w-full flex flex-col lg:flex-row;
}
.our-story .our-story-container .our-story-section .our-story-content {
  @apply w-full lg:w-1/2 lg:pr-10;
}
.our-story .our-story-container .our-story-section .our-story-content p {
  @apply text-lg mb-6;
}
.our-story .our-story-container .our-story-section .our-story-content p:last-child {
  @apply mb-0;
}
.our-story .our-story-container .our-story-section .our-story-content-images {
  @apply w-full lg:w-1/2 lg:pl-10;
}
.our-story .our-story-container .our-story-section .our-story-content-images .our-story-image {
  @apply w-full lg:w-auto h-auto mt-10 lg:mt-0 mb-10 rounded-md;
}

.stats {
  @apply bg-blue-light;
}
.stats .stats-container {
  @apply container mx-auto px-4 text-center;
}
.stats .stats-container .stats-body-title {
  @apply font-blackout text-4xl font-normal text-white m-16 mt-0;
}
.stats .stats-container .stats-list {
  @apply w-full flex-wrap flex justify-center lg:justify-evenly;
}
.stats .stats-container .stats-list li {
  @apply flex justify-center w-1/2 lg:w-auto mb-10 lg:mb-0;
}
.stats .stats-container .stats-list li:nth-child(3), .stats .stats-container .stats-list li:nth-child(4) {
  @apply mb-0;
}
.stats .stats-container .stats-list li .stats-list-item {
  @apply w-56;
}
.stats .stats-container .stats-list li .stats-list-item .stats-list-number {
  @apply text-6xl font-bold text-white mb-5;
}
.stats .stats-container .stats-list li .stats-list-item p {
  @apply text-sm font-normal text-white text-center;
}

.behind-the-scenes {
  @apply bg-escgrey-light;
}
.behind-the-scenes .behind-the-scenes-container {
  @apply container mx-auto text-center;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__title {
  @apply font-blackout text-4xl font-normal text-blue-dark mb-7 px-4;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body {
  @apply px-4;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .behind-the-scenes__body-left-title {
  @apply text-lg md:text-xl font-bold text-blue-dark mb-8;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .behind-the-scenes__team-makeup-list {
  @apply flex flex-col sm:flex-row w-full gap-x-10 mb-8;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .behind-the-scenes__team-makeup-list .behind-the-scenes__team-makeup-items {
  @apply flex flex-col md:flex-row justify-center items-center w-full bg-blue-pale rounded-md mb-4 p-4 md:p-8;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .behind-the-scenes__team-makeup-list .behind-the-scenes__team-makeup-items .behind-the-scenes__team-makeup-item-number {
  @apply text-3xl md:text-4xl font-bold text-blue-dark md:mr-3;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .behind-the-scenes__team-makeup-list .behind-the-scenes__team-makeup-items .behind-the-scenes__team-makeup-item-title {
  @apply text-sm md:text-base font-normal text-blue-dark;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .section-benefits__title {
  @apply text-lg md:text-xl font-bold text-blue-dark mb-8;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits {
  @apply space-y-4;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit {
  @apply bg-white text-left p-6 rounded-[10px];
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__title {
  @apply text-lg font-bold mb-4;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list {
  @apply grid md:grid-cols-2 lg:grid-cols-3 gap-6;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li {
  @apply flex gap-x-3;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .img-container {
  @apply w-[50px] h-[50px];
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .img-container .img, .behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .img-container .tooltip {
  @apply w-full h-full;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .details {
  @apply flex flex-1 flex-col pt-0.5;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .details .title {
  @apply text-xs font-normal mb-1;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .details .description {
  @apply text-lg font-bold leading-snug;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .details .description span {
  @apply inline-flex items-center bg-blue text-xs font-normal text-white px-2 py-1 rounded;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .details .description span .tick {
  @apply w-3 h-auto mr-1.5;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body .benefits .benefit .benefit__list li .tooltip {
  @apply mt-1;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body-bottom {
  @apply w-full flex justify-between mt-7;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body-bottom img {
  @apply mr-5;
}
.behind-the-scenes .behind-the-scenes-container .behind-the-scenes__body-bottom img:last-child {
  @apply mr-0;
}

.benefits .benefits-container {
  @apply container mx-auto;
}
.benefits .benefits-container .benefits__title {
  @apply font-blackout text-4xl font-normal text-blue-dark mx-4 mb-7;
}
.benefits .benefits-container .benefits__list {
  @apply flex flex-wrap w-full;
}
.benefits .benefits-container .benefits__list .benefits__list-item {
  @apply w-full md:w-1/3 p-4;
}
.benefits .benefits-container .benefits__list .benefits__list-item .benefits__list-item-header {
  @apply flex items-center mb-2.5;
}
.benefits .benefits-container .benefits__list .benefits__list-item .benefits__list-item-header .benefits__list-item-image {
  @apply w-7 h-auto mr-3 mb-1.5;
}
.benefits .benefits-container .benefits__list .benefits__list-item .benefits__list-item-header .benefits__list-item-title {
  @apply text-lg font-bold text-blue-dark;
}
.benefits .benefits-container .benefits__list .benefits__list-item .benefits__list-item-text {
  @apply text-sm;
}

.take-on-them {
  @apply bg-offwhite;
}
.take-on-them .take-on-them-container {
  @apply container mx-auto px-4 w-full md:w-4/5 text-center;
}
.take-on-them .take-on-them-container .take-on-them-image {
  @apply w-16 h-auto mx-auto mb-6;
}
.take-on-them .take-on-them-container .take-on-them-title {
  @apply font-blackout text-3xl md:text-4xl font-normal text-blue-dark mb-7;
}
.take-on-them .take-on-them-container .take-on-them-text {
  @apply text-xl md:text-2xl mb-6;
}
.take-on-them .take-on-them-container .take-on-them-text:before {
  content: "“";
  @apply text-4xl font-blackout font-bold align-middle pr-2;
}
.take-on-them .take-on-them-container .take-on-them-text:after {
  content: "”";
  @apply text-4xl font-blackout font-bold align-middle pl-2;
}
.take-on-them .take-on-them-container .take-on-them-text:last-child {
  @apply mb-0;
}

.landing-a-job {
  @apply bg-grey;
}
.landing-a-job .landing-a-job-container {
  @apply container mx-auto px-4;
}
.landing-a-job .landing-a-job-container .landing-a-job-title {
  @apply font-blackout text-4xl font-normal text-blue-dark mb-7 text-center;
}
.landing-a-job .landing-a-job-container .landing-a-job-body {
  @apply flex flex-col md:flex-row gap-x-40 gap-y-7 text-center md:text-left;
}
.landing-a-job .landing-a-job-container .landing-a-job-body .landing-a-job-item {
  @apply w-full;
}
.landing-a-job .landing-a-job-container .landing-a-job-body .landing-a-job-item .landing-a-job-item-title {
  @apply text-lg md:text-xl font-bold text-blue-dark mb-7;
}
.landing-a-job .landing-a-job-container .landing-a-job-body .landing-a-job-item p {
  @apply mb-6 last:mb-0;
}

.org-jobs .org-jobs-container {
  @apply container mx-auto px-4;
}
.org-jobs .org-jobs-container .org-jobs-header {
  @apply w-full justify-center flex items-center mb-7;
}
.org-jobs .org-jobs-container .org-jobs-header .org-jobs-title {
  @apply font-blackout text-4xl font-normal text-blue-dark mr-5;
}
.org-jobs .org-jobs-container .not-hiring {
  @apply text-center;
}
.org-jobs .org-jobs-container .job-roles {
  @apply flex items-center gap-2 mb-5 md:mb-9;
}
.org-jobs .org-jobs-container .job-roles button {
  @apply px-4 py-2 rounded-full hover:bg-escgrey-light hover:font-bold transition-all duration-300 ease-in-out;
}
.org-jobs .org-jobs-container .job-roles button.is-active {
  @apply bg-escgrey-light font-bold;
}
.org-jobs .org-jobs-container .btn-center {
  @apply mt-7;
}
.org-jobs .org-jobs-container .btn-center .btn, .org-jobs .org-jobs-container .btn-center .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .org-jobs .org-jobs-container .btn-center .email-subscribe__button {
  @apply px-10;
}
.org-jobs .org-jobs-container .org-jobs__current-roles .job-cards,
.org-jobs .org-jobs-container .org-jobs__previous-roles .job-cards {
  @apply grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5;
}
.org-jobs .org-jobs-container .org-jobs__previous-roles {
  @apply mt-8;
}

.hero--organisation {
  @apply bg-blue-dark text-white;
}
.hero--organisation .underlay,
.hero--organisation .overlay {
  @apply hidden;
}
.hero--organisation .hero__top {
  @apply space-x-4;
}
.hero--organisation .hero__top .badge {
  @apply h-20 md:h-24;
}
.hero--organisation .hero__container {
  @apply mt-16 pb-80 lg:pb-4 static xl:relative;
}
.hero--organisation .hero__container .hero__inner {
  @apply z-10;
}
.hero--organisation .hero__container .hero__inner.esc100-winner {
  @apply pt-28 lg:pt-0;
}
.hero--organisation .hero__container .hero__inner .hero__header {
  @apply mb-6;
}
.hero--organisation .hero__container .hero__inner .hero__header .hero__icon {
  @apply rounded-lg mr-6 bg-escgrey-light;
}
.hero--organisation .hero__container .hero__inner .hero__header .hero__icon .icon {
  @apply rounded-sm;
}
.hero--organisation .hero__container .hero__inner .hero__header .hero__title {
  @apply text-3xl font-blackout leading-tight text-white;
}
.hero--organisation .hero__container .hero__inner .hero__body {
  @apply mb-3;
}
.hero--organisation .hero__container .hero__inner .hero__body .hero__text {
  @apply font-bold mb-5;
}
.hero--organisation .hero__container .hero__inner .hero__body .hero__list {
  @apply flex items-center space-x-6;
}
.hero--organisation .hero__container .hero__inner .hero__body .hero__list li {
  @apply flex items-center;
}
.hero--organisation .hero__container .hero__inner .hero__body .hero__list li .hero__list-icon {
  @apply w-5 h-5 mr-3;
}
.hero--organisation .hero__container .hero__inner .hero__body .hero__list li .hero__list-title {
  @apply text-xs font-bold text-white;
}
.hero--organisation .hero__container .hero__footer-image {
  @apply absolute right-[56%] lg:right-0 bottom-0 translate-x-1/2 lg:translate-x-0;
}
.hero--organisation .hero__container .hero__footer-image .org-hero {
  @apply w-auto min-h-[250px] h-[47vw] sm:h-[300px] lg:h-[360px] -z-1;
}
.hero--organisation .hero__container .hero__footer-image img {
  @apply absolute top-[5.8%] left-[35.8%] w-[39.8%] h-[50%] bg-blue-dark object-cover;
}

.values {
  @apply bg-grey;
}
.values .values-container {
  @apply container mx-auto;
}
.values .values-container .values__title {
  @apply font-blackout text-4xl font-normal text-blue-dark mx-4 mb-4 text-center;
}
.values .values-container .values__list {
  @apply flex flex-wrap w-full;
}
.values .values-container .values__list .values__list-item {
  @apply w-full md:w-1/3 p-4;
}
.values .values-container .values__list .values__list-item .values__list-item-inner {
  @apply h-full bg-white rounded-md p-4;
}
.values .values-container .values__list .values__list-item .values__list-item-inner .values__list-item-header {
  @apply flex items-center mb-2.5;
}
.values .values-container .values__list .values__list-item .values__list-item-inner .values__list-item-header .values__list-item-image {
  @apply w-7 h-auto mr-3 mb-1.5;
}
.values .values-container .values__list .values__list-item .values__list-item-inner .values__list-item-header .values__list-item-title {
  @apply text-lg font-bold text-blue-dark;
}
.values .values-container .values__list .values__list-item .values__list-item-inner .values__list-item-text {
  @apply text-sm;
}

.reviews {
  @apply bg-grey;
}
.reviews .reviews-container-v2 {
  @apply container mx-auto flex flex-wrap;
}
.reviews .reviews-container-v2 .reviews__title {
  @apply w-full font-blackout text-4xl font-normal text-blue-dark mx-4 mb-8 text-center;
}
.reviews .reviews-container-v2 .review__summary {
  @apply w-full lg:w-1/3 flex flex-col pl-4 pr-16 lg:border-r border-r-grey-dark;
}
.reviews .reviews-container-v2 .review__summary .review__summary-item {
  @apply w-full flex justify-start;
}
.reviews .reviews-container-v2 .review__summary .review__summary-title {
  @apply text-2xl text-blue-light font-bold;
}
.reviews .reviews-container-v2 .review__summary .review__summary-title span {
  @apply text-xl text-black font-normal;
}
.reviews .reviews-container-v2 .review__summary .review__summary-best-for {
  @apply w-full flex flex-col;
}
.reviews .reviews-container-v2 .review__summary .review__summary-best-for p {
  @apply my-4 text-xl font-bold;
}
.reviews .reviews-container-v2 .review__summary .review__summary-best-for p span {
  @apply font-semibold text-sm text-grey-dark;
}
.reviews .reviews-container-v2 .review__summary .review__summary-best-for ul {
  @apply w-full flex flex-wrap lg:flex-col items-start;
}
.reviews .reviews-container-v2 .review__summary .review__summary-best-for ul li {
  @apply min-w-0 inline-flex justify-start items-center font-bold text-base py-3 px-3 mb-3 mr-3 lg:mr-0 whitespace-nowrap outline-none focus:outline-none bg-blue-light text-white rounded-lg;
}
.reviews .reviews-container-v2 .review__summary .review__summary-best-for ul li .roundal {
  @apply ml-4 bg-blue-dark text-white text-sm rounded-full flex justify-center items-center w-7 h-7;
}
.reviews .reviews-container-v2 .review__summary .review__summary-disclaimer p {
  @apply font-semibold text-sm text-grey-dark;
}
.reviews .reviews-container-v2 .reviews__container {
  @apply flex flex-col w-full pt-8 px-4 lg:pt-0 lg:w-2/3 lg:pl-16;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list {
  @apply flex flex-wrap w-full;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list .reviews__list-item {
  @apply w-full pb-8 last:pb-0;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list .reviews__list-item .reviews__list-item-inner {
  @apply h-full p-7 rounded-md bg-white flex flex-col;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list .reviews__list-item .reviews__list-item-inner .reviews__list-item-header {
  @apply w-full flex justify-start mb-4;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list .reviews__list-item .reviews__list-item-inner .reviews__list-item-header .reviews__list-item-avatar {
  @apply w-10 h-10 flex justify-center items-center bg-grey-medium text-grey-dark rounded-full mr-4;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list .reviews__list-item .reviews__list-item-inner .reviews__list-item-header .reviews__list-item-info .reviews__list-item-name {
  @apply text-sm font-bold;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list .reviews__list-item .reviews__list-item-inner .reviews__list-item-header .reviews__list-item-info .reviews__list-item-date {
  @apply text-sm;
}
.reviews .reviews-container-v2 .reviews__container .reviews__list .reviews__list-item .reviews__list-item-inner .reviews__list-item-review {
  @apply text-sm;
}
.reviews .reviews-container-v2 .reviews__container .btn-center {
  @apply w-full text-center pt-8;
}
.reviews .reviews-container-v2 .reviews__container .btn-center .btn, .reviews .reviews-container-v2 .reviews__container .btn-center .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .reviews .reviews-container-v2 .reviews__container .btn-center .email-subscribe__button {
  @apply m-0;
}

.team {
  @apply bg-grey;
}
.team .team-container {
  @apply container mx-auto;
}
.team .team-container .team__title {
  @apply font-blackout text-4xl font-normal text-blue-dark mx-7 mb-5 text-center;
}
.team .team-container .team__sub-title {
  @apply text-lg font-normal text-blue-dark mx-4 mb-7;
}
.team .team-container .team__list {
  @apply flex flex-wrap w-full mb-4;
}
.team .team-container .team__list .team__list-item {
  @apply w-1/2 md:w-1/3 lg:w-1/4 p-4;
}
.team .team-container .team__list .team__list-item .team__list-item-inner {
  @apply flex flex-col justify-center items-center mb-2.5;
}
.team .team-container .team__list .team__list-item .team__list-item-inner .shape {
  @apply w-0 h-0;
}
.team .team-container .team__list .team__list-item .team__list-item-inner .team__list-item-image {
  @apply w-full h-72 bg-cover bg-no-repeat bg-center bg-white mb-5 rounded-md;
  -webkit-clip-path: url(#my-clip-path);
  clip-path: url(#my-clip-path);
}
.team .team-container .team__list .team__list-item .team__list-item-inner .team__list-item-image-svg {
  @apply absolute w-0 h-0;
}
.team .team-container .team__list .team__list-item .team__list-item-inner .team__list-item-name {
  @apply text-lg font-bold text-blue-dark mb-1;
}
.team .team-container .team__list .team__list-item .team__list-item-inner .team__list-item-job-title {
  @apply text-base text-blue-dark;
}

.ways-of-working {
  @apply bg-escgrey-light;
}
.ways-of-working .ways-of-working__container {
  @apply container mx-auto px-4;
}
.ways-of-working .ways-of-working__container .ways-of-working__header {
  @apply flex justify-between items-center mb-4;
}
.ways-of-working .ways-of-working__container .ways-of-working__header .ways-of-working__title {
  @apply font-blackout text-4xl font-normal text-blue-dark;
}
.ways-of-working .ways-of-working__container .ways-of-working__header .ways-of-working__btn {
  @apply min-w-0 py-2 px-4 border-2 border-blue-dark rounded-full;
}
.ways-of-working .ways-of-working__container .ways-of-working__group {
  @apply space-y-4;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section {
  @apply bg-white p-6 rounded-[10px];
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section .ways-of-working__section-title {
  @apply text-lg font-bold mb-4;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul {
  @apply grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li {
  @apply flex;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li .img-container {
  @apply w-[50px] h-[50px] rounded-[5px];
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li .img-container .img {
  @apply w-full h-full bg-grey rounded-[5px];
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li .details {
  @apply flex flex-1 flex-col pl-3 pt-0.5;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li .details .title {
  @apply text-xs font-normal mb-1;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li .details .description {
  @apply text-lg font-bold leading-snug;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li .details .description span {
  @apply inline-flex items-center bg-blue text-xs font-normal text-white px-2 py-1 rounded;
}
.ways-of-working .ways-of-working__container .ways-of-working__group .ways-of-working__section ul li .details .description span .tick {
  @apply w-3 h-auto mr-1.5;
}

.more-employers {
  @apply bg-escgrey-light;
}
.more-employers .org-cards {
  @apply grid md:grid-cols-2 lg:grid-cols-3 gap-8 w-full mt-4;
}

.featured-resource {
  @apply bg-white;
}
.featured-resource .featured-resource__title {
  @apply font-blackout text-3xl font-normal text-blue-dark mb-5;
}
.featured-resource .featured-resource__card {
  @apply flex flex-col md:flex-row w-full h-full bg-white border border-grey-medium rounded-md shadow-md;
}
.featured-resource .featured-resource__card .featured-resource__image {
  @apply w-full md:w-1/2 h-40 md:h-auto p-5 bg-blue-dark bg-no-repeat bg-cover bg-center rounded-t-md md:rounded-none md:rounded-l-md;
}
.featured-resource .featured-resource__card .featured-resource__body {
  @apply w-full md:w-1/2 p-6 md:p-10;
}
.featured-resource .featured-resource__card .featured-resource__body .featured-resource__body-title {
  @apply text-lg font-bold text-blue-dark mb-2.5;
}
.featured-resource .featured-resource__card .featured-resource__body p {
  @apply text-sm font-normal text-blue-dark mb-5;
}
.featured-resource .featured-resource__card .featured-resource__body .featured-resource__body-tags {
  @apply flex mb-5;
}
.featured-resource .featured-resource__card .featured-resource__body .featured-resource__body-tags li {
  @apply text-xs font-bold text-grey-light uppercase mr-2;
}
.featured-resource .featured-resource__card .featured-resource__body .featured-resource__body-tags li:last-child {
  @apply mr-0;
}
.featured-resource .featured-resource__card .featured-resource__body .btn, .featured-resource .featured-resource__card .featured-resource__body .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .featured-resource .featured-resource__card .featured-resource__body .email-subscribe__button {
  @apply mb-0 py-1;
}

.useful-resources .useful-resources__title {
  @apply font-blackout text-3xl font-normal text-blue-dark mb-5;
}

.about .section__container {
  @apply flex justify-between items-center w-full mb-40;
}
.about .section__container:last-child {
  @apply mb-0;
}
.about .section__container .section__container-inner {
  @apply w-full md:w-3/5;
}
.about .section__container .section__img {
  @apply hidden md:block md:w-2/5 lg:w-1/3 h-auto;
}
.about .section__title {
  @apply text-3xl font-blackout mt-0 mb-5;
}
.about .section__title.section__title-center {
  @apply text-center mx-auto;
}
.about .section__title.section__title-mt-0 {
  @apply mt-0;
}
.about .section__text {
  @apply mb-5;
}
.about .section__text:last-child {
  @apply mb-0;
}
.about .section__text.section__text-center {
  @apply text-center mx-auto;
}
.about .stats {
  @apply flex flex-wrap w-full max-w-screen-lg lg:w-10/12 mx-auto bg-transparent;
}
.about .stats .stat {
  @apply w-full sm:w-1/2 md:w-1/3 p-4;
}
.about .stats .stat .stat__inner {
  @apply flex flex-col justify-center items-center bg-blue-pale py-12 px-6 h-full text-center rounded-md;
}
.about .stats .stat .stat__inner .stat__icon {
  @apply h-8 w-auto mx-auto mb-5 fill-current text-blue-light;
}
.about .stats .stat .stat__inner .stat__number {
  @apply text-4xl font-blackout text-blue-light uppercase mb-2.5;
}
.about .stats .stat .stat__inner .stat__text {
  @apply text-base text-blue-light;
}

.b-corp {
  @apply pb-0;
}
.b-corp .section__img {
  @apply w-72 h-auto mx-auto mb-10;
}
.b-corp .section__title {
  @apply text-3xl font-blackout mb-5;
}
.b-corp p {
  @apply mb-5;
}
.b-corp .link {
  @apply text-blue-light underline;
}
.b-corp .video-container {
  @apply relative h-0 pb-fluid-video my-16;
}
.b-corp .video-container .video {
  @apply absolute top-0 left-0 w-full h-full;
}
.b-corp .certified {
  @apply bg-red-esc mt-20 py-32;
}
.b-corp .certified .certified__container {
  @apply container mx-auto px-4 text-center;
}
.b-corp .certified .certified__container .certified__title {
  @apply lg:w-3/4 text-3xl font-blackout text-white mb-5 mx-auto;
}
.b-corp .certified .certified__container .btn-border--white {
  @apply hover:bg-white hover:text-pink transition duration-500 ease-in-out;
}

.staff, .our-advisors {
  @apply pb-11;
}
.staff .section__title, .our-advisors .section__title {
  @apply max-w-screen-xl text-3xl font-blackout text-blue-dark mx-auto mb-10 px-5;
}
.staff .members, .our-advisors .members {
  @apply flex flex-wrap w-full max-w-screen-xl mx-auto;
}
.staff .members .member, .our-advisors .members .member {
  @apply w-full sm:w-1/2 lg:w-1/3 mb-10 px-4;
}
.staff .members .member .member__body, .our-advisors .members .member .member__body {
  @apply relative flex flex-col justify-end h-96 bg-no-repeat bg-center bg-cover p-5 rounded-md z-0;
}
.staff .members .member .member__body:before, .our-advisors .members .member .member__body:before {
  content: "";
  @apply absolute inset-0 bg-black opacity-50 rounded-md -z-1;
}
.staff .members .member .member__body .member__name, .our-advisors .members .member .member__body .member__name {
  @apply w-1/2 text-2xl font-bold text-white leading-7 mb-2.5;
}
.staff .members .member .member__body .member__job-title, .our-advisors .members .member .member__body .member__job-title {
  @apply text-sm font-normal tracking-normal text-white uppercase;
}
.staff .members .member .member__footer, .our-advisors .members .member .member__footer {
  @apply mt-2.5;
}
.staff .members .member .member__footer .member__bio, .our-advisors .members .member .member__footer .member__bio {
  @apply hidden;
}
.staff .members .member .member__footer .member__bio:checked + .member__bio-title p:nth-child(1), .our-advisors .members .member .member__footer .member__bio:checked + .member__bio-title p:nth-child(1) {
  @apply invisible opacity-0;
}
.staff .members .member .member__footer .member__bio:checked + .member__bio-title p:nth-child(2), .our-advisors .members .member .member__footer .member__bio:checked + .member__bio-title p:nth-child(2) {
  @apply visible opacity-100 float-left;
}
.staff .members .member .member__footer .member__bio:checked + .member__bio-title + .member__footer-bio, .our-advisors .members .member .member__footer .member__bio:checked + .member__bio-title + .member__footer-bio {
  @apply visible opacity-100 h-full;
}
.staff .members .member .member__footer .member__bio-social, .our-advisors .members .member .member__footer .member__bio-social {
  @apply inline-block float-right;
}
.staff .members .member .member__footer .member__bio-social img, .our-advisors .members .member .member__footer .member__bio-social img {
  @apply w-6 h-auto;
}
.staff .members .member .member__footer .member__bio-title, .our-advisors .members .member .member__footer .member__bio-title {
  @apply inline-block text-sm font-normal tracking-tight text-blue-dark uppercase cursor-pointer;
}
.staff .members .member .member__footer .member__bio-title p, .our-advisors .members .member .member__footer .member__bio-title p {
  @apply inline-block transition duration-500 ease-in-out;
}
.staff .members .member .member__footer .member__bio-title p:nth-child(2), .our-advisors .members .member .member__footer .member__bio-title p:nth-child(2) {
  @apply invisible opacity-0;
}
.staff .members .member .member__footer .member__footer-bio, .our-advisors .members .member .member__footer .member__footer-bio {
  @apply invisible opacity-0 h-0 mt-4 transition duration-500 ease-in-out;
}

.reviews {
  @apply xl:flex xl:flex-wrap;
}
.reviews .review {
  @apply mb-20 xl:w-1/2 xl:px-8 xl:mb-0;
}
.reviews .review:nth-child(odd) {
  @apply xl:pl-0;
}
.reviews .review:nth-child(even) {
  @apply xl:mt-40 xl:pr-0;
}
.reviews .review .review__quote-mark {
  @apply relative w-6 h-auto fill-current text-blue-dark mx-auto -mb-3 md:mb-5 transform -translate-x-12 md:translate-x-0 z-20;
}
.reviews .review .review__container {
  @apply flex flex-col md:flex-row;
}
.reviews .review .review__container .review__img-wrap {
  @apply flex justify-center items-start md:pr-8 mb-5 md:mb-0;
}
.reviews .review .review__container .review__img-wrap .review__img {
  @apply w-28 h-auto;
}
.reviews .review .review__container .review__body {
  @apply w-full text-center md:text-left;
}
.reviews .review .review__container .review__body .review__text {
  @apply text-base mb-2.5;
}
.reviews .review .review__container .review__body .review__name {
  @apply text-lg font-bold;
}
.reviews .review .review__container .review__body .review__job-title {
  @apply text-sm;
}

.feedbacks {
  @apply xl:flex xl:flex-wrap xl:justify-between;
}
.feedbacks .feedback {
  @apply mb-5 xl:px-6 xl:w-1/2;
}
.feedbacks .feedback:nth-child(odd) {
  @apply xl:pl-0;
}
.feedbacks .feedback:nth-child(even) {
  @apply xl:pr-0;
}
.feedbacks .feedback .feedback__inner {
  @apply w-full h-full bg-offwhite p-6 rounded-md;
}
.feedbacks .feedback .feedback__inner .feedback__quote-mark {
  @apply w-6 h-auto fill-current text-blue-dark mb-5;
}
.feedbacks .feedback .feedback__inner .feedback__text {
  @apply text-base mb-2.5;
}
.feedbacks .feedback .feedback__inner .feedback__name {
  @apply text-lg font-bold;
}

.canvas-confetti {
  @apply fixed top-0 left-0 w-full h-full pointer-events-none z-40;
}

.escape100 .hero--escape100 {
  @apply bg-blue-medium;
}
.escape100 .hero--escape100 .underlay {
  @apply hidden;
}
.escape100 .hero--escape100 .hero__container {
  @apply pt-0 items-start;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__header .hero__icon {
  @apply w-auto h-40 mx-auto mb-6 rounded-none;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__header .hero__icon img {
  @apply w-auto h-full;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__body.center {
  @apply transform -translate-y-1/2;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__body .hero__intro {
  @apply text-white text-xs uppercase mb-4;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__body .hero__text {
  @apply text-xl;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__body .hero__video {
  @apply flex justify-center items-center text-white text-sm font-bold mt-6 cursor-pointer select-none;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__body .hero__video .play-button-outer {
  @apply flex justify-center items-center w-14 h-9 bg-red-escmedium rounded-xl mr-3;
}
.escape100 .hero--escape100 .hero__container .hero__inner .hero__body .hero__video .play-button {
  @apply w-0 h-0 border-solid border-t-8 border-b-8 border-r-0 border-l-[14px] border-transparent border-l-white;
}
.escape100 .metrics {
  @apply bg-yellow-medium text-blue-dark;
}
.escape100 .metrics .title {
  @apply text-xl font-bold mb-12;
}
.escape100 .metrics .sub-title {
  @apply text-base font-semibold uppercase mb-12;
}
.escape100 .metrics .top-stats {
  @apply flex flex-col md:flex-row justify-center items-center;
}
.escape100 .metrics .top-stats li {
  @apply w-full md:min-h-[7.75rem] mb-8 md:mb-0;
}
.escape100 .metrics .top-stats li:last-child {
  @apply mb-0;
}
.escape100 .metrics .top-stats li .number {
  @apply text-5xl md:text-6xl font-bold mb-4;
}
.escape100 .metrics .top-stats li .title {
  @apply text-base font-semibold uppercase mb-0;
}
.escape100 .sign-up {
  @apply max-w-screen-lg mx-auto pb-0;
}
.escape100 .sign-up .esc-logo {
  @apply w-20 h-auto mx-auto mb-10 -rotate-2;
}
.escape100 .sign-up .title {
  @apply text-2xl font-bold text-blue-dark mb-5;
}
.escape100 .sign-up p {
  @apply text-lg mb-5;
}
.escape100 .sign-up p:last-child {
  @apply mb-0;
}
.escape100 .sign-up .btn, .escape100 .sign-up .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .escape100 .sign-up .email-subscribe__button {
  @apply mt-10 mb-0;
}
.escape100 .our-criteria .title {
  @apply text-3xl font-blackout font-normal text-blue-dark mb-3;
}
.escape100 .our-criteria .sub-title {
  @apply text-xl font-normal text-blue-dark mb-5;
}
.escape100 .our-criteria .criteria-list {
  @apply flex justify-center items-center flex-wrap lg:flex-nowrap my-10;
}
.escape100 .our-criteria .criteria-list li {
  @apply flex flex-col justify-center items-center w-48 h-48;
}
.escape100 .our-criteria .criteria-list li img {
  @apply mb-5 w-auto h-20;
}
.escape100 .our-criteria .criteria-list li p {
  @apply text-xl;
}
.escape100 .winners {
  @apply bg-offwhite;
}
.escape100 .winners img {
  @apply w-auto h-40 mx-auto mb-8;
}
.escape100 .winners .title {
  @apply text-xl font-bold uppercase;
}
.escape100 .winners .e100-stats {
  @apply flex flex-col md:flex-row justify-center items-center pt-12;
}
.escape100 .winners .e100-stats li {
  @apply w-full md:min-h-[7.75rem] mb-8 md:mb-0;
}
.escape100 .winners .e100-stats li:last-child {
  @apply mb-0;
}
.escape100 .winners .e100-stats li .number {
  @apply text-5xl md:text-6xl text-blue-light font-bold mb-4;
}
.escape100 .winners .e100-stats li .title {
  @apply text-base text-escgrey-dark font-semibold uppercase mb-0;
}
.escape100 .escape100 {
  @apply bg-offwhite;
}
.escape100 .escape100 .escape100-nav-container .escape100-nav {
  @apply flex justify-center items-center text-xl font-semibold text-escgrey-dark mb-16;
}
.escape100 .escape100 .escape100-nav-container .escape100-nav li {
  @apply mr-10;
}
.escape100 .escape100 .escape100-nav-container .escape100-nav li:last-child {
  @apply mr-0;
}
.escape100 .escape100 .escape100-nav-container .escape100-nav li a.active {
  @apply relative font-bold text-blue-dark after:content-[""] after:absolute after:-bottom-3.5 after:left-0 after:w-full after:h-[3px] after:bg-blue-dark;
}
.escape100 .escape100 .title {
  @apply text-3xl font-blackout font-normal text-blue-dark mb-3;
}
.escape100 .escape100 .sub-title {
  @apply text-xl font-normal text-blue-dark mb-20;
}

.methodology .when-it-comes-to-work {
  @apply bg-yellow-medium;
}
.methodology .when-it-comes-to-work .section__container {
  @apply max-w-screen-md container mx-auto px-4 text-center;
}
.methodology .when-it-comes-to-work .section__container .section__title {
  @apply w-96 text-3xl font-blackout mb-5 mx-auto;
}
.methodology .when-it-comes-to-work .section__container p {
  @apply text-lg mb-5;
}
.methodology .when-it-comes-to-work .section__container p:last-child {
  @apply mb-0;
}
.methodology .our-criteria .section__container {
  @apply max-w-screen-md container mx-auto px-4 text-center;
}
.methodology .our-criteria .section__container .section__title {
  @apply text-3xl font-blackout font-normal text-blue-dark mb-3;
}
.methodology .our-criteria .section__container .section__sub-title {
  @apply text-xl font-normal text-blue-dark mb-10;
}
.methodology .our-criteria .section__container .criteria-list {
  @apply flex justify-around md:justify-between items-center flex-wrap mt-8;
}
.methodology .our-criteria .section__container .criteria-list li {
  @apply flex flex-col justify-center items-center w-40 md:w-48 h-48;
}
.methodology .our-criteria .section__container .criteria-list li img {
  @apply mb-5 w-auto h-20;
}
.methodology .our-criteria .section__container .criteria-list li a {
  @apply w-28 bg-red-esc text-sm text-white font-bold py-1 px-4 rounded-full cursor-pointer;
}
.methodology .sub-section {
  @apply pt-16 pb-0;
}
.methodology .sub-section:last-child {
  @apply pb-24;
}
.methodology .sub-section .sub-section__header {
  @apply relative bg-blue-dark mb-5 pt-2;
}
.methodology .sub-section .sub-section__header:after {
  content: "";
  @apply absolute -bottom-9 left-0 w-full bg-blue-dark h-10 -z-1;
  -webkit-clip-path: polygon(100% 0%, 100% 60%, 0% 10%);
  clip-path: polygon(100% 0%, 100% 60%, 0% 10%);
}
.methodology .sub-section .sub-section__header .sub-section__header-container {
  @apply relative container mx-auto px-4 flex justify-center;
}
.methodology .sub-section .sub-section__header .sub-section__header-container .sub-section__title {
  @apply text-3xl font-blackout font-normal text-white;
}
.methodology .sub-section .sub-section__header .sub-section__header-container .sub-section__scroll-to-top {
  @apply absolute right-4 top-1 text-center text-sm text-white;
}
.methodology .sub-section .sub-section__container {
  @apply max-w-screen-md container mx-auto mt-20 px-4;
}
.methodology .sub-section .sub-section__container .sub-section__intro {
  @apply text-base text-center text-black font-normal mb-5;
}
.methodology .sub-section .sub-section__container .sub-section__title {
  @apply max-w-screen-sm text-2xl text-center text-blue font-blackout my-10 mx-auto;
}
.methodology .sub-section .sub-section__container .sub-section__scale-title {
  @apply text-2xl text-center text-blue font-blackout mt-10 mb-2.5;
}
.methodology .sub-section .sub-section__container .sub-section__scale-text {
  @apply text-base text-center text-black font-normal;
}
.methodology .sub-section .sub-section__container .sub-section__text {
  @apply text-base text-left text-escgrey-dark font-normal mb-5;
}
.methodology .sub-section .sub-section__container .sub-section__link {
  @apply text-blue font-bold;
}
.methodology .sub-section .sub-section__container input[range] {
  @apply bg-offwhite w-full h-4 outline-none rounded-md appearance-none cursor-pointer;
}
.methodology .sub-section .sub-section__container .ratings {
  @apply flex flex-col md:flex-row;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards,
.methodology .sub-section .sub-section__container .ratings .employee-cards {
  @apply w-full mt-4 pr-2;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-cards__title,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-cards__title {
  @apply text-base text-black uppercase mb-5 text-center;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container {
  @apply mb-4;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container input,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container input {
  @apply hidden;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container input:checked + .rating-card + .rating-card__desc,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container input:checked + .rating-card + .rating-card__desc {
  @apply max-h-full mt-2 py-4 opacity-100;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container input:checked + .rating-card,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container input:checked + .rating-card {
  @apply bg-blue-pale;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container input:checked + .rating-card .rating-card__title,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container input:checked + .rating-card .rating-card__title {
  @apply font-bold;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container input:checked + .rating-card .icon,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container input:checked + .rating-card .icon {
  @apply transform rotate-90;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container .rating-card,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container .rating-card {
  @apply relative flex flex-col bg-grey py-3 px-4 rounded-md cursor-pointer;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container .rating-card .rating-card__type,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container .rating-card .rating-card__type {
  @apply text-xs text-escgrey-dark uppercase mb-1 p-0 pr-8;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container .rating-card .rating-card__title,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container .rating-card .rating-card__title {
  @apply text-sm uppercase p-0 pr-8;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container .rating-card .icon,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container .rating-card .icon {
  @apply absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-auto transition-all duration-300 ease-in-out;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container .rating-card__desc,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container .rating-card__desc {
  @apply bg-offwhite px-4 max-h-0 opacity-0 overflow-hidden transition-all duration-300 ease-in-out;
}
.methodology .sub-section .sub-section__container .ratings .policy-cards .rating-card__container .rating-card__desc p,
.methodology .sub-section .sub-section__container .ratings .employee-cards .rating-card__container .rating-card__desc p {
  @apply text-sm italic leading-relaxed;
}
.methodology .sub-section .sub-section__container .ratings .employee-cards {
  @apply pr-0 pl-2;
}
.methodology .methodology_slider {
  @apply w-11/12 h-4 border-none rounded-md bg-offwhite mx-auto my-8 cursor-pointer;
}
.methodology .methodology_slider .noUi-handle {
  @apply w-5 h-8 -top-2 bg-blue shadow-none;
}
.methodology .methodology_slider .noUi-handle:before, .methodology .methodology_slider .noUi-handle:after {
  @apply hidden;
}
.methodology .methodology-slider-container {
  @apply mb-4;
}
.methodology .methodology-slider-container .methodology-slider-card {
  @apply bg-escgrey-light p-8;
}
.methodology .methodology-slider-container .methodology-slider-card .methodology-slider-title {
  @apply font-blackout text-2xl text-blue-dark mb-4;
}
.methodology .methodology-slider-container .methodology-slider-card .methodology-slider-body {
  @apply text-base text-escgrey-dark;
}
.methodology .methodology-slider-container .methodology-slider-card .methodology-slider-body p, .methodology .methodology-slider-container .methodology-slider-card .methodology-slider-body a {
  @apply mb-4;
}
.methodology .methodology-slider-container .methodology-slider-card .methodology-slider-body p:last-child, .methodology .methodology-slider-container .methodology-slider-card .methodology-slider-body a:last-child {
  @apply mb-0;
}
.methodology .methodology-slider-container .methodology-slider-card .methodology-slider-body a {
  @apply text-blue font-bold underline cursor-pointer;
}
.methodology .methodology-slider-container .methodology-slider-card .methodology-slider-body img {
  @apply block w-full h-auto mb-4;
}
.methodology .the-escape-score-container {
  @apply w-full flex flex-wrap justify-center md:justify-between mt-8;
}
.methodology .the-escape-score-container .the-escape-score {
  @apply flex flex-col w-24 mt-6;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header {
  @apply relative w-24 h-24;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header .the-escape-score-header__shape-1 {
  @apply absolute top-0 left-1/2 -translate-x-1/2 flex flex-col justify-center items-center w-20 h-16 bg-yellow z-10;
  clip-path: polygon(0% 0%, 100% 7%, 95% 99%, 3% 95%);
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header .the-escape-score-header__shape-1 p {
  @apply flex justify-center items-center font-blackout text-lg uppercase;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header .the-escape-score-header__shape-1 p:last-child {
  @apply text-sm;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header .the-escape-score-header__shape-2 {
  @apply absolute top-14 left-1/2 -translate-x-1/2 w-2.5 h-9 bg-yellow;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header.total .the-escape-score-header__shape-1 {
  @apply bg-offwhite;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header.total .the-escape-score-header__shape-1 p:first-child {
  @apply text-xl;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-header.total .the-escape-score-header__shape-2 {
  @apply bg-escgrey-dark;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-footer {
  @apply relative text-center mt-2;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-footer .the-escape-score-footer__title {
  @apply text-base text-blue-dark font-blackout;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-footer .the-escape-score-footer__title .info {
  @apply absolute -top-1 right-0;
}
.methodology .the-escape-score-container .the-escape-score .the-escape-score-footer .the-escape-score-footer__title .info .icon {
  @apply w-3.5 h-3.5;
}
.methodology .the-escape-score-container .the-escape-score__plus,
.methodology .the-escape-score-container .the-escape-score__equals {
  @apply flex justify-center items-center text-2xl font-bold text-escgrey-dark mb-4;
}

.resources-hub .collections .collections-items {
  @apply flex flex-wrap items-center md:flex-col lg:flex-row p-2 lg:p-0;
}
.resources-hub .collections .collections-items .collections-item {
  @apply flex w-1/3 md:w-full lg:w-1/2 xl:w-1/3 h-40 md:h-36 xl:h-52 lg:my-2 p-2 lg:px-4;
}
.resources-hub .collections .collections-items .collections-item .collections-item__card {
  @apply relative flex items-center w-full h-full;
}
.resources-hub .collections .collections-items .collections-item .collections-item__card .collections-item__img {
  @apply absolute left-1/2 transform -translate-x-1/2 md:translate-x-0 md:left-2.5 -top-2 md:top-1/2 md:-translate-y-1/2 w-auto h-14 md:h-32 xl:h-48;
}
.resources-hub .collections .collections-items .collections-item .collections-item__card a {
  @apply flex md:text-left w-full h-3/4 bg-white rounded-md p-4 border border-transparent;
}
.resources-hub .collections .collections-items .collections-item .collections-item__card a.active {
  @apply border border-blue-dark;
}
.resources-hub .collections .collections-items .collections-item .collections-item__card a .collections-item__body {
  @apply flex flex-col justify-center w-full mt-8 md:mt-0 md:ml-28 xl:ml-36 z-10;
}
.resources-hub .collections .collections-items .collections-item .collections-item__card a .collections-item__body .collections-item__title {
  @apply text-sm md:text-base xl:text-lg font-bold leading-snug text-blue-dark md:mb-1.5;
}
.resources-hub .collections .collections-items .collections-item .collections-item__card a .collections-item__body .collections-item__text {
  @apply hidden md:block text-sm font-normal text-blue-dark;
}

.programmes-and-courses .section.featured {
  @apply bg-grey;
}
.programmes-and-courses .section.programmes, .programmes-and-courses .section.courses {
  @apply pt-4 md:pt-24;
}
.programmes-and-courses .section.courses {
  @apply bg-grey;
}
.programmes-and-courses .section .section-title {
  @apply hidden md:block font-blackout text-3xl font-normal text-blue-dark mb-10 px-4;
}

.programme-course .hero__container .hero__inner {
  @apply my-4;
}
.programme-course .hero__container .hero__inner .hero__body .hero__sub-title {
  @apply text-xs font-normal text-white uppercase mb-1;
}
.programme-course .hero__container .hero__inner .hero__body .hero__text {
  @apply md:w-4/5 font-bold mb-4;
}
.programme-course .hero__container .hero__inner .hero__body .hero__group {
  @apply flex flex-col lg:flex-row;
}
.programme-course .hero__container .hero__inner .hero__body .hero__group .btn-group {
  @apply items-start;
}
.programme-course .hero__container .hero__inner .hero__body .hero__group .hero__group-info {
  @apply flex-1;
}
.programme-course .hero__container .hero__inner .hero__body .hero__group .hero__group-video {
  @apply w-full lg:w-80 h-auto mt-4 lg:mt-0;
}
.programme-course .programme-course__page-header {
  @apply flex items-center mb-8;
}
.programme-course .programme-course__page-header .programme-course__page-header-img-wrapper {
  @apply w-10 h-10 mr-2.5 bg-white rounded-full overflow-hidden;
}
.programme-course .programme-course__page-header .programme-course__page-header-img-wrapper .programme-course__page-header-img {
  @apply w-full h-auto;
}
.programme-course .programme-course__page-header .programme-course__page-header-group {
  @apply flex flex-col flex-1;
}
.programme-course .programme-course__page-header .programme-course__page-header-group .programme-course__page-header-title {
  @apply text-xs font-normal text-blue-dark uppercase mb-0.5;
}
.programme-course .programme-course__page-header .programme-course__page-header-group .programme-course__page-header-name {
  @apply text-base font-bold text-blue-dark;
}
.programme-course .section-title {
  @apply font-blackout text-3xl font-normal text-blue-dark mb-8;
}
.programme-course .programme-course-cards .programme-course-card-container {
  @apply w-full h-full m-0 p-0;
}

.programme-course .section__container {
  @apply container max-w-screen-md mx-auto px-4;
}
.programme-course .overview {
  @apply pb-0;
}
.programme-course .overview .overview-items {
  @apply flex flex-wrap;
}
.programme-course .overview .overview-items .overview-item {
  @apply w-1/2 md:w-1/3 p-4;
}
.programme-course .overview .overview-items .overview-item:last-child {
  @apply w-full md:w-1/3;
}
.programme-course .overview .overview-items .overview-item .overview-item__inner {
  @apply flex flex-col justify-center items-center bg-grey py-12 px-6 h-full text-center rounded-md;
}
.programme-course .overview .overview-items .overview-item .overview-item__inner .overview-item__icon {
  @apply h-11 w-auto mx-auto mb-3 fill-current text-blue-light;
}
.programme-course .overview .overview-items .overview-item .overview-item__inner .overview-item__text {
  @apply text-lg font-bold text-blue-dark;
}
.programme-course .what-youll-learn {
  @apply text-center;
}
.programme-course .what-youll-learn p {
  @apply mb-5;
}
.programme-course .curriculum .section-title {
  @apply text-center;
}
.programme-course .curriculum .curriculum__section {
  @apply mb-8;
}
.programme-course .curriculum .curriculum__section .curriculum__header {
  @apply flex items-start justify-between mb-4;
}
.programme-course .curriculum .curriculum__section .curriculum__header .curriculum__header-title {
  @apply text-lg font-bold text-blue-dark pr-4;
}
.programme-course .curriculum .curriculum__section .curriculum__header .curriculum__header-time {
  @apply text-lg font-bold text-blue-dark whitespace-nowrap;
}
.programme-course .curriculum .curriculum__section .curriculum__body li {
  @apply flex items-start justify-between mb-3;
}
.programme-course .curriculum .curriculum__section .curriculum__body li .curriculum__body-group {
  @apply flex items-start;
}
.programme-course .curriculum .curriculum__section .curriculum__body li .curriculum__body-group .curriculum__body-item-icon-container {
  @apply w-3 h-3 mr-3 pt-0.5;
}
.programme-course .curriculum .curriculum__section .curriculum__body li .curriculum__body-group .curriculum__body-item-icon-container .curriculum__body-item-icon {
  @apply w-full h-auto;
}
.programme-course .curriculum .curriculum__section .curriculum__body li .curriculum__body-group .curriculum__body-item-title {
  @apply flex-1 text-base font-normal text-blue-dark pr-4;
}
.programme-course .curriculum .curriculum__section .curriculum__body li .curriculum__body-item-time {
  @apply text-sm font-normal text-blue-dark whitespace-nowrap;
}
.programme-course .testimonial .section-title {
  @apply text-center;
}
.programme-course .testimonial .testimonial-container {
  @apply flex flex-col md:flex-row items-center;
}
.programme-course .testimonial .testimonial-container p {
  @apply flex-1 text-center md:text-left;
}
.programme-course .testimonial .testimonial-container .testimonial-img-container {
  @apply w-40 h-40 flex justify-center items-center rounded-md overflow-hidden mt-8 md:m-0 md:ml-8;
}
.programme-course .testimonial .testimonial-container .testimonial-img-container .testimonial-img {
  @apply w-full h-auto;
}
.programme-course .instructors .section-title {
  @apply text-center;
}
.programme-course .instructors .instructor {
  @apply flex flex-col md:flex-row mb-8;
}
.programme-course .instructors .instructor .instructor-img-container {
  @apply w-28 h-28 rounded-full border-4 border-white mx-auto mb-4 md:m-0 md:mr-8 overflow-hidden shadow-md;
}
.programme-course .instructors .instructor .instructor-img-container .instructor-img {
  @apply w-full h-auto;
}
.programme-course .instructors .instructor .instructor-info {
  @apply flex flex-col flex-1 text-center md:text-left;
}
.programme-course .instructors .instructor .instructor-info .instructor-name {
  @apply text-lg font-bold text-blue-dark mb-2.5;
}
.programme-course .instructors .instructor .instructor-info .instructor-intro {
  @apply text-base font-normal text-blue-dark;
}
.programme-course .faqs.programme .section-title,
.programme-course .faqs.programme .section-sub-title {
  @apply text-left mb-4;
}
.programme-course .faqs.programme .section-sub-title {
  @apply mb-8;
}
.programme-course .faqs.programme .section__container {
  @apply container;
}
.programme-course .faqs .section-title,
.programme-course .faqs .section-sub-title {
  @apply text-center;
}
.programme-course .faqs .faq-container {
  @apply border rounded-md mt-8;
}
.programme-course .faqs .faq-container .faq {
  @apply border-b;
}
.programme-course .faqs .faq-container .faq:last-child {
  @apply border-none;
}
.programme-course .faqs .faq-container .faq input {
  @apply hidden;
}
.programme-course .faqs .faq-container .faq input:checked + .faq-header .faq-header-img-container .faq-header-img.open {
  @apply hidden;
}
.programme-course .faqs .faq-container .faq input:checked + .faq-header .faq-header-img-container .faq-header-img.closed {
  @apply block;
}
.programme-course .faqs .faq-container .faq input:checked + .faq-header + .faq-body {
  @apply max-h-full py-4 pt-0;
}
.programme-course .faqs .faq-container .faq .faq-header {
  @apply flex items-center justify-between p-4 cursor-pointer;
}
.programme-course .faqs .faq-container .faq .faq-header .faq-header-title {
  @apply flex-1 text-lg font-bold text-blue-dark pr-4;
}
.programme-course .faqs .faq-container .faq .faq-header .faq-header-img-container {
  @apply relative w-6 h-6;
}
.programme-course .faqs .faq-container .faq .faq-header .faq-header-img-container .faq-header-img {
  @apply absolute w-full h-auto bg-white;
}
.programme-course .faqs .faq-container .faq .faq-header .faq-header-img-container .faq-header-img.open {
  @apply block;
}
.programme-course .faqs .faq-container .faq .faq-header .faq-header-img-container .faq-header-img.closed {
  @apply hidden;
}
.programme-course .faqs .faq-container .faq .faq-body {
  @apply max-h-0 px-4 overflow-hidden transform transition-all duration-150 ease-in-out;
}
.programme-course .faqs .faq-container .faq .faq-body .faq-body-text {
  @apply text-base font-normal text-blue-dark;
}

.programme-overview {
  @apply bg-grey;
}
.programme-overview .section__container {
  @apply container mx-auto px-0;
}
.programme-overview .section__container .programme-overview-items {
  @apply flex flex-wrap;
}
.programme-overview .section__container .programme-overview-items .programme-overview-item {
  @apply w-1/2 md:w-1/4 p-4;
}
.programme-overview .section__container .programme-overview-items .programme-overview-item .programme-overview-item__inner {
  @apply flex flex-col justify-center items-center bg-white pt-8 pb-10 px-4 h-full text-center rounded-md;
}
.programme-overview .section__container .programme-overview-items .programme-overview-item .programme-overview-item__inner .programme-overview-item__icon {
  @apply w-8 h-8 mx-auto mb-3 fill-current text-blue-light;
}
.programme-overview .section__container .programme-overview-items .programme-overview-item .programme-overview-item__inner .programme-overview-item__title {
  @apply text-lg font-bold text-blue-dark mb-5;
}
.programme-overview .section__container .programme-overview-items .programme-overview-item .programme-overview-item__inner .programme-overview-item__text {
  @apply text-sm font-bold text-blue-dark;
}
.programme-overview .section__container .programme-overview-items .programme-overview-item .programme-overview-item__inner .programme-overview-item__text .open {
  @apply text-green-medium;
}
.programme-overview .section__container .programme-overview-items .programme-overview-item .programme-overview-item__inner .programme-overview-item__text .close {
  @apply text-red-esc;
}

.event .hero__container .hero__inner .hero__body .hero__sub-title {
  @apply text-xs font-normal text-white uppercase mb-1;
}
.event .hero__container .hero__inner .hero__body .hero__text {
  @apply font-bold mb-4;
}
.event .event__page-header {
  @apply flex items-center mb-8;
}
.event .event__page-header .event__page-header-img-wrapper {
  @apply w-10 h-10 mr-2.5 bg-white rounded-full overflow-hidden;
}
.event .event__page-header .event__page-header-img-wrapper .event__page-header-img {
  @apply w-full h-auto;
}
.event .event__page-header .event__page-header-group {
  @apply flex flex-col flex-1;
}
.event .event__page-header .event__page-header-group .event__page-header-title {
  @apply text-xs font-normal text-blue-dark uppercase mb-0.5;
}
.event .event__page-header .event__page-header-group .event__page-header-name {
  @apply text-base font-bold text-blue-dark;
}
.event .section-title {
  @apply font-blackout text-3xl font-normal text-blue-dark mx-auto mt-20 mb-10;
}
.event .event-cards .event-card-container {
  @apply w-full h-full m-0 p-0;
}

.breadcrumb {
  @apply bg-grey;
}
.breadcrumb .breadcrumb__inner {
  @apply flex flex-wrap justify-center md:justify-between items-center container mx-auto py-4 px-4;
}
.breadcrumb .breadcrumb__inner .breadcrumb__links {
  @apply flex text-xs font-normal text-blue-dark;
}
.breadcrumb .breadcrumb__inner .breadcrumb__links li {
  @apply flex items-center mr-2;
}
.breadcrumb .breadcrumb__inner .breadcrumb__links li:last-child {
  @apply mr-0;
}
.breadcrumb .breadcrumb__inner .breadcrumb__links li a {
  @apply mr-2 py-2;
}
.breadcrumb .breadcrumb__inner .btn, .breadcrumb .breadcrumb__inner .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .breadcrumb .breadcrumb__inner .email-subscribe__button {
  @apply flex justify-between m-0 py-1.5 text-sm bg-blue;
}

.content-section .content-section__container {
  @apply flex flex-wrap container mx-auto px-4;
}
.content-section .content-section__container .content-section__main {
  @apply w-full md:flex-1 mb-4 md:mb-0 pr-0 md:pr-8;
}
.content-section .content-section__container .content-section__main .video-container {
  @apply relative h-0 aspect-w-16 aspect-h-9 mb-6;
}
.content-section .content-section__container .content-section__main iframe[src*=youtube] {
  width: 100%;
  height: 450px;
}
.content-section .content-section__container .content-section__aside {
  @apply w-full md:w-2/5 lg:w-1/3;
}
.content-section .content-section__container .content-section__aside .side-element-card {
  @apply bg-grey mb-7 p-6 border rounded-md;
}
.content-section .content-section__container .content-section__aside .side-element-card .side-element-card__title {
  @apply text-2xl font-bold text-blue-dark mb-4;
}
.content-section .content-section__container .content-section__aside .side-element-card .words-of-wisdom {
  @apply mb-6;
}
.content-section .content-section__container .content-section__aside .side-element-card .words-of-wisdom .words-of-wisdom__title {
  @apply text-sm font-bold text-escgrey-dark uppercase mb-2;
}
.content-section .content-section__container .content-section__aside .side-element-card .words-of-wisdom p {
  @apply text-xs font-normal text-escgrey-dark;
}
.content-section .content-section__container .content-section__aside .side-element-card .best-for {
  @apply mb-6;
}
.content-section .content-section__container .content-section__aside .side-element-card .best-for .best-for__title {
  @apply text-sm font-bold text-escgrey-dark uppercase mb-2;
}
.content-section .content-section__container .content-section__aside .side-element-card .best-for p {
  @apply text-sm font-normal text-escgrey-dark;
}
.content-section .content-section__container .content-section__aside .side-element-card .esc-rating {
  @apply mb-6;
}
.content-section .content-section__container .content-section__aside .side-element-card .esc-rating .esc-rating__title {
  @apply text-sm font-bold text-escgrey-dark uppercase mb-2;
}
.content-section .content-section__container .content-section__aside .side-element-card .esc-rating .esc-rating-group {
  @apply flex items-center text-sm text-blue-dark;
}
.content-section .content-section__container .content-section__aside .side-element-card .esc-rating .esc-rating-group .esc-rating-icon-wrapper {
  @apply w-5 h-auto mr-1.5;
}
.content-section .content-section__container .content-section__aside .side-element-card .esc-rating .esc-rating-group .esc-rating-icon-wrapper .esc-rating-icon {
  @apply w-auto h-4;
}
.content-section .content-section__container .content-section__aside .side-element-card .esc-rating .esc-rating-group span {
  @apply mx-1;
}
.content-section .content-section__container .content-section__aside .side-element-card .esc-rating.text-center .esc-rating-group {
  @apply justify-center;
}
.content-section .content-section__container .content-section__aside .side-element-card .author,
.content-section .content-section__container .content-section__aside .side-element-card .company {
  @apply text-center mb-6;
}
.content-section .content-section__container .content-section__aside .side-element-card .author .author__logo-container,
.content-section .content-section__container .content-section__aside .side-element-card .author .company__logo-container,
.content-section .content-section__container .content-section__aside .side-element-card .company .author__logo-container,
.content-section .content-section__container .content-section__aside .side-element-card .company .company__logo-container {
  @apply w-14 h-14 flex justify-center items-center bg-white rounded-full border border-gray-200 mx-auto mb-2.5 overflow-hidden;
}
.content-section .content-section__container .content-section__aside .side-element-card .author .author__logo-container .author__logo .company__logo,
.content-section .content-section__container .content-section__aside .side-element-card .author .company__logo-container .author__logo .company__logo,
.content-section .content-section__container .content-section__aside .side-element-card .company .author__logo-container .author__logo .company__logo,
.content-section .content-section__container .content-section__aside .side-element-card .company .company__logo-container .author__logo .company__logo {
  @apply w-14 h-auto;
}
.content-section .content-section__container .content-section__aside .side-element-card .author .author__name,
.content-section .content-section__container .content-section__aside .side-element-card .author .company__title,
.content-section .content-section__container .content-section__aside .side-element-card .company .author__name,
.content-section .content-section__container .content-section__aside .side-element-card .company .company__title {
  @apply text-2xl font-blackout text-blue-dark mb-2.5;
}
.content-section .content-section__container .content-section__aside .side-element-card .author .author__title,
.content-section .content-section__container .content-section__aside .side-element-card .author .company__sub-title,
.content-section .content-section__container .content-section__aside .side-element-card .company .author__title,
.content-section .content-section__container .content-section__aside .side-element-card .company .company__sub-title {
  @apply text-base font-normal text-blue-dark mb-2.5;
}
.content-section .content-section__container .content-section__aside .side-element-card .author .author__bio,
.content-section .content-section__container .content-section__aside .side-element-card .author .company__Published,
.content-section .content-section__container .content-section__aside .side-element-card .company .author__bio,
.content-section .content-section__container .content-section__aside .side-element-card .company .company__Published {
  @apply text-xs font-normal text-blue-dark;
}
.content-section .content-section__container .content-section__aside .side-element-card .social-media {
  @apply flex justify-between items-center mt-5 py-2 px-4;
}
.content-section .content-section__container .content-section__aside .side-element-card .social-media li .icon-container {
  @apply flex justify-center w-6 cursor-pointer;
}

.takeaways {
  @apply bg-grey;
}
.takeaways .takeaway-cards {
  @apply flex flex-wrap container mx-auto;
}
.takeaways .takeaway-cards .takeaway-card {
  @apply w-full sm:w-1/2 lg:w-1/3 my-2 md:my-4 px-4;
}
.takeaways .takeaway-cards .takeaway-card .takeaway-card__inner {
  @apply h-full bg-white text-center p-6 rounded-md shadow-md;
}
.takeaways .takeaway-cards .takeaway-card .takeaway-card__inner .takeaway-card__img {
  @apply w-8 h-auto mb-6 mx-auto;
}
.takeaways .takeaway-cards .takeaway-card .takeaway-card__inner .takeaway-card__title {
  @apply text-2xl font-bold text-blue-dark leading-tight mb-4;
}
.takeaways .takeaway-cards .takeaway-card .takeaway-card__inner .takeaway-card__text {
  @apply text-sm font-normal text-grey-dark;
}

.download {
  @apply bg-blue-light px-4 py-8;
}
.download .download__container {
  @apply flex flex-col md:flex-row justify-between items-center container mx-auto md:px-4;
}
.download .download__container .download__group {
  @apply flex items-center w-full md:w-2/3 lg:w-1/2 mb-4 md:mb-0;
}
.download .download__container .download__group .download__group-img {
  @apply w-auto h-24 rounded mr-4;
}
.download .download__container .download__group .download__group-title {
  @apply text-xl md:text-2xl flex-1 font-blackout text-white;
}
.download .download__container .btn, .download .download__container .email-subscribe .email-subscribe__container .email-subscribe__button, .email-subscribe .email-subscribe__container .download .download__container .email-subscribe__button {
  @apply my-0;
}

.why-we-like-it .why-we-like-it__list {
  @apply flex flex-wrap px-4;
}
.why-we-like-it .why-we-like-it__list li {
  @apply relative w-full md:w-1/2 p-4 pl-6;
}
.why-we-like-it .why-we-like-it__list li:before {
  content: "";
  @apply absolute left-0 top-6 w-1.5 h-1.5 bg-blue-light rounded-full;
}

.hub-page .section:nth-child(odd) {
  @apply bg-grey;
}
.hub-page .section:nth-child(even) {
  @apply bg-white;
}
.hub-page .section-sidebar {
  @apply w-full lg:w-1/3;
}
.hub-page .section-sidebar .side-card {
  @apply bg-grey-medium border border-gray-200 rounded-md p-10 m-4;
}
.hub-page .section-sidebar .side-card .side-card-title {
  @apply font-blackout text-2xl text-blue-dark mb-4;
}
.hub-page .section-sidebar .side-card .side-card-text {
  @apply text-sm text-blue-dark;
}
.hub-page .hub-resource .section-body {
  @apply flex flex-col-reverse lg:flex-row;
}
.hub-page .hub-resource .section-body .resource-cards {
  @apply w-full lg:w-2/3;
}
.hub-page .hub-resource .section-body .resource-cards .resource-card-container {
  @apply w-full md:w-1/2;
}
.hub-page .hub-job .section-body {
  @apply flex flex-col-reverse lg:flex-row;
}
.hub-page .hub-job .section-body .job-cards {
  @apply w-full lg:w-2/3 mt-4 px-4;
}

.career-change-quiz {
  @apply pt-16 pb-4;
}
.career-change-quiz .career-change-quiz__iframe {
  @apply block w-screen h-screen border-none bg-white;
}

.hero--podcast {
  @apply bg-blue;
}
.hero--podcast .hero__container {
  @apply max-w-screen-lg h-[500px] sm:h-auto flex flex-col sm:flex-row justify-evenly;
}
.hero--podcast .hero__container .hero__inner.half {
  @apply h-full sm:h-auto py-2 sm:py-0;
}
.hero--podcast .hero__container .hero__inner.half .hero__body {
  @apply h-full flex flex-col justify-between;
}
.hero--podcast .hero__container .hero__inner.half .hero__body .hero__title {
  @apply text-[2.5rem] sm:text-[3.5rem];
}
.hero--podcast .hero__container .hero__inner.half .hero__body .hero__title span, .hero--podcast .hero__container .hero__inner.half .hero__body .hero__title b, .hero--podcast .hero__container .hero__inner.half .hero__body .hero__title strong {
  @apply text-yellow;
}
.hero--podcast .hero__container .hero__inner.half .hero__body .hero__body-bottom .hero__listen-now {
  @apply mt-4;
}
.hero--podcast .hero__container .hero__inner.half .hero__body .hero__body-bottom .hero__listen-now a {
  @apply flex items-center space-x-3;
}
.hero--podcast .hero__container .hero__inner.half .hero__body .hero__body-bottom .hero__listen-now a img {
  @apply w-9 h-9;
}
.hero--podcast .hero__container .hero__inner.half .hero__body .hero__body-bottom .hero__listen-now a p {
  @apply text-sm text-white;
}
.hero--podcast .hero__container .hero__inner.hero__inner-image {
  @apply absolute top-20 sm:relative sm:top-0;
}

.podcast .podcast__description {
  @apply bg-blue-light py-6;
}
.podcast .podcast__description .podcast__description-container {
  @apply container max-w-screen-lg mx-auto px-4;
}
.podcast .podcast__description .podcast__description-container p {
  @apply text-white text-base sm:text-lg mb-4;
}
.podcast .podcast__navigation {
  @apply mb-6 pb-4 overflow-y-auto;
}
.podcast .podcast__navigation .podcast__navigation-links {
  @apply flex space-x-8 sm:space-x-16 text-xl sm:text-2xl whitespace-nowrap;
}
.podcast .podcast__navigation .podcast__navigation-links .active {
  @apply font-bold;
}
.podcast .podcast-cards {
  @apply space-y-6 sm:space-y-8;
}
.podcast .podcast-cards .podcast-card {
  @apply bg-white relative flex flex-col sm:flex-row rounded-2xl overflow-hidden;
}
.podcast .podcast-cards .podcast-card .transcript {
  @apply absolute w-full h-full;
}
.podcast .podcast-cards .podcast-card .podcast-card__image {
  @apply w-full sm:w-72 h-60 sm:h-auto bg-blue-dark;
}
.podcast .podcast-cards .podcast-card .podcast-card__details {
  @apply flex-1 p-6 sm:p-8;
}
.podcast .podcast-cards .podcast-card .podcast-card__details .podcast-card__details-title,
.podcast .podcast-cards .podcast-card .podcast-card__details .podcast-card__details-name {
  @apply text-xl sm:text-2xl font-bold mb-2.5;
}
.podcast .podcast-cards .podcast-card .podcast-card__details .podcast-card__details-summary {
  @apply font-medium mb-6 sm:mb-8;
}
.podcast .podcast-cards .podcast-card .podcast-card__details .podcast-card__details-listen-now {
  @apply flex items-center space-x-4;
}
.podcast .podcast-cards .podcast-card .podcast-card__details .podcast-card__details-listen-now a {
  @apply z-10;
}
.podcast .podcast-cards .podcast-card .podcast-card__details .podcast-card__details-listen-now a img {
  @apply w-9 h-9;
}

.categories-page__intro {
  @apply bg-offwhite pt-8;
}
.categories-page__intro .container {
  @apply container mx-auto px-4;
}
.categories-page__intro .container h1 {
  @apply text-3xl text-blue-extradark font-bold;
}
.categories-page__intro .container p {
  @apply font-semibold text-xl mt-8;
}

.wl-header {
  @apply fixed top-0 left-0 w-full h-16 bg-white z-50;
}
.wl-header .wl-header__container {
  @apply flex justify-between items-center w-full h-full bg-white text-white px-4;
}
.wl-header .wl-header__container .wl-header-logo__container {
  @apply w-14 h-auto;
}
.wl-header .wl-header__container .wl-header-logo__container .wl-header-logo {
  @apply relative w-full h-auto fill-current text-white z-50;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu {
  @apply relative w-7 h-6 transform rotate-0 transition duration-500 ease-in-out cursor-pointer z-50;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu span {
  @apply block absolute left-0 h-0.5 w-full bg-black rounded-md opacity-100 transform rotate-0 transition duration-300 ease-in-out;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu span:nth-child(1) {
  @apply top-0;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu span:nth-child(2), .wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu span:nth-child(3) {
  @apply top-2.5;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu span:nth-child(4) {
  @apply top-5;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu.open span {
  @apply bg-white;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu.open span:nth-child(1), .wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu.open span:nth-child(4) {
  @apply top-5 w-0 left-1/2;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu.open span:nth-child(2) {
  @apply transform rotate-45;
}
.wl-header .wl-header__container .wl-header-nav .wl-hamburger-menu.open span:nth-child(3) {
  @apply transform -rotate-45;
}

.wl-menu {
  @apply fixed inset-0 bg-black pt-20 pb-28 px-4 z-40;
}
.wl-menu .wl-menu-list {
  @apply flex flex-col justify-center items-center w-full h-full;
}
.wl-menu .wl-menu-list li {
  @apply mb-10;
}
.wl-menu .wl-menu-list li:last-child {
  @apply mb-0;
}
.wl-menu .wl-menu-list li a {
  @apply relative text-3xl md:text-6xl hover:underline;
}
.wl-menu .wl-menu-footer {
  @apply absolute left-0 bottom-0 w-full py-6 md:py-10 text-center;
}
.wl-menu .wl-menu-footer a {
  @apply inline-flex flex-col-reverse md:flex-row items-center;
}
.wl-menu .wl-menu-footer a p {
  @apply text-sm md:text-base text-center;
}
.wl-menu .wl-menu-footer a .wl-logo {
  @apply w-auto h-6 md:h-9 mb-3 md:mb-0 md:ml-4;
}

.wl-footer {
  @apply relative w-full bg-blue-dark text-white text-sm mt-auto py-20;
}
.wl-footer .wl-footer__middle {
  @apply container mx-auto px-4 text-center;
}
.wl-footer .wl-footer__middle a {
  @apply inline-flex flex-col-reverse md:flex-row items-center;
}
.wl-footer .wl-footer__middle p {
  @apply text-base text-center;
}
.wl-footer .wl-footer__middle .wl-logo {
  @apply w-auto h-9 mb-4 md:mb-0 md:ml-4;
}