.methodology {

  .when-it-comes-to-work {
    @apply bg-yellow-medium;

    .section__container {
      @apply max-w-screen-md container mx-auto px-4 text-center;

      .section__title {
        @apply w-96 text-3xl font-blackout mb-5 mx-auto;
      }

      p {
        @apply text-lg mb-5;
  
        &:last-child {
          @apply mb-0;
        }
      }
    }
  }

  .our-criteria {

    .section__container {
      @apply max-w-screen-md container mx-auto px-4 text-center;

      .section__title {
        @apply text-3xl font-blackout font-normal text-blue-dark mb-3;
      }

      .section__sub-title {
        @apply text-xl font-normal text-blue-dark mb-10;
      }
      
      .criteria-list {
        @apply flex justify-around md:justify-between items-center flex-wrap mt-8;

        li {
          @apply flex flex-col justify-center items-center w-40 md:w-48 h-48;

          img {
            @apply mb-5 w-auto h-20;
          }
          a {
            @apply w-28 bg-red-esc text-sm text-white font-bold py-1 px-4 rounded-full cursor-pointer;
          }
        }
      }
    }
  }

  .sub-section {
    @apply pt-16 pb-0;

    &:last-child {
      @apply pb-24;
    }

    .sub-section__header {
      @apply relative bg-blue-dark mb-5 pt-2;

      &:after {
        content: '';
        @apply absolute -bottom-9 left-0 w-full bg-blue-dark h-10 -z-1;
        -webkit-clip-path: polygon(100% 0%, 100% 60%, 0% 10%);
        clip-path: polygon(100% 0%, 100% 60%, 0% 10%);
      }

      .sub-section__header-container {
        @apply relative container mx-auto px-4 flex justify-center;

        .sub-section__title {
          @apply text-3xl font-blackout font-normal text-white;
        }
  
        .sub-section__scroll-to-top {
          @apply absolute right-4 top-1 text-center text-sm text-white;
        }
      }
    }

    .sub-section__container {
      @apply max-w-screen-md container mx-auto mt-20 px-4;

      .sub-section__intro {
        @apply text-base text-center text-black font-normal mb-5;
      }
      .sub-section__title {
        @apply max-w-screen-sm text-2xl text-center text-blue font-blackout my-10 mx-auto;
      }
      .sub-section__scale-title {
        @apply text-2xl text-center text-blue font-blackout mt-10 mb-2.5;
      }
      .sub-section__scale-text {
        @apply text-base text-center text-black font-normal;
      }
      .sub-section__text {
        @apply text-base text-left text-escgrey-dark font-normal mb-5;
      }
      .sub-section__link {
        @apply text-blue font-bold;
      }

      input[range] {
        @apply bg-offwhite w-full h-4 outline-none rounded-md appearance-none cursor-pointer;
      }

      .ratings {
        @apply flex flex-col md:flex-row;

        .policy-cards,
        .employee-cards {
          @apply w-full mt-4 pr-2;

          .rating-cards__title {
            @apply text-base text-black uppercase mb-5 text-center;
          }

          .rating-card__container {
            @apply mb-4;

            input {
              @apply hidden;

              &:checked + .rating-card + .rating-card__desc {
                @apply max-h-full mt-2 py-4 opacity-100;
              }
              &:checked + .rating-card {
                @apply bg-blue-pale;

                .rating-card__title {
                  @apply font-bold;
                }
                .icon {
                  @apply transform rotate-90;
                }
              }
            }
            
            .rating-card {
              @apply relative flex flex-col bg-grey py-3 px-4 rounded-md cursor-pointer;

              .rating-card__type {
                @apply text-xs text-escgrey-dark uppercase mb-1 p-0 pr-8;
              }
              .rating-card__title {
                @apply text-sm uppercase p-0 pr-8;
              }
              .icon {
                @apply absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-auto transition-all duration-300 ease-in-out;
              }
            }

            .rating-card__desc {
              @apply bg-offwhite px-4 max-h-0 opacity-0 overflow-hidden transition-all duration-300 ease-in-out;

              p {
                @apply text-sm italic leading-relaxed;
              }
            }
          }
        }
        .employee-cards {
          @apply pr-0 pl-2;
        }
      }
    }
  }

  .methodology_slider {
    @apply w-11/12 h-4 border-none rounded-md bg-offwhite mx-auto my-8 cursor-pointer;

    .noUi-handle {
      @apply w-5 h-8 -top-2 bg-blue shadow-none;

      &:before,&:after {
        @apply hidden;
      }
    }
  }
  .methodology-slider-container {
    @apply mb-4;

    .methodology-slider-card {
      @apply bg-escgrey-light p-8;

      .methodology-slider-title {
        @apply font-blackout text-2xl text-blue-dark mb-4;
      }

      .methodology-slider-body {
        @apply text-base text-escgrey-dark;

        p,a {
          @apply mb-4;

          &:last-child {
            @apply mb-0;
          }
        }
        a {
          @apply text-blue font-bold underline cursor-pointer;
        }
        img {
          @apply block w-full h-auto mb-4;
        }
      }
    }
  }

  .the-escape-score-container {
    @apply w-full flex flex-wrap justify-center md:justify-between mt-8;

    .the-escape-score {
      @apply flex flex-col w-24 mt-6;

      .the-escape-score-header {
        @apply relative w-24 h-24;

        .the-escape-score-header__shape-1 {
          @apply absolute top-0 left-1/2 -translate-x-1/2 flex flex-col justify-center items-center w-20 h-16 bg-yellow z-10;
          clip-path: polygon(0% 0%, 100% 7%, 95% 99%, 3% 95%);

          p {
            @apply flex justify-center items-center font-blackout text-lg uppercase;

            &:last-child {
              @apply text-sm;
            }
          }
        }
        .the-escape-score-header__shape-2 {
          @apply absolute top-14 left-1/2 -translate-x-1/2 w-2.5 h-9 bg-yellow;
        }

        &.total {
          .the-escape-score-header__shape-1 {
            @apply bg-offwhite;

            p:first-child {
              @apply text-xl;
            }
          }
          .the-escape-score-header__shape-2 {
            @apply bg-escgrey-dark;
          }
        }
      }

      .the-escape-score-footer {
        @apply relative text-center mt-2;

        .the-escape-score-footer__title {
          @apply text-base text-blue-dark font-blackout;

          .info {
            @apply absolute -top-1 right-0;

            .icon {
              @apply w-3.5 h-3.5;
            }
          }
        }
      }
    }

    .the-escape-score__plus,
    .the-escape-score__equals {
      @apply flex justify-center items-center text-2xl font-bold text-escgrey-dark mb-4;
    }
  }
}
