.modal-join-prompt {
	@apply absolute bottom-0 w-full max-w-90 bg-white flex justify-center shadow-md rounded-t-md p-9 z-20 border-t-4 border-blue-light;
	min-height: 20rem;

	.modal-join-prompt__inner {
		@apply relative w-full max-w-screen-md flex flex-col md:flex-row;

		.modal-join-prompt__image {
			@apply mr-10 h-24 md:h-auto;
		}

		.modal-join-prompt__body {
			.modal-message__title {
				@apply font-blackout text-2xl text-blue mt-8;

				&:after {
					content: '';
					@apply w-20 block border-2 border-blue-light rounded mt-2;
				}
			}

			.modal-message__text {
				@apply font-blackout text-2xl md:text-3xl my-4;
			}

			.modal-message__login {
				@apply pt-4;

				a {
					@apply font-bold underline;
				}
			}
		}
	}
}
